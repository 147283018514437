import { useEffect, useState } from 'react'

import { Button, FormErrorsType, Input, Popup, Select } from '@/components'
import { InverterManufacturerEnum } from '@/enums'
import { InverterManufacturer, Product, Warranty } from '@/models'
import { FormChangeEventHandler, JsonDataType, IdType } from '@/types'

export interface BatteryFormDataType extends JsonDataType {
    id: IdType
    product_id: IdType
    size_kw: string | number
    serial_number: string
    product?: {
        id: IdType
        model_name: string
    }
}

type BatteryFormProps = {
    form: BatteryFormDataType
    products?: Product[]
    onChange: FormChangeEventHandler
    inputClassName?: string
    labelPrefix?: string
    errors: FormErrorsType
}

export const BatteryForm = ({
    form,
    inputClassName,
    products,
    errors,
    labelPrefix = '',
    ...props
}: BatteryFormProps) => {
    const [showDINGuide, swtShowDINGuide] = useState(false)
    const battery = products?.find(({ id }) => id === parseInt(form.product_id as string))
    const availableSizes = battery?.properties
        .find(item => item.property.name === 'available_sizes')?.value as string[] || []

    const capacityOptions = [{
        value: '',
        title: ''
    }, ...availableSizes.map((item: string) => ({
        value: parseFloat(item),
        title: Warranty.getField('size_kw')
            .format(item)
    }))]

    useEffect(() => {
        if (capacityOptions.every(({ value }) => value.toString() !== form.size_kw.toString())) {
            props.onChange({ target: { value: '', name: 'size_kw' } })
        }
    }, [form.product_id])

    const isTesla = !!battery?.model_name.toLowerCase().includes('tesla')

    const getSNLabel = () => isTesla ? 'Tesla DIN' : 'Serial Number'

    return <>
        <Select
            id="product_id"
            name="product_id"
            label={`${labelPrefix} Make & Model*`}
            options={[{ title: '', value: '' }, ...products?.map(({ id, model_name: title }) => ({
                value: id,
                title
            })) || []]}
            onChange={props.onChange}
            value={form.product_id}
            errors={errors.product_id}
            className={inputClassName}
            data-test="battery-product-id"
        />
        <Select
            id="size_kw"
            name="size_kw"
            label={`${labelPrefix} Capacity (kWh)*`}
            options={capacityOptions}
            onChange={props.onChange}
            value={form.size_kw}
            errors={errors.size_kw}
            disabled={!form.product_id}
            className={inputClassName}
            data-test="battery-size-kw"
        />
        <Input
            id="serial_number"
            name="serial_number"
            label={`${labelPrefix} ${getSNLabel()}`}
            onChange={props.onChange}
            value={form.serial_number}
            errors={errors.serial_number}
            disabled={!form.product_id}
            className={inputClassName}
            data-test="battery-serial-number"
            hint={isTesla &&
                <Button
                    design="btn-link"
                    type="button"
                    className="!text-primary-700"
                    onClick={() => swtShowDINGuide(true)}
                >
                    How to Find Tesla DIN
                </Button>}
        />

        {battery && <Popup open={showDINGuide} onClose={() => swtShowDINGuide(false)}>
            <h3 className="flex gap-3 items-center text-xl font-semibold">
                {InverterManufacturer.find(InverterManufacturerEnum.TESLA).getLogo()}
                How to Find Tesla DIN
            </h3>

            <div className="p-4 mt-2 text-sm text-gray-750 bg-primary-25">
                {InverterManufacturer.find(InverterManufacturerEnum.TESLA).serialNumberGuide}
            </div>
        </Popup>}
    </>
}
