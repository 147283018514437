import React, { useEffect, useState } from 'react'

import {
    Input,
    Select,
    FormErrorsType
} from '@/components'
import { api, isApiCancelError } from '@/services'
import { AddressDataType, FormChangeEvent, FormChangeEventHandler, StatesListType } from '@/types'

export interface SingleAddressFormDataType {
    address: string
}

export interface AddressFormDataType {
    street_address: string
    city: string
    state: string
    zip: string
}

type AddressFormProps = {
    form: SingleAddressFormDataType | AddressFormDataType
    inline?: boolean
    onChange: FormChangeEventHandler
    errors: FormErrorsType
    inputClassName?: string
    streetAddressIcon?: React.JSX.Element
    required?: boolean
}
export const AddressForm = ({
    form,
    errors,
    inputClassName,
    inline,
    streetAddressIcon,
    required,
    ...props
}: AddressFormProps) => {
    const [addresses, setAddresses] = useState<AddressDataType[]>([])
    const [states, setStates] = useState<StatesListType>({})
    const [abortController, setAbortController] = useState<AbortController | null>()

    const fetchStates = async () => {
        if (inline) return
        const res = await api.address.states()
        setStates(res)
    }

    useEffect(() => {
        fetchStates()
    }, [])

    const handleChange = async (e: FormChangeEvent) => {
        try {
            abortController?.abort('canceled')
            const controller = new AbortController
            setAbortController(controller)
            props.onChange(e)
            if (e.target.value) {
                const res = await api.address.autocomplete(e.target.value as string, { signal: controller.signal })
                setAddresses(res)
            } else {
                setAddresses([])
            }
        } catch (err) {
            if (!isApiCancelError(err)) {
                throw err
            }
        }
    }

    const handleSuggestionSelect = (index: number) => {
        const address = addresses[index]
        if (inline) {
            props.onChange({
                target: {
                    name: 'address',
                    value: `
${address.street_address} ${address.secondary_line || ''}
${address.city}, ${address.state} ${address.zip}
`
                }
            })
        } else {
            props.onChange({ target: { name: 'street_address', value: address.street_address } })
            props.onChange({ target: { name: 'city', value: address.city } })
            props.onChange({ target: { name: 'state', value: address.state } })
            props.onChange({ target: { name: 'zip', value: address.zip } })
        }
    }

    if (inline) return <Input
        id="address"
        name="address"
        errors={errors.address || []}
        className={inputClassName}
        label={`Address${required ? '*' : ''}`}
        placeholder="Address"
        onChange={handleChange}
        onSuggestionSelect={handleSuggestionSelect}
        value={(form as SingleAddressFormDataType).address}
        autoComplete="off"
        suggestions={addresses.map(item =>
            `${item.street_address} ${item.secondary_line || ''}${item.city}, ${item.state} ${item.zip}`)}
        postIcon={streetAddressIcon}
    />
    return <>
        <Input
            id="street_address"
            name="street_address"
            errors={errors.street_address || []}
            className={inputClassName}
            label={`Street Address${required ? '*' : ''}`}
            placeholder="Street Address"
            onChange={handleChange}
            onSuggestionSelect={handleSuggestionSelect}
            value={(form as AddressFormDataType).street_address}
            autoComplete="off"
            suggestions={addresses.map(item =>
                `${item.street_address} ${item.secondary_line || ''}${item.city}, ${item.state} ${item.zip}`)}
            postIcon={streetAddressIcon}
        />
        <Input
            id="city"
            name="city"
            errors={errors.city || []}
            className={inputClassName}
            label={`City${required ? '*' : ''}`}
            placeholder="City"
            onChange={props.onChange}
            value={(form as AddressFormDataType).city}
        />
        <Select
            id="state"
            name="state"
            errors={errors.state}
            className={inputClassName}
            label={`State${required ? '*' : ''}`}
            options={[{
                value: '',
                title: 'State'
            }, ...Object.keys(states).map(key => ({ value: key, title: states[key] }))]}
            disabled={!Object.keys(states).length}
            value={(form as AddressFormDataType).state}
            onChange={props.onChange}
        />
        <Input
            id="zip"
            name="zip"
            errors={errors.zip}
            className={inputClassName}
            label={`Zip Code${required ? '*' : ''}`}
            placeholder="Zip Code"
            onChange={props.onChange}
            value={(form as AddressFormDataType).zip}
        />
    </>
}
