import { useState } from 'react'
import { useNavigate } from 'react-router'

import { Button, ContactFormDataType, Form, FormErrorsType, Input } from '@/components'
import { useToastNotifications } from '@/hooks'
import { User } from '@/models'
import { isApiError } from '@/services'
import { FormChangeEvent } from '@/types'

const UserCreateForm = ({ role }: { role: string }) => {
    const navigate = useNavigate()
    const { success, error } = useToastNotifications()
    const [errors, setErrors] = useState<FormErrorsType>({})
    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState<ContactFormDataType>({
        name: '',
        email: '',
        role
    })

    const handleChange = (e: FormChangeEvent) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleSubmit = async () => {
        setErrors({})
        setProcessing(true)
        try {
            const res = await User.store(form)
            success('User was successfully created.')
            navigate(`/users/${res.data.id}`)
        } catch (err) {
            if (!isApiError(err)) throw err
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }
    return <Form className="lg:col-span-2 grid lg:grid-cols-2 gap-6" onSubmit={handleSubmit}>
        <Input
            id="name"
            name="name"
            label="Username*"
            className="col-span-1 col-start-1"
            placeholder="Username"
            onChange={handleChange}
            value={form.name}
            errors={errors.name}
        />
        <Input
            id="email"
            name="email"
            label="Main Company Email*"
            type="email"
            placeholder="Email"
            onChange={handleChange}
            value={form.email}
            errors={errors.email}
        />

        <div className="lg:col-span-2 flex justify-end">
            <Button type="button" design="btn-secondary-gray" hasError onClick={() => navigate(-1)}>
                Cancel
            </Button>
            <Button className="ml-3" processing={processing}>
                Create Account
            </Button>
        </div>
    </Form>
}

export default UserCreateForm
