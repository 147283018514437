export default class SessionStorage {
    static get isAvailable() {
        try {
            const testKey = 'test'
            const storage = window.sessionStorage
            storage.setItem(testKey, '1')
            storage.removeItem(testKey)
            return true
        } catch {
            return false
        }
    }

    static set(name: string, value: string | number | boolean | object | null) {
        if (!this.isAvailable) return

        let stringValue: string
        if (value === null) {
            stringValue = ''
        } else if (typeof value === 'object') {
            stringValue = JSON.stringify(value)
        } else {
            stringValue = String(value)
        }

        return sessionStorage.setItem(name, stringValue)
    }

    static get(name: string): string | object | null {
        if (!this.isAvailable) return null
        const value = sessionStorage.getItem(name) || ''
        try {
            return JSON.parse(value)
        } catch {
            return value
        }
    }

    static delete(name: string) {
        if (!this.isAvailable) return
        return sessionStorage.removeItem(name)
    }
}
