import { Icon, IconProps } from './Icon'

export const IconHamburgerMenu = (props: IconProps) =>
    <Icon {...props}>
        <rect
            x="3"
            y="6"
            width="18"
            height="2"
            rx=".5"
        />
        <rect
            x="3"
            y="12"
            width="18"
            height="2"
            rx=".5"
        />
        <rect
            x="3"
            y="18"
            width="18"
            height="2"
            rx=".5"
        />
    </Icon>
