import {
    Button,
    IconAlertTriangle, IconBatteryCharging2,
    IconBatteryEmpty, IconBatteryLow,
    IconCheckCircle, IconClockSnoozed, IconFileCheck01, IconHelpCircle,
    IconLightning02, IconLoading01, IconMicroInverter,
    IconModem, IconPasscode, IconPasscodeLock, IconSearch, IconSearchFile01, IconSlashCircle01, IconSliders,
    IconSpeedometer, IconTool01,
    IconWifiOff
} from '@/components'
import { IconSize } from '@/components/icons/Icon'
import { SystemStatusDataType } from '@/types'

export enum SystemStatusEnum {
    // Tier 1
    POWER = 'power',
    IMPACT_ALERT_LEVEL_9 = 'impact_alert_level_9',
    IMPACT_ALERT_LEVEL_8 = 'impact_alert_level_8',
    IMPACT_ALERT_LEVEL_7 = 'impact_alert_level_7',
    METER_ISSUE = 'meter_issue',
    STORAGE_IDLE = 'storage_idle',
    GATEWAY_CRITICAL = 'gateway_critical',
    NEVER_CONNECTED = 'never_connected',
    COMPONENT_ERROR = 'component_error',

    // Tier 2
    IMPACT_ALERT_LEVEL_6 = 'impact_alert_level_6',
    IMPACT_ALERT_LEVEL_5 = 'impact_alert_level_5',
    IMPACT_ALERT_LEVEL_4 = 'impact_alert_level_4',
    MICRO = 'micro',
    ENCHARGE_ISSUE = 'encharge_issue',
    SYSTEM = 'system',
    ENPOWER_ISSUE = 'enpower_issue',
    GATEWAY = 'gateway',
    SYSTEM_DISCONNECTED = 'system_disconnected',

    // Tier 3
    IMPACT_ALERT_LEVEL_3 = 'impact_alert_level_3',
    IMPACT_ALERT_LEVEL_2 = 'impact_alert_level_2',
    IMPACT_ALERT_LEVEL_1 = 'impact_alert_level_1',
    BATTERY = 'battery',
    ENCHARGE = 'encharge',
    METER = 'meter',
    ENPOWER = 'enpower',
    ACTIVATION = 'activation',
    UNKNOWN_STATUS = 'unknown_status',

    // Normal
    NORMAL = 'normal',

    // Unmonitored
    PENDING = 'pending',
    DELAYED = 'delayed',
    SYSTEM_NOT_FOUND = 'system_not_found',

    // Excluded
    AUTH_ERROR = 'auth_error',
    UNKNOWN_MANUFACTURER = 'unknown_manufacturer',
    INTEGRATION_UPDATE_REQUIRED = 'integration_update_required',
    MISSING_INTEGRATION = 'missing_integration',

    UNKNOWN = 'unknown'
}

export const SYSTEM_STATUSES_MONITORED_TIER_1: SystemStatusDataType[] = [
    {
        value: SystemStatusEnum.POWER,
        title: 'Production Issue',
        refreshable: true,
        icon: (size: IconSize) => <IconLightning02 size={size}/>,
        secondaryOrder: 1,
        description: 'System production is below expected levels, indicating potential hardware issues or setting' +
            ' inaccuracies.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of Production issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase
                    Enlighten Portal </span>at <a
                        href="https://enlighten.enphaseenergy.com/"
                        target="_blank"
                        rel="noreferrer"
                    >https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the
                    homeowner&apos;s site from the <span className="font-semibold">systems
                    list,</span> and <span className="font-semibold">navigate</span> to
                    the <span className="font-semibold">Devices tab</span> for detailed information.
                </li>
                <li>
                    <span className="font-semibold">Look for</span> Microinverters with
                    a <span className="font-semibold">Production Issue</span>, which will be indicated by a flag.
                </li>
                <li>
                    <span className="font-semibold">Click</span> the <span className="font-semibold">status
                    link</span> of a specific microinverter to view its current issues.
                </li>
                <li>
                    For additional support, <span className="font-semibold">contact </span>
                    <span className="font-semibold">Enphase Customer Support</span> for detailed troubleshooting steps.
                </li>
                <li>
                    If no microinverters with production issues appear in the list, consider checking the
                    system&apos;s <span className="font-semibold">Production Threshold settings</span>. They might be
                    set too conservatively.
                </li>
                <li>
                    <span className="font-semibold">Assess</span> recent weather conditions in the area, as unusual
                    weather can trigger production issues by causing actual system production to fall below estimated
                    production, even when the system is working properly.
                </li>
                <li>
                    If weather frequently triggers false production issues, you may need
                    to <span className="font-semibold">adjust the Production Threshold</span> lower than the current
                    estimate.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_9,
        title: 'High Impact Alert',
        refreshable: true,
        icon: () => 9,
        secondaryOrder: 5,
        description: 'Critical alert with maximum severity score, indicating substantial energy loss or system risk.' +
            ' For detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves' +
            ' the  Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of Impact Alert Level 9 issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge
                    Monitoring Portal</span> at <a
                        href="https://monitoring.solaredge.com/solaredge-web/p/login"
                        target="_blank"
                        rel="noreferrer"
                    >https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the &apos;
                    <span className="font-semibold">Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the &apos;
                    <span className="font-semibold">Alerts&apos; tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked
                    Alert type</span> in the &apos;
                    <span className="font-semibold">Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting
                    tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact</span>
                    <span className="font-semibold">SolarEdge support</span> for additional assistance if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_8,
        title: 'High Impact Alert',
        refreshable: true,
        secondaryOrder: 6,
        icon: () => 8,
        description: 'Urgent alert with high severity score, denoting significant risk and requiring immediate' +
            ' attention. For detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only' +
            ' retrieves the Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of Impact Alert Level 8 issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge
                    Monitoring Portal</span> at <a
                        href="https://monitoring.solaredge.com/solaredge-web/p/login"
                        target="_blank"
                        rel="noreferrer"
                    >https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the &apos;
                    <span className="font-semibold">Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the &apos;
                    <span className="font-semibold">Alerts&apos; tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked
                    Alert type</span> in the &apos;<span className="font-semibold">Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting
                    tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact</span>
                    <span className="font-semibold">SolarEdge support</span> for additional assistance if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_7,
        title: 'High Impact Alert',
        refreshable: true,
        secondaryOrder: 7,
        icon: () => 7,
        description: 'Serious alert with elevated impact score, signifying notable energy loss or hazard. For' +
            ' detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves the' +
            ' Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of Impact Alert Level 7 issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge
                    Monitoring Portal</span> at <a
                        href="https://monitoring.solaredge.com/solaredge-web/p/login"
                        target="_blank"
                        rel="noreferrer">https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the &apos;
                    <span className="font-semibold">Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the &apos;
                    <span className="font-semibold">Alerts&apos; tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked
                    Alert type</span> in the &apos;<span className="font-semibold">Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting
                    tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact</span>
                    <span className="font-semibold">SolarEdge support</span> for additional assistance if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.METER_ISSUE,
        title: 'Meter Issue',
        refreshable: true,
        secondaryOrder: 2,
        icon: (size: IconSize) => <IconSpeedometer size={size}/>,
        description: 'Possible problem with measurement equipment, likely affecting data accuracy and requiring' +
            ' on-site maintenance.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase
                    Enlighten Portal</span> at <a
                        href="https://enlighten.enphaseenergy.com/"
                        target="_blank"
                        rel="noreferrer"
                    >https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the
                    homeowner&apos;s site from the <span className="font-semibold">systems
                    list,</span> and <span className="font-semibold">navigate</span> to
                    the <span className="font-semibold">Devices tab</span> for detailed information.
                </li>
                <li>
                    Check if the issue is with <span className="font-semibold">CT Measurements</span> (negative
                    production or consumption readings) or <span className="font-semibold">Storage
                    Measurements</span> (storage activity not reflected).
                </li>
                <li>
                    For CT issues, <span className="font-semibold">inspect</span> <span className="font-semibold">CT
                    orientation and leads</span>. Compare with voltage measurements using your regional troubleshooting
                    guide.
                </li>
                <li>
                    For Storage issues, <span className="font-semibold">verify wiring</span> to include AC Batteries in
                    both production and consumption circuits.
                </li>
                <li>
                    The <span className="font-semibold">Meter Issue status</span> will update after 24 hours of
                    corrected data.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.STORAGE_IDLE,
        title: 'Battery Storage Inactive',
        refreshable: true,
        secondaryOrder: 3,
        icon: (size: IconSize) => <IconBatteryEmpty size={size}/>,
        description: 'The AC Battery(ies) charging state has not changed in 72+ hours.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase
                    Enlighten Portal</span> at <a
                        href="https://enlighten.enphaseenergy.com/"
                        target="_blank"
                        rel="noreferrer"
                    >https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the
                    homeowner&apos;s site from the <span className="font-semibold">systems
                    list,</span> and <span className="font-semibold">navigate</span> to
                    the <span className="font-semibold">Devices tab</span> for detailed information.
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">status
                    link</span> of the specific device in question.
                </li>
                <li>
                    <span className="font-semibold">View</span> the <span className="font-semibold">current
                    issues</span> and <span className="font-semibold">recommended actions</span> for that device.
                </li>
                <li>
                    For more detailed instructions, refer to the relevant <span className="font-semibold">Installation
                    and Operation Manual</span>.
                </li>
                <li>
                    If you require additional support, <span className="font-semibold">contact Customer Support</span>.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.GATEWAY_CRITICAL,
        title: 'Gateway Critical',
        refreshable: true,
        secondaryOrder: 4,
        icon: (size: IconSize) => <IconModem size={size}/>,
        description: 'The gateway stopped reporting more than 8 days ago, indicating possible connectivity or' +
            ' hardware problems. Since gateway issues extending beyond a week usually point to serious, ongoing' +
            ' component malfunctions or necessary changes in the connection setup, these are critical concerns that' +
            ' demand prompt manual intervention.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-error-300 mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Determine</span> your connection
                    type: <span className="font-semibold">Wi-Fi,
                    Ethernet,</span> or <span className="font-semibold">Cellular</span>.
                </li>
                <li>
                    For Wi-Fi: <span className="font-semibold">Verify</span> if any changes were made to
                    your <span className="font-semibold">ISP, network
                    password,</span> or <span className="font-semibold">network name</span>.
                </li>
                <li>
                    For Ethernet: <span className="font-semibold">Reconnect</span> the ethernet cable to restore
                    communication.
                </li>
                <li>
                    For Cellular: <span className="font-semibold">Locate</span> the Multi-Tech
                    modem, <span className="font-semibold">unplug</span> the USB
                    cable, <span className="font-semibold">power off and on</span> the Gateway, ensure the modem antenna
                    is vertical, and then <span className="font-semibold">reconnect</span> the USB.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.NEVER_CONNECTED,
        title: 'Never Connected',
        refreshable: true,
        secondaryOrder: 8,
        icon: (size: IconSize) => <IconWifiOff size={size}/>,
        description: 'One or more systems have been registered but not yet internet connected.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-error-300 mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Determine</span> your connection
                    type: <span className="font-semibold">Wi-Fi,
                    Ethernet,</span> or <span className="font-semibold">Cellular</span>.
                </li>
                <li>
                    For Wi-Fi: <span className="font-semibold">Verify</span> if any changes were made to
                    your <span className="font-semibold">ISP, network
                    password,</span> or <span className="font-semibold">network name</span>.
                </li>
                <li>
                    For Ethernet: <span className="font-semibold">Reconnect</span> the ethernet cable to restore
                    communication.
                </li>
                <li>
                    For Cellular: <span className="font-semibold">Locate</span> the Multi-Tech
                    modem, <span className="font-semibold">unplug</span> the USB
                    cable, <span className="font-semibold">power off and on</span> the Gateway, ensure the modem antenna
                    is vertical, and then <span className="font-semibold">reconnect</span> the USB.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.COMPONENT_ERROR,
        title: 'Component Error',
        refreshable: true,
        secondaryOrder: 9,
        icon: (size: IconSize) => <IconTool01 size={size}/>,
        description: 'A malfunction has been identified in one or more key components: the Inverter, PVL, or Battery.' +
            ' To diagnose and address this issue, please refer to the Device Details page for specific error codes.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-error-300 mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Determine</span> your connection
                    type: <span className="font-semibold">Wi-Fi,
                    Ethernet,</span> or <span className="font-semibold">Cellular</span>.
                </li>
                <li>
                    For Wi-Fi: <span className="font-semibold">Verify</span> if any changes were made to
                    your <span className="font-semibold">ISP, network
                    password,</span> or <span className="font-semibold">network name</span>.
                </li>
                <li>
                    For Ethernet: <span className="font-semibold">Reconnect</span> the ethernet cable to restore
                    communication.
                </li>
                <li>
                    For Cellular: <span className="font-semibold">Locate</span> the Multi-Tech
                    modem, <span className="font-semibold">unplug</span> the USB
                    cable, <span className="font-semibold">power off and on</span> the Gateway, ensure the modem antenna
                    is vertical, and then <span className="font-semibold">reconnect</span> the USB.
                </li>
            </ol>
        </div>
    }
]

export const SYSTEM_STATUSES_MONITORED_TIER_2: SystemStatusDataType[] = [
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_6,
        title: 'Medium Impact Alert - Level 6',
        refreshable: true,
        secondaryOrder: 6,
        icon: () => 6,
        description: 'Alert with medium severity score, suggesting a moderate risk that may escalate without action.' +
            ' For detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves' +
            ' the Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="list-decimal pl-5 mt-4">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge
                    Monitoring Portal</span> at <a
                        href="https://monitoring.solaredge.com/solaredge-web/p/login"
                        target="_blank"
                        rel="noreferrer"
                    >https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in
                    the <span className="font-semibold">&apos;Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to
                    the <span className="font-semibold">&apos;Alerts&apos; tab.</span>
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked
                    Alert type</span> in the <span className="font-semibold">&apos;Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting
                    tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact SolarEdge support</span> for additional assistance if
                    needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_5,
        title: 'Medium Impact Alert - Level 5',
        refreshable: true,
        secondaryOrder: 7,
        icon: () => 5,
        description: 'Noticeable alert requiring attention, with medium impact on system performance. For detailed' +
            ' information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves the Impact' +
            ' Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="list-decimal pl-5 mt-4">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge
                    Monitoring Portal</span> at <a
                        href="https://monitoring.solaredge.com/solaredge-web/p/login"
                        target="_blank"
                        rel="noreferrer"
                    >https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in
                    the <span className="font-semibold">&apos;Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to
                    the <span className="font-semibold">&apos;Alerts&apos; tab.</span>
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked
                    Alert type</span> in the <span className="font-semibold">&apos;Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting
                    tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact SolarEdge support</span> for additional assistance if
                    needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_4,
        title: 'Medium Impact Alert - Level 4',
        refreshable: true,
        secondaryOrder: 8,
        icon: () => 4,
        description: 'Alert indicating a moderate issue, needs monitoring to avoid further system impact. For' +
            ' detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves the' +
            ' Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="list-decimal pl-5 mt-4">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge
                    Monitoring Portal</span> at <a
                        href="https://monitoring.solaredge.com/solaredge-web/p/login"
                        target="_blank"
                        rel="noreferrer"
                    >https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in
                    the <span className="font-semibold">&apos;Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to
                    the <span className="font-semibold">&apos;Alerts&apos; tab.</span>
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked
                    Alert type</span> in the <span className="font-semibold">&apos;Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting
                    tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact SolarEdge support</span> for additional assistance if
                    needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.MICRO,
        title: 'Microinverters Not Reporting',
        refreshable: true,
        secondaryOrder: 1,
        icon: (size: IconSize) => <IconMicroInverter size={size}/>,
        description: 'One or more microinverters are not communicating, potentially due to range or interference' +
            ' issues. This can usually be resolved remotely.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="list-decimal pl-5 mt-4">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase
                    Enlighten Portal</span> at <a
                        href="https://enlighten.enphaseenergy.com/"
                        target="_blank"
                        rel="noreferrer"
                    >https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the
                    homeowner&apos;s site from the <span className="font-semibold">systems
                    list</span>, and <span className="font-semibold">navigate</span> to
                    the <span className="font-semibold">Devices tab</span> to investigate site details.
                </li>
                <li>
                    <span className="font-semibold">Ensure</span> the Gateway is on
                    a <span className="font-semibold">dedicated circuit</span> near the electrical panel without
                    electrical interference.
                </li>
                <li>
                    <span className="font-semibold">Disconnect</span> devices on the same outlet such
                    as <span className="font-semibold">power strips, UPS units, touch
                    lamps,</span> and <span className="font-semibold">battery chargers</span>.
                </li>
                <li>
                    <span className="font-semibold">Relocate</span> heavy-duty appliances and workshop equipment to
                    other circuits.
                </li>
                <li>
                    <span className="font-semibold">Remove</span> any older CFLs to reduce interference and check if
                    reporting resumes.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.ENCHARGE_ISSUE,
        title: 'Battery Issue',
        refreshable: true,
        secondaryOrder: 2,
        icon: (size: IconSize) => <IconBatteryLow size={size}/>,
        description: 'Detected problem with battery, needs checking to ensure proper storage performance.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase
                    Enlighten Portal</span> at <a
                        href="https://enlighten.enphaseenergy.com/"
                        target="_blank"
                        rel="noreferrer"
                    >https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the
                    homeowner&apos;s site from the <span className="font-semibold">systems list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the <span className="font-semibold">Events
                    Tab.</span>
                </li>
                <li>
                    <span className="font-semibold">Investigate</span> battery-related issues
                    by <span className="font-semibold">clicking</span> on the <span className="font-semibold">event
                    name.</span>
                </li>
                <li>
                    <span className="font-semibold">Follow</span> the recommended actions from Enphase for resolution.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.SYSTEM,
        title: 'System Issue',
        secondaryOrder: 3,
        icon: (size: IconSize) => <IconAlertTriangle size={size}/>,
        description: 'General alert for an undefined system problem that requires further investigation.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase
                    Enlighten Portal</span> at <a
                        href="https://enlighten.enphaseenergy.com/"
                        target="_blank"
                        rel="noreferrer"
                    >https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the
                    homeowner&apos;s site from the <span className="font-semibold">systems
                    list</span> and <span className="font-semibold">navigate</span> to
                    the <span className="font-semibold">Events tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Check</span> for any non-normal statuses on
                    the <span className="font-semibold">Devices tab.</span>
                </li>
                <li>
                    <span className="font-semibold">Take note</span> of <span className="font-semibold">system
                    alerts</span> and follow up with recommended actions.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.ENPOWER_ISSUE,
        title: 'System Controller Issue',
        refreshable: true,
        secondaryOrder: 4,
        icon: (size: IconSize) => <IconSliders size={size}/>,
        description: 'The system control unit is reporting a malfunction, needs attention to maintain system' +
            ' integrity.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase
                    Enlighten Portal</span> at <a
                        href="https://enlighten.enphaseenergy.com/"
                        target="_blank"
                        rel="noreferrer"
                    >https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the
                    homeowner&apos;s site from the <span className="font-semibold">systems list</span>.
                </li>
                <li>
                    <span className="font-semibold">Go</span> to the <span className="font-semibold">Events Tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Look into</span> issues related to system controllers
                    by <span className="font-semibold">selecting</span> the <span className="font-semibold">event
                    name</span>.
                </li>
                <li>
                    <span className="font-semibold">Apply</span> the recommended actions provided by Enphase.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.GATEWAY,
        title: 'Gateway Non-Critical Issue',
        refreshable: true,
        secondaryOrder: 5,
        icon: (size: IconSize) => <IconModem size={size}/>,
        description: 'Assigned to gateway issues detected within the last 7 days. Gateway issues typically become' +
            ' critical if they persist for more than a week, suggesting ongoing component problems or alterations in' +
            ' the connection setup that require manual resolution.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase
                    Enlighten Portal</span> at <a
                        href="https://enlighten.enphaseenergy.com/"
                        target="_blank"
                        rel="noreferrer"
                    >https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the
                    homeowner&apos;s site from the <span className="font-semibold">systems list</span>.
                </li>

                <li>
                    <span className="font-semibold">Navigate</span> to
                    the<span className="font-semibold"> Devices</span> or <span className="font-semibold">Communications
                    tab</span> to identify Gateway warnings.
                </li>
                <li>
                    Inspect any <span className="font-semibold">alerts or notifications</span> related to the Gateway.
                </li>
                <li>
                    <span className="font-semibold">Check</span> the Gateway&apos;s physical connections and power
                    source for any disruptions.
                </li>
                <li>
                    <span className="font-semibold">If the issue persists, reset</span> the Gateway by turning it off
                    and on again.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">Installation and
                    Operation Manual</span> for troubleshooting non-critical issues.
                </li>
                <li>
                    For persistent problems, <span className="font-semibold">contact Enphase Customer Support</span> to
                    resolve the issue without affecting your system’s operation.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.SYSTEM_DISCONNECTED,
        title: 'System Disconnected',
        refreshable: true,
        secondaryOrder: 9,
        icon: (size: IconSize) => <IconWifiOff size={size}/>,
        description: 'One or more systems in this site have temporarily lost internet connectivity.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Determine</span> your connection
                    type: <span className="font-semibold">Wi-Fi,
                    Ethernet,</span> or <span className="font-semibold">Cellular</span>.
                </li>
                <li>
                    For Wi-Fi: <span className="font-semibold">Verify</span> if any changes were made to
                    your <span className="font-semibold">ISP, network
                    password,</span> or <span className="font-semibold">network name</span>.
                </li>
                <li>
                    For Ethernet: <span className="font-semibold">Reconnect</span> the ethernet cable to restore
                    communication.
                </li>
                <li>
                    For Cellular: <span className="font-semibold">Locate</span> the Multi-Tech
                    modem, <span className="font-semibold">unplug</span> the USB
                    cable, <span className="font-semibold">power off and on</span> the Gateway, ensure the modem antenna
                    is vertical, and then <span className="font-semibold">reconnect</span> the USB.
                </li>
            </ol>
        </div>
    }
]

export const SYSTEM_STATUSES_MONITORED_TIER_3: SystemStatusDataType[] = [
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_3,
        title: 'Low Impact Alert - Level 3',
        refreshable: true,
        secondaryOrder: 6,
        description: 'Low-tier alert, indicates a minor issue that should be addressed to maintain system efficiency.' +
            ' For detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only' +
            ' retrieves the Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        icon: () => 3,
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge
                    Monitoring Portal</span> at <a
                        href="https://monitoring.solaredge.com/solaredge-web/p/login"
                        target="_blank"
                        rel="noreferrer"
                    >https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the &apos;
                    <span className="font-semibold">Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the &apos;
                    <span className="font-semibold">Alerts&apos; tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked
                    Alert type</span> in the &apos;
                    <span className="font-semibold">Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting
                    tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact</span>
                    <span className="font-semibold">SolarEdge support</span> for additional assistance if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_2,
        title: 'Low Impact Alert - Level 2',
        refreshable: true,
        icon: () => 2,
        secondaryOrder: 7,
        description: 'Alert signifies a small impact issue, low priority but warrants monitoring. For detailed' +
            ' information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves the Impact' +
            ' Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge
                    Monitoring Portal</span> at <a
                        href="https://monitoring.solaredge.com/solaredge-web/p/login"
                        target="_blank"
                        rel="noreferrer"
                    >https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the &apos;
                    <span className="font-semibold">Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the &apos;
                    <span className="font-semibold">Alerts&apos; tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked
                    Alert type</span> in the &apos;
                    <span className="font-semibold">Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting
                    tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact</span>
                    <span className="font-semibold">SolarEdge support</span> for additional assistance if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_1,
        title: 'Low Impact Alert - Level 1',
        refreshable: true,
        icon: () => 1,
        secondaryOrder: 8,
        description: 'Minimal impact alert, indicating very low risk, to be addressed in routine maintenance checks.' +
            ' For detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves' +
            ' the Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge
                    Monitoring Portal</span> at <a
                        href="https://monitoring.solaredge.com/solaredge-web/p/login"
                        target="_blank"
                        rel="noreferrer"
                    >https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the &apos;
                    <span className="font-semibold">Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the &apos;
                    <span className="font-semibold">Alerts&apos; tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked
                    Alert type</span> in the &apos;
                    <span className="font-semibold">Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting
                    tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact</span>
                    <span className="font-semibold">SolarEdge support</span> for additional assistance if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.BATTERY,
        title: 'AC Batteries Not Reporting',
        refreshable: true,
        secondaryOrder: 1,
        icon: (size: IconSize) => <IconBatteryCharging2 size={size}/>,
        description: 'AC battery units are not communicating, possibly due to interference or connection issues.This' +
            ' can usually be addressed by the system owner and should not require and on-site service visit.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase
                    Enlighten Portal</span> at <a
                        href="https://enlighten.enphaseenergy.com/"
                        target="_blank"
                        rel="noreferrer"
                    >https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the
                    homeowner&apos;s site from the <span className="font-semibold">systems
                    list</span>, and <span className="font-semibold">navigate</span> to
                    the <span className="font-semibold">Devices tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Verify</span> no interfering devices are on the same circuit as the
                    Gateway.
                </li>
                <li>
                    <span className="font-semibold">Remove</span> potential sources of interference, such as CFLs.
                </li>
                <li>
                    <span className="font-semibold">Check</span> the AC Battery status on
                    the <span className="font-semibold">Devices</span> list if issues persist.
                </li>
                <li>
                    <span className="font-semibold">Consult</span> the <span className="font-semibold">Enphase AC
                    Battery Manual</span> or <span className="font-semibold">contact Customer Support</span> if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.ENCHARGE,
        title: 'Battery Not Reporting',
        refreshable: true,
        secondaryOrder: 2,
        icon: (size: IconSize) => <IconBatteryCharging2 size={size}/>,
        description: 'Battery disconnected and not reporting data. Momentary disruptions in communication due to' +
            ' changes in environmental variables are normal.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase
                    Enlighten Portal</span> at <a
                        href="https://enlighten.enphaseenergy.com/"
                        target="_blank"
                        rel="noreferrer"
                    >https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the
                    homeowner&apos;s site from the <span className="font-semibold">systems
                    list</span>, and <span className="font-semibold">navigate</span> to
                    the <span className="font-semibold">Devices tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Wait</span> 15 minutes for potential self-diagnosis and recovery.
                </li>
                <li>
                    <span className="font-semibold">Check</span> the Battery&apos;s State of Charge; allow 24 hours if
                    recently depleted.
                </li>
                <li>
                    <span className="font-semibold">Reset</span> the Zigbee communication kit if necessary.
                </li>
                <li>
                    <span className="font-semibold">Contact Enphase Support</span> for unresolved issues.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.METER,
        title: 'Meter Not Reporting',
        refreshable: true,
        secondaryOrder: 3,
        icon: (size: IconSize) => <IconSpeedometer size={size}/>,
        description: 'The system has a revenue grade meter (RGM) that has stopped communicating with the gateway.' +
            ' Occasional instances of this message may be ignored, as a temporary interference to the radio signal' +
            ' may cause this condition.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase
                    Enlighten Portal</span> at <a
                        href="https://enlighten.enphaseenergy.com/"
                        target="_blank"
                        rel="noreferrer"
                    >https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the
                    homeowner&apos;s site from the <span className="font-semibold">systems
                    list</span>, and <span className="font-semibold">navigate</span> to
                    the <span className="font-semibold">Devices tab</span> to see device details.
                </li>
                <li>
                    <span className="font-semibold">Work with</span> the homeowner to remotely confirm if the USB ZigBee
                    radio stick is properly inserted.
                </li>
                <li>
                    <span className="font-semibold">Power cycle</span> the Gateway
                    and <span className="font-semibold">check system status</span> after 15 minutes.
                </li>
                <li>
                    <span className="font-semibold">Install</span> a repeater if signal issues persist, per
                    the <span className="font-semibold">RGM Manual</span>.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.ENPOWER,
        title: 'System Controller Not Reporting',
        refreshable: true,
        secondaryOrder: 4,
        icon: (size: IconSize) => <IconSliders size={size}/>,
        description: 'The system controller is offline. Momentary disruptions in communication are normal and are' +
            ' seen due to changes in environmental variables.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase
                    Enlighten Portal</span> at <a
                        href="https://enlighten.enphaseenergy.com/"
                        target="_blank"
                        rel="noreferrer"
                    >https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the
                    homeowner&apos;s site from the <span className="font-semibold">systems list</span>.
                </li>
                <li>
                    <span className="font-semibold">Go</span> to the <span className="font-semibold">Events Tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Look into</span> issues related to system controllers
                    by <span className="font-semibold">selecting</span> the <span className="font-semibold">event
                    name</span>.
                </li>
                <li>
                    <span className="font-semibold">Apply</span> the recommended actions provided by Enphase.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.ACTIVATION,
        title: 'Activation Incomplete',
        refreshable: true,
        secondaryOrder: 5,
        icon: (size: IconSize) => <IconFileCheck01 size={size}/>,
        description: 'System activation is not fully completed and may need additional setup or configuration steps.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <span className="font-semibold block mt-2">For Enphase systems,</span>
            <ol className="mt-4 mb-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase
                    Enlighten Portal</span> at <a
                        href="https://enlighten.enphaseenergy.com/"
                        target="_blank"
                        rel="noreferrer"
                    >https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the
                    homeowner&apos;s site from the <span className="font-semibold">systems
                    list</span>, and <span className="font-semibold">navigate</span> to
                    the <span className="font-semibold">Activations tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Confirm</span> all four stages of system activation in Enlighten
                    have been completed.
                </li>
                <li>
                    <span className="font-semibold">Revisit</span> each stage to ensure all checks are cleared.
                </li>
                <li>
                    <span className="font-semibold">Contact Enphase support</span> for additional assistance if needed.
                </li>
            </ol>
            <span className="font-semibold">For SolarEdge Systems, </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log</span> into the <span className="font-semibold">SolarEdge
                    Monitoring Portal</span> at <a
                        href="https://monitoring.solaredge.com/solaredge-web/p/login"
                        target="_blank"
                        rel="noreferrer"
                    >https://monitoring.solaredge.com/solaredge-web/p/login</a>..
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in
                    the <span className="font-semibold">&apos;Sites&apos; list.</span>
                </li>
                <li>
                    <span className="font-semibold">A Pending status</span> usually indicates that the system has not
                    achieved PTO. <span className="font-semibold">Confirm</span> site connection details.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.UNKNOWN_STATUS,
        title: 'Unknown Status',
        secondaryOrder: 9,
        refreshable: true,
        icon: (size: IconSize) => <IconHelpCircle size={size}/>,
        description: 'This status is assigned when the system status is not known. Log into Generac for more details',
        troubleshooting: <div className="font-normal text-sm p-3 bg-error-300 mt-6 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <span className="font-semibold block mt-2">For Enphase systems,</span>
            <ol className="mt-4 mb-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase
                    Enlighten Portal</span> at <a
                        href="https://enlighten.enphaseenergy.com/"
                        target="_blank"
                        rel="noreferrer"
                    >https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the
                    homeowner&apos;s site from the <span className="font-semibold">systems
                    list</span>, and <span className="font-semibold">navigate</span> to
                    the <span className="font-semibold">Activations tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Confirm</span> all four stages of system activation in Enlighten
                    have been completed.
                </li>
                <li>
                    <span className="font-semibold">Revisit</span> each stage to ensure all checks are cleared.
                </li>
                <li>
                    <span className="font-semibold">Contact Enphase support</span> for additional assistance if needed.
                </li>
            </ol>
            <span className="font-semibold">For SolarEdge Systems, </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log</span> into the <span className="font-semibold">SolarEdge
                    Monitoring Portal</span> at <a
                        href="https://monitoring.solaredge.com/solaredge-web/p/login"
                        target="_blank"
                        rel="noreferrer"
                    >https://monitoring.solaredge.com/solaredge-web/p/login</a>..
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in
                    the <span className="font-semibold">&apos;Sites&apos; list.</span>
                </li>
                <li>
                    <span className="font-semibold">A Pending status</span> usually indicates that the system has not
                    achieved PTO. <span className="font-semibold">Confirm</span> site connection details.
                </li>
            </ol>
        </div>
    }
]

export const SYSTEM_STATUSES_MONITORED_NORMAL: SystemStatusDataType[] = [
    {
        value: SystemStatusEnum.NORMAL,
        title: 'Normal',
        refreshable: true,
        icon: (size: IconSize) => <IconCheckCircle size={size}/>,
        description: 'All systems are functioning within normal parameters; no immediate action is required.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-3 md:mt-6 rounded">
            <span className="font-semibold">No action required as no alert or issue statuses were detected.</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    Your system is <span className="font-semibold">functioning normally</span>.
                </li>
                <li>
                    <span className="font-semibold">Continue</span> to <span className="font-semibold">monitor </span>
                    your system periodically through the <span className="font-semibold">Enphase Enlighten</span> or
                    <span className="font-semibold">&nbsp;SolarEdge Monitoring portal</span> to ensure ongoing optimal
                    performance.
                </li>
                <li>
                    Enjoy the <span className="font-semibold">peace of mind</span> that comes with a smoothly operating
                    solar energy system.
                </li>
            </ol>
        </div>
    }
]

export const SYSTEM_STATUSES_UNMONITORED: SystemStatusDataType[] = [
    {
        value: SystemStatusEnum.PENDING,
        title: 'Connection Pending',
        refreshable: true,
        icon: (size: IconSize) => <IconLoading01 size={size}/>,
        description: 'Initial status for newly registered warranties, indicating the initial efforts to match with' +
            ' the manufacturer\'s database, typically transitioning to a Connected Site status within two weeks.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    Your solar system <span className="font-semibold">monitoring is in the setup process.</span>
                </li>
                <li>
                    <span className="font-semibold">No immediate action</span> is needed as setup is expected to
                    complete within two weeks.
                </li>
                <li>
                    <span className="font-semibold">Monitor</span> the status periodically in the manufacturer&apos;s
                    monitoring portal.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.DELAYED,
        title: 'Connection Delayed',
        refreshable: true,
        icon: (size: IconSize) => <IconClockSnoozed size={size}/>,
        description: 'Indicates a site remains unmonitored beyond 10 days or after three connection attempts,' +
            ' suggesting matching difficulties; resolution can be expedited by verifying address details or providing' +
            ' the system ID.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Navigate</span> to
                    the <span className="font-semibold">&quot;Homeowner Details - Warranty Tab&quot;</span> on
                    the <span className="font-semibold">Solar Insure platform.</span>
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">&quot;Edit
                    Warranty&quot;</span> option to access and <span className="font-semibold">modify</span> the
                    warranty registration details.
                </li>
                <li>
                    <span className="font-semibold">Ensure</span> that the <span className="font-semibold">Manufacturer
                    System Identifier</span> is correctly populated. This field, though not mandatory during initial
                    registration, can significantly increase the success rate of system connection.
                </li>
                <li>
                    <span className="font-semibold">Review</span> all registration details, particularly the address, to
                    ensure they are accurate and match the address listed for the same site on the manufacturer&apos;s
                    portal.
                </li>
                <li>
                    <span className="font-semibold">Verify</span> that the inverter manufacturer is correctly identified
                    in the registration.
                </li>
                <li>
                    If all details are correct and the issue persists, <span className="font-semibold">contact Solar
                    Insure customer support</span> for further assistance.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.SYSTEM_NOT_FOUND,
        title: 'System Not Found',
        refreshable: true,
        icon: (size: IconSize) => <IconSearch size={size}/>,
        description: 'We were unable to locate the system ID registered for this warranty in the inverter' +
            ' manufacturer\'s database. This may be due to incorrect entry or data sharing issues. Please verify the' +
            ' system ID and/or ensure Solar Insure is authorized as a subcontractor in the manufacturer\'s monitoring' +
            ' portal.',
        troubleshooting: {
            generac: <div className="font-normal text-sm p-3 bg-blue-50 text-gray-700 rounded font-body">
                <span className="font-semibold text-gray-800">For Generac Systems</span>
                <div className="my-4">
                    Your company successfully associated your PWRfleet account with Solar Insure at the company level,
                    but we were unable to view details for this specific site. This most often occurs when a company
                    forgets to share an individual site or neglects to add a site ID to the warranty registration,
                    hindering Solar Insure&apos;s ability to locate it in PWRfleet. You must share access with Solar
                    Insure at the fleet and site level for data to appear on the Solar Insure platform.
                </div>
                <span className="font-semibold text-gray-800">
                    Step One: Add a Manufacturer System ID to the Warranty Details
                </span>
                <ol className="mt-4 list-decimal pl-5">
                    <li>
                        <span className="font-semibold">Navigate</span> to
                        the <span className="font-semibold">&quot;Homeowner Details - Warranty Tab&quot;.</span>
                    </li>
                    <li>
                        <span className="font-semibold">Click</span> on the <span className="font-semibold">&quot;Edit
                        Warranty&quot;</span> option to access and modify the warranty registration details.
                    </li>
                    <li>
                        Make sure that the Manufacturer System Identifier is populated and correct. This should be
                        populated with the site ID for the related installation found in
                        your <a
                            href="https://pwrfleet.generac.com/login"
                            target="_blank"
                            rel="noreferrer"
                            className="text-primary-700 underline"
                        >PWRfleet portal.</a>
                        <div>
                            a. In PWRfleet, Navigate to the &quot;All Sites&quot; list to find the related system.
                        </div>
                        <div>
                            b. Click on the system to open its details page.
                        </div>
                        <div>
                            c. The Site ID can be found in the URL of the site. It is the unique identifier after
                            /sites/ in the URL.
                        </div>
                    </li>
                    <li>
                        Update the ID on Solar Insure Daybreak Platform and save the changes.
                    </li>
                </ol>
                <div className="font-semibold my-4 text-gray-800">
                    Step Two: Verify that the Individual Site is Shared with Solar Insure
                </div>
                <ol className="mt-4 list-decimal pl-5">
                    <li>
                        Log into the <a
                            href="https://pwrfleet.generac.com/login"
                            target="_blank"
                            rel="noreferrer"
                            className="text-primary-700 underline"
                        >PWRfleet portal.</a> portal.
                    </li>
                    <li>
                        Navigate to the &quot;All Sites&quot; list to find the related system. Click on the system
                        to open its details page.
                    </li>
                    <li>Click on the “Access” tab in the left sidebar.</li>
                    <li>Select “Share Site”</li>
                    <li>Select Solar Insure and click “Share”.</li>
                    <li>
                        Return to the Daybreak platform. Click on the refresh monitoring button to initiate a new
                        attempt to pull monitoring data.
                    </li>
                </ol>
                <Button
                    design="btn-secondary"
                    className="block text-center mt-4 w-fit"
                    target="_blank"
                    href="/resources/Generac_Fleet_&_Site_Level_Integrations.pdf"
                >
                    Click here for a Visual Guide
                </Button>
            </div>,
            maintainer: {
                enphase: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700 font-body">
                    <span className="font-semibold">For Enphase Systems</span>
                    <div className="my-4">
                        Your company has chosen to share monitoring data for all registered warranties using the
                        Maintainer method in Enlighten. For this method, you need to individually set up data access for
                        every individual system that is registered on the Daybreak platform. An authorization error most
                        likely indicates that you have not yet set up access permissions to share monitoring data with
                        Solar Insure. This system needs to be accessible in Solar Insure&apos;s Enlighten Maintainer
                        Account to show up in Daybreak. Follow these steps to resolve the issue:
                    </div>
                    <span className="font-semibold">
                        Step One: Add a Manufacturer System ID to the Warranty Details
                    </span>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>Navigate to the &quot;Homeowner Details - Warranty Tab&quot;.</li>
                        <li>
                            Click on the &quot;Edit Warranty&quot; option to access and modify the warranty registration
                            details.
                        </li>
                        <li>
                            Make sure that the Manufacturer System Identifier is populated and correct. This should be
                            populated with the System ID for the related installation found in
                            your <a
                                href="https://enlighten.enphaseenergy.com/"
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary-700 underline"
                            >Enphase Enlighten Portal.</a>
                            <div>
                                a. In Enlighten, Navigate to the &ldquo;Systems&ldquo; list to find the related system.
                            </div>
                            <div>b. Click on the system to open its details page.</div>
                            <div>c. Click on the gear icon to navigate to the Settings tab.</div>
                            <div>d. The system ID can be found in the Overview card.</div>
                        </li>
                        <li>Update the ID on Solar Insure Daybreak Platform and save the changes.</li>
                    </ol>
                    <div className="font-semibold my-4">Step Two: Verify Maintainer Access</div>
                    <div className="my-4">
                        If the System is New and Has Not Yet Been Activated in the Enlighten Portal:
                    </div>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>
                            Log into the <a
                                href="https://enlighten.enphaseenergy.com/"
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary-700 underline"
                            >Enphase Enlighten Portal.</a>
                        </li>
                        <li>
                            Locate and select the specific system that is experiencing the error to view its details.
                        </li>
                        <li>Go to the &apos;Settings&apos; page for that system by clicking on the gear icon.</li>
                        <li>
                            Review the Activation checklist. If Step 4 is still not complete, add Solar Insure as an
                            Authorized Subcontractor.
                        </li>
                        <li>
                            Return to the Daybreak platform. Click on the refresh monitoring button to initiate a new
                            attempt to pull monitoring data.
                        </li>
                    </ol>
                    <div className="my-4">If the System Has Already Been Activated:</div>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>
                            Log into the <a
                                href="https://enlighten.enphaseenergy.com/"
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary-700 underline"
                            >Enphase Enlighten Portal.</a>
                        </li>
                        <li>
                            Locate and select the specific system that is experiencing the error to view its details.
                        </li>
                        <li>Go to the &apos;Settings&apos; page for that system by clicking on the gear icon.</li>
                        <li>Scroll down to the System Access section.</li>
                        <li>
                            In the &apos;Companies with Access&apos; section, add the email monitoring@solarinsure.com
                            in the Email Address input field. Select that email address as an authorized subcontractor
                            and hit save.
                        </li>
                        <li>
                            Return to the Daybreak platform. Click on the refresh monitoring button to initiate a new
                            attempt to pull monitoring data.
                        </li>
                    </ol>
                </div>
            },
            default: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
                <ol className="mt-4 list-decimal pl-5">
                    <li>
                        <span className="font-semibold">Navigate</span> to
                        the <span className="font-semibold">&quot;Homeowner Details - Warranty Tab&quot;.</span>
                    </li>
                    <li>
                        <span className="font-semibold">Click</span> on
                        the <span className="font-semibold">&quot;Edit Warranty&quot;</span> option to access and modify
                        the warranty registration details.
                    </li>
                    <li>
                        Carefully verify that the Manufacturer System Identifier entered is correct. This is crucial as
                        an incorrect ID can lead to the system not being recognized in the manufacturer&apos;s database.
                        <div className="pl-4 -indent-2">
                            i. To find the system ID for Enphase systems, log into your Enlighten portal and Navigate
                            to the &quot;Systems&quot; list to find the related system. Click on the system to open its
                            details page. Click on the gear icon to navigate to the Settings tab. The system ID can be
                            found in listed in the Overview card.
                        </div>
                        <div className="pl-4 -indent-2">
                            ii. To find the site ID for SolarEdge systems, log into your SolarEdge portal and Navigate
                            to the &quot;Sites&quot; list to find the related site. Click on the system to open its
                            details page. The Site ID is listed under the homeowner name on the Site details card on the
                            right.
                        </div>
                    </li>
                    <li>
                        To ensure accuracy, <span className="font-semibold">cross</span>-reference this ID with the site
                        listed in the manufacturer&apos;s monitoring portal.
                    </li>
                    <li>
                        <span className="font-semibold">Check</span> that the registration details include a defined
                        inverter manufacturer, and confirm that this value is correct.
                    </li>
                    <li>
                        <span className="font-semibold">Save</span> any changes you make to the warranty details.
                    </li>
                    <li>
                        Click on the <span className="font-semibold">Refresh Monitoring</span> button to initiate a new
                        attempt to pull monitoring data.
                    </li>
                </ol>
            </div>
        }
    }
]

export const SYSTEM_STATUSES_EXCLUDED: SystemStatusDataType[] = [
    {
        value: SystemStatusEnum.AUTH_ERROR,
        title: 'Authorization Error',
        refreshable: true,
        icon: (size: IconSize) => <IconSearchFile01 size={size}/>,
        description: 'We have located this system in the inverter manufacturer\'s database, but Solar Insure lacks' +
            ' the necessary permissions to access the monitoring data. This is often due to restrictive settings or' +
            ' insufficient access rights configured in the monitoring portal or system.',
        troubleshooting: {
            solaredge: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
                <span className="font-semibold">For SolarEdge Systems:</span>
                <div className="my-4 italic">
                    Authorization errors from SolarEdge are most often caused by the submission of a limited scope API
                    key that only grants Solar Insure access to one individual site, instead of an entire fleet. To
                    resolve this issue, please follow these steps:
                </div>
                <ol className="mt-4 list-decimal pl-5">
                    <li>
                        Log into <a
                            href="https://monitoring.solaredge.com/solaredge-web/p/login"
                            className="text-primary-700 underline"
                            target="_blank"
                            rel="noreferrer"
                        >monitoring.solaredge.com.</a>
                    </li>
                    <li>Click on &apos;Monitoring Admin&apos; and select &apos;My Account&apos;.</li>
                    <li>
                        Navigate to the &apos;Company Details&apos; tab, scroll down, and click the checkbox indicating
                        you have read, understood, and agreed to the SolarEdge API T&C.
                    </li>
                    <li>Generate and Copy the API Key.</li>
                    <li>Click the Save button.</li>
                    <li>Login to Solar Insure.</li>
                    <li>Click on your avatar in the top right corner and select &apos;Profile&apos;.</li>
                    <li>Go to the &apos;Integrations&apos; tab.</li>
                    <li>Open the section for SolarEdge.</li>
                    <li>Replace the existing API key with the newly generated key from SolarEdge.</li>
                    <li>Save the changes.</li>
                    <li>Click on the refresh monitoring button to initiate a new attempt to pull monitoring data.</li>
                </ol>
            </div>,
            enphase: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
                <span className="font-semibold">For Enphase Systems:</span>
                <div className="my-4">
                    Authorization errors from Enphase are most often caused by insufficient data access permissions. To
                    share data for a specific solar system with an external platform like the Solar Insure Daybreak
                    Platform, the company that owns the Enphase Enlighten Portal account must also be listed as the PV
                    System Maintainer (instead of listing a third-party company). Make sure the company in the PV System
                    Maintainer field is the same as the company owning the account (you can see the account company on
                    the home dashboard). To resolve this issue:
                </div>
                <ol className="mt-4 list-decimal pl-5">
                    <li>
                        Login into the <a
                            href="https://enlighten.enphaseenergy.com/"
                            target="_blank"
                            rel="noreferrer"
                            className="text-primary-700 underline"
                        >Enphase Enlighten Portal.</a>
                    </li>
                    <li>Locate and select the specific system that is experiencing the error to view its details.</li>
                    <li>Go to the &apos;Settings&apos; page for that system at the site level.</li>
                    <li>Scroll down to the &apos;Access&apos; section on this page.</li>
                    <li>Check if the &apos;PV System Maintainer&apos; field lists the name of your company.</li>
                    <li>
                        If the field is not populated or incorrect, update it accordingly to ensure proper access
                        permissions are set for Solar Insure to connect and retrieve data. Save any changes made.
                    </li>
                    <li>Click on the refresh monitoring button to initiate a new attempt to pull monitoring data.</li>
                </ol>
                <div className="italic mt-4">
                    If the issue persists for 10 days after making a correction to the &apos;PV System Maintainer&apos;
                    field, or if you were unable to find this specific issue, please contact Enphase customer support.
                    There may be other permission settings on the Enphase site that Solar Insure is currently unaware
                    of.
                </div>
            </div>,
            generac: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
                <span className="font-semibold">For Generac Systems</span>
                <div className="my-4">
                    Your company successfully associated your PWRfleet account with Solar Insure at the company level,
                    but we were unable to view details for this specific site. This most often occurs when a company
                    forgets to share an individual site. You must share access with Solar Insure at the fleet and site
                    level for data to appear on the Solar Insure platform.
                </div>
                <span className="font-semibold">
                    Step One: Verify Manufacturer System ID Registered on the Warranty
                </span>
                <ol className="mt-4 list-decimal pl-5">
                    <li>Navigate to the &quot;Homeowner Details - Warranty Tab&quot;.</li>
                    <li>
                        Click on the &quot;Edit Warranty&quot; option to access and modify the warranty registration
                        details.
                    </li>
                    <li>
                        Carefully verify that the Manufacturer System Identifier entered is correct.
                        Cross-reference this ID with the site listed in your <a
                            href="https://pwrfleet.generac.com/login"
                            target="_blank"
                            rel="noreferrer"
                            className="text-primary-700 underline"
                        >PWRfleet portal.</a>
                        <div>a. Navigate to the &quot;All Sites&quot; list to find the related system.</div>
                        <div>b. Click on the system to open its details page.</div>
                        <div>
                            c. The Site ID can be found in the URL of the site. It is the unique identifier after
                            /sites/ in the URL.
                        </div>
                    </li>
                    <li>
                        If the system ID does not match, update the ID on Solar Insure Daybreak Platform and save the
                        changes.
                    </li>
                </ol>
                <div className="font-semibold mt-4">Step Two: Associate an Individual Site with Solar Insure</div>
                <ol className="mt-4 list-decimal pl-5">
                    <li>
                        Log into the <a
                            href="https://pwrfleet.generac.com/login"
                            target="_blank"
                            rel="noreferrer"
                            className="text-primary-700 underline"
                        >PWRfleet portal.</a></li>
                    <li>
                        Navigate to the &quot;All Sites&quot; list to find the related system. Click on the system to
                        open its details page.
                    </li>
                    <li>Click on the “Access” tab in the left sidebar.</li>
                    <li>Select “Share Site”</li>
                    <li>Select Solar Insure and click “Share”. </li>
                    <li>
                        Return to the Daybreak platform. Click on the refresh monitoring button to initiate a new
                        attempt to pull monitoring data.
                    </li>
                </ol>
                <Button
                    design="btn-secondary"
                    className="block text-center mt-4 w-fit"
                    target="_blank"
                    href="/resources/Generac_Fleet_&_Site_Level_Integrations.pdf"
                >
                    Click here for a Visual Guide
                </Button>
            </div>,
            maintainer: {
                enphase: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
                    <span className="font-semibold">
                        For Enphase Systems using the Authorized Subcontractor Method to Share Monitoring Data
                    </span>
                    <div className="my-4">
                        Your company has chosen to share monitoring data for all registered warranties using the
                        Authorized Subcontractor method in Enlighten. For this method, you need to individually set up
                        data access for every individual system that is registered on the Daybreak platform. An
                        authorization error most likely indicates that you have not yet set up access permissions to
                        share monitoring data with Solar Insure. This system needs to be accessible in Solar
                        Insure&apos;s Enlighten Maintainer Account to show up in Daybreak. Follow these steps to resolve
                        the issue:
                    </div>
                    <span className="font-semibold">Step One: Verify Manufacturer System ID</span>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>Navigate to the &quot;Homeowner Details - Warranty Tab&quot;.</li>
                        <li>
                            Click on the &quot;Edit Warranty&quot; option to access and modify the warranty registration
                            details.
                        </li>
                        <li>
                            Carefully verify that the Manufacturer System Identifier entered is correct. Cross-reference
                            this ID with the site listed in the Enphase Enlighten Portal to ensure accuracy.
                        </li>
                        <li>If the system ID does not match, update the ID and save the changes.</li>
                    </ol>
                    <div className="font-semibold mt-4">Step Two: Set Up Authorized Subcontractor Access</div>
                    <div className="my-4">
                        If the System is New and Has Not Yet Been Activated in the Enlighten Portal:
                    </div>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>Log into the Enphase Enlighten Portal.</li>
                        <li>
                            Locate and select the specific system that is experiencing the error to view its details.
                        </li>
                        <li>Go to the &apos;Settings&apos; page for that system by clicking on the gear icon.</li>
                        <li>
                            Review the Activation checklist. If Step 4 is still not complete, add Solar Insure as an
                            Authorized Subcontractor.
                        </li>
                        <li>
                            Return to the Daybreak platform. Click on the refresh monitoring button to initiate a new
                            attempt to pull monitoring data.
                        </li>
                    </ol>
                    <div className="my-4">If the System Has Already Been Activated:</div>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>Log into the Enphase Enlighten Portal.</li>
                        <li>
                            Locate and select the specific system that is experiencing the error to view its details.
                        </li>
                        <li>Go to the &apos;Settings&apos; page for that system by clicking on the gear icon.</li>
                        <li>Scroll down to the System Access section.</li>
                        <li>
                            In the &apos;Companies with Access&apos; section, add the email monitoring@solarinsure.com
                            in the Email Address input field. Select that email address as an authorized subcontractor
                            and hit save.
                        </li>
                        <li>
                            Return to the Daybreak platform. Click on the refresh monitoring button to initiate a new
                            attempt to pull monitoring data.
                        </li>
                    </ol>
                </div>
            },
            default: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
                <span className="font-semibold">
                    For efficient resolution of any system issues, please follow these steps:
                </span>
                <ol className="mt-4 list-decimal pl-5">
                    <li>
                        <span className="font-semibold">Login</span> into the <span className="font-semibold">Enphase
                        Enlighten Portal</span> at https://enlighten.enphaseenergy.com/.
                    </li>
                    <li>
                        <span className="font-semibold">Locate</span> and <span className="font-semibold">select </span>
                        the specific system that is experiencing the error to view its details.
                    </li>
                    <li>
                        Go to the <span className="font-semibold">&apos;Settings&apos; page</span> for that system at
                        the site level.
                    </li>
                    <li>
                        <span className="font-semibold">Scroll down</span> to
                        the <span className="font-semibold">&apos;Access&apos;</span> section on this page.
                    </li>
                    <li>
                        <span className="font-semibold">Check</span> if the <span className="font-semibold">&apos;PV
                        System Maintainer&apos;</span> field lists the name of the your company. If not, please update
                        it accordingly.
                    </li>
                    <li>
                        If the field is not populated or incorrect, <span className="font-semibold">update</span> it
                        accordingly to ensure proper access permissions are set for Solar Insure to connect and retrieve
                        data. Save any changes made.
                    </li>
                    <li>
                        If you&apos;re not ready to provide your personal credentials, note that the &apos;Solar Insure
                        Maintainer Account&apos; will be used temporarily for system monitoring,
                    </li>
                    <li>
                        If the issue persists for 10 days after making a correction to
                        the <span className="font-semibold">&apos;PV System Maintainer&apos;</span> field, or if you
                        were unable to find this specific issue, please <span className="font-semibold">contact customer
                        support.</span> There may be other permission settings on the Enphase site we are currently
                        unaware of.
                    </li>
                </ol>
            </div>
        }
    },
    {
        value: SystemStatusEnum.UNKNOWN_MANUFACTURER,
        title: 'Unsupported Manufacturer',
        icon: (size: IconSize) => <IconSlashCircle01 size={size}/>,
        description: 'Monitoring is currently unavailable for this inverter manufacturer on the Daybreak Platform.' +
            ' However, claims for inverters on our AVL will still be honored. To get assistance from Solar Insure for' +
            ' troubleshooting or expediting claims, you can provide your manufacturer monitoring portal credentials' +
            ' in the Integrations tab in your Profile to offer an alternative way to view site issues without the' +
            ' Solar Insure Fleet Monitoring dashboard.',
        action: <Button href="/profile/integrations#unsupported" className="!font-normal">
            Update Credentials
        </Button>,
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    If using inverters other than our currently supported inverter manufacturers, monitoring integration
                    is not yet available.
                </li>
                <li>
                    If the manufacturer of your solar inverter isn&apos;t currently supported in our integrations, we
                    recommend securely providing your monitoring login details to our customer support team for enhanced
                    troubleshooting assistance. <span className="font-semibold">Click</span> your avatar in the top
                    right-hand corner of the Solar Insure platform, <span className="font-semibold">select
                    &quot;Profile&quot;</span> from the dropdown menu, and
                    then <span className="font-semibold">go</span> to
                    the <span className="font-semibold">&quot;Integrations&quot;</span> tab.
                </li>
                <li>
                    <span className="font-semibold">Stay updated</span> on when your manufacturer will be supported by
                    checking announcements or <span className="font-semibold">contacting customer service.</span>
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.INTEGRATION_UPDATE_REQUIRED,
        title: 'Integration Update Required',
        refreshable: true,
        icon: (size: IconSize) => <IconPasscodeLock size={size}/>,
        description: 'Your monitoring credentials for one or more inverter manufacturers are invalid. To enable Solar' +
            ' Insure Monitoring updates, please update your credentials and check back for a revised connection status',
        action: <Button href="/profile/integrations" className="!font-normal">
            Update Credentials
        </Button>,
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 rounded">
            <span className="font-semibold">
                For efficient resolution of any system issues, please follow these steps:
            </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Click</span> on your avatar in the top right-hand corner of the
                    Solar Insure platform.
                </li>
                <li>
                    From the dropdown menu that appears, <span className="font-semibold">select </span>
                    the <span className="font-semibold">&quot;Profile&quot;</span> option.
                </li>
                <li>
                    Once in your profile, <span className="font-semibold">navigate</span> to
                    the <span className="font-semibold">&quot;Integrations&quot;</span> tab.
                </li>
                <li>
                    Carefully <span className="font-semibold">check</span> that all required credentials fields are
                    filled out correctly. <span className="font-semibold">Ensure</span> there are no error messages
                    indicating invalid field values.
                </li>
                <li>
                    If you made any changes or updates to the credentials, be sure
                    to <span className="font-semibold">save</span> these changes before exiting.
                </li>
                <li>
                    After updating your credentials, Solar Insure will attempt to connect sites to the monitoring for
                    the related manufacturer. Please allow up to 10 days for this process to complete, assuming there
                    are no additional issues.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.MISSING_INTEGRATION,
        title: 'Missing Integration',
        refreshable: true,
        icon: (size: IconSize) => <IconPasscode size={size}/>,
        description: 'You are missing the required fleet monitoring credentials for one or more inverter' +
            ' manufacturers.',
        action: <Button href="/profile/integrations" className="!font-normal">
            Update Credentials
        </Button>,
        troubleshooting: {
            generac: <div>
                <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
                    <div className="my-4">
                        The &quot;Missing Credentials&quot; error for Generac systems can show when a company has
                        correctly associated their Generac account at the company level, but they have not yet
                        associated any of their sites at the site level OR when a company has failed to associate at
                        both the company and site level. We cannot verify a company-level connection until at least one
                        site is associated.
                    </div>

                    <span className="font-semibold">
                        Step One: Verify that a valid Manufacturer System ID is populated on the Warranty Details
                    </span>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>Navigate to the &quot;Homeowner Details - Warranty Tab&quot;.</li>
                        <li>
                            Click on the &quot;Edit Warranty&quot; option to access and modify the warranty registration
                            details.
                        </li>
                        <li>
                            Ensure that the Manufacturer System Identifier is populated and correct. This should be
                            populated with the site ID for the related installation found in your PWRfleet portal.
                            <div>
                                a. In PWRfleet, Navigate to the &quot;All Sites&quot; list to find the related system.
                            </div>
                            <div>b. Click on the system to open its details page.</div>
                            <div>
                                c. The Site ID can be found in the URL of the site. It is the unique identifier after
                                /sites/ in the URL.
                            </div>
                        </li>
                        <li>Update the ID on the Solar Insure Daybreak Platform and save the changes.</li>
                    </ol>
                    <div className="font-semibold mt-4">
                        Step Two: Verify that you have set up Monitoring Data Sharing Access at the Company Level on
                        PWRfleet
                    </div>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>
                            Log into the Generac portal at <a
                                href="https://mypwrview.generac.com/login"
                                className="text-primary-700 underline"
                                target="_blank"
                                rel="noreferrer"
                            >https://mypwrview.generac.com/</a> and select the three-dot menu.
                        </li>
                        <li>
                            Navigate to &quot;Account Settings&quot; and in the dropdown, go to the &quot;Company
                            Associations&quot; section. Here you will see a list of your installation partners.
                        </li>
                        <li>Select &quot;Associations Requests&quot;.</li>
                        <li>
                            If you need to request an association with a financing or O&M partner, click on the
                            &quot;Request Association&quot; button in the &quot;Your financing and O&M partners&quot;
                            section to open up further options.</li>
                        <li>
                            A pop-up will appear prompting you to associate companies with your PWRfleet. Enter
                            monitoring@solarinsure.com address into the field provided.
                        </li>
                    </ol>
                    <div className="font-semibold mt-4">
                        Step Three: Verify that this Individual Site is Shared with Solar Insure
                    </div>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>Log into the PWRfleet portal.</li>
                        <li>
                            Navigate to the &quot;All Sites&quot; list to find the related system. Click on the system
                            to open its details page.
                        </li>
                        <li>Click on the &quot;Access&quot; tab in the left sidebar.</li>
                        <li>Select &quot;Share Site&quot;.</li>
                        <li>Select Solar Insure and click &quot;Share&quot;.</li>
                        <li>
                            Return to the Daybreak platform. Click on the refresh monitoring button to initiate a new
                            attempt to pull monitoring data.
                        </li>
                    </ol>
                </div>
            </div>,
            default: <div className="font-normal text-sm p-3 bg-blue-50 rounded">
                <span className="font-semibold">
                    For efficient resolution of any system issues, please follow these steps:
                </span>
                <ol className="mt-4 list-decimal pl-5">
                    <li>
                        <span className="font-semibold">Click</span> on your avatar in the top right-hand corner of the
                        Solar Insure platform.
                    </li>
                    <li>
                        From the dropdown menu that appears, <span className="font-semibold">select </span>
                        the <span className="font-semibold">&quot;Profile&quot;</span> option.
                    </li>
                    <li>
                        Once in your profile, <span className="font-semibold">navigate</span> to
                        the <span className="font-semibold">&quot;Integrations&quot;</span> tab.
                    </li>
                    <li>
                        Carefully <span className="font-semibold">check</span> that all required credentials fields are
                        filled out correctly. <span className="font-semibold">Ensure</span> there are no error messages
                        indicating invalid field values.
                    </li>
                    <li>
                        If you made any changes or updates to the credentials, be sure
                        to <span className="font-semibold">save</span> these changes before exiting.
                    </li>
                    <li>
                        After updating your credentials, Solar Insure will attempt to connect sites to the monitoring
                        for the related manufacturer. Please allow up to 10 days for this process to complete, assuming
                        there are no additional issues.
                    </li>
                </ol>
            </div>
        }
    }
]
