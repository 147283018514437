import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'

import { useNavigateWithQuery, useQuery } from '@/hooks/index'
import {
    FormChangeEvent,
    IndexContainerFilterPropertiesType,
    IndexContainerFiltersType,
    QueryParamsType
} from '@/types'

export const useIndexContainer = <T extends IndexContainerFiltersType>(rawFilters: T) => {
    const query = useQuery()
    const navigateWithQuery = useNavigateWithQuery()
    const location = useLocation()

    const convertQueryToFilters = (query: QueryParamsType) => Object
        .keys(rawFilters)
        .reduce((acc, key) => {
            const filter = rawFilters[key]
            let value = filter.defaultValue
            if (filter.convert) {
                value = filter.convert(query[key])
            } else if (query[key]) {
                if (Array.isArray(filter.defaultValue) && !Array.isArray(query[key])) {
                    value = [query[key]]
                } else {
                    value = query[key]
                }
            }
            return {
                ...acc,
                [key]: { ...filter, value } as IndexContainerFilterPropertiesType
            }
        }, {} as T)
    const initialFilters = useMemo<T>(() => convertQueryToFilters(query) as T, [query])
    const [filters, setFilters] = useState(initialFilters)
    const filtersStringValue = useMemo(() => Object.keys(filters)
        .map(key => `${key}:${filters[key].value?.toString() || ''}`)
        .join(), [filters])

    const handleFiltersChange = (e: FormChangeEvent) => {
        const filter = filters[e.target.name]
        setFilters(filters => ({
            ...filters,
            [e.target.name]: {
                ...filter,
                value: e.target.value,
                displayValue: e.target.displayValue || e.target.value
            }
        } as T))
        navigateWithQuery({
            page: null,
            [e.target.name]: e.target.value?.toString() === filter.defaultValue?.toString() ? null : e.target.value
        })
    }

    useEffect(() => {
        const isFiltersChanged = Object
            .keys(filters)
            .some(key => initialFilters[key].value?.toString() !== filters[key].value?.toString())
        if (isFiltersChanged) {
            setFilters(initialFilters)
        }
    }, [location])

    return {
        filters,
        setFilters,
        handleFiltersChange,
        initialFilters,
        filtersStringValue
    }
}
