import { FormErrorsType, Upload } from '@/components'
import { FormChangeEventHandler, JsonDataType } from '@/types'

export interface ImagesFormDataType extends JsonDataType {
    images?: File[]
}

type ImagesFormProps = {
    form: ImagesFormDataType
    onChange: FormChangeEventHandler
    errors: FormErrorsType
    inputClassName?: string
}

export const ImagesForm = ({
    form,
    inputClassName,
    errors,
    ...props
}: ImagesFormProps) =>
    <>
        <Upload
            multiple
            value={form.images}
            onChange={props.onChange}
            name="images"
            accept="Image/png,Image/jpeg,Image/gif"
            className={inputClassName}
            errors={errors.images}
        >
            <div className="mt-3 text-center">
                {form.images?.length
                    ? <p className="text-sm">{form.images.map((item: File, index: number) =>
                        <span key={index}>{item.name}</span>)}</p>
                    : <>
                        <p className="text-sm">
                            <span className="text-orange-700">Click to upload</span> or drag and drop</p>
                        <p className="text-xs mt-1">PNG or JPG (max. 800x400px)</p>
                    </>}
            </div>
        </Upload>
    </>
