import { useEffect, useMemo, useState } from 'react'
import { Link, useLoaderData, useNavigate } from 'react-router'

import {
    Card,
    IconCertificate,
    PageHeader,
    Steps,
    StepVerifyColumns,
    StepConfirmation,
    FAQ,
    StepUpload,
    StepValidateData,
    FormErrorsType,
    WarrantyTypeSwitchButton
} from '@/components'
import { WARRANTY_COLUMNS } from '@/components/bulk-upload/default-coolumns'
import { AuthLayout } from '@/containers'
import { useAnalytics, useAppDispatch, useAppSelector, useDebounce, useToastNotifications } from '@/hooks'
import { AuthUser, Company, Warranty, WarrantyProductType } from '@/models'
import { JSONResult, fuse, cookies, loadingBar, isApiError, Payload } from '@/services'
import { login, fetchNotifications, showNotifications } from '@/store'
import { FormChangeEvent, IdType, WarrantyBulkUploadColumnType } from '@/types'

const breadcrumb = [
    <IconCertificate key={1} className="stroke-gray-500"/>,
    <Link key={2} to="/warranties">Warranties</Link>,
    'Add Warranty - Bulk Upload'
]

export type UploadWarrantiesRowDataType = {
    id?: number
    company_id?: IdType
    index?: number
    allow_invalid?: boolean
    duplicate_override?: boolean

    first_name?: string
    last_name?: string
    full_name?: string
    phone?: string
    email?: string
    address?: string
    street_address?: string
    city?: string
    state?: string
    zip?: string
    panel_name?: string
    inverter_name?: string
    num_panels?: string
    size_kw?: string
    install_date?: string
    submission_date?: string
    num_microinverters?: string
    system_id?: string
}

export type UploadWarrantyDataType = Omit<UploadWarrantiesRowDataType, 'index' | 'id' | 'allow_invalid' | 'duplicate_override'>

export type UploadWarrantiesFormDataType = {
    warranties: UploadWarrantiesRowDataType[]
    user_id?: IdType
}

const WarrantiesUpload = () => {
    const dispatch = useAppDispatch()
    const {
        company,
        warrantyProductType
    } = useLoaderData() as { company: Company, warrantyProductType: WarrantyProductType }
    const { trackEvent } = useAnalytics()
    const navigate = useNavigate()
    const { success, error } = useToastNotifications()
    const auth = useAppSelector(({ auth }) => auth)
    const [step, setStep] = useState(0)
    const [processing, setProcessing] = useState(false)
    const [data, setData] = useState<JSONResult>([])
    const [errors, setErrors] = useState<FormErrorsType>({})
    const [form, setForm] = useState<{ warranties: JSONResult, company_id: IdType }>({
        warranties: [],
        company_id: company.id
    })

    const defaultColumns = useMemo(() => {
        const columns = WARRANTY_COLUMNS.filter(column => !column.admin || auth.user?.role.isAdminOrStaff)
        const savedColumns = auth.user?.preferences?.warranty_prefs
        return savedColumns && auth.user?.role.isContractor
            ? columns.map(item => {
                const pref = savedColumns.find(({ value }) => value === item.value)
                return {
                    ...item,
                    index: pref?.index === 0 || pref?.index ? pref.index : item.index,
                    header: pref?.header ? pref.header : item.header
                }
            })
            : columns
    }, [])
    const [columns, setColumns] = useState<WarrantyBulkUploadColumnType[]>(defaultColumns)

    useEffect(() => {
        setForm({
            ...form,
            warranties: data.map(item => {
                const values = Object.values(item)
                return columns.reduce((acc, {
                    value,
                    index,
                    show
                }) => {
                    if (!show) return acc
                    return {
                        ...acc,
                        [value]: index === null ? '' : values[index as number]
                    }
                }, {})
            })
        })
    }, [data, columns])

    const handleChange = (e: FormChangeEvent) => {
        setForm({ ...form, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value })
    }

    const handleFileUpload = (data: JSONResult) => {
        setData(data)
        setErrors({})
        const headers = Object.keys(data[0])
        const columns = defaultColumns.reduce((acc, item) => {
            const res = fuse(headers.map(item => item.toLowerCase()), [
                item.title.toLowerCase(),
                item.value,
                ...item.header ? [item.header?.toLowerCase()] : [],
                ...item.keywords || []
            ])

            const score = typeof res?.score === 'undefined' ? 1 : res?.score
            const index = typeof res?.refIndex === 'undefined' ? null : res?.refIndex
            const header = index === null ? '' : headers[index]

            const takenIndex = acc.findIndex(item => item.index === index)
            // Col umn with same index found
            if (takenIndex > -1) {
                const taken = acc[takenIndex]
                // Taken column have less score or less priority or hidden
                if (taken.score >= score || !taken.show || !item.show || (!taken.required && item.required)) {
                    if (taken.show && item.show) {
                        acc[takenIndex] = { ...taken, score: 1, index: null, header: '' }
                    }
                    return [...acc, { ...item, score, index, header }]
                }
                return [...acc, { ...item, score: 1, index: null, header: '' }]
            }

            return [...acc, { ...item, score, index, header }]
        }, [] as WarrantyBulkUploadColumnType[])
        setColumns(columns)
    }

    const handleSubmit = async () => {
        trackEvent('click_bulk_upload_step_2_save', 'User Interaction', 'Bulk Upload Save Button')
        setProcessing(true)
        setErrors({})
        const payload = {
            warranties: form.warranties,
            columns,
            company_id: company.id
        }
        try {
            await Warranty.upload(payload as Payload)
            const user = await AuthUser.currentUser()
            dispatch(login({ user, apiToken: cookies.get('api_token') }))
            dispatch(fetchNotifications)
            const toast = success('Bulk upload is being processed. You can track the progress in the notifications center.')
            toast.onClose = () => dispatch(showNotifications())
            setStep(3)
        } catch (err) {
            if (!isApiError(err)) throw err
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    const handleComplete = () => {
        navigate('/warranties')
    }

    const handleValidate = async (form: UploadWarrantiesFormDataType) => {
        setProcessing(true)
        loadingBar.start()
        try {
            await Warranty.validateUpload(form)
            setErrors({})
        } catch (err) {
            if (!isApiError(err)) throw err
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
            loadingBar.stop()
        }
    }

    const debounceValidate = useDebounce(form => {
        handleValidate(form)
    }, 500)

    useEffect(() => {
        if (step === 2) {
            debounceValidate(form)
        }
    }, [form.warranties])

    return <AuthLayout
        heading={
            <PageHeader
                title={<div className="w-full flex flex-col xl:flex-row gap-4 justify-between xl:items-center mt-7.5">
                    Bulk Upload

                    <div className="text-base flex flex-col md:flex-row items-center gap-2 md:gap-4 mt-2 md:mt-0 text-gray-900">
                        Need to register a Battery Warranty?
                        <WarrantyTypeSwitchButton
                            company={company}
                            warrantyProductType={warrantyProductType}
                        />
                    </div>
                </div>}
                backUrl="/warranties"
            />
        }
        breadcrumb={breadcrumb}
    >
        <Card className="lg:!p-8">
            <Steps
                className="mt-4 lg:px-8"
                step={step}
                steps={[
                    'Upload File',
                    'Verify Columns',
                    'Validate Data',
                    'Confirmation'
                ]}
            />

            <div className="mt-8">
                {step === 0 && <StepUpload
                    onChange={handleFileUpload}
                    onNext={() => setStep(1)}
                />}
                {step === 1 && <StepVerifyColumns
                    form={form}
                    columns={columns}
                    headers={Object.keys(data[0])}
                    onNext={async () => {
                        await handleValidate(form)
                        setStep(2)
                    }}
                    onPrev={() => setStep(0)}
                    onColumnsChange={setColumns}
                    processing={processing}
                />}
                {step === 2 && <StepValidateData
                    form={form}
                    errors={errors}
                    columns={columns}
                    onNext={handleSubmit}
                    onPrev={() => setStep(1)}
                    onChange={handleChange}
                    processing={processing}
                    company={company}
                />}

                {step === 3 && <StepConfirmation
                    onNext={handleComplete}
                />}
            </div>
        </Card>

        {step === 0 && <FAQ className="mt-8"/>}
    </AuthLayout>
}

export default WarrantiesUpload
