import batteryImage from '@/assets/images/warranty-types/battery.png'
import battery2xImage from '@/assets/images/warranty-types/battery@2x.png'
import battery3xImage from '@/assets/images/warranty-types/battery@3x.png'
import legacyImage from '@/assets/images/warranty-types/legacy.png'
import legacy2xImage from '@/assets/images/warranty-types/legacy@2x.png'
import legacy3xImage from '@/assets/images/warranty-types/legacy@3x.png'
import solarImage from '@/assets/images/warranty-types/solar.png'
import solar2xImage from '@/assets/images/warranty-types/solar@2x.png'
import solar3xImage from '@/assets/images/warranty-types/solar@3x.png'
import totalImage from '@/assets/images/warranty-types/total.png'
import total2xImage from '@/assets/images/warranty-types/total@2x.png'
import total3xImage from '@/assets/images/warranty-types/total@3x.png'
import { IconBatteryCharging, IconSun, Image } from '@/components'
import { ProductRateTypeEnum } from '@/enums/ProductRateTypeEnum'
import { WarrantyRegistrationTypeEnum } from '@/enums/WarrantyRegistrationTypeEnum'
import { WarrantyProductTypeDataType } from '@/types'


export enum WarrantyProductTypeEnum {
    POWER_PRODUCTION_ONLY = 'power_production_only',
    SEPARATE_30_YEAR_STORAGE_ONLY = 'separate_30_year_storage_only',
    PRODUCTION_WITH_20_YEAR_SEPARATE_STORAGE = 'production_with_20_year_separate_storage',
    PRODUCTION_WITH_30_YEAR_SEPARATE_STORAGE = 'production_with_30_year_separate_storage'
}

export enum WarrantyProductCoverageTypeEnum {
    POWER_PRODUCTION_COVERAGE = 'power_production_coverage',
    STORAGE_ONLY_30_YEAR_COVERAGE = 'storage_only_30_year_coverage',
    STORAGE_30_YEAR_COVERAGE = 'storage_30_year_coverage',
    STORAGE_20_YEAR_COVERAGE = 'storage_20_year_coverage'
}

export const WARRANTY_PRODUCT_TYPES: WarrantyProductTypeDataType[] = [
    {
        key: WarrantyProductTypeEnum.POWER_PRODUCTION_ONLY,
        coverageKey: WarrantyProductCoverageTypeEnum.POWER_PRODUCTION_COVERAGE,
        title: 'SI-30 solar',
        brandedTitle: <span>SI-30<span className="text-[70%]">✦</span>solar</span>,
        brandedTitleColored: <span>SI-30<span className="text-[70%] text-brand-orange">✦</span>solar</span>,
        coverageTitle: '30-Year Solar System Coverage',
        subtitle: '30-year Solar Warranty',
        color: 'orange',
        IconComponent: IconSun,
        hasPowerProduction: true,
        powerProductionRateKey: ProductRateTypeEnum.POWER_PRODUCTION,
        registrationTypes: [
            WarrantyRegistrationTypeEnum.SINGLE,
            WarrantyRegistrationTypeEnum.BULK
        ],
        description: '30-Year Solar Warranty for new solar power systems, including panels, inverters, & other' +
            ' related components. Batteries are not included.',
        image: <Image
            src={solarImage}
            src2x={solar2xImage}
            src3x={solar3xImage}
            className="max-w-50"
        />
    },
    {
        key: WarrantyProductTypeEnum.SEPARATE_30_YEAR_STORAGE_ONLY,
        coverageKey: WarrantyProductCoverageTypeEnum.STORAGE_ONLY_30_YEAR_COVERAGE,
        title: 'SI-30 battery',
        brandedTitle: <span>SI-30<span className="text-[70%]">✦</span>battery</span>,
        brandedTitleColored: <span>SI-30<span className="text-[70%] text-brand-blue">✦</span>battery</span>,
        coverageTitle: '30-Year Exclusive Battery Coverage',
        subtitle: '30-year Battery-Only Warranty',
        color: 'blue',
        IconComponent: IconBatteryCharging,
        hasStorage: true,
        storageRateKey: ProductRateTypeEnum.STORAGE_ONLY,
        registrationTypes: [
            WarrantyRegistrationTypeEnum.SINGLE
        ],
        description: '30-Year Battery Warranty for solo or retrofit battery storage systems (standalone or integrated' +
            ' inverter/battery components). Panels and other solar system components are not included.',
        image: <Image
            src={batteryImage}
            src2x={battery2xImage}
            src3x={battery3xImage}
            className="max-w-50"
        />
    },
    {
        key: WarrantyProductTypeEnum.PRODUCTION_WITH_30_YEAR_SEPARATE_STORAGE,
        coverageKey: WarrantyProductCoverageTypeEnum.STORAGE_30_YEAR_COVERAGE,
        title: 'SI-30 total',
        brandedTitle: <span>SI-30<span className="text-[70%]">✦</span>total</span>,
        brandedTitleColored: <span>SI-30<span className="text-[70%] text-brand-green">✦</span>total</span>,
        coverageTitle: '30-Year Total Battery Coverage',
        subtitle: '30-year Solar & Battery Warranty',
        color: 'green',
        IconComponent: IconBatteryCharging,
        hasPowerProduction: true,
        hasStorage: true,
        powerProductionRateKey: ProductRateTypeEnum.POWER_PRODUCTION,
        storageRateKey: ProductRateTypeEnum.SEPARATE_30_YEAR_STORAGE,
        registrationTypes: [
            WarrantyRegistrationTypeEnum.SINGLE
        ],
        description: '30-Year Solar & Battery Warranty with total coverage for new systems where batteries and' +
            ' inverters are installed together (integrated or separate), including panels and all related components.',
        image: <Image
            src={totalImage}
            src2x={total2xImage}
            src3x={total3xImage}
            className="max-w-50"
        />
    },
    {
        key: WarrantyProductTypeEnum.PRODUCTION_WITH_20_YEAR_SEPARATE_STORAGE,
        coverageKey: WarrantyProductCoverageTypeEnum.STORAGE_20_YEAR_COVERAGE,
        title: 'SI-30:20 legacy',
        brandedTitle: <span>SI-30:20<span className="text-[70%]">✦</span>legacy</span>,
        brandedTitleColored: <span>SI-30:20<span className="text-[70%] text-brand-purple">✦</span>legacy</span>,
        coverageTitle: '20-Year Legacy Battery Coverage',
        subtitle: '30-year Solar & 20-year Battery Warranty',
        color: 'purple',
        IconComponent: IconBatteryCharging,
        hasPowerProduction: true,
        hasStorage: true,
        isDeprecated: true,
        powerProductionRateKey: ProductRateTypeEnum.POWER_PRODUCTION,
        storageRateKey: ProductRateTypeEnum.SEPARATE_20_YEAR_STORAGE,
        registrationTypes: [
            WarrantyRegistrationTypeEnum.SINGLE
        ],
        description: '30-Year Solar and 20-Year Battery Warranty with coverage for new systems where batteries and' +
            ' inverters are installed together (hybrid or separate), including panels and all related components.',
        image: <Image
            src={legacyImage}
            src2x={legacy2xImage}
            src3x={legacy3xImage}
            className="max-w-50"
        />
    }
]
