import { Icon, IconProps } from './Icon'

export const IconRefreshCw01 = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M21 10s-2.005-2.732-3.634-4.362a9 9 0 1 0 2.282 8.862M21 10V4m0 6h-6"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
