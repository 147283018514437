import { Icon, IconProps } from './Icon'

export const IconPercent03 = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M9 9h.01M15 15h.01M16 8l-8 8m1.5-7a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm6 6a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm6.5-3c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
