export type JSONResultRow = {
    [key: string]: string | number
}
export type JSONResult = JSONResultRow[]

export default {
    parse(file: File): Promise<JSONResult> {
        return new Promise((resolve, reject) => {
            if (!file) return
            const reader = new FileReader()
            reader.onload = async e => {
                try {
                    if (e.target?.result) {
                        const [header, ...data]: string[][] = (e.target.result as string)
                            .split('\n')
                            .map((item: string) => item.split(','))
                        const parsed = data.map(row => header
                            .reduce((acc, key, index) => {
                                const value = row[index]
                                return {
                                    ...acc,
                                    [key]: this.parseValue(value, key)
                                }
                            }, {}))
                        resolve(parsed)
                    }
                } catch (err) {
                    reject(err)
                }
            }
            reader.onerror = reject

            reader.readAsText(file)
        })
    },

    async export(data: JSONResult, name = `export-${new Date().toISODate()}`, format = 'csv') {
        if (format === 'csv') {
            const csv = [
                Object.keys(data[0]).join(','),
                ...data.map(item => Object.values(item).join(','))
            ].join('\n')

            const s2ab = (s: string) => {
                const buf = new ArrayBuffer(s.length)
                const view = new Uint8Array(buf)
                for (let i = 0; i !== s.length; i = i + 1) view[i] = s.charCodeAt(i) & 0xFF
                return buf
            }
            this.downloadFile(new Blob([s2ab(csv)], { type: 'application/octet-stream' }), `${name}.csv`)
        } else if (format === 'json') {
            this.downloadFile(new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' }), `${name}.json`)
        }
    },

    parseValue: (item: string | number | undefined | null, key: string) => {
        if (item && key.toLowerCase().includes('date')) {
            const date = new Date(item)
            const dateWithYear = new Date(`${item}/${new Date().getFullYear()}`)

            if (date.isValid()) {
                if (date.getFullYear() === 2001 && typeof item === 'string' && !item.includes('2001')) {
                    date.setFullYear(new Date().getFullYear())
                    return date.format()
                }

                return date.format()
            } else if (dateWithYear.isValid()) {
                return dateWithYear.format()
            }
        } else if (typeof item === 'string') {
            const bytes = new Uint8Array([...item].map(char => char.charCodeAt(0)))
            const decoder = new TextDecoder('utf-8')
            return decoder.decode(bytes)
        }

        return item
    },

    downloadFile: (file: Blob, name: string) => {
        const a = document.createElement('a')
        a.setAttribute('href', URL.createObjectURL(file))
        a.setAttribute('download', name)
        document.body.appendChild(a)
        a.click()
        a.remove()
    }
}
