import { useState } from 'react'

import { Button, IconMail, ButtonSize } from '@/components'
import { WarrantyStatusEnum } from '@/enums'
import { useQuery } from '@/hooks'
import { Warranty, WarrantyStatus } from '@/models'
import { WarrantyIndexDataType } from '@/types'

type EmailWarrantiesButtonProps = {
    quantity?: number
    selected: Warranty[] | 'all'
    size?: ButtonSize
    data?: WarrantyIndexDataType
    'data-test'?: string
}

export const EmailWarrantiesButton = ({
    quantity = 1,
    size = "btn-lg",
    data,
    selected,
    'data-test': dataTest
}: EmailWarrantiesButtonProps) => {
    const query = useQuery()
    const [processing, setProcessing] = useState<boolean>(false)

    const isInactiveWarrantySelected = selected === 'all'
        ? query.statuses?.some((status: WarrantyStatusEnum) => WarrantyStatus.find(status).isInactive)
        : selected.some(item => item.status.isInactive)

    const getTooltip = () => {
        if (isInactiveWarrantySelected) {
            return 'This functionality is disabled for canceled warranties'
        }

        if (quantity < 1) {
            return 'Select at least one warranty to send an email.'
        }

        return `Email ${quantity > 1 ? 'Warranties' : 'Warranty'}`
    }

    const handleClick = async () => {
        setProcessing(true)
        try {
            if (selected === 'all') {
                if (!data) {
                    return
                }
                await Warranty.send(data.rows.map(({ id }) => id))
            } else {
                await Warranty.send(selected.map(({ id }) => id))
            }
        } finally {
            setProcessing(false)
        }
    }

    return <Button
        square
        tooltip={getTooltip()}
        tooltipDisabled={false}
        design="btn-secondary-gray"
        size={size}
        disabled={!quantity || processing || isInactiveWarrantySelected}
        onClick={handleClick}
        data-test={`${dataTest}-send-button`}
    >
        <IconMail/>
    </Button>
}
