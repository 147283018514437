import { Link } from 'react-router'

import {
    Badge,
    Card,
    EnphaseCredentialsSection,
    MaintainerCredentialsSection,
    SolarEdgeCredentialsSection,
    UnsupportedManufacturerCredentialsSection
} from '@/components'
import { Company, Integration } from '@/models'

type CompanyIntegrationCardProps = {
    integration: Integration
    company: Company
}

const CompanyIntegrationCard = ({ integration, company }: CompanyIntegrationCardProps) =>
    <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
                {integration.manufacturer.getLogo()}
                <h3>{integration.manufacturer.title}</h3>
                <Link to={`/monitoring?company_id=${company.id}&manufacturers=${integration.manufacturer.key}`}>
                    <Badge className={integration.isMissing ? 'bg-error-50 text-error-600' : 'bg-blue-50 text-blue-600'}>
                        {integration.warranties_count} warranties
                    </Badge>
                </Link>
            </div>
            {integration.status.getBadge('sm')}
        </div>
        {!integration.manufacturer.isGenerac && <>
            {integration.isUsesMaintainer
                ? <Card>
                    <div className="text-green-700 text-xs">Access via Maintainer Account</div>
                </Card>
                : <>
                    {integration.manufacturer.isEnphase &&
                        <EnphaseCredentialsSection integration={integration}/>}
                    {integration.manufacturer.isSolaredge &&
                        <SolarEdgeCredentialsSection integration={integration}/>}
                    {integration.manufacturer.isUnsupported &&
                        <UnsupportedManufacturerCredentialsSection integration={integration}/>}
                </>}
        </>}
        {!integration.isUnsupported && <MaintainerCredentialsSection integration={integration}/>}
    </div>

export default CompanyIntegrationCard
