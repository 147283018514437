import { useMemo, useState } from 'react'

import { Select, DatePicker, SelectOptionProps } from '@/components'
import { IndexContainer } from '@/containers'
import { useIndexContainer } from '@/hooks'
import { Model, SystemHistory, SystemStatus, SystemStatusCategory, Warranty } from '@/models'
import {
    IndexContainerChangeEvent,
    SystemHistoryIndexFiltersType,
    SystemHistoryIndexDataType,
    IndexContainerFilterValueType, DateRangeType
} from '@/types'

export const SYSTEM_HISTORY_INDEX_FILTERS: SystemHistoryIndexFiltersType = {
    search: {
        label: 'Search',
        defaultValue: '',
        value: '',
        isStandalone: true
    },
    detection_date_range: {
        label: 'Date',
        defaultValue: [null, null],
        value: [null, null],
        isStandalone: true,
        convert: (value: IndexContainerFilterValueType) =>
            value ? (value as [string, string]).map(Model.toDate) as DateRangeType : [null, null]
    },
    status: {
        label: 'Status',
        defaultValue: '',
        value: '',
        isStandalone: true
    }
}
const SystemHistoryIndex = ({ warranty }: { warranty: Warranty }) => {
    const {
        filters,
        handleFiltersChange
    } = useIndexContainer(SYSTEM_HISTORY_INDEX_FILTERS)
    const [data, setData] = useState<SystemHistoryIndexDataType>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0
        },
        rows: []
    })

    const handleDataChange = (e: IndexContainerChangeEvent) => {
        setData(data => ({ ...data, [e.name]: e.value }))
    }
    const statusOptions = useMemo(() => [
        {
            value: '',
            title: 'Any Status'
        },
        ...SystemStatusCategory.all.reduce((acc, item, index) =>
            [
                ...acc,
                {
                    value: item.value,
                    title: <span className="font-semibold w-full">
                        {item.title}
                    </span>,
                    group: index > 0
                },
                ...item.subcategories.reduce((acc, item, _, all) =>
                    [
                        ...acc,
                        ...all.length > 1 ? [{
                            value: item.value,
                            title: <span className="font-semibold w-full text-sm">
                                • {item.title}
                            </span>
                        }] : [],
                        ...item.statuses.map((item: SystemStatus) => ({
                            value: item.value,
                            title: <div className="flex w-full gap-2 items-center font-body text-gray-900 text-sm">
                                {item.iconBadge('xs')} {item.title}
                            </div>
                        }))
                    ], [] as SelectOptionProps[])
            ], [] as SelectOptionProps[])
    ], [] as SelectOptionProps[])

    const rowsMemo = useMemo(() => data.rows.map(item => ({
        id: item.id,
        status: <div className="flex items-center gap-3">
            {item.status.iconBadge()}
            {item.status.title}
        </div>,
        status_raw: item.status.value,
        duration_seconds: (() => {
            const timeUnits = {
                year: 31536000,
                month: 2592000,
                day: 86400,
                hour: 3600,
                minute: 60,
                second: 1
            }

            for (const unit in timeUnits) {
                const unitDuration = timeUnits[unit as keyof typeof timeUnits]
                if (item.duration_seconds >= unitDuration) {
                    const value = Math.floor(item.duration_seconds / unitDuration)
                    return `${value} ${unit.pluralize(value)}`
                }
            }

            return '0 seconds'
        })(),
        detection_start_date: item.detection_start_date?.format({
            hour: '2-digit',
            minute: '2-digit'
        }) || '',
        detection_end_date: item.detection_end_date?.format({
            hour: '2-digit',
            minute: '2-digit'
        }) || ''
    })), [data.rows])

    return <>
        <IndexContainer
            id="system-history"
            tableOptions={{
                selectable: false,
                dataName: 'Events'
            }}
            columns={[
                { field: 'status', title: 'System Status' },
                { field: 'duration_seconds', title: 'Status Duration' },
                { field: 'detection_start_date', title: 'Detection Start Date' },
                { field: 'detection_end_date', title: 'Detection End Date' }
            ]}
            rows={rowsMemo}
            data={data}
            filters={filters}
            onFiltersChange={handleFiltersChange}
            api={(params, options) => SystemHistory.index({ id: warranty.homeowner.id, ...params }, options)}
            onDataChange={handleDataChange}
            leftSide={<>
                <DatePicker
                    clearable
                    id="detection-date-range-range"
                    name="detection_date_range"
                    placeholder="Select dates"
                    value={filters.detection_date_range.value}
                    onChange={handleFiltersChange}
                    options={{ singleMode: false, maxDate: new Date() }}
                />

                <Select
                    name="status"
                    id="status"
                    options={statusOptions}
                    value={filters.status.value}
                    onChange={handleFiltersChange}
                />
            </>}
        />

        <p className="italic text-sm text-gray-500 mt-6">
            History collection started on {new Date('02/29/2024').format()}
        </p>
    </>
}

export default SystemHistoryIndex
