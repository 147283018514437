import { Icon, IconProps } from './Icon'

export const IconFileInvoice = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M20.833 12.5V6.8c0-1.68 0-2.52-.34-3.162a3.065 3.065 0 0 0-1.366-1.311C18.458 2 17.583 2 15.833 2H9.166c-1.75 0-2.625 0-3.293.327a3.065 3.065 0 0 0-1.366 1.311c-.34.642-.34 1.482-.34 3.162v10.4c0 1.68 0 2.52.34 3.162.3.565.778 1.023 1.366 1.311C6.54 22 7.416 22 9.166 22h3.855m1.562-11h-6.25m2.083 4H8.333m8.333-8H8.333"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.365 20.333c0 1.013.855 1.834 1.91 1.834h1.91c1.054 0 1.909-.821 1.909-1.834 0-1.012-.855-1.833-1.91-1.833h-1.91c-1.054 0-1.91-.821-1.91-1.834 0-1.012.856-1.833 1.91-1.833h1.91c1.055 0 1.91.821 1.91 1.833m-2.865-2.75v9.167"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
