import { Badge } from '@/components'
import { IndexContainerDataType } from '@/types'

type IndexItemsCountBadgeProps = {
    id: string
    data: IndexContainerDataType
    dataName: string
}

export const IndexItemsCountBadge = ({
    id,
    data,
    dataName
}: IndexItemsCountBadgeProps) => {
    const { total } = data.meta
    const overall = data.meta.overall_count
    return !!data.meta.overall_count || !!data.meta.total || data.meta.total === 0
        ? <Badge
            className="bg-primary-50 text-primary-600 whitespace-nowrap"
            data-test={`${id}-index-count-badge`}
        >
            {total.format()} {overall && overall !== total ? ` of ${overall.format()}` : ''} {dataName}
        </Badge>
        : null
}
