import { useState } from 'react'
import { useNavigate, Link } from 'react-router'

import { Button, Checkbox, Input, Feature, FormErrorsType, Form } from '@/components'
import { GuestLayout, LoginWrapper } from '@/containers'
import { useAnalytics, useAppDispatch, useAppSelector, useToastNotifications } from '@/hooks'
import { AuthUser } from '@/models'
import { isApiError } from '@/services'
import { setRedirectUrl, login } from '@/store'
import { FormChangeEvent, JsonDataType } from '@/types'

interface LoginFormDataType extends JsonDataType {
    email: string
    password: string
    remember_me: string
}

const Login = () => {
    const { error } = useToastNotifications()
    const { setUser } = useAnalytics()
    const app = useAppSelector(state => state.app)
    const dispatch = useAppDispatch()
    const [errors, setErrors] = useState<FormErrorsType>({})
    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState<LoginFormDataType>({
        email: '',
        password: '',
        remember_me: ''
    })

    const navigate = useNavigate()

    const handleChange = (e: FormChangeEvent) => {
        setForm({
            ...form,
            [e.target.name]: e.target.type === 'checkbox' && !e.target.checked ? '' : e.target.value
        })
    }

    const handleSubmit = async () => {
        setErrors({})
        setProcessing(true)
        try {
            const res = await AuthUser.signIn(form)
            await dispatch(login(res))
            navigate(app.redirectUrl || '/dashboard')
            dispatch(setRedirectUrl(''))
            setUser(res.user)
        } catch (err) {
            if (!isApiError(err)) throw err
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    return <GuestLayout>
        <LoginWrapper>
            <div className="w-80 h-auto">
                <h1 className="text-3xl text-left font-medium font-display" data-test="login-title">
                    Welcome back
                </h1>
                <h3 className="text-gray-600 text-left login-subheader text-gray-700 font-body" data-test="login-subtitle">
                    Welcome back! Please enter your details.
                </h3>
                <Form onSubmit={handleSubmit} noValidate>
                    <Input
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        className="mb-4"
                        placeholder="Enter your email"
                        onChange={handleChange}
                        value={form.email}
                        errors={errors.email}
                        data-test="login-email"
                    />
                    <Input
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        className="mb-4"
                        placeholder="*******"
                        onChange={handleChange}
                        value={form.password}
                        errors={errors.password}
                        data-test="login-password"
                    />
                    <div className="flex justify-between mb-4 actions-section text-sm font-light font-body">
                        <Checkbox
                            checked={form.remember_me === 'yes'}
                            name="remember_me"
                            value="yes"
                            onChange={handleChange}
                            label="Remember me"
                            data-test="login-remember-me"
                        />
                        <Link
                            to="/forgot-password"
                            className="text-orange-700 font-normal"
                            data-test="login-forgot-password-link"
                        >
                            Forgot password
                        </Link>
                    </div>
                    <Button className="w-full" disabled={processing} data-test="login-submit-button">
                        Sign in
                    </Button>

                    <Feature name="onboarding">
                        <div className="links-section flex justify-center mt-5 gap-1 font-body text-sm">
                            <span className="text-gray-700">Don{'\''}t have an account?</span>
                            <Link to="/sign-up" className="text-orange-700">Sign up</Link>
                        </div>
                    </Feature>
                    <div className="links-section flex justify-center gap-1 font-body text-sm mt-5">
                        <Link to="/terms-of-service" className="text-orange-700" data-test="login-terms-link">Terms & Conditions </Link> and
                        <Link to="/privacy-policy" className="text-orange-700" data-test="login-privacy-link"> Privacy Policy</Link>
                    </div>
                </Form>
            </div>
        </LoginWrapper>
    </GuestLayout>
}

export default Login
