import { Icon, IconProps } from './Icon'

export const IconChevronDown = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="m6 9 6 6 6-6"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
