import { AccountTypeDataType } from '@/types'

export enum AccountTypeEnum {
    ADMINISTRATOR = 'administrator',
    STAFF = 'staff',
    ADVISOR = 'advisor',

    CONTRACTOR = 'contractor',
    CONTRACTOR_USER = 'contractor-user',

    SOFTWARE_AFFILIATE = 'software_affiliate',
    LENDER_AFFILIATE = 'lender_affiliate',
    AFFILIATE_USER = 'affiliate-user'
}

export enum AccountFormTypeEnum {
    USER = 'user',
    COMPANY = 'company',
    COMPANY_USER = 'company-user'
}

export const ACCOUNT_TYPES: AccountTypeDataType[] = [
    {
        key: AccountTypeEnum.ADMINISTRATOR,
        title: 'Administrator',
        type: AccountFormTypeEnum.USER
    },
    {
        key: AccountTypeEnum.STAFF,
        title: 'Staff',
        type: AccountFormTypeEnum.USER
    },
    {
        key: AccountTypeEnum.ADVISOR,
        title: 'Advisor',
        type: AccountFormTypeEnum.USER
    },

    {
        key: AccountTypeEnum.CONTRACTOR,
        title: 'Contractor Company',
        shortTitle: 'Contractor',
        type: AccountFormTypeEnum.COMPANY
    },
    {
        key: AccountTypeEnum.CONTRACTOR_USER,
        title: 'Contractor User',
        type: AccountFormTypeEnum.COMPANY_USER
    },

    {
        key: AccountTypeEnum.SOFTWARE_AFFILIATE,
        title: 'Software Affiliate Company',
        shortTitle: 'Software Affiliate',
        type: AccountFormTypeEnum.COMPANY
    },
    {
        key: AccountTypeEnum.LENDER_AFFILIATE,
        title: 'Lender Affiliate Company',
        shortTitle: 'Lender Affiliate',
        type: AccountFormTypeEnum.COMPANY
    },

    {
        key: AccountTypeEnum.AFFILIATE_USER,
        title: 'Affiliate User',
        type: AccountFormTypeEnum.COMPANY_USER
    }
]
