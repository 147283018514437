import { memo, ReactNode } from 'react'

import { Header, ImpersonationModeBanner, SideBar } from '@/components'

type AuthLayoutProps = {
    children: ReactNode,
    heading: ReactNode,
    breadcrumb?: Array<ReactNode>
}

const AuthLayout = ({ children, heading, breadcrumb }: AuthLayoutProps) => <>
    <ImpersonationModeBanner/>
    <div data-test="auth-layout" className="auth-layout flex flex-col sm:flex-row min-h-full px-2 pt-0 sm:px-0 sm:pt-3 bg-primary-700">
        <SideBar/>

        <div className="grow bg-primary-25 p-4 sm:p-8 rounded-t-2xl sm:rounded-tr-none sm:rounded-tl-3xl overflow-hidden">
            <Header className="mb-4 sm:mb-12" breadcrumb={breadcrumb}>
                {heading}
            </Header>
            {children}
        </div>
    </div>
</>

export default memo(AuthLayout)
