import classnames from 'classnames'
import { useState } from 'react'

import {
    Card,
    IconReloadCw05,
    ReportBoxes,
    GraphReport,
    MonitoringSummary,
    LinkedinInsights,
    SalesTrackingByMonth,
    CompanyActivity,
    DashboardAlert,
    PageHeader, MarketingPopup
} from '@/components'
import { AuthLayout } from '@/containers'
import { useAppSelector, useAnalytics } from '@/hooks'

const Dashboard = () => {
    const { trackEvent } = useAnalytics()
    const auth = useAppSelector(({ auth }) => auth)
    const [processing, setProcessing] = useState({
        reportBoxes: true,
        monitoringSummary: true,
        salesReport: true,
        salesTrackingByMonth: true,
        adminBar: !!auth.user?.role.isAdminOrStaffOrAdvisor
    })

    const getProcessing = () => Object.values(processing).some(item => item)

    const handleComplete = (name: string, value = false) => {
        setProcessing(processing => ({ ...processing, [name]: value }))
    }

    const handleHover = () => {
        trackEvent('hover_dashboard_reload_data', 'User Interaction', 'Reload Button Hover')
    }

    const handleReload = () => {
        trackEvent('click_dashboard_reload_data', 'User Interaction', 'Reload Button')
        setProcessing({
            reportBoxes: true,
            monitoringSummary: true,
            salesReport: true,
            salesTrackingByMonth: true,
            adminBar: !!auth.user?.role.isAdminOrStaffOrAdvisor
        })
    }

    return <AuthLayout
        heading={<PageHeader
            title={<div className="flex gap-6">
                Dashboard
                <MarketingPopup/>
            </div>}
        />}
    >
        <div className="flex justify-end my-4">
            <button
                className="text-primary-700 stroke-primary-700 flex items-center cursor-pointer"
                onClick={handleReload}
                onMouseOver={handleHover}
                onFocus={handleHover}
                disabled={getProcessing()}
                data-test="dashboard-reload-button"
            >
                <IconReloadCw05 className={`mr-2 ${getProcessing() ? 'animate-spin' : ''}`}/> Reload Data
            </button>
        </div>

        <DashboardAlert data-test="dashboard-alert" className="mb-4 lg:mb-6"/>

        <div className="grid grid-cols-12 gap-6">
            <Card className="col-span-12 xl:col-span-4 row-span-1 xl:row-span-2">
                <MonitoringSummary
                    processing={processing.monitoringSummary}
                    onChange={handleComplete}
                />
            </Card>
            <ReportBoxes
                processing={processing.reportBoxes}
                onChange={handleComplete}
            />

            <Card className="row-span-2 col-span-12 min-h-[25rem]">
                <GraphReport
                    processing={processing.salesReport}
                    onChange={handleComplete}
                />
            </Card>

            {auth.user?.role.isAdminOrStaffOrAdvisor &&
                <Card className="col-span-12 2xl:col-span-5 row-span-2">
                    <CompanyActivity
                        processing={processing.adminBar}
                        onChange={handleComplete}
                    />
                </Card>}
            {auth.user?.role.isContractor &&
                <Card className="col-span-12 2xl:col-span-4 row-span-2">
                    <LinkedinInsights/>
                </Card>}
            <SalesTrackingByMonth
                processing={processing.salesTrackingByMonth}
                onChange={handleComplete}
                className={classnames('col-span-12 row-span-2', auth.user?.role.isContractor ? '2xl:col-span-8' : '2xl:col-span-7')}
            />
        </div>
    </AuthLayout>
}

export default Dashboard
