import { Icon, IconProps } from './Icon'

export const IconSliders = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M3 8h12m0 0a3 3 0 1 0 6 0 3 3 0 0 0-6 0zm-6 8h12M9 16a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
