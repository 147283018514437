import { Button, IconDownload, ButtonSize } from '@/components'
import { WarrantyStatusEnum } from '@/enums'
import { useQuery } from '@/hooks'
import { Warranty, WarrantyStatus } from '@/models'

type DownloadWarrantiesButtonProps = {
    quantity?: number
    selected: Warranty[] | 'all'
    size?: ButtonSize
    excluded?: Warranty[]
    'data-test'?: string
}

const DOWNLOAD_LIMIT = 20

export const DownloadWarrantiesButton = ({
    quantity = 1,
    size = "btn-lg",
    selected,
    excluded = [],
    'data-test': dataTest
}: DownloadWarrantiesButtonProps) => {
    const query = useQuery()

    const handleClick = () => Warranty.downloadMany({
        ids: selected === 'all'
            ? excluded.map(({ id }) => id)
            : selected.map(({ id }) => id)
    })

    const isInactiveWarrantySelected = selected === 'all'
        ? query.statuses?.some((status: WarrantyStatusEnum) => WarrantyStatus.find(status).isInactive)
        : selected.some(item => item.status.isInactive)

    const getTooltip = () => {
        if (isInactiveWarrantySelected) {
            return 'This functionality is disabled for canceled warranties'
        }

        if (quantity < 1) {
            return 'Select at least one warranty to download the certificate.'
        }

        if (quantity > DOWNLOAD_LIMIT) {
            return `Download is limited to ${DOWNLOAD_LIMIT} warranties at a time.`
        }

        return `Download Warranty ${quantity > 1 ? 'Certificates' : 'Certificate'}`
    }

    return <Button
        square
        tooltip={getTooltip()}
        tooltipDisabled={false}
        design="btn-secondary-gray"
        size={size}
        disabled={quantity < 1 ||
            quantity > DOWNLOAD_LIMIT ||
            isInactiveWarrantySelected}
        onClick={handleClick}
        data-test={`${dataTest}-download-button`}
    >
        <IconDownload/>
    </Button>
}
