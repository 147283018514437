import { Suspense } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router'

import routes from './routes'
import { Loading } from '@/components'
import { Error } from '@/containers'
import AppRoute from '@/router/AppRoute'
import { loadingBar } from '@/services'

const router = createBrowserRouter(routes.map(item => ({
    ...item,
    element: <AppRoute route={item}/>,
    errorElement: <Error/>,
    async loader(e) {
        loadingBar.start()
        const res = item.loader ? await item.loader(e) : null
        loadingBar.stop()
        return res
    }
})))

const RouterView = () => <Suspense fallback={<Loading/>}>
    <RouterProvider router={router}/>
</Suspense>

export default RouterView

export * from './routes'
