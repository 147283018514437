import { Link } from 'react-router'

import { AccountCreateForm, IconBuilding07, PageHeader } from '@/components'
import { AuthLayout } from '@/containers'

const breadcrumb = [
    <IconBuilding07 key={1} className="stroke-gray-500"/>,
    <Link key={2} to="/companies">Companies</Link>,
    'Create New Account'
]

const CompaniesCreate = () =>
    <AuthLayout heading={<PageHeader title="Create a New Account" backUrl="/companies"/>} breadcrumb={breadcrumb}>
        <AccountCreateForm/>
    </AuthLayout>

export default CompaniesCreate
