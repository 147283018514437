import { createSearchParams, useNavigate } from 'react-router'

import { convert } from '@/hooks/useFormData'
import { QueryParamsType } from '@/types'

export default () => {
    const navigate = useNavigate()
    return (newQueryParams: QueryParamsType, pathname: string = location.pathname) => {
        const searchParams = new URLSearchParams(location.search)
        const oldQueryParams: QueryParamsType =
            Object.fromEntries([...searchParams].filter(([key, value]) => key && value))
        for (const key in oldQueryParams) {
            const isArray = /[A-Za-z0-9]+\[[0-9]+]/i.test(key)
            if (isArray) {
                const realKey = key.substring(0, key.indexOf('['))
                oldQueryParams[realKey] = oldQueryParams[realKey]
                    ? [...oldQueryParams[realKey], oldQueryParams[key]]
                    : [oldQueryParams[key]]
                Reflect.deleteProperty(oldQueryParams, key)
            }
        }
        const queryParams = {
            ...oldQueryParams,
            ...newQueryParams
        }
        const search = createSearchParams(convert(
            Object.keys(queryParams).reduce((acc, key) => queryParams[key]
                ? { ...acc, [key]: queryParams[key] }
                : acc, {}
            ), {}
        ) as { [key: string]: string })
        navigate({
            pathname,
            search: `?${search}`
        })
    }
}
