import { useEffect, useState } from 'react'

import {
    Button,
    Checkbox, Form, FormErrorsType,
    GeneracIntegrationInstructions,
    IntegrationMaintainerForm, IntegrationMaintainerFormDataType
} from '@/components'
import { IntegrationCredentialStatusEnum } from '@/enums'
import { useToastNotifications } from '@/hooks'
import { Company, Integration, InverterManufacturer } from '@/models'
import { isApiError } from '@/services'
import { FormChangeEvent } from '@/types'

type EditGeneracPortalFormProps = {
    company: Company
    integration?: Integration
    onChange: () => void
    onAdded: () => void
}

const EditGeneracMaintainerForm = ({ integration, company, ...props }: EditGeneracPortalFormProps) => {
    const { error } = useToastNotifications()
    const getMode = () => integration?.contact_name && integration?.contact_email
        ? 'view'
        : 'edit'

    const [mode, setMode] = useState<'view' | 'edit'>(getMode())
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState<FormErrorsType>({})

    const getForm = (): IntegrationMaintainerFormDataType => ({
        company_id: company.id,
        provider: InverterManufacturer.generac.key,
        status: integration?.contact_name && integration?.contact_email ? IntegrationCredentialStatusEnum.USES_MAINTAINER : '',
        contact_name: integration?.contact_name || '',
        contact_email:  integration?.contact_email || ''
    })

    const [form, setForm] = useState<IntegrationMaintainerFormDataType>(getForm())

    const handleChange = (e: FormChangeEvent) => setForm({ ...form, [e.target.name]: e.target.value })

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors({})

        try {
            if (integration) {
                await integration.update(form)
            } else {
                await Integration.store(form)
            }
            setMode('view')
            await props.onChange()
            props.onAdded()
        } catch (err) {
            if (!isApiError(err)) throw err
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    const handleClickUndo = async () => {
        setProcessing(true)
        try {
            await integration?.destroy()
            if (props.onChange) {
                await props.onChange()
            }
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        setForm(getForm())
        setMode(getMode())
    }, [company])

    const isUsesMaintainer = form.status === IntegrationCredentialStatusEnum.USES_MAINTAINER

    const isDisabled = () => !isUsesMaintainer || !form.contact_email || !form.contact_name

    return <div className="w-full">
        {mode === 'edit'
            ? <Form onSubmit={handleSubmit} className="flex flex-col gap-4 items-start">
                {!integration?.isUsesMaintainer && <GeneracIntegrationInstructions className="self-start"/>}

                <div className="flex flex-wrap gap-x-6 gap-y-2">
                    <Checkbox
                        className="w-full"
                        label="I have associated my Generac monitoring portal with SolarInsure."
                        name="status"
                        checked={isUsesMaintainer}
                        value={isUsesMaintainer ? '' : IntegrationCredentialStatusEnum.USES_MAINTAINER}
                        onChange={handleChange}
                    />

                    <IntegrationMaintainerForm
                        errors={errors}
                        disabled={!isUsesMaintainer}
                        form={form}
                        onChange={handleChange}
                    />
                </div>
                <div className="flex flex-wrap gap-3">
                    <Button disabled={!isUsesMaintainer} design={!isUsesMaintainer ? 'btn-tertiary' : 'btn-secondary-gray'} hasError={isUsesMaintainer} onClick={() => setForm(getForm)}>
                        Cancel
                    </Button>
                    <Button disabled={isDisabled()} design={!isUsesMaintainer ? 'btn-tertiary' : 'btn-primary'} processing={processing}>
                        Submit Confirmation & E-Sign
                    </Button>
                </div>
            </Form>
            : <div className="flex flex-wrap gap-4 justify-between items-end">
                <Checkbox
                    className="w-full"
                    label={<div>
                        I have associated my Generac monitoring portal with SolarInsure.
                        <p className="text-sm text-gray-500">
                            {integration?.contact_name}&nbsp;
                            {integration?.contact_email}&nbsp;
                            {integration?.status_change_detected_at?.format()}
                        </p>
                    </div>}
                    name="status"
                    checked
                    disabled
                />

                <div className="flex flex-wrap gap-10">
                    <Button design="btn-link" hasError className="whitespace-nowrap" onClick={handleClickUndo} disabled={processing}>
                        Undo Signature
                    </Button>

                    <Button design="btn-link" className="!text-primary-800 whitespace-nowrap" onClick={() => setMode('edit')} disabled={processing}>
                        Edit Signature
                    </Button>
                </div>
            </div>}
    </div>
}

export default EditGeneracMaintainerForm
