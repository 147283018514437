import { Invoice } from '@/models'
import { api } from '@/services'

export const invoices = {
    email(id: string | number, email: string) {
        return api.http.get(`api/v1/invoices/email/${id}`, { email })
    },

    download(id: string | number) {
        return api.http.get(`api/v1/invoices/download/${id}`)
    },

    downloadMany(invoices: Invoice[]) {
        const params = invoices.reduce((acc, { row_id: rowId, date }, index) => {
            const d = new Date(date).isValid() ? new Date(date) : new Date(`1 ${date}`)
            return {
                ...acc,
                [`invoices[${index}][company_id]`]: rowId,
                [`invoices[${index}][date]`]: d.toISODate()
            }
        }, {})
        return api.http.get('api/v1/invoices/download-many', params)
    }
}
