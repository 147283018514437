import { Icon, IconProps } from './Icon'

export const IconPin = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M12 22c1-5 8-5.582 8-12a8 8 0 1 0-16 0c0 6.418 7 7 8 12z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
