import { ChartDataset, ChartOptions, Plugin } from 'chart.js'
import { ReactNode } from 'react'

import Chart from './Chart'

type BarChartProps = {
    datasets: ChartDataset[]
    labels: string[]
    plugins?: Plugin[]
    options?: ChartOptions
    customTooltip?: (index: number) => ReactNode
}

const BarChart = ({ datasets, labels, plugins = [], options = {}, customTooltip }: BarChartProps) => <Chart
    type="bar"
    data={{
        datasets,
        labels
    }}
    plugins={plugins}
    customTooltip={customTooltip}
    options={{
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                grid: { color: '#f5f4f1' },
                border: { display: false }
            },
            x: {
                grid: { display: false },
                border: { display: false }
            }
        },
        ...options
    }}
/>

export default BarChart
