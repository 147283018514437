import { Icon, IconProps } from './Icon'

export const IconCurrencyDollar = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M6 16a4 4 0 0 0 4 4h4a4 4 0 0 0 0-8h-4a4 4 0 0 1 0-8h4a4 4 0 0 1 4 4m-6-6v20"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
