import { Button, IconAlertTriangle, Popup } from '@/components'
import { Company } from '@/models'
import { FormChangeEventHandler } from '@/types'

type CompanyAffiliateChangePromptProps = {
    open: boolean
    company: Company
    onChange: FormChangeEventHandler
}

const CompanyAffiliateChangePrompt = ({ open, company, ...props }: CompanyAffiliateChangePromptProps) => {
    const handleClose = () => {
        props.onChange({
            target: {
                name: 'conglomerate_id',
                value: company.conglomerate_id || company.conglomerate?.id || ''
            }
        })
    }

    const handleChange = (value: string) => {
        props.onChange({
            target: {
                name: 'update_policy',
                value
            }
        })
    }

    return <Popup open={open} onClose={handleClose}>
        <div className="p-4">
            <h3 className="text-xl leading-8 flex items-center gap-3 text-gray-900 font-semibold mb-2">
                <IconAlertTriangle size="xl" className="stroke-warning-600"/>
                Warning: Warranty Association Decision Needed
            </h3>

            <div className="text-gray-500">

                <p className="mb-4">
                    You are about to associate this subcontractor to an Affiliate with a 100% warranty
                    match method. This may affect past warranties registered by this subcontractor.
                    Please choose one of the following options:
                </p>

                <h4 className="font-semibold mb-4">Option 1: Assign Future Warranties Only</h4>
                <ul className="mb-4 ml-6 list-disc">
                    <li className="mb-1">
                        All warranties registered by this subcontractor moving forward will be assigned to the selected
                        Affiliate partner.
                    </li>
                    <li>
                        Past warranties will remain unchanged and will not be associated with the new Affiliate partner.
                    </li>
                </ul>

                <h4 className="font-semibold mb-4">Option 2: Assign Future and Update Past Warranties</h4>
                <ul className="mb-4 ml-6 list-disc">
                    <li className="mb-1">
                        All warranties registered by this subcontractor moving forward will be assigned to the selected
                        Affiliate partner.
                    </li>
                    <li>
                        Additionally, the affiliate association of all past warranties will be updated to the new
                        Affiliate partner.
                    </li>
                </ul>
            </div>

            <p className="text-error-600 mb-8">This action is irreversible. Please choose carefully.</p>

            <div className="flex justify-center gap-4">
                <Button
                    type="button"
                    name="update_policy"
                    value="future_only"
                    onClick={() => handleChange('future_only')}
                >
                    Option 1: Future Only
                </Button>
                <Button
                    type="button"
                    name="update_policy"
                    value="all"
                    onClick={() => handleChange('all')}
                >
                    Option 2: Future + Past
                </Button>
            </div>
        </div>
    </Popup>
}

export default CompanyAffiliateChangePrompt
