import { useEffect, useState } from 'react'

import { Button, Form, Select, Table } from '@/components'
import { UploadAffiliateRowDataType, UploadAffiliateFormDataType } from '@/containers/warranties/UploadAffiliateData'
import { Warranty } from '@/models'
import { fuse, loadingBar } from '@/services'
import { FormChangeEvent, FormChangeEventHandler } from '@/types'

export type ColumnType = {
    key: string
    title: string
    index: number
    score?: number
}

type ColumnMappingStepProps = {
    form: UploadAffiliateFormDataType
    onCancel: () => void
    onNext: () => void
    onChange: FormChangeEventHandler
}

const defaultColumns: ColumnType[] = [
    { key: 'company_unique_id', title: 'Company ID', index: 0 },
    { key: 'company_name', title: 'Company', index: 1 },
    { key: 'policy_num', title: 'Policy Number', index: 2 },
    { key: 'first_name', title: 'First Name', index: 3 },
    { key: 'last_name', title: 'Last Name', index: 4 },
    { key: 'street_address', title: 'Street Address', index: 5 }
]

const ColumnMappingStep = ({ form, ...props }: ColumnMappingStepProps) => {
    const [processing, setProcessing] = useState(false)
    const getFileColumns = () => Object.keys(form.data[0]).map((item: string, index) => ({ value: index, title: item }))
    const [columns, setColumns] = useState(defaultColumns.map(item => {
        const res = fuse(getFileColumns().map(item => item.title), item.title)
        return {
            ...item,
            score: typeof res?.score === 'undefined' ? 1 : res.score,
            index: typeof res?.refIndex === 'undefined' ? null : res.refIndex
        }
    }) as ColumnType[])

    const handleSubmit = async () => {
        loadingBar.start()
        setProcessing(true)
        try {
            const res = await Warranty.validateMatch(form.payload)
            props.onChange({ target: { name: 'response', value: res.data } })
            props.onNext()
        } finally {
            setProcessing(false)
            loadingBar.stop()
        }
    }

    const handleChange = (e: FormChangeEvent) => {
        const clone = columns.map(item => item.key === e.target.name
            ? { ...item, index: e.target.value }
            : item) as ColumnType[]
        setColumns(clone)
    }

    useEffect(() => {
        const payload = form.data.map(item => ({
            affiliate_id: form.affiliate?.id,
            ...columns.reduce((acc, column) => {
                if (column.index === null) return acc
                const key = getFileColumns()[column.index].title
                return { ...acc, [column.key]: item[key as keyof UploadAffiliateRowDataType] }
            }, {})
        }))
        props.onChange({
            target: {
                name: 'payload',
                value: payload
            }
        })
    }, [columns])

    return <Form onSubmit={handleSubmit}>
        <h4 className="text-primary-700 py-4 mb-2">Column Mapping</h4>
        <p className="mb-4 text-sm">Please make sure the column headers match the required fields in the right column.</p>

        <Table
            className="stripped-table"
            options={{
                pagination: false,
                sortable: false,
                useQueryParams: false
            }}
            columns={[
                {
                    title: '#',
                    field: 'number',
                    cellClassName: row => row.score as number > 0.5 ? 'bg-warning-50' : '',
                    className: 'w-13.5'
                },
                { title: 'Uploaded File Headers', field: 'uploaded_file_headers', cellClassName: 'text-base' },
                { title: 'Solar Insure Headers', field: 'solar_insure_headers', cellClassName: 'text-base' }
            ]}
            rows={columns.map((item, index) => ({
                id: item.key,
                index: item.index,
                score: item.score,
                number: index + 1,
                uploaded_file_headers: <Select
                    className="min-w-[250px]"
                    id={`file-header-${item.key}`}
                    name={item.key}
                    options={[{ value: '', title: '' }, ...getFileColumns()]}
                    value={item.index}
                    onChange={handleChange}
                />,
                solar_insure_headers: item.title
            }))}
        />

        <div className="flex justify-end gap-3 mt-4">
            <Button processing={processing} design="btn-secondary-gray" onClick={props.onCancel} type="button">
                Previous
            </Button>

            <Button processing={processing} disabled={columns.some(item => item.index === null)}>
                Next
            </Button>
        </div>
    </Form>
}

export default ColumnMappingStep
