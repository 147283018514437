import { IconInfoCircle, WarrantyUpdateDetails } from '@/components'
import { WarrantyHistoryTypeEnum } from '@/enums/WarrantyHistoryTypeEnum'
import { Warranty, WarrantyHistory } from '@/models'
import { WarrantyChangeDataType } from '@/types'

export type WarrantyEditDetailsProps = {
    event: WarrantyHistory
    warranty: Warranty
}

export const WarrantyEditDetails = ({ event, warranty }: WarrantyEditDetailsProps) =>
    <div>
        <div className="flex items-center gap-2">
            <IconInfoCircle className="stroke-warning-600" size="lg"/>
            <div>
                <h2 className="text-xl leading-8 font-semibold text-gray-900">
                    Warranty Edit{event.event?.key === WarrantyHistoryTypeEnum.WARRANTY_EDIT_REQUESTED ? ' Request' : ''} Details
                </h2>
                <h3 className="text-sm text-gray-750 leading-5">
                    Request Date - {event.created_at?.format({
                        hour: '2-digit',
                        minute: '2-digit'
                    }) || 'N/A'}
                </h3>
            </div>
        </div>

        <div className="my-6">
            <span className="font-semibold">Plan ID:</span> {warranty.policy_num}
        </div>

        <WarrantyUpdateDetails
            oldValues={event.properties?.old as WarrantyChangeDataType | undefined}
            newValues={event.properties?.attributes as WarrantyChangeDataType | undefined}
        />

        {event.properties?.contact_email && <div className="mt-4 text-sm">
            <div>
                <span className="font-semibold">Edit Reason</span>
                <span className="italic font-light"> {event.properties?.reason}</span>
            </div>
            <div>
                <span className="font-semibold">Contact Email:</span>
                <a href={`mailto:${event.properties?.contact_email}`} className="italic font-light"> {event.properties?.contact_email}</a>
            </div>
        </div>}

        <p className="text-sm mt-4 text-gray-500">
            For more information, please reach out to a Solar Insure representative.
        </p>
    </div>
