import { COMPANY_TYPES, CompanyTypeEnum } from '@/enums'
import { CompanyTypeDataType } from '@/types'

export class CompanyType {
    key: CompanyTypeEnum

    title: string

    constructor(data: CompanyTypeDataType) {
        this.key = data.key
        this.title = data.title
    }

    static get all() {
        return COMPANY_TYPES.map(item => new CompanyType(item))
    }

    static find(key: CompanyTypeEnum) {
        return CompanyType.all.find(item => item.key === key) ||
            CompanyType.defaultType
    }

    static get defaultType() {
        return CompanyType.all.find(item => item.key === CompanyTypeEnum.CONTRACTOR) as CompanyType
    }

    get isContractor() {
        return this.key === CompanyTypeEnum.CONTRACTOR
    }

    get isSoftwareAffiliate() {
        return this.key === CompanyTypeEnum.SOFTWARE_AFFILIATE
    }

    get isLenderAffiliate() {
        return this.key === CompanyTypeEnum.LENDER_AFFILIATE
    }

    get isAffiliate() {
        return this.isLenderAffiliate || this.isSoftwareAffiliate
    }
}
