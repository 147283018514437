import { useMemo, useState } from 'react'

import {
    Button,
    IconDownload,
    IconX,
    Switch,
    IconLockUnlocked, Select, PageHeader, Feature, IconCheck
} from '@/components'
import { AuthLayout, IndexContainer } from '@/containers'
import { useAuth, useToastNotifications, useIndexContainer } from '@/hooks'
import { AuthUser, User } from '@/models'
import {
    FormChangeEvent,
    IndexContainerChangeEvent,
    UserIndexDataType,
    UserIndexFiltersType
} from '@/types'

export const USER_INDEX_FILTERS: UserIndexFiltersType = {
    search: {
        label: 'Search',
        defaultValue: '',
        value: '',
        isStandalone: true
    },
    role: {
        label: 'Account Type',
        defaultValue: '',
        value: '',
        isStandalone: true
    }
}

const UsersIndex = () => {
    const { success } = useToastNotifications()
    const auth = useAuth()
    const { filters, handleFiltersChange } = useIndexContainer(USER_INDEX_FILTERS)

    const [reload, setReload] = useState(false)
    const [data, setData] = useState<UserIndexDataType>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0
        },
        rows: []
    })

    const handleChange = (e: IndexContainerChangeEvent) => {
        setData(data => ({ ...data, [e.name]: e.value }))
    }

    const handleStatusChange = async (user: User, e: FormChangeEvent) => {
        await user.destroy()
        success(`User successfully ${e.target.checked ? 'suspended' : 'reinstated'}.`)
        setReload(true)
    }

    const handleToggleVerificationCLick = async (user: User) => {
        await user.toggleVerification()
        setReload(true)
    }

    const handleResetPasswordClick = async (user: User) => {
        await AuthUser.forgotPassword({ email: user.email })
        success(`Reset link successfully sent to ${user.email}.`)
    }

    const rowsMemo = useMemo(() => data.rows.map(item => ({
        id: item.id,
        name: item.name,
        company: item.company?.name,
        email: item.email,
        account_type: item.accountType?.title,
        status: <Switch
            checked={!item.deleted_at}
            onChange={e => handleStatusChange(item, e)}
            disabled={item.role.isAdminOrStaff || !auth.user?.role.isAdminOrStaff}
            input-size="sm"
        />,
        status_raw: item.status,
        last_online: item.last_online,
        actions: <div className="flex items-center gap-2">
            <Button
                tooltip="Reset Password"
                disabled={!auth.user?.role.isAdminOrStaff}
                design="btn-link"
                onClick={() => handleResetPasswordClick(item)}
            >
                <IconLockUnlocked className="stroke-gray-300 hover:stroke-orange-600"/>
            </Button>
            <Button
                tooltip={item.email_verified_at ? 'Mark As Not Verified' : 'Mark As Verified'}
                design="btn-link"
                onClick={() => handleToggleVerificationCLick(item)}
                disabled={item.role.isAdminOrStaff || !auth.user?.role.isAdminOrStaff}
            >
                {item.email_verified_at
                    ? <IconX className="stroke-gray-300 hover:stroke-error-600"/>
                    : <IconCheck className="stroke-gray-300 hover:stroke-success-600"/>}
            </Button>

            <Feature name="onboarding">
                <Button design="btn-link" tooltip="Download">
                    <IconDownload className="stroke-gray-300 hover:stroke-primary-600"/>
                </Button>
            </Feature>
        </div>,

        _path: `/users/${item.id}`
    })), [data.rows])

    return <AuthLayout heading={<PageHeader title="Users"/>}>
        <IndexContainer
            id="users"
            api={(...args) => User.index(...args)}
            data={data}
            onDataChange={handleChange}
            filters={filters}
            tableOptions={{ dataName: 'Users' }}
            onFiltersChange={handleFiltersChange}
            reload={reload}
            onReloadChange={setReload}
            columns={[
                { title: 'User Name', field: 'name' },
                { title: 'Company', field: 'company' },
                { title: 'User Email', field: 'email' },
                { title: 'Account Type', field: 'account_type' },
                { title: 'Status', field: 'status', clickable: false },
                { title: 'Last Online', field: 'last_online' },
                { title: 'Actions', field: 'actions', clickable: false, sortable: false }
            ]}
            rows={rowsMemo}
            leftSide={<>
                <Select
                    name="role"
                    id="account-type"
                    options={[
                        { value: '', title: 'Any Account Type' },
                        { value: 'advisor', title: 'Advisor' },
                        { value: 'administrator', title: 'Administrator' },
                        { value: 'staff', title: 'Staff' },
                        { value: 'contractor', title: 'Contractor User' },
                        { value: 'conglomerate', title: 'Affiliate User' }
                    ]}
                    value={filters.role.value}
                    onChange={handleFiltersChange}
                />
            </>}
            rightSide={<>
                {auth.user?.role.isAdminOrStaff && <Button href="/users/create">
                    New Account
                </Button>}
            </>}
        />
    </AuthLayout>
}

export default UsersIndex
