import { ReactNode } from 'react'

import { feature } from '@/services'
import { FeatureNameType } from '@/utils'

type FeatureProps = {
    name: FeatureNameType,
    active?: boolean
    children: ReactNode
}

export const Feature = ({
    name,
    children,
    active = true
}: FeatureProps) => <>{feature(name, active) ? children : null}</>
