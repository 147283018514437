import { ReactNode } from 'react'

import { Popup, IconFileInvoice } from '@/components'

type InvoiceHistoryInformationPopupProps = {
    children: ReactNode
    isOpen: boolean
    onClose: () => void
}

const InvoiceHistoryInformationPopup = ({ isOpen, children, ...props }: InvoiceHistoryInformationPopupProps) => <>
    <span role="button" tabIndex={-1}>
        {children}
    </span>
    <Popup
        open={isOpen}
        onClose={props.onClose}
        className="w-200"
    >
        <div className="px-2 md:px-4 py-4">
            <div className="flex items-center gap-3 mb-6">
                <IconFileInvoice className="stroke-primary-700" data-test="invoice-history-information-icon"/>
                <p className="font-semibold text-base md:text-xl font-display" data-test="invoice-history-information-header">Welcome to Invoice Summary</p>
            </div>
            <div className="lg:px-4 text-sm font-body">
                <p>
                    This section offers a dynamic summary of warranty registrations associated with your invoices.
                    Each row displays aggregated data for a given month, showing you the currently active warranties
                    and related panels for that period.</p>
                <p className="mt-4">
                    The information here dynamically updates to reflect any recent changes to warranties, such as
                    price edits or cancellations, which means it may differ from the initial invoice details.
                </p>
                <p className="mt-4 font-semibold">
                    Monthly Invoice Access and Payment:
                </p>
                <p className="mt-4">
                    To review your monthly invoices and complete payments, please <span className="font-semibold">refer to the QuickBooks emails</span> sent to
                    your preferred contact address. In QuickBooks, our dedicated invoicing system, you&apos;ll find
                    detailed payment instructions and information on outstanding balances. This is also where your
                    official and static invoice records are maintained, reflecting the exact charges at the time each
                    invoice was generated.
                </p>
                <p className="mt-4">
                    If you need <span className="font-semibold">assistance</span> with invoice details, warranty activities, payment processing, or have other
                    billing-related questions, our <span className="font-semibold">account managers are ready to provide personalized support.</span> For direct
                    help, please contact them.</p>
                <p className="mt-4">
                    Alternatively, for general inquiries or further assistance, reach out to us at <a href="mailto:accounting@solarinsure.com" data-test="invoice-history-contact-us-email">accounting@solarinsure.com</a>
                </p>
            </div>
        </div>
    </Popup></>

export default InvoiceHistoryInformationPopup
