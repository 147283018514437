import { useState, useEffect } from 'react'

import { Alert, Button, IconBatteryCharging, IconHome, IconAlertTriangle } from '@/components'
import { useAuth } from '@/hooks'
import { Invoice } from '@/models'
import { isApiCancelError, sessionStorage } from '@/services'

type DashboardAlertProps = {
    className?: string
}

const DashboardAlert = ({ className, ...props }: DashboardAlertProps) => {
    const [abortController, setAbortController] = useState<AbortController | null>(null)
    const [seenBanners, setSeenBanners] = useState<string[]>(sessionStorage.get('seen_banners') as string[] || [])
    const [isInvoiced, setIsInvoiced] = useState(false)
    const [processing, setProcessing] = useState(true)
    const auth = useAuth()

    const fetchIsInvoiced = async (controller: AbortController) => {
        try {
            const { meta } = await Invoice.index({}, { signal: controller.signal })
            setIsInvoiced(!!meta?.overall_count)
        } catch (err) {
            if (!isApiCancelError(err)) {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    const fetchData = () => {
        const controller = new AbortController
        setAbortController(controller)
        if (auth.user?.role.isContractor) {
            fetchIsInvoiced(controller)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => () => {
        abortController?.abort('canceled')
    }, [abortController])

    const handleClose = (banner: string) => {
        const seenBanners = sessionStorage.get('seen_banners') as string[] || []
        seenBanners.push(banner)
        setSeenBanners(seenBanners)
        sessionStorage.set('seen_banners', seenBanners)
    }

    if (processing) return null

    const banners = [
        {
            name: 'welcome',
            showIf: auth.user?.role.isContractor && !auth.user?.company.integrations.length && !isInvoiced,
            banner: <Alert
                type="custom"
                className="bg-orange-400 font-body"
                icon={<div className="bg-orange-100 flex items-center justify-center p-3 rounded-lg">
                    <IconHome className="stroke-orange-600" size="lg"/>
                </div>}
            >
                <div className="flex gap-2 flex-col lg:flex-row items-start lg:items-center justify-between grow">
                    <p className="leading-6 ml-4">
                        Welcome to the Solar Insure Web Platform! To finish Certification and start
                        registering <br/>Solar Insure warranties, <span className="font-semibold">Add Your Monitoring
                        Credentials</span></p>

                    <Button design="btn-secondary-gray" href="/profile/integrations">
                        Add Your Credentials
                    </Button>
                </div>
            </Alert>
        },
        {
            name: 'invalidCredentials',
            showIf: auth.user?.role.isContractor &&
            !seenBanners.includes('invalidCredentials') &&
            (auth.user?.company.monitoringStatus.isInvalid ||
                auth.user?.company.monitoringStatus.isMissing ||
                auth.user?.company.monitoringStatus.isIncomplete),
            banner: <Alert
                type="custom"
                icon={<div className="w-10 h-10 rounded-full bg-error-50 flex justify-center items-center">
                    <div className="w-8 h-8 rounded-full bg-error-100 flex justify-center items-center stroke-error-700">
                        <IconAlertTriangle/>
                    </div>
                </div>}
                className="bg-white border-error-300 font-body"
                onClose={() => handleClose('invalidCredentials')}
            >
                <div className="flex gap-2 flex-col lg:flex-row items-start lg:items-center justify-between grow">
                    <p className="leading-6">
                        <span className="font-semibold">You have invalid or missing monitoring credentials!</span><br/>
                        Update now to enable Solar Insure Monitoring.
                    </p>
                    <Button design="btn-secondary" href="/profile/integrations" className="whitespace-nowrap">
                        <span className="font-normal">
                            Update Credentials
                        </span>
                    </Button>
                </div>
            </Alert>
        },
        {
            name: 'batteryInfo',
            showIf: auth.user?.role.isContractor &&
                !auth.user?.company.eligibleProductTypes.some(item => item.hasStorage) &&
                !seenBanners.includes('batteryInfo'),
            banner: <Alert
                type="custom"
                icon={<div className="w-10 h-10 rounded-full bg-purple-50 flex justify-center items-center">
                    <div className="w-8 h-8 rounded-full bg-purple-100 flex justify-center items-center stroke-purple-700">
                        <IconBatteryCharging/>
                    </div>
                </div>}
                className="bg-white font-body"
                onClose={() => handleClose('batteryInfo')}
            >
                <div className="flex gap-2 flex-col lg:flex-row items-start lg:items-center justify-between grow">
                    <p className="leading-6">
                        Interested in selling our <span className="font-semibold">New Battery Monitoring & Warranty
                        Product</span>? <br/>
                        Learn More about our Certified Installer program!
                    </p>
                    <Button
                        design="btn-secondary-gray"
                        href="https://www.solarinsure.com/battery"
                        className="whitespace-nowrap"
                        size="btn-sm"
                    >
                        Learn More
                    </Button>
                </div>
            </Alert>
        },
        {
            name: 'batteryWelcome',
            showIf: auth.user?.role.isContractor &&
                auth.user?.company.eligibleProductTypes.some(item => item.hasStorage) &&
                !seenBanners.includes('batteryWelcome'),
            banner: <Alert
                type="custom"
                className="bg-white font-body"
                onClose={() => handleClose('batteryWelcome')}
                icon={<div className="w-10 h-10 rounded-full bg-purple-50 flex justify-center items-center">
                    <div className="w-8 h-8 rounded-full bg-purple-100 flex justify-center items-center stroke-purple-700">
                        <IconBatteryCharging/>
                    </div>
                </div>}
            >
                <div className="flex gap-2 flex-col lg:flex-row items-start lg:items-center justify-between grow">
                    <p className="leading-6">
                        You can now sell our <span className="font-semibold">New Battery Monitoring & Warranty
                        Product</span>! Congrats on becoming a Certified Installer!
                    </p>
                    <Button
                        design="btn-secondary-gray"
                        href="https://www.solarinsure.com/battery"
                        className="whitespace-nowrap"
                        size="btn-sm"
                    >
                        Learn More
                    </Button>
                </div>
            </Alert>
        }
    ]

    const getBanner = () => banners.find(({ showIf }) => showIf)?.banner

    return <div className={className} {...props}>{getBanner()}</div>
}

export default DashboardAlert
