import { api, Params } from '@/services'

export const products = {
    indexTypes() {
        return api.http.get('api/v1/product-types')
    },
    batteries(params?: Params) {
        return api.http.get('api/v1/products/batteries', params)
    }
}
