import ReactGA from 'react-ga4'
import { UaEventOptions } from 'react-ga4/types/ga4'

import store from '@/store'

type Params = {
    user_detail: {
        name?: string,
        email?: string,
        role?: string
    }
}

type FieldObjectType = {
    hitType: string
    page: string
    title: string
}

export const ENABLED = import.meta.env.PROD && import.meta.env.VITE_GTAG_ID
const { user } = store.getState().auth

declare global {
    interface Window {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        gtag: (...args: any[]) => any
    }
}

export const setUserProperties = () => {
    if (ENABLED) {
        ReactGA.set({ userId: user?.id || null })
        window.gtag('set', 'user_properties', {
            name: user?.name || null,
            email: user?.email || null,
            role: user?.role.key || null,
            company_name: user?.company?.name || null,
            company_unique_id: user?.company?.unique_id || null
        })
    }
}

export const initGA = () => {
    if (ENABLED) {
        ReactGA.initialize(import.meta.env.VITE_GTAG_ID)
        setUserProperties()
    }
}

export const gaEvent = (optionsOrName: UaEventOptions | string, params?: Params) => {
    if (ENABLED) {
        ReactGA.event(optionsOrName, params)
    }
}

export const gaSend = (fieldObject: FieldObjectType) => {
    if (ENABLED) {
        ReactGA.send(fieldObject)
    }
}

initGA()
