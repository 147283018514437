import {
    Button,
    CustomManufacturerForm, CustomManufacturerFormDataType, FormErrorsType,
    FormWarningsType,
    IconX,
    Tooltip
} from '@/components'
import { FormChangeEvent, FormChangeEventHandler, JsonDataType } from '@/types'

export interface CustomManufacturersFormDataType extends JsonDataType {
    integrations: CustomManufacturerFormDataType[]
}

type CustomManufacturersFormProps = {
    form: CustomManufacturersFormDataType
    errors: FormErrorsType
    warnings?: FormWarningsType
    onChange: FormChangeEventHandler
    onDelete: (e: CustomManufacturerFormDataType) => void
    inputClassName?: string
    className?: string
}
export const CustomManufacturersForm = ({
    form,
    errors,
    warnings,
    inputClassName,
    className,
    ...props
}: CustomManufacturersFormProps) => {
    const handleChange = (e: FormChangeEvent, index: number) => {
        const integrations = [...form.integrations]
        integrations[index][e.target.name] = e.target.value
        props.onChange({ target: { name: 'integrations', value: integrations } })
    }

    const handleDeleteRow = async (index: number) => {
        props.onDelete(form.integrations[index])
    }

    const isDisabled = () => {
        const [last] = form.integrations
        return form.integrations.length === 1 && !last.provider && !last.user && !last.secret
    }

    const getErrors = (index: number) => {
        const obj: FormErrorsType = {}
        const keyStart = `integrations.${index}.`
        Object.keys(errors).forEach(key => {
            const fieldName = key.replace(keyStart, '')
            const value = errors[key] as string
            if (key.startsWith(keyStart)) {
                obj[fieldName] = value.replace(key, fieldName).replaceAll('_', ' ')
            }
        })
        return obj
    }

    return <div className={`flex flex-col gap-6 ${className}`}>
        {form.integrations.map((item, index) =>
            <div key={item.id} className="flex flex-col md:flex-row items-center gap-6">
                <CustomManufacturerForm
                    index={index}
                    form={item}
                    errors={getErrors(index)}
                    warnings={warnings}
                    onChange={(e: FormChangeEvent) => handleChange(e, index)}
                    inputClassName={inputClassName}
                />

                <Button design="btn-link" type="button" onClick={() => handleDeleteRow(index)} disabled={isDisabled()}>
                    <Tooltip content="Remove & Delete Credentials" className="md:mt-5">
                        <IconX className={isDisabled() ? 'stroke-error-300' : 'stroke-error-600'}/>
                    </Tooltip>
                </Button>
            </div>)}
    </div>
}
