import { Icon, IconProps } from './Icon'

export const IconCheckSteps = (props: IconProps) =>
    <Icon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m22.795 9.853-9.546 9.214-2.534-2.707c-.466-.44-1.2-.466-1.733-.093-.52.387-.667 1.067-.347 1.613l3 4.88c.294.453.8.733 1.374.733a1.63 1.63 0 0 0 1.36-.733c.48-.627 9.64-11.547 9.64-11.547 1.2-1.226-.254-2.306-1.214-1.373v.013z"
        />
    </Icon>
