import { useEffect, useState } from 'react'
import { Link, useLoaderData, useLocation } from 'react-router'

import {
    WarrantyPricingOverview,
    Card, CreateWarrantyHomeownerFormDataType, CreateWarrantySystemFormDataType, Form, FormErrorsType,
    IconCertificate,
    PageHeader,
    Steps, SystemIdWarningPopup, WarrantyCreateFinalizeStep,
    WarrantyCreateHomeownerStep, WarrantyCreatePreviewStep,
    WarrantyCreateSystemStep, WarrantyTypeSwitchButton
} from '@/components'
import { AuthLayout } from '@/containers'
import { InverterManufacturerEnum, WarrantyProductTypeEnum } from '@/enums'
import { useAuth, useToastNotifications } from '@/hooks'
import { InverterManufacturer, Warranty, WarrantyProductType, Company } from '@/models'
import { isApiError, uuid } from '@/services'
import { IdType, FormChangeEvent } from '@/types'

const breadcrumb = [
    <IconCertificate key={1} className="stroke-gray-500"/>,
    <Link key={2} to="/warranties">Warranties</Link>,
    'Add Warranty - Single Registration'
]

const heading = <PageHeader title="Create New Single Warranty" backUrl="/warranties"/>

export interface WarrantyCreateFormType extends CreateWarrantyHomeownerFormDataType, CreateWarrantySystemFormDataType {
    company_id: IdType
    type: WarrantyProductTypeEnum
}

const WarrantiesCreate = () => {
    const auth = useAuth()
    const { state } = useLocation()
    const {
        company,
        warrantyProductType
    } = useLoaderData() as { company: Company, warrantyProductType: WarrantyProductType }
    const { error } = useToastNotifications()
    const [step, setStep] = useState(0)
    const [showWarrantyPricingOverview, setShowWarrantyPricingOverview] = useState(false)
    const [showSystemIdWarning, setShowSystemIdWarning] = useState(false)
    const [errors, setErrors] = useState<FormErrorsType>({})
    const [warranty, setWarranty] = useState<Warranty | null>(null)
    const [processing, setProcessing] = useState(false)
    const [previewPdf, setPreviewPdf] = useState<Blob | null>(null)
    const getDefaultForm = ():WarrantyCreateFormType => ({
        company_id: company.id,
        type: warrantyProductType.key,

        first_name: '',
        last_name: '',
        email: '',
        phone: '',

        street_address: '',
        city: '',
        state: '',
        zip: '',

        duplicate_override: undefined,
        allow_invalid: undefined,

        num_panels: '',
        panel_name: '',
        panel_wattage: '',
        size_kw: '',

        inverter_name: '',
        inverter_manufacturer: '',
        num_microinverters: '',
        install_date: null,
        system_id: '',

        batteries: warrantyProductType.hasStorage ? [{
            id: uuid(),
            product_id: '',
            size_kw: '',
            serial_number: ''
        }] : []
    })
    const [form, setForm] = useState<WarrantyCreateFormType>(getDefaultForm())

    const handleSubmit = async (formOverride = {}, validateOnly = false) => {
        setErrors({})
        setProcessing(true)

        const payload = {
            ...form,
            ...formOverride,
            ...!warrantyProductType.hasStorage && { batteries: [] },
            ...!warrantyProductType.hasPowerProduction && {
                panel_name: '',
                size_kw: 0,
                num_panels: 0,
                panel_wattage: 0
            }
        }

        try {
            if (step === 0) {
                await Warranty.validateHomeowner(payload)
                if (!validateOnly) {
                    setStep(1)
                }
            }
            if (step === 1) {
                const res = await Warranty.validateSystem(payload)
                if (!validateOnly) {
                    setPreviewPdf(res)
                    if (!form.system_id && auth.user?.role.isContractor) {
                        setShowSystemIdWarning(true)
                    } else {
                        setShowWarrantyPricingOverview(true)
                    }
                }
            }
            if (step === 2) {
                const res = await Warranty.store(payload)
                setWarranty(new Warranty(res.data))
                if (!validateOnly) {
                    setStep(3)
                }
            }
        } catch (err) {
            if (!isApiError(err)) throw err
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    const handleChange = (e: FormChangeEvent) => {
        const isChecked = e.target.checked ? 1 : 0
        const value = e.target.type === 'checkbox'
            ? isChecked
            : e.target.value
        setForm(form => ({
            ...form,
            [e.target.name]: value
        }))
        if (e.target.dataset?.submit) {
            handleSubmit({ [e.target.name]: value })
        }
    }

    useEffect(() => {
        if (state?.reset) {
            setStep(0)
            setForm(getDefaultForm())
            setWarranty(null)
            setPreviewPdf(null)
        } else {
            const newForm = {
                ...form,
                company_id: company.id,
                type: warrantyProductType.key,
                ...warrantyProductType.hasStorage && !form.batteries.length
                    ? {
                        batteries: [{
                            id: uuid(),
                            product_id: '',
                            size_kw: '',
                            serial_number: ''
                        }]
                    }
                    : {}
            }
            setForm(newForm)
            handleSubmit(newForm, true)
        }
    }, [company, warrantyProductType])

    return <AuthLayout heading={heading} breadcrumb={breadcrumb}>
        <Card>
            <Form onSubmit={handleSubmit} className="md:px-2 lg:px-10" noValidate>
                <Steps
                    className="md:px-5 xl:px-40 mb-12"
                    steps={[
                        'Homeowner Info',
                        'System Info',
                        'Preview Certificate',
                        'Finalize Warranty'
                    ]}
                    step={step}
                />

                {step < 2 && <>
                    <div className="flex flex-wrap justify-between items-center">
                        <h2 className="flex items-center text-xl font-semibold">
                            {warrantyProductType.brandedTitle}
                        </h2>
                        <div className="flex flex-col md:flex-row items-center gap-2 md:gap-4 mt-2 md:mt-0 text-gray-900">
                            Need to register for a different warranty?
                            <WarrantyTypeSwitchButton
                                company={company}
                                warrantyProductType={warrantyProductType}
                            />
                        </div>
                    </div>
                    <hr className="lg:col-span-2 mt-4 border-gray-200"/>
                </>}

                {step === 0 && <WarrantyCreateHomeownerStep
                    form={form}
                    errors={errors}
                    onChange={handleChange}
                    processing={processing}
                />}

                {step === 1 && <WarrantyCreateSystemStep
                    form={form}
                    errors={errors}
                    company={company}
                    onChange={handleChange}
                    onPrevious={() => setStep(0)}
                    processing={processing}
                    warrantyProductType={warrantyProductType}
                />}

                {step === 2 && <WarrantyCreatePreviewStep
                    onPrevious={() => setStep(1)}
                    processing={processing}
                    pdf={previewPdf}
                />}

                {step === 3 && <WarrantyCreateFinalizeStep
                    warranty={warranty}
                />}
            </Form>
        </Card>

        {showWarrantyPricingOverview && <WarrantyPricingOverview
            form={form}
            company={company}
            warrantyProductType={warrantyProductType}
            onClose={() => setShowWarrantyPricingOverview(false)}
            onNext={() => {
                setShowWarrantyPricingOverview(false)
                setStep(2)
            }}
        />}

        <SystemIdWarningPopup
            show={showSystemIdWarning}
            onClose={() => setShowSystemIdWarning(false)}
            inverterManufacturer={InverterManufacturer.find(form.inverter_manufacturer as InverterManufacturerEnum)}
            onSkip={() => {
                setShowSystemIdWarning(false)
                setShowWarrantyPricingOverview(true)
            }}
            onAdd={() => {
                setShowSystemIdWarning(false)
                const el = document.querySelector('[name="system_id"]') as HTMLInputElement
                el.focus()
            }}
        />
    </AuthLayout>
}

export default WarrantiesCreate
