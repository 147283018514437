import { ReactNode } from 'react'

export type ErrorsType = ReactNode | ReactNode[]

export type FormErrorsType = {
    [key: string]: ErrorsType
}

type InputErrorProps = {
    errors: ErrorsType
}

export const InputError = ({ errors, ...props }: InputErrorProps) =>
    <>
        {Array.isArray(errors)
            ? errors.map((item, index) =>
                <div className="text-error-500 text-sm mt-1.5" {...props} key={index}>{item}</div>)
            : <div className="text-error-500 text-sm mt-1.5" {...props}>{errors}</div>}
    </>
