import { FormErrorsType, Input, TextEditor } from '@/components'
import { FormChangeEventHandler, JsonDataType, IdType } from '@/types'

export interface ArticleFormDataType extends JsonDataType {
    category_id: IdType
    title: string
    content: string
}

type ArticleFormProps = {
    form: ArticleFormDataType
    errors: FormErrorsType
    onChange: FormChangeEventHandler
}

export const ArticleForm = ({
    form,
    errors,
    ...props
}: ArticleFormProps) => <>
    <Input
        id="title"
        name="title"
        label="Title*"
        placeholder="Title"
        onChange={props.onChange}
        value={form.title}
        errors={errors.title}
    />

    <TextEditor
        id="content"
        name="content"
        label="Content*"
        placeholder="Content"
        onChange={props.onChange}
        value={form.content}
        errors={errors.content}
    />
</>
