import { ReactNode } from 'react'

import { Card } from '@/components'

type ReportBoxProps = {
    className?: string
    icon: ReactNode
    children?: ReactNode
    title: string
    value: ReactNode
    'data-test'?: string
}

const ReportBox = ({ className, icon, title, value, children, 'data-test': dataTest }: ReportBoxProps) =>
    <Card className={`${className} flex flex-col gap-6`} data-test={dataTest}>
        <h3 className="flex items-center justify-start gap-4 w-full lg:w-auto">
            <div className="shrink-0 stroke-gray-900 p-3 rounded-xl shadow-sm" data-test={`${dataTest}-icon`}>
                {icon}
            </div>
            <div className="flex justify-between items-baseline w-full gap-x-2 flex-wrap text-2xl font-semibold">
                <div className="text-base font-normal" data-test={`${dataTest}-title`}>{title}</div>
                {value}
            </div>
        </h3>

        <div className="flex items-center justify-start">
            {children}
        </div>
    </Card>

export default ReportBox
