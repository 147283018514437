import { Icon, IconProps } from './Icon'

export const IconMicroInverter = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M7.5 11.5v-3h-5v6.178h5V11.5zm0 0h4M15 5.41V2.322h5V8.5h-5V5.41zm0 0h-3.5v6.09m0 0v6.178H15m0 0v-3h5v6.179h-5v-3.178z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
