import { useEffect, useMemo, useState } from 'react'

import {
    AddressForm,
    Button,
    CompanyForm, CompanyFormDataType,
    EligibleCommissionEditForm, Form, FormErrorsType,
    Popup, SingleAddressFormDataType
} from '@/components'
import { useAuth, useToastNotifications } from '@/hooks'
import { Company } from '@/models'
import { isApiError } from '@/services'
import { FormChangeEvent } from '@/types'

interface CompanyEditFormDataType extends CompanyFormDataType, SingleAddressFormDataType {}

type CompanyEditFormProps = {
    company: Company,
    onSuccess?: () => void
    onExit: () => void
}

const CompanyEditForm = ({ company, ...props }: CompanyEditFormProps) => {
    const { success, error } = useToastNotifications()
    const auth = useAuth()
    const [processing, setProcessing] = useState(false)
    const [showEligibleCommissionPopup, setShowEligibleCommissionPopup] = useState(false)
    const [errors, setErrors] = useState<FormErrorsType>({})
    const getForm = (): CompanyEditFormDataType => ({
        id: company.id,
        name: company.name || '',
        email: company.email || '',
        address: company.address || '',

        role: auth.user?.role.isAdminOrStaff ? company.type.key || '' : undefined,
        create_warranty: auth.user?.role.isAdminOrStaff ? company.create_warranty || 'disabled' : undefined,
        battery_eligibility: auth.user?.role.isAdminOrStaff && !company?.type.isAffiliate
            ? company.battery_eligibility.key : undefined,

        policy_rate: auth.user?.role.isAdminOrStaff && !company?.type.isAffiliate
            ? company.product_rates?.power_production || '' : undefined,
        battery_only_30_year_rate: auth.user?.role.isAdminOrStaff && !company?.type.isAffiliate
            ? company.product_rates?.storage_only || '' : undefined,
        battery_20_year_rate: auth.user?.role.isAdminOrStaff && !company?.type.isAffiliate
            ? company.product_rates?.separate_20_year_storage || '' : undefined,
        battery_30_year_rate: auth.user?.role.isAdminOrStaff && !company?.type.isAffiliate
            ? company.product_rates?.separate_30_year_storage || '' : undefined,

        solar_commission_rate: auth.user?.role.isAdminOrStaff && company?.type.isSoftwareAffiliate
            ? company.currentEligibleCommission?.solar_commission_rate || ''
            : undefined,
        battery_commission_rate: auth.user?.role.isAdminOrStaff && company?.type.isSoftwareAffiliate
            ? company.currentEligibleCommission?.battery_commission_rate || ''
            : undefined,
        warranty_match_method: auth.user?.role.isAdminOrStaff && company?.type.isAffiliate
            ? company.warranty_match_method.key || '' : undefined,

        monthly_sales_quota: auth.user?.role.isAdminOrStaff
            ? company.monthly_sales_quota || 0 : undefined,
        conglomerate_id: auth.user?.role.isAdminOrStaff && company.type.isContractor
            ? company.conglomerate?.id || '' : undefined,
        update_policy: auth.user?.role.isAdminOrStaff ? '' : undefined,

        unique_id: auth.user?.role.isAdminOrStaff ? company.unique_id || '' : undefined
    })
    const [form, setForm] = useState<CompanyEditFormDataType>(getForm())

    const handleChange = (e: FormChangeEvent) => {
        setForm(form => ({
            ...form,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        }))
    }

    const handleEligibleCommissionFocus = () => {
        setShowEligibleCommissionPopup(true)
    }

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors({})
        const clone = { ...form }
        if (!clone.update_policy) {
            clone.update_policy = 'all'
        }
        try {
            if (auth.user?.company?.id === clone.id) {
                await Company.updateSelf(clone)
            } else {
                await company.update(clone)
            }
            success('Company successfully updated!')
            if (props.onSuccess) await props.onSuccess()
            props.onExit()
        } catch (err) {
            if (!isApiError(err)) throw err
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        setForm(form => ({
            ...form,
            solar_eligible_commission_rate: auth.user?.role.isAdminOrStaff && company?.type.isSoftwareAffiliate
                ? company.currentEligibleCommission?.solar_commission_rate || ''
                : undefined,
            battery_eligible_commission_rate: auth.user?.role.isAdminOrStaff && company?.type.isSoftwareAffiliate
                ? company.currentEligibleCommission?.battery_commission_rate || ''
                : undefined
        }))
    }, [company])

    const isDirty = useMemo(() => Object.keys(form).some(key =>
        getForm()[key]?.toString() !== form[key]?.toString()), [form, company])

    return <>
        <Form isDirty={isDirty} confirmation className="grid lg:grid-cols-2 gap-6" onSubmit={handleSubmit}>
            <CompanyForm
                form={form}
                company={company}
                errors={errors}
                onChange={handleChange}
                onEligibleCommissionFocus={handleEligibleCommissionFocus}
            />

            <div className="col-start-1">
                <AddressForm
                    inline
                    form={form}
                    errors={errors}
                    onChange={handleChange}
                />
            </div>

            <div className="lg:col-span-2 flex flex-col gap-3 md:flex-row justify-end">
                <Button design="btn-secondary-gray" hasError onClick={props.onExit} type="button">
                    Exit Edit Mode Without Saving
                </Button>
                <Button processing={processing} disabled={!isDirty}>
                    Save and Exit
                </Button>
            </div>
        </Form>

        <Popup open={showEligibleCommissionPopup} onClose={() => setShowEligibleCommissionPopup(false)}>
            {showEligibleCommissionPopup && <EligibleCommissionEditForm
                company={company}
                onSuccess={props.onSuccess}
            />}
        </Popup>
    </>
}

export default CompanyEditForm
