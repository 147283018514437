import { Badge, Button, IconX, Tooltip } from '@/components'
import { FormChangeEventHandler, IndexContainerFiltersType, AppliedFilterType } from '@/types'

type IndexAppliedFiltersSectionProps = {
    filters: IndexContainerFiltersType
    appliedFilters: AppliedFilterType[]
    onClear?: () => void
    onChange: FormChangeEventHandler
}

export const IndexAppliedFiltersSection = ({
    filters,
    appliedFilters,
    ...props
}: IndexAppliedFiltersSectionProps) => {
    if (!appliedFilters.length) return null

    const handleChange = (appliedFilter: AppliedFilterType) => {
        const filter = filters[appliedFilter.key]
        if (filter.isMultiple) {
            const value = filter.value as string[]
            const displayValue = filter.displayValue as string[]
            const index = value.indexOf(appliedFilter.value)
            props.onChange({
                target: {
                    name: appliedFilter.key,
                    value: value.filter((_, i) => i !== index),
                    displayValue: displayValue.filter((_, i) => i !== index)
                }
            })
        } else {
            props.onChange({
                target: {
                    value: filter.defaultValue,
                    name: appliedFilter.key
                }
            })
        }
    }

    return <div className="flex justify-between gap-4 items-start">
        <div className="flex flex-wrap gap-2">
            {appliedFilters.map(item => <Tooltip
                key={`${item.key}-${item.value}`}
                content={item.label}
            >
                <Badge
                    className="bg-primary-100 text-primary-700 whitespace-nowrap flex gap-1 items-center"
                >
                    {item.displayValue}
                    <Button
                        design="btn-link"
                        onClick={() => handleChange(item)}
                    >
                        <IconX size="sm" className="stroke-primary-700"/>
                    </Button>
                </Badge>
            </Tooltip>)}
        </div>

        <Button
            design="btn-link"
            size="btn-lg"
            className="!text-primary-800 gap-2 whitespace-nowrap"
            onClick={props.onClear}
        >
            <IconX className="stroke-black"/>
            Clear All Filters
        </Button>
    </div>
}
