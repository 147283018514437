import { Icon, IconProps } from './Icon'

export const IconDiamondBrand = (props: IconProps) =>
    <Icon {...props} viewBox="0 0 20 19">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 9C5.52285 9 10 13.4772 10 19C10 13.4772 14.2533 9 19.5 9C19.6677 9 19.8344 9.00457 20 9.01361V8.98707C19.8344 8.99565 19.6677 9 19.5 9C14.421 9 10.2729 5.01428 10.0129 0H9.98639C9.7127 5.01428 5.3463 9 0 9Z"
        />
    </Icon>
