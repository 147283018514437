import { FormValueType, JsonDataType } from '@/types'

export const convert = (
    value: FormValueType | JsonDataType | FormValueType[] | JsonDataType[],
    data: FormData | JsonDataType = new FormData(),
    namespace = ''
): FormData | { [key: string]: string } => {
    const isFormData = data instanceof FormData
    if (value instanceof Date) {
        if (isFormData) data.append(namespace, value.toJSON())
        else data[namespace] = value.toJSON()
    } else if (value instanceof File) {
        if (isFormData) data.append(namespace, value)
        else data[namespace] = value
    } else if (Array.isArray(value)) {
        for (const index in value) {
            convert(value[index], data, `${namespace}[${index}]`)
        }
    } else if (typeof value === 'object') {
        for (const key in value) {
            const name = namespace ? `${namespace}[${key}]` : key as string
            convert((value as JsonDataType)[key], data, name)
        }
    } else if (typeof value === 'boolean') {
        if (isFormData) data.append(namespace, value ? '1' : '0')
        else data[namespace] = value
    } else if (typeof value === 'undefined') {
        // skip undefined value
    } else {
        if (isFormData) data.append(namespace, value.toString())
        else data[namespace] = value
    }

    return data as FormData | { [key: string]: string }
}

export default () => ({ convert })
