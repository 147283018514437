import { useState } from 'react'
import { useNavigate } from 'react-router'


import { Button, Form, FormErrorsType, Input, Logo } from '@/components'
import { FormChangeEvent } from '@/types'

type ComponentProps = {
    onSubmit: () => void
}

type MemberFormDataType = {
    email: string
    role: string
}

type StepFormDataType = {
    members: MemberFormDataType[]
}

const StepFive = ({ ...props }: ComponentProps) => {
    const [errors, setErrors] = useState<FormErrorsType>({})
    const [form, setForm] = useState<StepFormDataType>({
        members: [
            { email: '', role: '' }
        ]
    })
    const navigate = useNavigate()

    const handleSubmit = async () => {
        setErrors({})
        props.onSubmit()
    }

    const handleChange = (e: FormChangeEvent, index: number) => {
        if (typeof index === 'undefined') {
            setForm({ ...form, [e.target.name]: e.target.value })
        } else {
            const clone = [...form.members]
            clone[index as number][e.target.name as keyof MemberFormDataType] = e.target.value as string
            setForm({ ...form, members: clone })
        }
    }

    const handleAddUser = () => {
        setForm({
            ...form,
            members: [...form.members, { email: '', role: '' }]
        })
    }

    const invitations = [
        {
            email: 'john@doe.com',
            role: 'CEO'
        },
        {
            email: 'jane@doe.com',
            role: 'Operations & Maintenance Manager'
        }
    ]

    const skipToFinalStep = () => {
        navigate('/sign-up/6')
    }

    return <div className="h-auto signup-step-five md:flex-row">
        <Logo type="small" className="mx-auto small-logo w-12 h-12 object-contain mb-8"/>
        <h1 className="text-3xl text-center text-header font-medium">
            Invite Your Team
        </h1>
        <h3 className="text-gray-600 text-center signup-subheader text-gray-700 font-light">
            Most of our partners have multiple employees that use the Solar Insure for different functions. For example,
            an Office Administrator may own registering warranties, while an Operations & Maintenance Manager needs
            monitoring data, and an Accountant needs access to invoicing.
        </h3>
        <h3 className="text-gray-600 text-center signup-subheader text-gray-700 font-semibold account-setup">
            Account setup has already been sent to the following users at your company:
        </h3>
        {invitations?.length && invitations.map((item, index) => <div key={index}>
            <div className="flex justify-between flex-col md:flex-row md:px-0 items-center gap-5">
                <div className="w-full">
                    <Input
                        id={`invitation-email-${index}`}
                        name="email"
                        label="Email"
                        type="email"
                        className="mb-4"
                        disabled
                        value={item.email}
                    />
                </div>
                <div className="w-full">
                    <Input
                        id={`invitation-role-${index}`}
                        name="role"
                        label="Employee Role/Job Title"
                        className="mb-4 disabled"
                        disabled
                        value={item.role}
                    />
                </div>
            </div>
        </div>)}
        <h3 className="text-gray-600 text-center signup-subheader text-gray-700 font-light account-setup font-normal">
            Add Additional Team Members Below:
        </h3>

        <Form onSubmit={handleSubmit} noValidate>
            {form.members.map((item, index) => <div className="flex justify-between flex-col md:flex-row items-center gap-5" key={index}>
                <div className="w-full">
                    <Input
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        className="mb-4 disabled"
                        placeholder="Member email"
                        value={item.email}
                        errors={errors[`email[${index}]`]}
                        onChange={(e: FormChangeEvent) => handleChange(e, index)}
                    />
                </div>
                <div className="w-full">
                    <Input
                        id="role"
                        name="role"
                        label="Employee Role/Job Title"
                        type="text"
                        className="mb-4 disabled"
                        placeholder="Member role"
                        value={item.role}
                        errors={errors[`role[${index}]`]}
                        onChange={(e: FormChangeEvent) => handleChange(e, index)}
                    />
                </div>
            </div>)}
            <div className="mb-10 mt-2 flex justify-center md:justify-start add-another-member">
                <Button type="button" className="flex justify-start w-48" design="btn-link" onClick={handleAddUser}>
                    Add Another Team Member
                </Button>
            </div>
            <div className="buttons flex flex-col md:flex-row gap-5">
                <Button className="w-full" design="btn-secondary" onClick={skipToFinalStep}>
                    Skip & Continue
                </Button>
                <Button className="w-full">
                    Add Team Members & Continue
                </Button>
            </div>
        </Form>
    </div>
}

export default StepFive
