import classnames from 'classnames'
import {
    ChangeEvent,
    ReactNode,
    SelectHTMLAttributes,
    KeyboardEvent,
    useEffect,
    useRef,
    useState, useCallback, useMemo, memo
} from 'react'

import SelectOption, { SelectOptionProps } from './SelectOption'
import {
    Dropdown,
    FormControl,
    FormControlProps,
    IconChevronDown
} from '@/components'

type ValueType = string | number

export interface SelectProps extends FormControlProps, SelectHTMLAttributes<HTMLSelectElement> {
    options: SelectOptionProps[]
    selectedOptionOverride?: (title: ReactNode) => ReactNode
    showChevron?: boolean
    native?: boolean
}

const RESERVED_OPTIONS: ValueType[] = [
    'toggle'
]

export const Select = memo(({
    id,
    label,
    options,
    native,
    hint,
    errors = [],
    warnings = [],
    className,
    preIcon,
    postIcon,
    value,
    transparent,
    tooltip,
    'input-size': size,
    'data-test': dataTest,
    selectedOptionOverride,
    showChevron = true,
    ...props
}: SelectProps) => {
    const dropdownRef = useRef<HTMLUListElement>(null)
    const [activeIndex, setActiveIndex] = useState<number>(0)

    const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
        const lastIndex = options.length - 1
        if (e.code === 'ArrowUp') {
            e.preventDefault()
            const index = activeIndex > 0 ? activeIndex - 1 : lastIndex
            if (dropdownRef.current) {
                dropdownRef.current.children[index].scrollIntoView()
            }
            setActiveIndex(index)
        }
        if (e.code === 'ArrowDown') {
            e.preventDefault()
            const index = activeIndex < lastIndex ? activeIndex + 1 : 0
            if (dropdownRef.current) {
                dropdownRef.current.children[index].scrollIntoView({ block: 'end' })
            }
            setActiveIndex(index)
        }
        if (e.code === 'Enter' && props.onChange) {
            e.preventDefault()
            props.onChange({
                target: {
                    value: (options[activeIndex] as SelectOptionProps)?.value,
                    name: props.name
                }
            } as ChangeEvent<HTMLSelectElement>)
        }
    }

    const defaultIndex = options.findIndex(item => item.value === '')
    const selectedIndex = options.findIndex(item =>
        value?.toString() === item.value?.toString() || (value === null && !item.value))

    const selectedOption = options[selectedIndex]
    const defaultOption = options[defaultIndex]
    useEffect(() => {
        setActiveIndex(selectedIndex)
    }, [value])

    const findOption = (value: ValueType) => options.find(item => item.value === value)
    const getDescendants = useCallback((value: ValueType): ValueType[] =>
        options.reduce((acc: ValueType[], option) =>
            option.parent === value ? [...acc, option.value, ...getDescendants(option.value)] : acc, []), [options])

    const getAncestors = useCallback((value: ValueType): ValueType[] => {
        const option = findOption(value)
        return option?.parent ? [option?.parent, ...getAncestors(option.parent)] : []
    }, [options])

    const title = useMemo<ReactNode>(() => {
        if (selectedOptionOverride) {
            return selectedOptionOverride(selectedOption?.title as ReactNode)
        }
        if (props.multiple && Array.isArray(value) && value.length) {
            const count = options.reduce((acc, item) => !getDescendants(item.value).length &&
                (value.includes(item.value) || getAncestors(item.value).some(item => value.includes(item)))
                ? acc + 1 : acc, 0)

            if (count) {
                return `Selected (${count})`
            } else if (defaultOption) {
                return defaultOption.title as ReactNode
            }
        }
        if (selectedOption?.title) {
            return selectedOption.title as ReactNode
        }
        return 'Select an option'
    }, [value, options])

    const handleChange = useCallback((val: ValueType) => {
        if (props.onChange) {
            let selected: ValueType | ValueType[] = value as ValueType | ValueType[]
            if (props.multiple && Array.isArray(selected) && !RESERVED_OPTIONS.includes(val)) {
                if (val) {
                    selected = selected as ValueType[]
                    const getChildren = (value: ValueType): ValueType[] => options.reduce(
                        (acc: ValueType[], option) => option.parent === value ? [...acc, option.value] : acc, [])

                    const option = findOption(val)
                    const descendants = getDescendants(val)
                    const ancestors = getAncestors(val)
                    if (descendants.length) {
                        selected = selected.filter(item => !descendants.includes(item))
                    }
                    const capturedSelected = selected as ValueType[]

                    if (selected.includes(val)) {
                        selected = selected.filter(item => item !== val)
                    } else if (ancestors.some(item => capturedSelected.includes(item))) {
                        if (option?.parent) {
                            let currentChild = option
                            let currentParent = findOption(option.parent)
                            while (currentParent) {
                                if (!selected.includes(currentParent.value) &&
                                    getAncestors(currentParent.value).every(item => !capturedSelected.includes(item))) {
                                    break
                                }
                                const children = getChildren(currentParent.value)
                                const currentParentValue = currentParent.value
                                const currentChildValue = currentChild.value
                                selected = [
                                    ...selected.filter(item => item !== currentParentValue),
                                    ...children.filter(item => item !== currentChildValue)
                                ]
                                currentChild = currentParent
                                currentParent = currentParent.parent ? findOption(currentParent.parent) : undefined
                            }
                        }
                    } else {
                        selected = [...selected, val]

                        if (option?.parent) {
                            let current = findOption(option.parent)
                            while (current) {
                                const children = getChildren(current.value)
                                const capturedSelected = selected as ValueType[]
                                if (children.every(item => capturedSelected.includes(item))) {
                                    selected = [
                                        ...selected.filter(item => !children.includes(item)),
                                        current.value
                                    ]
                                }
                                current = current.parent ? findOption(current.parent) : undefined
                            }
                        }
                    }
                } else {
                    selected = []
                }
            } else {
                selected = val
            }
            props.onChange({
                target: {
                    value: selected,
                    name: props.name
                }
            } as ChangeEvent<HTMLSelectElement>)
        }
    }, [options, value, props.name])

    dataTest = dataTest || id

    return <FormControl
        id={id}
        label={label}
        input-size={size}
        data-test={dataTest}
        preIcon={preIcon}
        postIcon={postIcon || (native && <IconChevronDown size="lg" className="stroke-gray-500"/>)}
        errors={errors}
        hint={hint}
        warnings={warnings}
        transparent={transparent}
        tooltip={tooltip}
        className={classnames('form-control-select', className)}
    >
        {native
            ? <select id={id} value={value === null ? '' : value} {...props} data-test={`${dataTest}-select`}>
                {options.map(item =>
                    typeof item === 'object'
                        ? <option key={item.value} value={item.value}>{item.title as ReactNode}</option>
                        : <option key={item} value={item}>{item}</option>)}
            </select>
            : <Dropdown
                withChevron={showChevron}
                trigger="click"
                disabled={props.disabled}
                persistent={props.multiple}
                reference={<button
                    id={id}
                    type="button"
                    disabled={props.disabled}
                    className={classnames('form-control-element', { placeholder: selectedOption?.value === '' || title === defaultOption?.title })}
                    onKeyDown={handleKeyDown}
                    data-test={`${dataTest}-select-button`}
                >
                    {title}
                </button>}
            >
                <ul
                    ref={dropdownRef}
                    className="-m-2 overflow-auto min-w-full"
                >
                    {options.map((option, index) => <SelectOption
                        key={option.value}
                        {...option}
                        active={selectedOption?.value === option.value || index === activeIndex}
                        multiple={props.multiple}
                        selectedOption={selectedOption}
                        allOptions={options}
                        selectedValue={value}
                        onChange={handleChange}
                        data-test={dataTest}
                    />)}
                </ul>
            </Dropdown>}

        <select
            className="hidden"
            name={props.name}
            onChange={props.onChange}
            value={value}
            multiple={props.multiple}
            data-test={`${dataTest}-select`}
        >
            {options.map(item => <option key={item.value} value={item.value}></option>)}
        </select>
    </FormControl>
})
