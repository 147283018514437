import {
    autoUpdate,
    flip, FloatingPortal,
    offset,
    Placement,
    shift, size,
    useClick,
    useDismiss,
    useFloating, useFocus, useHover,
    useInteractions, useRole
} from '@floating-ui/react'
import { Strategy } from '@floating-ui/utils'
import classnames from 'classnames'
import {
    ReactNode,
    useEffect,
    useState,
    memo
} from 'react'

import { IconChevronDown } from '@/components'

type DropdownProps = {
    reference: ReactNode
    children: ReactNode
    trigger?: 'click' | 'hover' | 'manual'
    isOpen?: boolean
    className?: string
    disabled?: boolean
    withChevron?: boolean
    placement?: Placement
    strategy?: Strategy
    persistent?: boolean
    onChange?: (e: { open: boolean }) => void
}

export const Dropdown = memo(({
    children,
    className,
    trigger = 'click',
    placement = 'bottom-start',
    reference,
    withChevron = false,
    strategy,
    disabled = false,
    persistent = false,
    ...props
}: DropdownProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const {
        refs,
        floatingStyles,
        context
    } = useFloating({
        open: isOpen,
        placement,
        strategy,
        onOpenChange: setIsOpen,
        middleware: [
            offset(4),
            flip(),
            shift({ padding: 4 }),
            size({
                apply({
                    availableHeight,
                    elements
                }) {
                    elements.floating.style.maxHeight = `${availableHeight - 4}px`
                }
            })
        ],
        whileElementsMounted: autoUpdate
    })

    const click = useClick(context, { enabled: !disabled && trigger === 'click' })
    const hover = useHover(context, { enabled: !disabled && trigger === 'hover' })
    const focus = useFocus(context, { enabled: !disabled && trigger === 'hover' })
    const dismiss = useDismiss(context)
    const role = useRole(context)

    const {
        getReferenceProps,
        getFloatingProps
    } = useInteractions([
        click,
        hover,
        focus,
        dismiss,
        role
    ])

    useEffect(() => {
        if (props.onChange) {
            if (isOpen !== props.isOpen) {
                props.onChange({ open: isOpen })
            }
        }
    }, [isOpen])

    useEffect(() => {
        if (typeof props.isOpen !== 'undefined') {
            if (props.isOpen !== isOpen) {
                setIsOpen(props.isOpen)
            }
        }
    }, [props.isOpen])

    const handleDropdownClick = () => {
        if (!persistent) {
            setIsOpen(false)
        }
    }

    return <>
        <div
            ref={refs.setReference}
            {...getReferenceProps()}
            className="relative"
        >
            {reference}
            {withChevron && <IconChevronDown
                className={classnames(
                    'stroke-gray-500 absolute right-4 top-1/2 -translate-y-1/2 transition-all',
                    { 'rotate-180': isOpen }
                )}
            />}
        </div>
        {isOpen && children && <FloatingPortal>
            <div
                ref={refs.setFloating}
                style={floatingStyles}
                {...getFloatingProps()}
                className="rounded-md bg-white shadow-md p-2 overflow-auto animate-fade-in z-50"
                onClick={handleDropdownClick}
            >
                {children}
            </div>
        </FloatingPortal>}
    </>
})
