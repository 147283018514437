import { IconChevronDown, IconFileDownload02 } from '@/components/icons'
import { Button, Dropdown } from '@/components/ui'

export type ExportFileType = 'csv' | 'json'

type ExportButtonProps = {
    disabled: boolean
    tooltip?: string
    onExport: (e: ExportFileType) => void
    'data-test'?: string
}

export const ExportButton = ({
    disabled,
    tooltip = 'Export',
    'data-test': dataTest,
    ...props
}: ExportButtonProps) => {
    const handleClick = (e: ExportFileType) => {
        props.onExport(e)
    }

    return <Dropdown
        disabled={disabled}
        reference={<Button
            tooltip={tooltip}
            tooltipDisabled={false}
            design="btn-secondary"
            size="btn-lg"
            disabled={disabled}
            data-test={`${dataTest}-export-button`}
        >
            <IconFileDownload02/>
            <IconChevronDown className="ml-2"/>
        </Button>}
    >
        <div className="p-2 flex flex-col gap-2">
            <Button
                design="btn-link-gray"
                className="w-full whitespace-nowrap"
                onClick={() => handleClick('csv')}
                data-test={`${dataTest}-export-csv`}
            >
                Export CSV
            </Button>
            <Button
                design="btn-link-gray"
                className="w-full whitespace-nowrap"
                onClick={() => handleClick('json')}
                data-test={`${dataTest}-export-json`}
            >
                Export JSON
            </Button>
        </div>
    </Dropdown>
}
