import { useState } from 'react'

import { Button, Form, FormErrorsType, Input } from '@/components'
import { LoginWrapper } from '@/containers'
import { useToastNotifications } from '@/hooks'
import { AuthUser } from '@/models'
import { isApiError } from '@/services'
import { FormChangeEvent, JsonDataType } from '@/types'

interface ForgotPasswordFormDataType extends JsonDataType {
    email: string
}

const ForgotPassword = () => {
    const { success, error } = useToastNotifications()
    const [errors, setErrors] = useState<FormErrorsType>({})
    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState<ForgotPasswordFormDataType>({ email: '' })

    const handleChange = (e: FormChangeEvent) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async () => {
        setErrors({})
        setProcessing(true)
        try {
            await AuthUser.forgotPassword(form)
            success('We have e-mailed your password reset link.')
            setForm({ email: '' })
        } catch (err) {
            if (!isApiError(err)) throw err
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    return <LoginWrapper>
        <div className="w-80 h-auto">
            <h1 className="text-3xl text-left font-medium font-display" data-test="forgot-password-title">
                Forgot Password
            </h1>
            <Form onSubmit={handleSubmit} noValidate>
                <Input
                    id="email"
                    name="email"
                    type="email"
                    className="mb-4 mt-4"
                    placeholder="Enter your email"
                    onChange={handleChange}
                    value={form.email}
                    errors={errors.email}
                    data-test="forgot-password-email"
                />
                <div className="flex justify-start-left">
                    <Button className="w-2/6 text-white" disabled={processing} data-test="forgot-password-submit-button">
                        Send
                    </Button>
                    <Button href="/login" design="btn-secondary-gray" className="w-2/6 ml-4" disabled={processing} data-test="forgot-password-sign-in-button">
                        Sign in
                    </Button>
                </div>

            </Form>
        </div>
    </LoginWrapper>
}

export default ForgotPassword
