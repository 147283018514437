import { Icon, IconProps } from './Icon'

export const IconChevronSelectorVertical = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="m7 15 5 5 5-5M7 9l5-5 5 5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
