import tableEmptyStateAdd from '@/assets/images/table/tableEmptyStateAdd.svg'
import { Image, WarrantyCreateButton } from '@/components'

export const WarrantyIndexTablePlaceholder = () =>
    <>
        <Image
            className="w-30"
            src={tableEmptyStateAdd}
        />
        <div className="mt-4 flex flex-col items-center">
            <h4 className="text-center font-light">Start by Adding a New Warranty</h4>
            <p className="text-center text-gray-500 font-light text-sm mt-1">
                Any data from registered warranties will live here. Start registering Solar Insure warranties today
            </p>
            <WarrantyCreateButton className="mt-6"/>
        </div>
    </>
