import { memo, ReactNode } from 'react'
import { Link, To } from 'react-router'

import { IconFlipBackward } from '@/components'

type PageHeadingProps = {
    title: ReactNode
    backUrl?: To
    className?: string
    subtitle?: ReactNode
}

const PageHeader = ({ title, className, backUrl, subtitle }: PageHeadingProps) =>
    <h1 className={`flex items-baseline mt-2 ${className || ''}`} data-test="page-heading">
        {backUrl && <Link
            to={backUrl}
            className="w-7 h-7 rounded-full bg-primary-200 mr-4 flex items-center justify-center"
            data-test="page-heading-back-button"
        >
            <IconFlipBackward className="stroke-primary-700" size="sm"/>
        </Link>}
        <div className="w-full">
            <span className="text-2xl lg:text-3xl font-display leading-[38px]" data-test="page-heading-title">{title}</span>
            {subtitle && <div className="font-body" data-test="page-heading-subtitle">
                {subtitle}
            </div>}
        </div>
    </h1>

export default memo(PageHeader)
