import {
    Button,
    HomeownerForm,
    FormErrorsType, HomeownerFormDataType, HomeownerAddressFormDataType
} from '@/components'
import { HomeownerAddressForm } from '@/components/forms/HomeownerAddress'
import { FormChangeEventHandler } from '@/types'

export interface CreateWarrantyHomeownerFormDataType extends HomeownerFormDataType, HomeownerAddressFormDataType {}

type WarrantyCreateHomeownerStepProps = {
    form: CreateWarrantyHomeownerFormDataType
    onChange: FormChangeEventHandler
    errors: FormErrorsType
    processing: boolean
}
export const WarrantyCreateHomeownerStep = ({
    form,
    errors,
    processing,
    ...props
}: WarrantyCreateHomeownerStepProps) =>
    <div className="grid lg:grid-cols-2 gap-3 lg:gap-6 mt-4 lg:mt-6" data-test="warranty-create-step-1">
        <h3 className="font-semibold lg:col-span-2">Homeowner Information</h3>
        <HomeownerForm
            form={form}
            errors={errors}
            onChange={props.onChange}
        />

        <hr className="lg:col-span-2 border-gray-200"/>
        <h3 className="font-semibold lg:col-span-2">Address</h3>
        <HomeownerAddressForm
            form={form}
            errors={errors}
            onChange={props.onChange}
            required={true}
        />

        <div className="flex justify-end lg:col-span-2">
            <Button processing={processing} data-test="warranty-create-step-1-continue-button">
                Continue
            </Button>
        </div>
    </div>
