import { Link, To } from 'react-router'

import { PageHeader } from '@/components'
import { User } from '@/models'

type UserHeaderProps = {
    user: User
    backUrl: To
}

const UserHeader = ({ user, backUrl }: UserHeaderProps) =>
    <PageHeader
        title={user.name}
        backUrl={backUrl}
        subtitle={
            <>
                <p className="text-gray-500 text-base mt-2 flex items-center font-body">
                    Account Type: <span className="bg-primary-50 px-2 py-1 text-primary-700 text-sm rounded-md ml-1">{user.accountType?.title}</span>
                </p>
                <p className="text-gray-500 text-base mt-2 flex items-center font-body">
                    Company: {user.company?.id
                        ? <Link to={`/companies/${user.company?.id}`} className="bg-orange-50 px-2 py-1 text-orange-700 text-sm rounded-md ml-1">
                            {user.company?.name}
                        </Link>
                        : <span className="bg-orange-50 px-2 py-1 text-orange-700 text-sm rounded-md ml-1">
                            {user.company?.name}
                        </span>}
                </p>
            </>
        }
    />

export default UserHeader
