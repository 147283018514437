import classnames from 'classnames'
import { ReactNode, useState } from 'react'

import { Button, IconChevronUp } from '@/components'

type StatusGuideExpandingItemProps = {
    title: ReactNode,
    content: ReactNode,
    showChevron?: boolean
}

const StatusGuideExpandingItem = ({
    title,
    content,
    showChevron = true
}: StatusGuideExpandingItemProps) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const handleExpandToggleClick = () => {
        setIsExpanded(isExpanded => !isExpanded)
    }

    return <div
        className={classnames('py-4 transition-colors', {
            'bg-gray-100 rounded-md': isExpanded,
            'px-6': showChevron || (!showChevron && isExpanded)
        })}
    >
        <Button design="btn-link" onClick={handleExpandToggleClick} className="gap-2">
            {showChevron && <IconChevronUp
                className={classnames('stroke-black transition-all', { 'rotate-180': !isExpanded })}
            />}
            <span className="text-gray-800 text-lg font-semibold">
                {title}
            </span>
        </Button>
        {isExpanded && <div className="mt-4 text-xs text-gray-750 font-body animate-fade-in">
            {content}
        </div>}
    </div>
}

export default StatusGuideExpandingItem
