import { WarrantyBulkUploadColumnType } from '@/types'

export const WARRANTY_COLUMNS: WarrantyBulkUploadColumnType[] = [
    {
        title: 'First name',
        value: 'first_name',
        index: 0,
        required: true,
        show: true,
        score: 1,
        header: '',
        keywords: [
            'fname',
            'name',
            'first',
            'initial name',
            'primary name',
            'client first name'
        ]
    },
    {
        title: 'Last name',
        value: 'last_name',
        index: 1,
        required: true,
        show: true,
        score: 1,
        header: '',
        keywords: [
            'surname',
            'family name',
            'l name',
            'client last name'
        ]
    },
    {
        title: 'Full name',
        value: 'full_name',
        index: 0,
        required: true,
        show: false,
        score: 1,
        header: '',
        keywords: [
            'name',
            'complete name',
            'entire name',
            'fullname',
            'name full',
            'client full name'
        ]
    },
    {
        title: 'Phone',
        value: 'phone',
        index: 2,
        show: true,
        score: 1,
        header: '',
        keywords: [
            'phone number',
            'contact number',
            'tel',
            'phone num',
            'telephone',
            'client phone'
        ]
    },
    {
        title: 'Email',
        value: 'email',
        index: 3,
        show: true,
        required: true,
        score: 1,
        header: '',
        keywords: [
            'email address',
            'e-mail',
            'email id',
            'electronic mail',
            'client email'
        ]
    },
    {
        title: 'Address',
        value: 'address',
        index: 4,
        show: false,
        required: true,
        score: 1,
        header: '',
        keywords: [
            'addr',
            'residential address',
            'address line',
            'addr',
            'mailing address',
            'client address'
        ]
    },
    {
        title: 'Street address',
        value: 'street_address',
        index: 5,
        show: true,
        required: true,
        score: 1,
        header: '',
        keywords: [
            'address 1',
            'street addr',
            'street add',
            'streetline',
            'str address',
            'client street address'
        ]
    },
    {
        title: 'City',
        value: 'city',
        index: 6,
        show: true,
        required: true,
        score: 1,
        header: '',
        keywords: [
            'town',
            'municipality',
            'city town',
            'locality',
            'client city'
        ]
    },
    {
        title: 'State',
        value: 'state',
        index: 7,
        show: true,
        required: true,
        score: 1,
        header: '',
        keywords: [
            'state name',
            'province',
            'state region',
            'state code',
            'client state',
            'client state abbr'
        ]
    },
    {
        title: 'ZIP',
        value: 'zip',
        index: 8,
        show: true,
        required: true,
        score: 1,
        header: '',
        keywords: [
            'postal code',
            'zip code',
            'zip num',
            'zip number',
            'client zip'
        ]
    },
    {
        title: 'Panel Model',
        value: 'panel_name',
        index: 9,
        show: true,
        required: true,
        score: 1,
        header: '',
        keywords: [
            'model of panel',
            'make of panel',
            'panel type',
            'panel make and model',
            'panel make',
            'panel mdl',
            'model panel'
        ]
    },
    {
        title: 'Inverter Model',
        value: 'inverter_name',
        index: 10,
        show: true,
        required: true,
        score: 1,
        header: '',
        keywords: [
            'model of inverter',
            'make of inverter',
            'inverter type',
            'inverter make and model',
            'inverter make',
            'inverter mdl',
            'model inverter'
        ]
    },
    {
        title: 'Number of Panels',
        value: 'num_panels',
        index: 11,
        show: true,
        required: true,
        score: 1,
        header: '',
        keywords: [
            'num panels',
            '# panels',
            '# of panels',
            'panels count',
            'panel quantity',
            'panels num',
            'total panels'
        ]
    },
    {
        title: 'System Size (kW)',
        value: 'size_kw',
        index: 12,
        show: true,
        required: true,
        score: 1,
        header: '',
        keywords: [
            'system size kw',
            'sys size kw',
            'system power',
            'size of system',
            'kw system'
        ]
    },
    {
        title: 'Installation Date',
        value: 'install_date',
        index: 13,
        show: true,
        required: true,
        score: 1,
        header: '',
        keywords: [
            'install date',
            'date of installation',
            'installation date',
            'install date',
            'date installed'
        ]
    },
    {
        title: 'Number of Microinverters/Optimizers',
        value: 'num_microinverters',
        index: 15,
        show: true,
        admin: false,
        score: 1,
        header: '',
        keywords: [
            'num of microinverters',
            '# of microinverters',
            '# microinverters',
            'microinverter count',
            'microinverter quantity',
            'num optimizers',
            'optimizers count'
        ]
    },
    {
        title: 'System ID',
        value: 'system_id',
        index: 16,
        show: true,
        admin: false,
        score: 1,
        header: '',
        keywords: [
            'system id',
            'id of system',
            'sys id',
            'id system',
            'system identification'
        ]
    }
]
