import { api, Payload } from '@/services'

export const auth = {
    signIn(payload: Payload) {
        return api.http.post('api/v1/auth/login', payload)
    },
    signOut() {
        return api.http.get('api/v1/auth/logout')
    },
    forgotPassword(payload: Payload) {
        return api.http.post('api/v1/auth/forget-password', payload)
    },
    resetPassword(payload: Payload) {
        return api.http.post('api/v1/auth/reset-password', payload)
    },
    updatePreferences(payload: Payload) {
        return api.http.put('api/v1/auth/user/preferences', payload)
    },
    updatePassword(payload: Payload) {
        return api.http.post('api/v1/auth/update-password', payload)
    },
    user() {
        return api.http.post('api/v1/auth/user')
    }
}
