import { convert } from '@/hooks/useFormData'
import { cookies, handleError, handleSuccess } from '@/services'
import { JsonDataType } from '@/types'

const BASE_PATH = import.meta.env.VITE_API_URL

export type Params = JsonDataType

export type Payload = FormData | JsonDataType | JsonDataType[]

export type Options = RequestInit

export * from './handleError'
export * from './handleSuccess'

export const http = new class Http {
    request(resource: URL, options: Options) {
        const headers = new Headers({
            Accept: 'application/json',
            Authorization: `Bearer ${cookies.get('api_token')}`,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        })
        if (options.body instanceof FormData) {
            headers.delete('Content-Type')
        }
        return fetch(new Request(resource, {
            headers,
            ...options
        }))
            .then(handleSuccess)
            .catch(handleError)
    }

    getUrl(path: string, params?: Params): URL {
        const uri = path.startsWith('http') ? path : `${BASE_PATH}/${path}`
        const url = new URL(uri)
        if (typeof params !== 'undefined') {
            const convertedParams = convert(params, {}) as { [key: string]: string | boolean | number }
            Object.keys(convertedParams)
                .forEach(key => {
                    const value = convertedParams[key]
                    if (value || value === false || value === 0) {
                        url.searchParams.append(key, value.toString())
                    }
                })
        }
        return url
    }

    get(path: string, params?: Params, options?: Options) {
        return this.request(this.getUrl(path, params), {
            ...options,
            method: 'GET'
        })
    }

    post(path: string, payload?: Payload, params?: Params, options?: Options) {
        return this.request(this.getUrl(path, params), {
            ...options,
            body: payload instanceof FormData ? payload : JSON.stringify(payload),
            method: 'POST'
        })
    }

    put(path: string, payload?: Payload, params?: Params, options?: Options) {
        return this.request(this.getUrl(path, params), {
            ...options,
            body: payload instanceof FormData ? payload : JSON.stringify(payload),
            method: 'PATCH'
        })
    }

    delete(path: string, params?: Params, options?: Options) {
        return this.request(this.getUrl(path, params), {
            ...options,
            method: 'DELETE'
        })
    }
}()
