import classnames from 'classnames'
import { useState } from 'react'

import { Button, IconChevronUp, StatusGuideExpandingItem } from '@/components'
import { useAuth } from '@/hooks'
import { SystemStatusCategory } from '@/models'

type StatusGuideExpansionSectionProps = {
    statusCategory: SystemStatusCategory
}

const StatusGuideExpansionSection = ({ statusCategory }: StatusGuideExpansionSectionProps) => {
    const auth = useAuth()
    const [isExpanded, setIsExpanded] = useState(false)

    const handleExpandToggleClick = () => {
        setIsExpanded(isExpanded => !isExpanded)
    }

    return <>
        <hr className="my-6 border-gray-200"/>
        <div>
            <Button design="btn-link" onClick={() => handleExpandToggleClick()} className="gap-2">
                <IconChevronUp
                    className={classnames('stroke-black transition-all', { 'rotate-180': !isExpanded })}
                />
                <div className="text-gray-800 text-lg font-semibold flex items-center">
                    <span className="mr-5">{statusCategory.title}</span>
                    {(statusCategory.isConnected ? statusCategory.subcategories : statusCategory.statuses)
                        .map((item, index) =>
                            <div key={item.value} className={index > 0 ? '-ml-1' : ''}>
                                {item.iconBadge('sm')}
                            </div>)}
                </div>
            </Button>
            <p className="mt-4 text-gray-600 text-xs">
                {statusCategory.description}
            </p>

            {isExpanded && <div className="mt-4 animate-fade-in">
                {statusCategory.instructions.map((item, index) =>
                    <div className="my-2" key={index}>
                        <StatusGuideExpandingItem
                            title={<p className="text-primary-800 font-normal text-sm">{item.title}</p>}
                            content={item.content}
                            showChevron={false}
                        />
                    </div>)}
                {statusCategory.isConnected
                    ? statusCategory.subcategories.map(item => <div key={item.value} className="my-2">
                        <StatusGuideExpandingItem
                            title={<div className="flex items-center gap-2 ml-4 text-sm">
                                {item.iconBadge('sm')} {item.title}{item.subtitle && ` - ${item.subtitle}`}
                            </div>}
                            content={<div>
                                {item.description}
                                <div className="flex mt-4 flex-col gap-4">
                                    {item.statuses.length > 1 && item.secondarySortedStatuses.map(
                                        item => <div className="flex gap-2 items-start" key={item.value}>
                                            <div>{item.iconBadge('xs')}</div>
                                            <div>
                                                <span className="font-semibold whitespace-nowrap">{item.title}:</span> {item.description}
                                            </div>
                                        </div>)}
                                </div>
                            </div>}/>
                    </div>)
                    : statusCategory.statuses.map(item => <div key={item.value} className="my-2">
                        <StatusGuideExpandingItem
                            title={<div className="flex items-center gap-2 ml-4 text-sm">
                                {item.iconBadge('sm')} {item.title}
                            </div>}
                            content={<div>
                                <div className="flex mt-4 flex-col gap-4 items-start">
                                    {item.description}
                                    {auth.user?.role.isContractor && item.action}
                                </div>
                            </div>}
                        />
                    </div>)}
            </div>}
        </div>
    </>
}

export default StatusGuideExpansionSection
