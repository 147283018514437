import { useMemo } from 'react'

import { Button, PdfViewer } from '@/components'

export type WarrantyCreatePreviewStepProps = {
    onPrevious: () => void
    processing: boolean
    pdf: Blob | null
}
export const WarrantyCreatePreviewStep = ({
    processing,
    pdf,
    ...props
}: WarrantyCreatePreviewStepProps) => {
    const previewUrl = useMemo(() => pdf ? URL.createObjectURL(pdf) : '', [pdf])

    return <div className="mt-12.5" data-test="warranty-create-step-3">
        <div className="flex justify-end gap-3">
            <Button design="btn-secondary-gray" type="button" onClick={props.onPrevious} processing={processing}>
                Back
            </Button>
            <Button processing={processing} data-test="warranty-create-step-3-continue-button">
                Finalize Warranty
            </Button>
        </div>

        <div className="my-8">
            <PdfViewer
                src={previewUrl}
                toolbar={false}
                className="h-screen"
                width="100%"
            />
        </div>

        <div className="flex justify-end gap-3">
            <Button design="btn-secondary-gray" type="button" onClick={props.onPrevious} processing={processing}>
                Back
            </Button>
            <Button processing={processing} data-test="warranty-create-step-3-continue-button-2">
                Finalize Warranty
            </Button>
        </div>
    </div>
}
