import { useEffect, useState, useMemo } from 'react'

import {
    Button,
    IconFileCheck02,
    IconFileClock,
    IconFileMultiple,
    Popup,
    Tabs, WarrantyDetailsPreview
} from '@/components'
import { useNavigateWithQuery } from '@/hooks'
import { Warranty, WarrantyProductType } from '@/models'
import { IdType } from '@/types'

type HandleMSIDuplicatesPopupProps = {
    isOpen: boolean
    warranty: Warranty

    onClose: () => void
}

export const HandleMSIDuplicatesPopup = ({
    warranty,
    isOpen,
    ...props
}: HandleMSIDuplicatesPopupProps) => {
    const [duplicates, setDuplicates] = useState<Warranty[]>([])
    const [tab, setTab] = useState<IdType>('current')
    const navigateWithQuery = useNavigateWithQuery()

    const fetchDuplicates = async () => {
        const res = await Warranty.getMSIDuplicates({
            warranty_type: warranty.type.key,
            inverter_manufacturer: warranty.inverter?.provider,
            system_id: warranty.homeowner.system_id,
            company_id: warranty.homeowner.company_id
        })
        setDuplicates(res.data)
    }

    useEffect(() => {
        fetchDuplicates()
    }, [])

    const active = useMemo(() => tab === 'current'
        ? warranty : duplicates.find(item => item.id === tab) as Warranty, [duplicates, tab])
    const activeDuplicate = active.id ? active : duplicates[0] as Warranty

    return <Popup open={isOpen} onClose={props.onClose} className="w-180">
        <h4 className="text-xl text-gray-900 flex gap-3 items-center mb-6">
            <IconFileMultiple className="stroke-primary-700" size="xl"/>
            Existing {activeDuplicate?.type.coverageTitle} Detected
        </h4>
        {activeDuplicate && <p className="text-primary-700 italic mb-6">
            A new <span className="font-bold text-sm inline-flex items-center gap-1">
                {warranty.type.brandIcon('xs')}{warranty.type.title}
            </span> warranty cannot be purchased for this manufacturer system ID as it is already covered by an
            existing <span className="font-bold text-sm inline-flex items-center gap-1">
                {activeDuplicate.type.brandIcon('xs')}{activeDuplicate.type.title}
            </span> warranty.
        </p>}
        {activeDuplicate && <p className="text-gray-700 mb-6 text-sm">
            {warranty.type.isTotal30 && activeDuplicate.type.isSolar
                ? 'If you\'re adding battery coverage for a retrofit after the original solar install, convert this' +
                ` to an ${WarrantyProductType.battery.title} warranty. This adds battery coverage only, avoiding` +
                ` duplicate solar coverage. It's also more affordable than the ${WarrantyProductType.total30.title}` +
                ' since your solar power system is already covered.'
                : 'If you\'re trying to update coverage for this manufacturer system ID to reflect system size' +
                ' expansions, such as adding panels, edit the existing warranty instead. If this was a mistake,' +
                ' select \'Cancel Duplicate Registration.'}
        </p>}

        <div>
            <Tabs
                value={tab}
                onChange={setTab}
                className="mb-6"
                size="sm"
                tabs={[
                    {
                        id: 'current',
                        title: <div className="flex items-center gap-2">
                            <IconFileClock/>
                            Current Warranty Registration
                        </div>
                    },
                    ...duplicates.map(item => ({
                        id: item.id,
                        title: <div className="flex items-center gap-2">
                            <IconFileCheck02/>
                            Existing Warranty {item.policy_num}
                        </div>
                    }))
                ]}
            />

            <WarrantyDetailsPreview
                className="mb-6"
                warranty={active}
                minCount={6}
            />
        </div>

        <div className="flex flex-wrap gap-4 justify-end">
            <Button
                type="button"
                design="btn-secondary"
                hasError
                href="/warranties"
            >
                Cancel Duplicate Registration
            </Button>
            {activeDuplicate && (warranty.type.isTotal30 && activeDuplicate.type.isSolar
                ? <Button
                    type="button"
                    onClick={() => {
                        navigateWithQuery({ warranty_product_type: WarrantyProductType.battery.key })
                        props.onClose()
                    }}
                >
                    <span>
                        Convert to <span className="capitalize">{WarrantyProductType.battery.title}</span> Warranty
                    </span>
                </Button>
                : <Button
                    type="button"
                    href={`/warranties/${activeDuplicate.id}/warranty/edit`}
                >
                    Edit Existing Warranty
                </Button>)}
        </div>
    </Popup>
}
