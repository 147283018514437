import { Navigate } from 'react-router'

import { FormErrorsType } from '@/components'
import { toastNotifications } from '@/services'
import store, { logout, setRedirectUrl } from '@/store'

type ErrorResponseBody = {
    status: 'Ok' | 'Failed'
    message?: string
    errors?: { [key: string]: string }
}

const UNAUTHENTICATED_STATUS = 401
const UNAUTHORIZED_STATUS = 403

const ERROR_MESSAGES: {
    [key: number]: string
} = {
    [UNAUTHORIZED_STATUS]: 'Action is not authorized.',
    [UNAUTHENTICATED_STATUS]: 'Action is not authorized.'
}

export class ApiError extends Error {
    status: number

    errors?: FormErrorsType

    constructor(message: string, status: number, errors?: FormErrorsType) {
        super(message)
        this.status = status
        this.errors = errors
        Object.setPrototypeOf(this, ApiError.prototype)
    }
}

export const isApiError = (error: unknown): error is ApiError => error instanceof ApiError
export const isApiCancelError = (error: unknown): error is ApiError =>
    error instanceof ApiError && error.message.includes('cancel')

export const handleError = async (errRes: Response | 'canceled') => {
    if ((typeof errRes === 'string' && errRes === 'canceled') ||
        errRes.toString().includes('abort')) {
        throw new ApiError('Request was canceled', 499)
    } else {
        const contentType = errRes.headers?.get('content-type')
        if (contentType && contentType.includes('application/json')) {
            const res: ErrorResponseBody = await errRes.json()
            if (ERROR_MESSAGES[errRes.status]) {
                toastNotifications.error(ERROR_MESSAGES[errRes.status])
                if (errRes.status === UNAUTHORIZED_STATUS) {
                    return <Navigate to="/"/>
                }
                if (errRes.status === UNAUTHENTICATED_STATUS) {
                    await store.dispatch(logout())
                    if (!location.pathname.includes('login')) {
                        store.dispatch(setRedirectUrl(`${location.pathname}${location.search}`))
                        return <Navigate to="/login"/>
                    }
                }
            } else if (res.errors?.message) {
                toastNotifications.error(res.errors?.message)
            }
            throw new ApiError(res.message || 'Unknown error', errRes.status, res.errors)
        }
    }
    throw new ApiError('Request failed', errRes instanceof Response ? errRes.status || 0 : 0)
}
