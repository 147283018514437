import { ReactElement } from 'react'

import { Company } from './Company.tsx'
import { WarrantyProductType } from './WarrantyProductType.tsx'
import { InverterModelEnum, WarrantyProductTypeEnum } from '@/enums'
import { InverterModelDataType } from '@/types'

export class InverterModel {
    key: InverterModelEnum

    name: string

    warrantyProductTypes: WarrantyProductType[]

    Alert?: (props: { company: Company, className?: string }) => ReactElement

    constructor(data: InverterModelDataType) {
        this.key = data.key
        this.name = data.name
        this.Alert = data.Alert
        this.warrantyProductTypes = data.warrantyProductTypes
            ? data.warrantyProductTypes.map(WarrantyProductType.find) as WarrantyProductType[]
            : WarrantyProductType.all
    }

    isSupportWarrantyProductType(type: WarrantyProductTypeEnum) {
        return this.warrantyProductTypes.some(item => item.key === type)
    }
}
