import { Icon, IconProps } from './Icon'

export const IconActivity = (props: IconProps) =>
    <Icon {...props}>
        <path
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M22 12H18L15 21L9 3L6 12H2"
        />
    </Icon>
