import { IconCheckCircle, IconLightBulb05, IconLightning02, IconSpeedometer } from '@/components'
import { IconSize } from '@/components/icons/Icon'
import {
    SYSTEM_STATUSES_MONITORED_TIER_1,
    SYSTEM_STATUSES_MONITORED_TIER_2,
    SYSTEM_STATUSES_MONITORED_TIER_3,
    SYSTEM_STATUSES_MONITORED_NORMAL,
    SYSTEM_STATUSES_UNMONITORED,
    SYSTEM_STATUSES_EXCLUDED
} from '@/enums/SystemStatusEnum'
import { SystemStatusSubcategoryDataType } from '@/types'

export enum SystemStatusSubcategoryEnum {
    // Connected Sites
    TIER_1 = 'tier_1',
    TIER_2 = 'tier_2',
    TIER_3 = 'tier_3',
    NORMAL_SITES = 'normal_sites',

    // Unmonitored sites
    UNMONITORED = 'unmonitored',

    // Excluded Sites
    EXCLUDED = 'excluded'
}

export const SYSTEM_STATUS_SUBCATEGORIES_MONITORED_SITES: SystemStatusSubcategoryDataType[] = [
    {
        value: SystemStatusSubcategoryEnum.TIER_1,
        title: 'Tier 1',
        severity: 'High Severity',
        subtitle: 'Critical alert with maximum severity score.',
        description: <>
            <span className="font-semibold">Tier 1</span> are of high severity and require immediate attention. They
            include production issues causing known energy losses, component errors requiring on-site intervention, or
            persistent communication issues lasting over a week, potentially indicating underlying energy production
            loss issues.
        </>,
        color: 'error',
        icon: (size?: IconSize) => <IconLightning02 size={size}/>,
        secondaryOrder: 2,
        statuses: SYSTEM_STATUSES_MONITORED_TIER_1
    },
    {
        value: SystemStatusSubcategoryEnum.TIER_2,
        title: 'Tier 2',
        severity: 'Medium Severity',
        subtitle: 'Moderate severity, may need intervention.',
        description: <>
            <span className="font-semibold">Tier 2</span> Issues have medium severity and may not need immediate
            intervention. They may resolve on their own but should be monitored in case they escalate. Examples include
            microinverters not reporting data or system controller issues.
        </>,
        color: 'warning',
        icon: (size?: IconSize) => <IconSpeedometer size={size}/>,
        secondaryOrder: 3,
        statuses: SYSTEM_STATUSES_MONITORED_TIER_2
    },
    {
        value: SystemStatusSubcategoryEnum.TIER_3,
        title: 'Tier 3',
        severity: 'Low Severity',
        subtitle: 'Low-tier alert, indicates a minor issue.',
        description: <>
            <span className="font-semibold">Tier 3</span> are low priority watch alerts. They usually resolve on their
            own or relate to administrative settings, such as incomplete activations. These issues do not require
            immediate action.
        </>,
        color: 'yellow',
        icon: (size?: IconSize) => <IconLightBulb05 size={size}/>,
        secondaryOrder: 4,
        statuses: SYSTEM_STATUSES_MONITORED_TIER_3
    },
    {
        value: SystemStatusSubcategoryEnum.NORMAL_SITES,
        title: 'Normal Sites',
        shortTitle: 'Normal',
        description: 'All systems are functioning within normal parameters, no immediate action is required.',
        color: 'success',
        icon: (size?: IconSize) => <IconCheckCircle size={size}/>,
        secondaryOrder: 1,
        statuses: SYSTEM_STATUSES_MONITORED_NORMAL
    }
]

export const SYSTEM_STATUS_SUBCATEGORIES_UNMONITORED_SITES: SystemStatusSubcategoryDataType[] = [
    {
        value: SystemStatusSubcategoryEnum.UNMONITORED,
        statuses: SYSTEM_STATUSES_UNMONITORED
    }
]

export const SYSTEM_STATUS_SUBCATEGORIES_EXCLUDED_SITES: SystemStatusSubcategoryDataType[] = [
    {
        value: SystemStatusSubcategoryEnum.EXCLUDED,
        statuses: SYSTEM_STATUSES_EXCLUDED
    }
]
