import { useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import {
    Button,
    IconInfoCircle,
    Popup,
    WarrantyUpdateDetails,
    Tooltip,
    BatteryFormDataType
} from '@/components'
import { InverterManufacturerEnum } from '@/enums'
import { Warranty } from '@/models'
import { FormChangeEventHandler, FormValueType, UpdateRequestType } from '@/types'


type WarrantyReviewEditRequestPopupProps = {
    warranty: Warranty
    onChange: FormChangeEventHandler
}

export const WarrantyReviewEditRequestPopup = ({ warranty, ...props }: WarrantyReviewEditRequestPopupProps) => {
    const navigate = useNavigate()
    const { type } = useParams()
    const [processing, setProcessing] = useState(false)
    const [isOpen, setIsOpen] = useState(warranty.status.isEditRequested)

    const request = warranty.pending_update_requests[0] as UpdateRequestType

    const handleSubmit = async (action: 'approve' | 'deny') => {
        setProcessing(true)
        try {
            await Warranty.updateRequest(request.id, action)
            setIsOpen(false)
            navigate(`/${type}/${warranty.id}/warranty`)
        } finally {
            setProcessing(false)
        }
    }

    const handleModifyForm = () => {
        Object.entries(request.requested_changes).forEach(([name, originalValue]) => {
            let value: FormValueType | BatteryFormDataType[]

            switch (name) {
                case 'install_date':
                    if (new Date(originalValue as string).toString() !== 'Invalid Date') {
                        value = new Date(originalValue as string)
                    } else {
                        value = ''
                    }
                    break
                case 'batteries':
                    value = originalValue as BatteryFormDataType[]
                    break
                case 'duplicate_override':
                    if (warranty.homeowner?.allow_duplicate) {
                        value = 1
                    } else if (warranty.status.isDuplicate) {
                        value = 0
                    } else {
                        value = undefined
                    }
                    break
                case 'allow_invalid':
                    value = originalValue ? 1 : undefined
                    break
                default:
                    value = originalValue as FormValueType || ''
            }
            props.onChange({ target: { name, value } })
        })

        props.onChange({ target: { name: 'warranty_update_request_id', value: request.id } })
        setIsOpen(false)
    }

    return request && <Popup
        open={isOpen}
        onClose={() => setIsOpen(false)}
        data-test="approve-warranty-edits-popup"
    >
        <div className="flex flex-col gap-4 text-gray-900 lg:min-w-[45rem]">
            <p className="text-lg font-semibold flex gap-2 items-center">
                <IconInfoCircle className="stroke-warning-600" size="lg"/>
                Approve Warranty Edits
            </p>

            <WarrantyUpdateDetails
                oldValues={{
                    ...warranty.raw,
                    ...warranty.homeowner.raw,
                    affiliate_name: warranty.affiliate?.name,
                    panel_name: warranty.panel?.model_name,
                    inverter_name: warranty.inverter?.model_name,
                    inverter_manufacturer: warranty.inverter?.provider as InverterManufacturerEnum,
                    batteries: Array.isArray(warranty.batteries) ? warranty.batteries.map(item => ({
                        id: item.id,
                        product_id: item.product_id,
                        size_kw: item.size_kw,
                        serial_number: item.serial_number || '',
                        product: {
                            id: item.id,
                            model_name: item.product.model_name
                        }
                    })) : []
                }}
                newValues={{
                    ...request.requested_changes,
                    price: request.new_price
                }}
            />

            <p className="text-sm font-semibold" data-test="approve-edits-text">
                Do you want to save these edits to the warranty?
            </p>

            <div className="flex flex-col lg:flex-row gap-2 justify-between">
                <Tooltip content="Modify Warranty Edits">
                    <Button type="button" design="btn-secondary" onClick={handleModifyForm} data-test="modify-warranty-edits-button">Modify Warranty Edits</Button>
                </Tooltip>

                <div className="flex justify-end gap-2">
                    <Button
                        processing={processing}
                        hasError
                        design="btn-secondary-gray"
                        className="w-40"
                        data-test="deny-warranty-edits-button"
                        onClick={() => handleSubmit('deny')}
                    >
                        Deny Edits
                    </Button>
                    <Button
                        processing={processing}
                        className="w-40"
                        data-test="approve-warranty-edits-button"
                        onClick={() => handleSubmit('approve')}
                    >
                        Yes, Approve Edits
                    </Button>
                </div>
            </div>
        </div>
    </Popup>
}
