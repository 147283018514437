import { useMemo, useState } from 'react'
import { flushSync } from 'react-dom'
import { Link, useLoaderData, useNavigate, useParams } from 'react-router'

import {
    Card,
    IconUsers01, Tabs,
    UserEditForm, CompanyEditForm,
    EditIntegrationsForm,
    PasswordEditForm,
    NotificationsEditForm,
    PreferencesEditForm, UserHeader, IconBuilding07
} from '@/components'
import { AuthLayout } from '@/containers'
import { Company, User } from '@/models'
import { feature } from '@/services'

const UsersEdit = () => {
    const { affiliateId, companyId, id, ...params } = useParams()
    const data = useLoaderData() as { user: User, company: Company }
    const navigate = useNavigate()

    const [user, setUser] = useState(data.user)
    const [company, setCompany] = useState(data.company)

    const basePath = useMemo(() => {
        if (affiliateId) {
            return `/companies/${affiliateId}/subsidiaries/${companyId}/users/${id}`
        } else if (companyId) {
            return `/companies/${companyId}/users/${id}`
        }
        return `/users/${id}`
    },
    [companyId, id])
    const tabs = useMemo(() => [
        {
            id: 'account',
            title: 'Account',
            to: `${basePath}/edit/account`
        },
        ...user?.role.isAffiliateOrContractor && company ? [{
            id: 'company',
            title: 'Company',
            to: `${basePath}/edit/company`
        }] : [],
        ...user?.role.isContractor && company ? [{
            id: 'integrations',
            title: 'Integrations',
            to: `${basePath}/edit/integrations`
        }] : [],
        {
            id: 'password',
            title: 'Password',
            to: `${basePath}/edit/password`
        },
        {
            id: 'notifications',
            title: 'Notifications',
            to: `${basePath}/edit/notifications`
        },
        ...feature('onboarding') && user?.role.isContractor ? [{
            id: 'user-preferences',
            title: 'User Preferences',
            to: `${basePath}/edit/user-preferences`
        }] : []
    ], [companyId, id])
    const tab = params.tab || tabs[0].id
    const activeTab = useMemo(() => tabs.find(item => item.id === tab) || tabs[0], [tabs, tab])
    const breadcrumb = useMemo(() => {
        if (affiliateId) {
            return [
                <IconBuilding07 key={1} className="stroke-gray-500"/>,
                <Link key={2} to="/companies">Companies</Link>,
                <Link key={3} to={`/companies/${affiliateId}`}>Company Details</Link>,
                <Link key={4} to={`/companies/${affiliateId}/subsidiaries/${companyId}`}>Subcontractor Details</Link>,
                <Link key={5} to={`/companies/${affiliateId}/subsidiaries/${companyId}/users/${id}`}>User Details</Link>,
                `Edit User - ${activeTab.title}`
            ]
        }
        if (companyId) {
            return [
                <IconBuilding07 key={1} className="stroke-gray-500"/>,
                <Link key={2} to="/companies">Companies</Link>,
                <Link key={3} to={`/companies/${companyId}`}>Company Details</Link>,
                <Link key={4} to={`/companies/${companyId}/users/${id}`}>User Details</Link>,
                `Edit User - ${activeTab.title}`
            ]
        }
        return [
            <IconUsers01 key={1} className="stroke-gray-500"/>,
            <Link key={2} to="/users">Users</Link>,
            <Link key={3} to={`/users/${id}`}>User Details</Link>,
            `Edit User - ${activeTab.title}`
        ]
    }, [activeTab, companyId])

    const fetchCompany = async () => {
        const res = await Company.show(company.id) as Company
        flushSync(() => {
            setCompany(res)
        })
    }

    const fetchUser = async () => {
        const res = await User.show(id as string) as User
        flushSync(() => {
            setUser(res)
        })
    }

    const handleExit = () => {
        navigate(`/users/${user.id}`)
    }


    return <AuthLayout
        heading={<UserHeader
            user={user}
            backUrl={basePath}
        />}
        breadcrumb={breadcrumb}
    >
        {<Tabs
            className="mb-8"
            size="sm"
            tabs={tabs}
            value={tab}
        />}
        <Card>
            {tab === 'account' && <UserEditForm
                user={user}
                onSuccess={fetchUser}
                onExit={handleExit}
            />}
            {tab === 'company' && company && <CompanyEditForm
                company={company}
                onSuccess={fetchCompany}
                onExit={handleExit}
            />}
            {tab === 'integrations' && <EditIntegrationsForm
                company={company}
                onChange={fetchCompany}
                basePath={`${basePath}/edit`}
            />}
            {tab === 'password' && <PasswordEditForm
                user={user}
                onExit={handleExit}
            />}
            {tab === 'notifications' && <NotificationsEditForm
                user={user}
                onSuccess={fetchUser}
                onExit={handleExit}
            />}
            {tab === 'user-preferences' && feature('onboarding') && <PreferencesEditForm
                user={user}
            />}
        </Card>
    </AuthLayout>
}

export default UsersEdit
