import { FieldDataType, FieldFormatType, FieldRawValueType } from '@/types'

export class Field {
    key: string

    title: string

    _format: FieldFormatType

    constructor(data: FieldDataType) {
        this.key = data.key
        this.title = data.title
        this._format = data.format || 'string'
    }

    get isDefault() {
        return this.key === Field.defaultField.key
    }

    static get defaultField() {
        return new Field({
            key: 'default',
            title: 'Default'
        })
    }

    static formatters = {
        string(value: FieldRawValueType) {
            return value ? value.toString() : ''
        },
        float(value: FieldRawValueType) {
            return value ? parseFloat(value).format({ minimumFractionDigits: 2 }) : ''
        },
        number(value: FieldRawValueType) {
            return value ? parseFloat(value).format() : ''
        },
        money(value: FieldRawValueType) {
            return value ? parseFloat(value).money({ minimumFractionDigits: 2 }) : ''
        },
        phone(value: FieldRawValueType) {
            return value ? value.replace(/\D/g, '') : ''
        },
        date(value: FieldRawValueType) {
            return value ? new Date(value).format() : ''
        },
        datetime(value: FieldRawValueType) {
            return value ? new Date(value).format({
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZoneName: 'short',
                hour12: false
            }) : ''
        }
    }

    format(value: FieldRawValueType): string | string[] {
        if (typeof this._format === 'string') {
            return Field.formatters[this._format](value)
        }
        return this._format(value)
    }

    compare(a: FieldRawValueType, b: FieldRawValueType) {
        return this.format(a)?.toString() === this.format(b)?.toString()
    }
}
