import classnames from 'classnames'

import { Card, WarrantyDetailsListField, WarrantyDetailsPreviewList } from '@/components'
import { Warranty, WarrantyProductType } from '@/models'

type WarrantyDetailsProps = {
    warranty: Warranty
    duplicate?: Warranty
}

export const WarrantyDetails = ({ warranty, duplicate }: WarrantyDetailsProps) => {
    const warrantyFields: WarrantyDetailsListField[] = [
        {
            key: 'company',
            value: warranty.homeowner?.company?.name,
            compareAtValue: duplicate?.homeowner?.company?.name,
            copyable: true
        },
        {
            key: 'created_at',
            value: warranty.homeowner?.created_at,
            compareAtValue: duplicate?.homeowner?.created_at
        },
        {
            key: 'policy_num',
            value: warranty.policy_num,
            compareAtValue: duplicate?.policy_num,
            copyable: true
        },

        {
            key: 'end_date',
            value: warranty.end_date,
            compareAtValue: duplicate?.end_date
        },
        {
            key: 'solar_price',
            title: `${WarrantyProductType.solar.coverageTitle} Price`,
            icon: WarrantyProductType.solar.coverageIcon(),
            value: warranty.solar_price,
            compareAtValue: duplicate?.solar_price,
            showIf: !!warranty.solar_price || !!duplicate?.solar_price
        },
        {
            key: 'battery_price',
            title: `${warranty.type.coverageTitle} Price`,
            icon: warranty.type.coverageIcon(),
            value: warranty.battery_price,
            compareAtValue: duplicate?.battery_price,
            showIf: !!warranty.battery_price || !!duplicate?.battery_price
        },
        {
            key: 'price',
            value: warranty.price,
            compareAtValue: duplicate?.price,
            className: warranty.type.hasPowerProduction && warranty.type.hasStorage ? 'sm:col-span-2' : ''
        }
    ]

    const homeownerFields: WarrantyDetailsListField[] = [
        {
            key: 'full_name',
            value: warranty.homeowner?.fullName,
            compareAtValue: duplicate?.homeowner?.fullName
        },
        {
            key: 'email',
            value: warranty.homeowner?.email,
            compareAtValue: duplicate?.homeowner?.email
        },
        {
            key: 'phone',
            value: warranty.homeowner?.phone,
            compareAtValue: duplicate?.homeowner?.phone
        },
        {
            key: 'address',
            value: warranty.homeowner?.address,
            compareAtValue: duplicate?.homeowner?.address,
            copyable: true
        }
    ]

    const systemFields: WarrantyDetailsListField[] = [
        {
            key: 'install_date',
            value: warranty.homeowner?.install_date,
            compareAtValue: duplicate?.homeowner?.install_date
        },
        {
            key: 'panel_name',
            value: warranty.panel?.model_name,
            compareAtValue: duplicate?.panel?.model_name,
            showIf: warranty.type.hasPowerProduction
        },
        {
            key: 'inverter_name',
            value: warranty.inverter?.model_name,
            compareAtValue: duplicate?.inverter?.model_name
        },
        {
            key: 'num_panels',
            value: warranty.num_panels,
            compareAtValue: duplicate?.num_panels,
            showIf: warranty.type.hasPowerProduction
        },
        {
            key: 'size_kw',
            value: warranty.size_kw,
            compareAtValue: duplicate?.size_kw,
            showIf: warranty.type.hasPowerProduction
        },
        {
            key: 'system_id',
            value: warranty.homeowner?.system_id,
            compareAtValue: duplicate?.homeowner?.system_id,
            copyable: true
        },
        {
            key: 'batteries',
            value: warranty.batteries,
            compareAtValue: duplicate?.batteries,
            showIf: warranty.type.hasStorage
        }
    ]

    return <div className="grid grid-cols-11 gap-6 w-full">
        <div
            className={classnames('flex gap-6 flex-col col-span-11', duplicate
                ? '2xl:col-span-6'
                : 'lg:col-span-6')}
        >
            <Card className={classnames('grid gap-3', duplicate ? 'xl:grid-cols-2' : 'sm:grid-cols-2')}>
                <h4
                    className={classnames('card-title !text-base flex justify-between gap-4', duplicate ? 'xl:col-span-2' : 'sm:col-span-2')}
                    data-test="warranty-details-homeowner-information-title"
                >
                    Warranty Attributes

                    <span className="font-semibold">
                        {warranty.type.brandedTitle}
                    </span>
                </h4>

                <WarrantyDetailsPreviewList fields={warrantyFields} compare={!!duplicate}/>
            </Card>
            <Card className={classnames('grid gap-3', duplicate ? 'xl:grid-cols-2' : 'sm:grid-cols-2')}>
                <h4
                    className={classnames('card-title !text-base', duplicate ? 'xl:col-span-2' : 'sm:col-span-2')}
                    data-test="warranty-details-homeowner-information-title"
                >
                    Homeowner Information
                </h4>
                <WarrantyDetailsPreviewList fields={homeownerFields} compare={!!duplicate}/>
            </Card>
        </div>

        <Card className={classnames('grid gap-3 col-span-11', duplicate
            ? '2xl:col-span-5'
            : 'lg:col-span-5')}>
            <h4 className="card-title !text-base" data-test="warranty-details-system-information-title">System Information</h4>
            <WarrantyDetailsPreviewList fields={systemFields} compare={!!duplicate}/>
        </Card>
    </div>
}
