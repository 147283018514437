import { useEffect, useState } from 'react'

import { SearchableSelect, SearchableSelectProps } from '@/components'
import { Product } from '@/models'

interface ProductFilterProps extends Omit<SearchableSelectProps, 'options'> {
    type: 'batteries' | 'inverters' | 'panels'
}

export const ProductFilter = ({ type, ...props }: ProductFilterProps) => {
    const [products, setProducts] = useState<Product[]>([])

    const fetchCompanies = async () => {
        if (type === 'batteries') {
            const res = await Product.onlyBatteries()
            setProducts(res)
        } else {
            const res = await Product.index()
            setProducts(res.data)
        }
    }
    useEffect(() => {
        fetchCompanies()
    }, [])

    return <SearchableSelect
        {...props}
        options={products.map(item => ({
            value: item.id,
            title: item.model_name
        }))}
    />
}
