import classnames from 'classnames'
import { useState } from 'react'

import { Alert, Button, IconAlertCircle, IconArrowRight, Popup } from '@/components'
import { useNavigateWithQuery } from '@/hooks'
import { Company, WarrantyProductType } from '@/models'

type TeslaPowerwall3RestrictionAlertType = {
    company: Company
    className?: string
}

export const TeslaPowerwall3RestrictionAlert = ({
    company,
    className
}: TeslaPowerwall3RestrictionAlertType) => {
    const navigateWithQuery = useNavigateWithQuery()
    const [isPopupOpen, setIsPopupOpen] = useState(false)

    const si30Total = WarrantyProductType.total30
    const isEligibleForSI30Total = company.isEligibleFor(si30Total.key)

    const handleSwitch = () => {
        navigateWithQuery({ warranty_product_type: si30Total.key })
    }

    return <>
        <Alert type="error" className={className}>
            {isEligibleForSI30Total
                ? <h4 className="font-semibold text-error-700">
                    Tesla Powerwall 3 requires a warranty enrollment of {si30Total.title}.
                </h4>
                : <h4 className="font-semibold text-error-700">
                    Tesla Powerwall 3 requires a warranty enrollment of {si30Total.title}, but you are
                    not currently certified to sell this product
                </h4>}
            <p>
                {isEligibleForSI30Total
                    ? <>
                        This is to ensure full coverage on the inverter component and the battery component of the Tesla
                        Powerwall 3.
                        <div className="flex gap-3 mt-3 flex-wrap">
                            <Button
                                design="btn-link"
                                className="!text-primary-600 underline"
                                type="button"
                                onClick={() => setIsPopupOpen(true)}
                            >
                                Learn More
                            </Button>
                            <Button
                                design="btn-secondary-gray"
                                type="button"
                                onClick={handleSwitch}
                                size="btn-sm"
                            >
                                Switch to {si30Total.title} <IconArrowRight/>
                            </Button>
                        </div>
                    </>
                    : <>
                        You are not eligible to proceed with this enrollment. To check your recertification status and
                        understand next steps, please reach out to your account manager. Once recertification is
                        complete, you can return and register this system
                        under {si30Total.title}. <Button
                            design="btn-link"
                            className="!text-primary-600 !inline-flex underline"
                            type="button"
                            onClick={() => setIsPopupOpen(true)}
                        >
                            Learn More
                        </Button>
                    </>}

            </p>
        </Alert>

        <Popup
            open={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
            className="w-180"
        >
            <h3 className="font-semibold text-xl text-gray-900 flex mb-6 gap-1 items-center">
                <div className="shrink-0">
                    <IconAlertCircle className="stroke-primary-600" size="lg"/>
                </div>
                Tesla Powerwall 3 Requires an {si30Total.brandIcon('lg')} {si30Total.title} Warranty
            </h3>

            <div className="flex flex-col gap-4 text-gray-700 mb-6">
                <div className="flex flex-col gap-3">
                    <h4 className="font-semibold">Why this warranty is needed:</h4>
                    <ul className="list-disc pl-8">
                        <li>
                            While most hybrid systems can have individual components like the inverter or battery
                            repaired or replaced separately, <span
                                className={classnames('font-semibold italic underline',
                                    { 'bg-yellow-200': !isEligibleForSI30Total })}
                            >The Tesla
                            Powerwall 3 is unique: if either the inverter or battery fails, the entire unit must be
                            replaced.</span> It is ineligible for the SI-30 Solar Warranty, which does not cover
                            batteries.
                        </li>
                    </ul>
                </div>

                {isEligibleForSI30Total
                    ? <>
                        <div className="flex flex-col gap-3">
                            <h4 className="flex gap-1 items-center font-semibold">
                                The {si30Total.brandIcon('md')} {si30Total.title} Warranty is designed for systems like
                                this and provides:
                            </h4>
                            <ul className="list-disc pl-8">
                                <li>Replacement coverage for the entire unit.</li>
                                <li>Diagnostics, repair support, and no deductible or transfer fees.</li>
                            </ul>
                        </div>
                        <div className="flex flex-col gap-3">
                            <h4 className="font-semibold">
                                What happens next?
                            </h4>
                            <ul className="list-disc pl-8">
                                <li>
                                    Switching to the {si30Total.title} Warranty ensures full protection for your Tesla
                                    Powerwall 3 system.
                                </li>
                            </ul>
                        </div>
                    </>
                    : <>
                        <div className="flex flex-col gap-3">
                            <h4 className="flex gap-1 items-center font-semibold">
                                You are not currently certified to sell {si30Total.brandIcon('md')} {si30Total.title}.
                            </h4>
                            <ul className="list-disc pl-8">
                                <li>
                                    You are <span className="font-semibold">not eligible</span> to register this
                                    warranty at this time.
                                </li>
                                <li>
                                    To check your recertification status and understand next
                                    steps, <span className="font-semibold">please reach out to your account
                                    manager.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="flex flex-col gap-3">
                            <h4 className="font-semibold">
                                ⚠️ You cannot proceed with this warranty registration at this time.
                            </h4>
                            <ul className="list-disc pl-8">
                                <li>
                                    After completing recertification, you can return and initiate
                                    an {si30Total.title} warranty registration.
                                </li>
                            </ul>
                        </div>
                    </>}
            </div>

            <div className="flex justify-end">
                {isEligibleForSI30Total
                    ? <Button onClick={handleSwitch} type="button">
                        Switch to {si30Total.title}
                    </Button>
                    : <Button hasError href="/warranties">
                        Exit Warranty Enrollment
                    </Button>}
            </div>
        </Popup>
    </>
}
