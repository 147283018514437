import { useEffect } from 'react'

import {
    Dropdown,
    IconEdit,
    Checkbox,
    Button
} from '@/components'
import { useAnalytics } from '@/hooks'
import { localStorage } from '@/services'
import { FormChangeEvent, TableColumnType } from '@/types'

type TableEditColumnsProps = {
    name: string
    columns: TableColumnType[]
    value: string[]
    maxAmount?: number
    minAmount?: number
    onChange: (value: string[]) => void
    'data-test'?: string
}

type ColumnPreferences = { [key: string]: string[] }

const TableEditColumns = ({
    maxAmount = 6,
    minAmount = 1,
    columns,
    value,
    'data-test': dataTest,
    ...props
}: TableEditColumnsProps) => {
    const { trackEvent } = useAnalytics()

    const handleChange = (e: FormChangeEvent) => {
        const index = value.indexOf(e.target.name)
        let selectedFields = value
        if (index === -1) {
            if (e.target.checked && value.length < maxAmount) selectedFields =
                [...value as string[], e.target.name]
        } else {
            if (!e.target.checked && value.length > minAmount) selectedFields =
                value.filter(item => item !== e.target.name)
        }
        const columnPreferences = localStorage.get('column_preferences')
        localStorage.set('column_preferences', {
            ...columnPreferences as ColumnPreferences,
            [props.name]: selectedFields
        })
        props.onChange(selectedFields)
    }

    useEffect(() => {
        const columnPreferences = localStorage.get('column_preferences') as ColumnPreferences
        if (!value.length) {
            const savedValue = columnPreferences[props.name]
                ?.filter((item: string) => columns.some(({ field }) => field === item))
            props.onChange(
                savedValue || columns
                    .filter(({ showIf = true }) => showIf)
                    .map(({ field }) => field)
                    .slice(0, maxAmount)
            )
        }
    }, [])

    return <Dropdown
        persistent
        placement="bottom-end"
        data-test={dataTest}
        reference={
            <Button
                tooltip="Edit Columns"
                design="btn-link-gray"
                size="btn-lg"
                data-test={`${dataTest}-button`}
                onMouseOver={() =>
                    trackEvent('hover_customize_columns_warranty', 'User Interaction', 'Customize Columns Hover')}
                onClick={() =>
                    trackEvent('click_customize_columns_warranty', 'User Interaction', 'Customize Columns Click')}
            >
                <div className="w-full px-4">
                    <IconEdit/>
                </div>
            </Button>
        }>
        <div className="-m-2">
            <p
                className="px-3.5 py-2.5 text-gray-900 font-body font-normal text-left"
                data-test={`${dataTest}-title`}
            >
                Select up to {maxAmount}.
            </p>
            <div className="w-64">
                {columns.map(({ showIf = true, ...column }) => {
                    if (!showIf) return null
                    const isActive = value.includes(column.field)
                    return <div
                        data-test={`${dataTest}-item-${column.field}`}
                        className={`w-full px-3.5 py-2.5 ${isActive ? 'bg-gray-50' : 'bg-white'}`}
                        key={column.field}
                    >
                        <Checkbox
                            id={column.field}
                            label={column.title_raw || column.title}
                            name={column.field}
                            className="text-gray-900"
                            checked={isActive}
                            onChange={handleChange}
                            data-test={`${dataTest}-item-${column.field}-checkbox`}
                        />
                    </div>
                })}
            </div>
        </div>
    </Dropdown>
}

export default TableEditColumns
