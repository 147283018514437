import classnames from 'classnames'
import { HTMLAttributes, useEffect, useState } from 'react'

import { Checkbox, Switch } from '@/components'
import { WarrantyProductTypeEnum } from '@/enums'
import { WarrantyProductType } from '@/models'
import { MonitoringSummaryDataType, WarrantySummaryDataType, FormChangeEventHandler, FormChangeEvent } from '@/types'

interface WarrantyProductTypeFilterProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
    summary: MonitoringSummaryDataType | WarrantySummaryDataType
    value: string[]
    name: string
    onChange: FormChangeEventHandler
}

export const WarrantyProductTypeFilter = ({
    summary,
    name,
    value,
    className,
    ...props
}: WarrantyProductTypeFilterProps) => {
    const [showInactiveOptions, setShowInactiveOptions] = useState(false)
    const handleChange = (e: FormChangeEvent) => {
        const newValue = e.target.checked
            ? [...value, e.target.value] as string[]
            : value.filter(item => item !== e.target.value)

        props.onChange({
            target: {
                name,
                value: newValue,
                displayValue: newValue
                    .map(item => WarrantyProductType.find(item as WarrantyProductTypeEnum)?.title as string)
            }
        })
    }

    useEffect(() => {
        if (value.length) {
            props.onChange({
                target: {
                    name,
                    value,
                    displayValue: value
                        .map(item => WarrantyProductType.find(item as WarrantyProductTypeEnum)?.title as string)
                }
            })
        }
    }, [])

    const {
        onChange,
        ...divProps
    } = props
    return <div className={classnames('flex flex-col gap-5', className)} {...divProps}>
        <div className="flex flex-wrap gap-2">
            {WarrantyProductType.all
                .filter(item => showInactiveOptions ||
                    summary.warranty_product_types?.[item.key] ||
                    value.includes(item.key))
                .map(item => <label
                    key={item.key}
                    className="border border-gray-200 px-2.5 py-1 rounded flex items-center gap-2 cursor-pointer"
                >
                    <Checkbox
                        id={`${props.id}-${item.key}`}
                        name={name}
                        value={item.key}
                        onChange={handleChange}
                        checked={value.includes(item.key)}
                    />
                    <div className="flex items-center gap-2">
                        {item.brandIcon('lg')}{item.title}
                        <div className="bg-gray-100 rounded-sm px-1 text-xs text-gray-600">
                            {summary.warranty_product_types?.[item.key] || 0}
                        </div>
                    </div>
                </label>)}
        </div>

        <Switch
            name="toggle"
            input-size="sm"
            checked={showInactiveOptions}
            value="toggle"
            onChange={e => setShowInactiveOptions(e.target.checked)}
            label="Show Inactive Types"
        />
    </div>
}
