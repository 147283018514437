import { ReactNode, useState } from 'react'

import { Button, IconFilter, Popup } from '@/components'
import { AppliedFilterType } from '@/types'

type IndexFiltersPopupProps = {
    popupContent?: ReactNode
    appliedFilters: AppliedFilterType[]
    onClear?: () => void
}

export const IndexFiltersPopup = ({
    popupContent,
    appliedFilters,
    ...props
}: IndexFiltersPopupProps) => {
    if (!popupContent) return null

    const [isOpen, setIsOpen] = useState(false)
    return <>
        <Button
            design="btn-link"
            className="!text-primary-800 gap-2"
            onClick={() => setIsOpen(true)}
        >
            Filters
            {appliedFilters.length
                ? <div
                    className="w-5 h-5 rounded-full bg-gray-100 text-black text-xs flex justify-center items-center"
                >
                    {appliedFilters.length}
                </div>
                : <IconFilter className="stroke-black"/>}
        </Button>
        <Popup
            open={isOpen}
            onClose={() => setIsOpen(false)}
            backdrop={false}
            backdropStyle="transparent"
            preventScroll={false}
            preserveState={true}
            className="w-110 overflow-x-hidden"
            placement="bottom-right"
        >
            <h3 className="text-lg text-gray-800 font-semibold mb-1.5">Filters</h3>
            <p className="text-gray-400 text-sm mb-7.5">Select filters to apply to the data table</p>
            <div className="flex flex-col gap-4 lg:gap-7.5 mb-9">
                {popupContent}
            </div>
            {props.onClear && <Button design="btn-secondary" className="ml-auto" onClick={props.onClear}>
                Clear All Filters
            </Button>}
        </Popup>
    </>
}
