import { useState } from 'react'

import { Button, WarrantyCreatePopup } from '@/components'
import { Company, WarrantyProductType } from '@/models'

type SwitchWarrantyTypeButtonProps = {
    className?: string
    company: Company
    warrantyProductType: WarrantyProductType
}
export const WarrantyTypeSwitchButton = ({
    company,
    warrantyProductType,
    className
}: SwitchWarrantyTypeButtonProps) => {
    const [isOpen, setIsOpen] = useState(false)

    return <>
        <Button
            onClick={() => setIsOpen(true)}
            design="btn-secondary"
            type="button"
            className={className}
            tooltip="Switch Warranty Type"
        >
            Switch warranty type
        </Button>

        {isOpen && <WarrantyCreatePopup
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            company={company}
            warrantyProductType={warrantyProductType}
            reset={false}
        />}
    </>
}
