import classnames from 'classnames'

import { Button } from '@/components'
import { ProductRateTypeEnum, WarrantyRegistrationTypeEnum } from '@/enums'
import { Company, WarrantyProductType, WarrantyRegistrationType } from '@/models'
import { FormChangeEventHandler } from '@/types'

export type WarrantyCreatePopupStep2FormType = {
    warranty_registration_type: WarrantyRegistrationTypeEnum | ''
}

type WarrantyCreatePopupStep2Props = {
    form: WarrantyCreatePopupStep2FormType
    onChange: FormChangeEventHandler
    productType: WarrantyProductType
    company: Company
    registrationType?: WarrantyRegistrationType
    onPrevious: () => void
    onNext: () => void
}

export const WarrantyCreatePopupStep2 = ({
    registrationType,
    productType,
    company,
    form,
    ...props
}: WarrantyCreatePopupStep2Props) =>
    <>
        <h2 className="text-gray-900 font-semibold text-xl pr-10 mb-2">
            Select your warranty registration method
        </h2>
        <p className="text-sm text-gray-600 mb-6.5">
            You can choose to register {productType.title} warranties as a single registration or in bulk.
        </p>

        <div className="py-4 px-8 rounded-md shadow-md border-2 border-gray-200 bg-primary-25 mb-6 flex justify-center">
            <div className="grid md:grid-cols-2 grid-rows-2 gap-y-3.5 gap-x-11 w-120">
                <div className="flex flex-col justify-around gap-3 items-center row-span-2 w-55 max-w-full">
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-col gap-1 w-full font-brand">
                            <span className="font-semibold">{productType.brandedTitleColored}</span>
                            <span className="text-xs">{productType.subtitle}</span>
                        </div>
                        <div className="text-gray-500 leading-5.5 text-xs text-left">
                            {productType.description}
                        </div>
                    </div>
                    {company?.product_rates && <div className="flex gap-4 justify-between">
                        {productType.hasPowerProduction && <div
                            className="flex items-center gap-1.5"
                        >
                            <WarrantyProductType.PowerProductionIcon
                                style={{ fill: `var(--color-brand-${productType.color})` }}
                            />
                            <span>
                                {company.product_rates[productType.powerProductionRateKey as ProductRateTypeEnum]
                                    .money()}
                                <span className="text-gray-500 text-2xs">/W</span>
                            </span>
                        </div>}
                        {productType.hasStorage && <div
                            className="flex items-center gap-1.5"
                        >
                            <WarrantyProductType.StorageIcon
                                style={{ stroke: `var(--color-brand-${productType.color})` }}
                            />
                            <span>
                                {company.product_rates[productType.storageRateKey as ProductRateTypeEnum].money()}
                                <span className="text-gray-500 text-2xs">/Wh</span>
                            </span>
                        </div>}
                    </div>}
                </div>
                {productType.registrationTypes.map(item => <label
                    key={item.key}
                    className={classnames(
                        'rounded-md shadow-md border-2 border-gray-200 transition-all p-4 font-semibold text-sm',
                        'flex flex-col items-center gap-2 bg-white cursor-pointer w-full md:w-auto',
                        'group/item',
                        { 'border-primary-500': form.warranty_registration_type === item.key }
                    )}
                >
                    <div className={classnames(
                        'flex justify-center items-center w-10 h-10 rounded-full border-[6px] stroke-orange-600',
                        'bg-warning-100 border-warning-50 transition-all',
                        'group-hover/item:stroke-primary-600',
                        'group-hover/item:bg-primary-100',
                        'group-hover/item:border-primary-50',
                        { 'stroke-primary-600 !bg-primary-100 !border-primary-50': form.warranty_registration_type === item.key }
                    )}>
                        <item.Icon/>
                    </div>
                    {item.title}
                    <input
                        type="radio"
                        className="hidden"
                        name="warranty_registration_type"
                        value={item.key}
                        onChange={props.onChange}
                    />
                </label>)}
            </div>
        </div>

        <div className="flex justify-between gap-4">
            <Button onClick={props.onPrevious} design="btn-secondary-gray">
                Back
            </Button>

            <Button disabled={!registrationType} onClick={props.onNext}>
                Continue
            </Button>
        </div>
    </>
