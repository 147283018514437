import { api, Options, Params } from '@/services'
import {
    CompaniesActivityDataType,
    EligibleCommissionTrackingDataType,
    SalesByContractorItemDataType, SalesByContractorParamsType,
    WarrantySalesItemDataType
} from '@/types'

export const dashboard = {
    async inactiveCompanies(params: Params, options: Options): Promise<CompaniesActivityDataType> {
        const { data } = await api.http.get('api/v1/dashboard/widgets/inactive-accounts', params, options)
        return data
    },
    downloadInactiveCompanies(params: Params) {
        api.http.get('api/v1/dashboard/widgets/inactive-accounts/download', params)
    },
    async topCompanies(params: Params, options: Options): Promise<CompaniesActivityDataType> {
        const { data } = await api.http.get('api/v1/dashboard/widgets/top-companies', params, options)
        return data
    },
    downloadTopCompanies(params: Params) {
        api.http.get('api/v1/dashboard/widgets/top-companies/download', params)
    },
    async warrantySales(params: Params, options: Options): Promise<WarrantySalesItemDataType[]> {
        const { data } = await api.http.get('api/v1/dashboard/widgets/warranty-sales', params, options)
        return data
    },
    async salesByContractor(params: SalesByContractorParamsType,
        options: Options): Promise<SalesByContractorItemDataType[]> {
        const { data } = await api.http.get('api/v1/dashboard/widgets/sales-by-contractor', params, options)
        return data
    },
    async revenueByState(params: { period: 'week' | 'month' | 'quarter' | 'year' }, options: Options) {
        const { data } = await api.http.get('api/v1/dashboard/widgets/revenue-by-state', params, options)
        return data
    },

    async eligibleCommissionTracking(params: Params, options: Options): Promise<EligibleCommissionTrackingDataType> {
        const { data } = await api.http.get('api/v1/dashboard/widgets/eligible-commission-tracking', params, options)
        return data
    },

    linkedInInsights(params: Params, options: Options) {
        return api.http.get('api/v1/dashboard/widgets/linkedin-latest-post', params, options)
    }
}
