import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router'

import {
    AssignAffiliateStep,
    Card,
    ColumnMappingStep, ConfirmationStep, IconList,
    PageHeader,
    Steps,
    UploadFileStep,
    ValidateDataStep
} from '@/components'
import { AuthLayout } from '@/containers'
import { Company } from '@/models'
import { FormChangeEvent, IdType } from '@/types'

export type UploadAffiliateRowDataType = {
    key?: string
    company_unique_id: string
    company_name: string
    policy_num: string
    first_name: string
    last_name: string
    street_address: string
}

export type UploadAffiliateFormDataType = {
    file?: File
    affiliate?: {
        id: IdType
        name: string
    }
    data: UploadAffiliateRowDataType[]
    payload: UploadAffiliateRowDataType[]
    response: {
        errors: {
            policy_num?: string
            company_unique_id?: string
        }
    }[]
}

const breadcrumb = [
    <IconList key={1} className="stroke-gray-500"/>,
    <Link key={2} to="/warranties">Warranties by Contractor</Link>,
    'Upload Affiliate Data'
]

const UploadAffiliateData = () => {
    const navigate = useNavigate()
    const [step, setStep] = useState(0)
    const [affiliates, setAffiliates] = useState<Company[]>([])
    const [form, setForm] = useState<UploadAffiliateFormDataType>({
        file: undefined,
        affiliate: undefined,
        data: [],
        payload: [],
        response: []
    })

    const fetchAffiliates = async () => {
        if (affiliates.length) return
        const data = await Company.onlyAffiliates()
        setAffiliates(data.filter((item: Company) => item.warranty_match_method.isExternalUploadMatch))
    }

    useEffect(() => {
        fetchAffiliates()
    }, [])

    const handleChange = (e: FormChangeEvent) => {
        setForm(form => ({ ...form, [e.target.name]: e.target.value }))
    }

    return <AuthLayout
        breadcrumb={breadcrumb}
        heading={<PageHeader title="Upload Affiliate Data" backUrl="/warranties"/>}
    >
        <Card>
            <Steps
                className="mx-8 mb-9"
                step={step}
                steps={[
                    'Upload File',
                    'Assign Affiliate',
                    'Column Mapping',
                    'Validate Data',
                    'Confirmation'
                ]}
            />

            {step === 0 && <UploadFileStep
                form={form}
                onChange={handleChange}
                onCancel={() => navigate('/warranties')}
                onNext={() => setStep(1)}
            />}
            {step === 1 && <AssignAffiliateStep
                form={form}
                affiliates={affiliates}
                onChange={handleChange}
                onCancel={() => setStep(0)}
                onNext={() => setStep(2)}
            />}
            {step === 2 && <ColumnMappingStep
                form={form}
                onChange={handleChange}
                onCancel={() => setStep(1)}
                onNext={() => setStep(3)}
            />}
            {step === 3 && <ValidateDataStep
                form={form}
                onChange={handleChange}
                onCancel={() => setStep(2)}
                onNext={() => setStep(4)}
            />}
            {step === 4 && <ConfirmationStep/>}
        </Card>
    </AuthLayout>
}

export default UploadAffiliateData
