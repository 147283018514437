import { Icon, IconProps } from './Icon'

export const IconMinus = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M5 12h14"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
