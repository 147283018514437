import { useState } from 'react'
import { useNavigate } from 'react-router'

import CompanyCreateForm from './CompanyCreateForm'
import UserCreateForm from './UserCreateForm'
import { Button, Card, Select } from '@/components'
import { AccountFormTypeEnum, AccountTypeEnum } from '@/enums'
import { AccountType } from '@/models'
import { FormChangeEvent } from '@/types'


const AccountsCreateForm = () => {
    const navigate = useNavigate()
    const [role, setRole] = useState('')

    const accountType = role ? AccountType.find(role as AccountTypeEnum) : undefined

    return <Card>
        <div className="grid lg:grid-cols-2 gap-6">
            <Select
                id="account-type"
                name="role"
                label="Account Type*"
                className="col-span-1"
                options={[{ value: '', title: 'Account Type' }, ...AccountType.all.map(item => ({
                    value: item.key,
                    title: item.title
                }))]}
                value={role}
                onChange={(e: FormChangeEvent) => setRole(e.target.value as string)}
            />
            {accountType && <>
                {accountType.type === AccountFormTypeEnum.USER && <UserCreateForm role={role}/>}

                {(accountType.type === AccountFormTypeEnum.COMPANY ||
                    accountType.type === AccountFormTypeEnum.COMPANY_USER) && <CompanyCreateForm role={role}/>}
            </>}

            {!role && <div className="lg:col-span-2 flex justify-end">
                <Button type="button" design="btn-secondary-gray" hasError onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button className="ml-3" disabled>
                    Create Account
                </Button>
            </div>}
        </div>
    </Card>
}

export default AccountsCreateForm
