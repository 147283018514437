import { useState } from 'react'
import { Link, useNavigate } from 'react-router'

import {
    Button,
    Card,
    Form, FormErrorsType,
    IconCube,
    PageHeader,
    ProductForm,
    ProductFormDataType
} from '@/components'
import { AuthLayout } from '@/containers'
import { useFormData, useToastNotifications } from '@/hooks'
import { Product } from '@/models'
import { isApiError } from '@/services'
import { FormChangeEvent } from '@/types'

const ProductsCreate = () => {
    const { success, error } = useToastNotifications()
    const navigate = useNavigate()
    const { convert } = useFormData()

    const [errors, setErrors] = useState<FormErrorsType>({})
    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState<ProductFormDataType>({
        model_name: '',
        pdf_template_id: '',
        product_type_id: ''
    })

    const handleChange = (e: FormChangeEvent) => {
        setForm({
            ...form,
            [e.target.name as string]: e.target.type === 'checkbox' ? e.target.checked : e.target.value as string
        })
    }

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors({})
        try {
            await Product.store(convert(form))
            success('Product successfully created')
            navigate('/products')
        } catch (err) {
            if (!isApiError(err)) throw err
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    const breadcrumb = [
        <IconCube key={1} className="stroke-gray-500"/>,
        <Link key={2} to="/products">Products</Link>,
        'Create New Product'
    ]
    return <AuthLayout heading={<PageHeader title="Create New Product" backUrl="/products"/>} breadcrumb={breadcrumb}>
        <Card>
            <Form onSubmit={handleSubmit} className="grid lg:grid-cols-2 gap-6">
                <div className="flex flex-col gap-6">
                    <ProductForm
                        onChange={handleChange}
                        form={form}
                        errors={errors}
                    />
                </div>
                <div className="flex justify-end lg:col-span-2 gap-3">
                    <Button design="btn-secondary-gray" hasError href="/products">
                        Cancel
                    </Button>
                    <Button type="submit" processing={processing}>
                        Save
                    </Button>
                </div>
            </Form>
        </Card>
    </AuthLayout>
}

export default ProductsCreate
