import classnames from 'classnames'

import { Button, SearchableSelect } from '@/components'
import { ProductRateTypeEnum, WarrantyProductTypeEnum } from '@/enums'
import { useAuth } from '@/hooks'
import { Company, WarrantyProductType } from '@/models'
import { IdType, FormChangeEventHandler } from '@/types'

export type WarrantyCreatePopupStep1FormType = {
    company_id: IdType
    warranty_product_type: WarrantyProductTypeEnum | ''
}

type WarrantyCreatePopupStep1Props = {
    form: WarrantyCreatePopupStep1FormType
    onChange: FormChangeEventHandler
    productType?: WarrantyProductType
    company?: Company
    companies: Company[]
    onNext: () => void
}

export const WarrantyCreatePopupStep1 = ({
    form,
    productType,
    company,
    companies,
    ...props
}: WarrantyCreatePopupStep1Props) => {
    const auth = useAuth()

    const warrantyProductTypes = company
        ? company.eligibleProductTypes
        : WarrantyProductType.all.filter(item => !item.isDeprecated)

    return <>
        {auth.user?.role.isAdminOrStaff && <>
            <h2 className="text-gray-900 font-semibold text-xl pr-10 mb-2">
                What company are you registering warranties for?
            </h2>
            <SearchableSelect
                label="Company*"
                name="company_id"
                className="mb-7"
                value={form.company_id}
                type="contractor"
                placeholder="Enter Company Name"
                onChange={props.onChange}
                options={companies.map(item => ({
                    value: item.id,
                    title: item.name
                }))}
            />
        </>}
        <h2 className="text-gray-900 font-semibold text-xl pr-10 mb-2">
            Select the warranty coverage that fits your installation.
        </h2>
        <p className="text-sm text-gray-600 mb-6.5">
            Protect your solar power system, battery storage, or both with tailored coverage.
        </p>

        <div className={classnames('flex flex-wrap lg:flex-nowrap gap-3 mb-6 font-body', { grayscale: !company })}>
            {warrantyProductTypes.map(item => <label
                className={classnames(
                    'py-4 px-4.5 flex justify-center grow gap-11 rounded-md shadow-md border-2 border-gray-200 hover:bg-primary-25',
                    'transition-all group',
                    {
                        'border-primary-500': productType?.key === item.key,
                        'cursor-pointer': form.company_id
                    }
                )}
                key={item.key}
            >
                <span className="relative w-full">
                    <div className="flex flex-col gap-3 items-center justify-between w-full opacity-0 h-full group-hover:opacity-100 transition-opacity absolute top-0">
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-col gap-1 w-full font-brand">
                                <span className="font-semibold">{item.brandedTitleColored}</span>
                                <span className="text-xs">{item.subtitle}</span>
                            </div>
                            <div className="text-gray-500 leading-5.5 text-xs text-left">
                                {item.description}
                            </div>
                        </div>
                        {company?.product_rates && <div className="flex gap-4 justify-between">
                            {item.hasPowerProduction && <div
                                className="flex items-center gap-1.5"
                            >
                                <WarrantyProductType.PowerProductionIcon
                                    style={{ fill: `var(--color-brand-${item.color})` }}
                                />
                                <span>
                                    {company.product_rates[item.powerProductionRateKey as ProductRateTypeEnum].money()}
                                    <span className="text-gray-500 text-2xs">/W</span>
                                </span>
                            </div>}
                            {item.hasStorage && <div
                                className="flex items-center gap-1.5"
                            >
                                <WarrantyProductType.StorageIcon
                                    style={{ stroke: `var(--color-brand-${item.color})` }}
                                />
                                <span>
                                    {company.product_rates[item.storageRateKey as ProductRateTypeEnum].money()}
                                    <span className="text-gray-500 text-2xs">/Wh</span>
                                </span>
                            </div>}
                        </div>}
                    </div>
                    <div className="flex flex-col gap-3 items-center w-full group-hover:opacity-0 transition-opacity">
                        <div className="w-50 h-50">
                            {item.image}
                        </div>

                        <div className="flex flex-col gap-1 text-center font-brand">
                            <span className="font-semibold">{item.brandedTitleColored}</span>
                            <span className="text-xs">{item.subtitle}</span>
                        </div>
                    </div>
                </span>
                <input
                    type="radio"
                    className="hidden"
                    name="warranty_product_type"
                    value={item.key}
                    onChange={props.onChange}
                    disabled={!form.company_id}
                />
            </label>)}
        </div>

        <div className="flex justify-end">
            <Button
                disabled={!company || !productType}
                onClick={props.onNext}
            >
                Continue
            </Button>
        </div>
    </>
}
