import { api, Payload } from '@/services'
import { IdType } from '@/types'

export const support = {
    feedback(payload: Payload) {
        return api.http.post('api/v1/support/articles/feedback', payload)
    },
    deleteNotification(id: IdType) {
        return api.http.get(`api/v1/support/articles/notification/${id}`)
    }
}
