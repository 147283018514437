import { Icon, IconProps } from './Icon'

export const IconStep = (props: IconProps) =>
    <Icon {...props}>
        <g clipPath="url(#czu2uz13ma)">
            <circle
                cx="12"
                cy="12"
                r="1"
                strokeWidth="5"
            />
            <rect
                x=".75"
                y=".75"
                width="22.5"
                height="22.5"
                rx="11.25"
                strokeWidth="1.5"
            />
        </g>
        <defs>
            <clipPath id="czu2uz13ma">
                <rect
                    width="24"
                    height="24"
                    rx="12"
                />
            </clipPath>
        </defs>
    </Icon>
