import classnames from 'classnames'
import { useState } from 'react'
import { Link } from 'react-router'

import { Button, IconChevronDown, WarrantyDetailsPreviewList } from '@/components'
import { WarrantyStatusEnum } from '@/enums'
import { Warranty, WarrantyProductType } from '@/models'

type WarrantyDetailsPreviewProps = {
    warranty: Warranty
    className?: string
    minCount?: number
}

export const WarrantyDetailsPreview = ({
    warranty,
    className,
    minCount
}: WarrantyDetailsPreviewProps) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const fields = [
        {
            key: 'address',
            value:  `${warranty.homeowner.street_address}, ${warranty.homeowner.city}, ${warranty.homeowner.state} ${warranty.homeowner.zip}`
        },
        {
            key: 'email',
            value: warranty.homeowner?.email
        },
        {
            key: 'created_at',
            value: warranty.homeowner?.created_at
        },
        {
            key: 'inverter_name',
            value: warranty.inverter?.model_name
        },
        {
            key: 'size_kw',
            value: warranty.size_kw
        },
        {
            key: 'status',
            value: warranty.status.key === WarrantyStatusEnum.UNKNOWN ? '' : warranty.status.title
        },
        {
            key: 'company',
            value: warranty.homeowner?.company?.name
        },
        {
            key: 'solar_price',
            title: `${WarrantyProductType.solar.coverageTitle} Price`,
            icon: WarrantyProductType.solar.coverageIcon(),
            value: warranty.solar_price
        },
        {
            key: 'battery_price',
            title: `${warranty.type.coverageTitle} Price`,
            icon: warranty.type.coverageIcon(),
            value: warranty.battery_price
        },
        {
            key: 'end_date',
            value: warranty.end_date
        },
        {
            key: 'num_panels',
            value: warranty.num_panels
        },
        {
            key: 'install_date',
            value: warranty.homeowner?.install_date
        },
        {
            key: 'panel_name',
            value: warranty.panel?.model_name
        },
        {
            key: 'price',
            value: warranty.price
        },
        {
            key: 'phone',
            value: warranty.homeowner?.phone
        },
        {
            key: 'batteries',
            value: warranty.batteries.length ? warranty.batteries : null
        }
    ].filter(item => item.value)

    return <div className={classnames('p-7.5 border border-gray-100 rounded relative', className)}>
        <div className="bg-brand-orange h-2.5 w-full rounded-t absolute top-0 left-0"/>
        <h3 className="border-b border-b-brand-orange pb-2 flex gap-4 justify-between mb-6">
            <div className="font-semibold flex flex-col gap-1">
                {warranty.id
                    ? <Link to={`/warranties/${warranty.id}`} target="_blank">
                        {warranty.homeowner.fullName}
                    </Link>
                    : <span>{warranty.homeowner.first_name || ''} {warranty.homeowner.last_name || ''}</span>}
                <div className="flex gap-1 items-center text-sm">
                    {warranty.type.brandIcon('md')} {warranty.type.title}
                </div>
            </div>
            <div className="flex flex-col gap-2 items-end">
                <span className="text-xs font-semibold">
                    Manufacturer System ID: <span className="font-normal">{warranty.homeowner.system_id || '-'}</span>
                </span>
                {warranty.inverter?.manufacturer?.getLogo()}
            </div>
        </h3>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-3.5">
            <WarrantyDetailsPreviewList
                fields={fields
                    .filter((_, index) => index < 6 || isExpanded)}
            />
            {minCount && fields.length < minCount && [...Array(minCount - fields.length).keys()]
                .map((item: number) => <div key={item} className="flex flex-col gap-1.5">
                    <div className="w-12.5 rounded bg-gray-200 h-5"/>
                    <div className="w-25 rounded bg-gray-200 h-5"/>
                </div>)}
        </div>

        {fields.length > 6 && <div className="flex justify-center">
            <Button
                type="button"
                design="btn-link"
                onClick={() => setIsExpanded(state => !state)}
            >
                View {isExpanded ? 'Less' : 'More'}
                <IconChevronDown
                    className={classnames('stroke-black transition-all', { 'rotate-180': isExpanded })}
                />
            </Button>
        </div>}
    </div>
}
