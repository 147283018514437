import classnames from 'classnames'
import { Fragment, useEffect, useState } from 'react'

import {
    BatteryForm,
    BatteryFormDataType,
    Button, FormErrorsType,
    IconClose,
    Input,
    DatePicker
} from '@/components'
import { Product, Warranty, WarrantyProductType } from '@/models'
import { uuid } from '@/services'
import { FormChangeEvent, FormChangeEventHandler, JsonDataType } from '@/types'

export interface BatteriesFormDataType extends JsonDataType {
    battery_qty?: number
    batteries: BatteryFormDataType[]
    install_date: Date | null
}

type BatteriesFormProps = {
    form: BatteriesFormDataType
    errors: FormErrorsType
    onChange: FormChangeEventHandler
    warranty?: Warranty
    productType: WarrantyProductType
    className?: string
}

export const BatteriesForm = ({
    form,
    errors = {},
    warranty,
    className,
    productType,
    ...props
}: BatteriesFormProps) => {
    const [batteryProducts, setBatteryProducts] = useState<Product[]>([])
    const [batteriesCount, setBatteriesCount] = useState<string | number>(form.batteries.length)

    const handleRemove = async (index: number) => {
        const batteries = [...form.batteries]
        batteries.splice(index, 1)
        await props.onChange({ target: { name: 'batteries', value: batteries } })
        setBatteriesCount(batteries.length)
    }

    const fetchProducts = async () => {
        const res = await Product.onlyBatteries({ warranty_id: warranty?.id })
        setBatteryProducts(res)
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    useEffect(() => {
        const batteries = [...form.batteries]
        const count = parseInt(batteriesCount as string)
        do {
            if (batteries.length > count) {
                batteries.pop()
            } else if (batteries.length < count) {
                batteries.push({
                    id: uuid(),
                    product_id: '',
                    size_kw: '',
                    serial_number: ''
                })
            }
        } while (batteries.length !== count)
        if (batteries.length !== form.batteries.length) {
            props.onChange({ target: { name: 'batteries', value: batteries } })
        }
    }, [batteriesCount])

    useEffect(() => {
        if (form.batteries.length !== batteriesCount) {
            setBatteriesCount(form.batteries.length)
        }
    }, [form.batteries.length])

    const handleChange = (e: FormChangeEvent, index: number) => {
        const batteries = [...form.batteries]
        batteries[index] = { ...batteries[index] }
        batteries[index][e.target.name] = e.target.value
        const product = batteryProducts
            .find(product => product.id.toString() === batteries[index].product_id.toString())
        batteries[index].product = {
            id: product?.id || '',
            model_name: product?.model_name || ''
        }
        props.onChange({ target: { name: 'batteries', value: batteries } })
    }

    const handleCountChange = (e: FormChangeEvent) => {
        const value = parseInt(e.target.value as string)
        if (!isNaN(value) && value > 0) {
            setBatteriesCount(value)
        } else {
            setBatteriesCount(1)
        }
    }

    const getErrors = (index: number) => {
        const obj: FormErrorsType = {}
        const keyStart = `batteries.${index}.`
        Object.keys(errors).forEach(key => {
            const fieldName = key.replace(keyStart, '')
            if (key.startsWith(keyStart)) {
                obj[fieldName] = (errors[key] as string).replace(key, fieldName).split('_').join(' ')
            }
        })
        return obj
    }
    return <div className={classnames('grid grid-cols-1 lg:grid-cols-11 gap-6 grow', className)}>
        {form.batteries.map((item, index) => <Fragment key={item.id}>
            {index
                ? <div className="flex col-span-1 lg:col-span-2 justify-end items-end">
                    <Button
                        square
                        design="btn-secondary"
                        hasError
                        type="button"
                        onClick={() => handleRemove(index)}
                    >
                        <IconClose/>
                    </Button>
                </div>
                : <Input
                    id="battery_qty"
                    name="battery_qty"
                    type="number"
                    label="# of Batteries*"
                    className="col-span-1 lg:col-span-2"
                    onChange={handleCountChange}
                    value={batteriesCount}
                />}
            {!!batteryProducts.length && <BatteryForm
                form={item}
                onChange={(e: FormChangeEvent) => handleChange(e, index)}
                inputClassName="col-span-1 lg:col-span-3"
                labelPrefix={`Battery ${1 + index}:`}
                products={batteryProducts}
                errors={getErrors(index)}
            />}
        </Fragment>)}
        {!productType.hasPowerProduction &&
            <DatePicker
                id="install-date"
                name="install_date"
                className="lg:col-span-5"
                label="Installation Date*"
                placeholder="Installation Date"
                onChange={props.onChange}
                value={form.install_date}
                errors={errors.install_date}
                options={{
                    minDate: warranty?.created_at
                        ? warranty.created_at?.clone().subtract('year', 1)
                        : new Date().subtract('year', 1)
                }}
            />}
    </div>
}
