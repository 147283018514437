import { Icon, IconProps } from './Icon'

export const IconChevronRight = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="m9 18 6-6-6-6"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
