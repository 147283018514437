import { useEffect, useState } from 'react'

import {
    Checkbox,
    CompanyAccountActivityStatusFilter,
    CompanyFilter,
    DatePicker, Feature, FormControl, InverterManufacturerFilter, ProductFilter, RangeInput, SearchableSelect,
    WarrantyProductTypeFilter,
    WarrantyStatusFilter
} from '@/components'
import { WarrantyStatusEnum } from '@/enums'
import { useAuth, useIndexContainer } from '@/hooks'
import { Company, Warranty } from '@/models'
import { api } from '@/services'
import {
    AccountManagerDataType,
    FormChangeEvent,
    FormChangeEventHandler,
    StatesListType, WarrantyIndexFiltersType, WarrantySummaryDataType
} from '@/types'

type WarrantyIndexFiltersProps = {
    standalone?: boolean
    filters: WarrantyIndexFiltersType
    meta?: {
        warranty_status_list: WarrantyStatusEnum[]
    }

    onChange: FormChangeEventHandler
}

export const WarrantyIndexFilters = ({
    standalone,
    filters,
    meta,
    ...props
}: WarrantyIndexFiltersProps) => {
    const { filtersStringValue } = useIndexContainer(filters)
    const auth = useAuth()
    const [abortController, setAbortController] = useState<AbortController | null>(null)
    const [summary, setSummary] = useState<WarrantySummaryDataType>({
        manufacturers: {},
        warranty_product_types: {}
    })
    const [states, setStates] = useState<StatesListType>({})
    const [accountManagers, setAccountManagers] = useState<AccountManagerDataType[]>([])

    const fetchStates = async () => {
        const res = await api.address.states()
        setStates(res)
    }
    const fetchAccountManagers = async () => {
        const res = await Company.accountManagers()
        setAccountManagers(res.data)
    }

    useEffect(() => {
        fetchStates()
        if (auth.user?.role.isAdminOrStaffOrAdvisor) {
            fetchAccountManagers()
        }
    }, [])

    const fetchSummary = async () => {
        abortController?.abort('canceled')
        const controller = new AbortController
        setAbortController(controller)
        const filterParams = Object.keys(filters)
            .reduce((acc, key) => ({
                ...acc,
                [key]: filters[key].value?.toString() === filters[key].defaultValue?.toString()
                    ? null : filters[key].value
            }), {})
        const { data } = await Warranty.summary(filterParams, { signal: controller.signal })
        setSummary(data)
    }

    useEffect(() => {
        fetchSummary()
    }, [filtersStringValue])

    const handleChange = (e: FormChangeEvent) => {
        if ([
            'with_pto_date' as keyof WarrantyIndexFiltersType,
            'with_emailed_at' as keyof WarrantyIndexFiltersType
        ].includes(e.target.name)) {
            props.onChange({
                ...e,
                target: {
                    name: e.target.name,
                    value: e.target.value,
                    checked: e.target.checked,
                    displayValue: e.target.value ? 'Yes' : ''
                }
            })
        } else {
            props.onChange(e)
        }
    }

    useEffect(() => {
        if (filters.with_pto_date.value) {
            props.onChange({
                target: {
                    name: 'with_pto_date',
                    value: '1',
                    checked: true,
                    displayValue: 'Yes'
                }
            })
        }
        if (filters.with_emailed_at.value) {
            props.onChange({
                target: {
                    name: 'with_emailed_at',
                    value: '1',
                    checked: true,
                    displayValue: 'Yes'
                }
            })
        }
    }, [])

    if (standalone) {
        return <>
            <DatePicker
                clearable
                id="created-at-range"
                name="created_at_range"
                placeholder="Submission Date range"
                value={filters.created_at_range.value}
                onChange={handleChange}
                options={{
                    singleMode: false,
                    maxDate: new Date()
                }}
                data-test="warranties-index-created-at-range-filter"
                className="flex-1 lg:flex-none lg:min-w-[13rem]"
            />
            <WarrantyStatusFilter
                name="statuses"
                id="warranty-statuses"
                className="flex-1 lg:flex-none lg:min-w-[13rem]"
                value={filters.statuses.value}
                onChange={handleChange}
                availableStatuses={meta?.warranty_status_list as WarrantyStatusEnum[]}
                data-test="warranties-index-statuses-filter"
            />
        </>
    }
    return <>
        <FormControl label="Warranty Type">
            <WarrantyProductTypeFilter
                name="types"
                id="warranty-types"
                value={filters.types.value}
                summary={summary}
                onChange={handleChange}
                data-test="warranties-index-types-filter"
            />
        </FormControl>
        <SearchableSelect
            id="state"
            name="state"
            label="State"
            placeholder="Any State"
            options={Object.keys(states)
                .map(key => ({
                    value: key,
                    title: `${states[key]}, ${key}`
                }))}
            value={filters.state.value}
            onChange={handleChange}
            data-test="warranties-index-state-filter"
            input-size="xs"
        />
        <InverterManufacturerFilter
            id="manufacturers"
            name="manufacturers"
            label="Manufacturers"
            value={filters.manufacturers.value}
            onChange={handleChange}
            summary={summary}
            data-test="warranties-index-manufacturers-filter"
            input-size="xs"
        />

        {auth.user?.role.isAdminOrStaffOrAdvisor && <CompanyFilter
            id="affiliate-id"
            type="contractor"
            name="company_id"
            label="Contractor"
            placeholder="Any Contractor"
            value={filters.company_id.value}
            onChange={handleChange}
            data-test="warranties-index-contractor-id-filter"
            input-size="xs"
        />}

        {(auth.user?.role.isAdminOrStaffOrAdvisor ||
                auth.user?.company?.affiliate?.warranty_match_method.isExternalUploadMatch) &&
            <CompanyFilter
                id="affiliate-id"
                type="conglomerate"
                name="affiliate_id"
                label="Affiliate"
                placeholder="Any Affiliate"
                value={filters.affiliate_id.value}
                onChange={handleChange}
                disabled={!auth.user?.role.isAdminOrStaffOrAdvisor}
                data-test="warranties-index-affiliate-id-filter"
                input-size="xs"
            />}

        {auth.user?.role.isAdminOrStaffOrAdvisor &&
            <FormControl label="Company Status">
                <CompanyAccountActivityStatusFilter
                    id="company-account-activity-status"
                    name="company_account_activity_statuses"
                    value={filters.company_account_activity_statuses.value}
                    onChange={handleChange}
                    data-test="company-account-activity-status-filter"
                />
            </FormControl>}
        {auth.user?.role.isAdminOrStaffOrAdvisor &&
            <SearchableSelect
                id="account-manager-email"
                name="account_manager_email"
                label="Account Manager"
                placeholder="Any Account Manager"
                options={accountManagers.map(item => ({
                    value: item.email,
                    title: item.name
                }))}
                value={filters.account_manager_email.value}
                onChange={handleChange}
                data-test="warranties-index-account-manager-email-filter"
                input-size="xs"
            />}

        <Feature name="new-index-page-filters">
            <DatePicker
                clearable
                id="install-date-range"
                name="install_date_range"
                label="Installation Date"
                placeholder="Select Dates"
                value={filters.install_date_range.value}
                onChange={handleChange}
                options={{
                    singleMode: false,
                    maxDate: new Date()
                }}
                data-test="warranties-index-install-date-range-filter"
                input-size="xs"
            />
        </Feature>

        <Feature name="new-index-page-filters">
            <FormControl label="PTO Date?" className="border-b border-gray-200 pb-4">
                <div className="flex flex-col gap-2">
                    <Checkbox
                        name="with_pto_date"
                        label="Yes"
                        checked={!!filters.with_pto_date.value}
                        value="1"
                        onChange={handleChange}
                    />
                    <Checkbox
                        name="with_pto_date"
                        label="No"
                        checked={!filters.with_pto_date.value}
                        value=""
                        onChange={handleChange}
                    />
                    {!!filters.with_pto_date.value && <DatePicker
                        clearable
                        id="pto-date-range"
                        name="pto_date_range"
                        placeholder="PTO Date"
                        value={filters.pto_date_range.value}
                        onChange={handleChange}
                        options={{ singleMode: false }}
                        data-test="warranties-index-pto-date-range-filter"
                    />}
                </div>
            </FormControl>
        </Feature>

        <Feature name="new-index-page-filters">
            <FormControl label="Emailed Date?" className="border-b border-gray-200 pb-4">
                <div className="flex flex-col gap-2">
                    <Checkbox
                        name="with_emailed_at"
                        label="Yes"
                        checked={!!filters.with_emailed_at.value}
                        value="1"
                        onChange={handleChange}
                    />
                    <Checkbox
                        name="with_emailed_at"
                        label="No"
                        checked={!filters.with_emailed_at.value}
                        value=""
                        onChange={handleChange}
                    />
                    {!!filters.with_emailed_at.value && <DatePicker
                        clearable
                        id="emailed-at-range"
                        name="emailed_at_range"
                        placeholder="Emailed Date"
                        value={filters.emailed_at_range.value}
                        onChange={handleChange}
                        options={{ singleMode: false }}
                        data-test="warranties-index-emailed-at-range-filter"
                    />}
                </div>
            </FormControl>
        </Feature>

        <ProductFilter
            id="battery-product-id"
            type="batteries"
            name="battery_product_id"
            label="Battery Make"
            placeholder="Any Battery Make"
            value={filters.battery_product_id.value}
            onChange={handleChange}
            data-test="warranties-index-battery-product-id-filter"
            input-size="xs"
        />

        <Feature name="new-index-page-filters">
            <RangeInput
                id="battery-size-kw-range"
                name="battery_size_kw_range"
                label="Battery Storage (kW)"
                min={0}
                max={1000}
                step={10}
                value={filters.battery_size_kw_range.value}
                onChange={handleChange}
                valueLabelOverride={value => `${value.format()} kW`}
            />
        </Feature>
        <Feature name="new-index-page-filters">
            <RangeInput
                id="size-kw-range"
                name="size_kw_range"
                label="System Size (kW)"
                min={0}
                max={1000}
                step={10}
                value={filters.size_kw_range.value}
                onChange={handleChange}
                valueLabelOverride={value => `${value.format()} kW`}
            />
        </Feature>
    </>
}
