import { Chart as ChartJs, ChartDataset, ChartOptions, Plugin } from 'chart.js'
import merge from 'lodash/merge'
import { ReactNode } from 'react'

import Chart from './Chart'
import { api } from '@/services'

type USMapChartProps = {
    datasets: ChartDataset[]
    labels: string[]
    plugins?: Plugin[]
    options?: ChartOptions
    onInit?: (chart: ChartJs) => void
    formatTooltip: (name: string) => ReactNode
}

const USMapChart = ({ plugins, labels, datasets, options, ...props }: USMapChartProps) => {
    const defaultOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            datalabels: {
                display: true,
                color: '#fff',
                font: { size: 10 },
                formatter: value => api.address.statesStatic[value.feature?.properties?.name] || ''
            },
            legend: { display: false }
        },
        scales: {
            projection: {
                axis: 'x',
                projection: 'albersUsa'
            },
            color: {
                display: false,
                axis: 'x',
                beginAtZero: true,
                interpolate: (v: number) => {
                    if (v < 0.2) {
                        return '#b4bcd5'
                    } else if (v < 0.4) {
                        return '#909dc2'
                    } else if (v < 0.6) {
                        return '#7c8cb7'
                    } else if (v < 0.8) {
                        return '#576fa3'
                    }
                    return '#3a5c96'
                },
                quantize: 5
            }
        }
    }

    return <div className="relative max-h-full flex justify-center items-center">
        <Chart
            type="choropleth"
            data={{
                labels,
                datasets
            }}
            options={merge(defaultOptions, options)}
            plugins={plugins}
            onInit={props.onInit}
            customTooltip={index => <div
                className="px-3 py-2 font-body bg-white shadow-md rounded-md"
            >
                <div className="absolute w-2 h-2 -left-1 top-1/2 -translate-y-1/2 bg-white rotate-45 origin-center"/>
                {props.formatTooltip((datasets[0].data[index] as unknown as {
                    feature: {
                        properties: {
                            name: string
                        }
                    }
                })?.feature?.properties?.name)}
            </div>}
        />
    </div>
}

export default USMapChart
