import { CompanyAccountActivityStatusDataType } from '@/types'

export enum CompanyAccountActivityStatusEnum {
    ACTIVE = 'active',
    ONBOARDING = 'onboarding',
    NOTICE_TO_SUSPEND = 'notice_to_suspend',
    CERTIFICATION = 'certification',
    SUSPENDED = 'suspended',
    INSOLVENT = 'insolvent',
    INACTIVE = 'inactive',
    SERVICE_PARTNER = 'service_partner',
    AFFILIATE = 'affiliate',
    TERMINATED = 'terminated'
}

export const COMPANY_ACCOUNT_ACTIVITY_STATUSES: CompanyAccountActivityStatusDataType[] = [
    {
        key: CompanyAccountActivityStatusEnum.ACTIVE,
        title: 'Active',
        className: 'text-success-800 bg-success-100',
        iconClassName: 'bg-success-400'
    },
    {
        key: CompanyAccountActivityStatusEnum.ONBOARDING,
        title: 'Onboarding',
        className: 'text-blue-800 bg-blue-100',
        iconClassName: 'bg-blue-400'
    },
    {
        key: CompanyAccountActivityStatusEnum.NOTICE_TO_SUSPEND,
        title: 'Notice to Suspend',
        className: 'text-orange-800 bg-warning-100',
        iconClassName: 'bg-yellow-500'
    },
    {
        key: CompanyAccountActivityStatusEnum.CERTIFICATION,
        title: 'Certification',
        className: 'text-teal-800 bg-teal-100',
        iconClassName: 'bg-teal-600'
    },
    {
        key: CompanyAccountActivityStatusEnum.SUSPENDED,
        title: 'Suspended',
        className: 'text-error-800 bg-error-100',
        iconClassName: 'bg-warning-500',
        isDisabled: true
    },
    {
        key: CompanyAccountActivityStatusEnum.INSOLVENT,
        title: 'Insolvent',
        className: 'text-gray-800 bg-gray-100',
        iconClassName: 'bg-gray-400',
        isDisabled: true
    },
    {
        key: CompanyAccountActivityStatusEnum.INACTIVE,
        title: 'Inactive',
        className: 'text-purple-800 bg-purple-100',
        iconClassName: 'bg-purple-400'
    },
    {
        key: CompanyAccountActivityStatusEnum.SERVICE_PARTNER,
        title: 'Service Partner',
        className: 'text-pink-800 bg-pink-100',
        iconClassName: 'bg-pink-400'
    },
    {
        key: CompanyAccountActivityStatusEnum.AFFILIATE,
        title: 'Affiliate',
        className: 'text-indigo-800 bg-indigo-100',
        iconClassName: 'bg-teal-600'
    },
    {
        key: CompanyAccountActivityStatusEnum.TERMINATED,
        title: 'Terminated',
        className: 'text-error-700 border border-error-700 font-semibold',
        iconClassName: 'bg-error-700',
        isDisabled: true
    }
]
