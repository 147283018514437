import classnames from 'classnames'
import { HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'

import { Button, IconChevronLeft, IconChevronRight } from '@/components'

type TabProps = {
    title: ReactNode
    icon?: ReactNode
    id: string | number | null
    to?: string
    regex?: RegExp
    showIf?: boolean
}

interface TabsProps extends Omit<HTMLAttributes<HTMLUListElement>, 'onChange'> {
    size?: 'sm' | 'md'
    design?: 'default' | 'buttons' | 'buttons-secondary'
    hash?: boolean
    value?: string | number | null
    tabs: Array<TabProps>
    'data-test'?: string
    replace?: boolean
    onChange?: (id: string | number) => void,
}

export const Tabs = ({
    size = 'md',
    className,
    value,
    tabs,
    design = 'default',
    hash = false,
    'data-test': dataTest = 'tabs',
    replace,
    ...props
}: TabsProps) => {
    const location = useLocation()
    const tabsRef = useRef<HTMLUListElement>(null)
    const [scrollable, serScrollable] = useState(false)

    useEffect(() => {
        const scrollWidth = tabsRef.current?.scrollWidth as number
        const width = Math.ceil(tabsRef.current?.getBoundingClientRect().width as number)
        serScrollable(scrollWidth > width)
    }, [tabsRef.current?.scrollWidth])

    useEffect(() => {
        if (hash && location.hash?.substring(1) && props.onChange) {
            props.onChange(location.hash.substring(1))
        }
    }, [])

    const getHref = (item: TabProps) => {
        if (item.to) {
            return item.to
        } else if (hash) {
            return `#${item.id}`
        }
        return undefined
    }

    const handleChange = ({ id }: TabProps) => {
        if (props.onChange) {
            props.onChange(id as string | number)
        }
    }

    const getIsActive = (item: TabProps, index: number) => {
        if (item.regex) {
            return item.regex.test(location.pathname)
        }
        return item.id === value || (!index && !value)
    }

    const handleScroll = (direction: -1 | 1) => {
        if (tabsRef.current) {
            const children = Array.from(tabsRef.current.children)
            const avgChildWidth = children.reduce((acc, item) =>
                acc + item.clientWidth, 0) / children.length
            tabsRef.current.scrollTo({
                top: 0,
                left: tabsRef.current.scrollLeft + (avgChildWidth * direction),
                behavior: 'smooth'
            })
        }
    }

    const {
        onChange,
        ...ulProps
    } = props

    return <div className={classnames('tabs-container', className, { scrollable })}>
        <Button
            design="btn-link"
            type="button"
            className="tabs-control tabs-control-left"
            name="left"
            onClick={() => handleScroll(-1)}
        >
            <IconChevronLeft/>
        </Button>
        <ul
            className={classnames('tabs', size, design)}
            data-test={dataTest}
            ref={tabsRef}
            {...ulProps}
        >
            {tabs.map((item, index) => {
                if (item.showIf === false) return null
                return <li
                    key={item.id}
                    className={getIsActive(item, index) ? 'current' : ''}
                    data-test={`${dataTest}-tab-${value}`}
                >
                    <Button
                        design="btn-link"
                        onClick={() => handleChange(item)}
                        href={getHref(item)}
                        type="button"
                        replace={replace}
                    >
                        <div className="flex gap-2 items-center">
                            {item.title} {item.icon}
                        </div>
                    </Button>
                </li>
            })}
        </ul>
        <Button
            design="btn-link"
            type="button"
            className="tabs-control tabs-control-right"
            name="right"
            onClick={() => handleScroll(1)}
        >
            <IconChevronRight/>
        </Button>
    </div>
}
