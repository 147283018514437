import { useState } from 'react'

import { Button, Form, FormErrorsType, Input, Logo } from '@/components'
import { FormChangeEvent, JsonDataType } from '@/types'

interface StepThreeFormDataType extends JsonDataType {
    password: string
    password_confirmation: string
}

type ComponentProps = {
    onSubmit: () => void
}

const StepThree = ({ ...props }: ComponentProps) => {
    const [errors, setErrors] = useState<FormErrorsType>({})
    const [form, setForm] = useState<StepThreeFormDataType>({
        password: '',
        password_confirmation: ''
    })

    const handleSubmit = async () => {
        if (!form.password) {
            setErrors({ password: ['This field is required'] })
        } else {
            props.onSubmit()
        }
    }

    const handleChange = (e: FormChangeEvent) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    return <div className="h-auto signup-step-three relative">
        <Logo type="small" className="mx-auto small-logo w-12 h-12 object-contain mb-8"/>
        <h1 className="text-3xl text-center text-header font-medium">
            Choose a password
        </h1>
        <h3 className="text-gray-600 text-center signup-subheader text-gray-700 font-light">
            Must be at least 8 characters and contain at least one number, one letter, and one special character.
            Passwords are case sensitive.
        </h3>
        <Form onSubmit={handleSubmit}>
            <div className="password-field relative">
                <Input
                    id="password"
                    name="password"
                    label="Password*"
                    type="password"
                    className="mb-4"
                    onChange={handleChange}
                    value={form.password}
                    errors={errors.password}
                    placeholder="Choose a password"
                />
            </div>
            <div className="password-field">
                <Input
                    id="passwordConfirm"
                    name="password_confirmation"
                    label="Confirm Password*"
                    type="password"
                    className="mb-6"
                    onChange={handleChange}
                    value={form.password_confirmation}
                    errors={errors.password_confirmation}
                    placeholder="Confirm password"
                />
            </div>
            <Button className="w-full">
                Continue
            </Button>
        </Form>
    </div>
}

export default StepThree
