import { Icon, IconProps } from './Icon'

export const IconSearch = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="m21 21-3.5-3.5m2.5-6a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
