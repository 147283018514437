import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

function useScrollToAnchor() {
    const location = useLocation()
    const lastHash = useRef('')

    useEffect(() => {
        if (location.hash) {
            lastHash.current = location.hash.slice(1)
        }

        const el = document.getElementById(lastHash.current)
        if (lastHash.current && el) {
            setTimeout(() => {
                el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                })
                lastHash.current = ''
            }, 100)
        }
    }, [location])
}

export default useScrollToAnchor
