type DateFormatOptions = {
    localeMatcher?: 'best fit' | 'lookup'
    weekday?: 'long' | 'short' | 'narrow'
    era?: 'long' | 'short' | 'narrow'
    year?: 'numeric' | '2-digit' | null
    month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | null
    day?: 'numeric' | '2-digit' | null
    hour?: 'numeric' | '2-digit'
    minute?: 'numeric' | '2-digit'
    second?: 'numeric' | '2-digit'
    timeZoneName?: 'short' | 'long' | 'shortOffset' | 'longOffset' | 'shortGeneric' | 'longGeneric'
    formatMatcher?: 'best fit' | 'basic'
    hour12?: boolean | null
    timeZone?: string
}

type DateTimeUnitPluralType = 'years' | 'quarters' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds'
type DateTimeUnitSingularType = 'year' | 'quarter' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second'
type DateTimeUnitType = DateTimeUnitPluralType | DateTimeUnitSingularType

enum DateTimeUnitEnum {
    YEAR = 'year',
    YEARS = 'years',
    QUARTER = 'quarter',
    QUARTERS = 'quarters',
    MONTH = 'month',
    MONTHS = 'months',
    WEEK = 'week',
    WEEKS = 'weeks',
    DAY = 'day',
    DAYS = 'days',
    HOUR = 'hour',
    HOURS = 'hours',
    MINUTE = 'minute',
    MINUTES = 'minutes',
    SECOND = 'second',
    SECONDS = 'seconds'
}

declare global {
    interface Date {
        clone(): Date

        parse(value?: string | number): Date

        isValid(): boolean

        toISODate(): string

        toISODateTime(): string

        format(options?: DateFormatOptions): string

        humanize(): string

        add(unit: DateTimeUnitType, value: number): Date

        subtract(unit: DateTimeUnitType, value: number): Date

        startOf(unit: DateTimeUnitSingularType): Date

        endOf(unit: DateTimeUnitSingularType): Date
    }
}

/**
 * Return date in this format 2023-01-19
 */
Date.prototype.toISODate = function (this: Date) {
    const month = this.getMonth() + 1
    const day = this.getDate()
    return `${this.getFullYear()}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`
}

/**
 * Return date in this format 2023-01-19 08:41:57
 */
Date.prototype.toISODateTime = function (this: Date) {
    const month = this.getMonth() + 1
    const day = this.getDate()
    const hour = this.getHours()
    const minute = this.getMinutes()
    const second = this.getSeconds()
    return `${this.getFullYear()}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`} ${hour > 9 ? hour : `0${hour}`}:${minute > 9 ? minute : `0${minute}`}:${second > 9 ? second : `0${second}`}`
}

Date.prototype.clone = function (this: Date) {
    return new Date(this)
}

Date.prototype.format = function (this: Date, {
    year = 'numeric',
    month = 'short',
    day = 'numeric',
    hour12 = true,
    ...options
}: DateFormatOptions = {}) {
    try {
        return new Intl.DateTimeFormat('en-US', {
            year: year || undefined,
            month: month || undefined,
            day: day || undefined,
            hour12: hour12 || undefined,
            ...options
        }).format(this)
    } catch {
        return 'Invalid Date'
    }
}

Date.prototype.isValid = function (this: Date) {
    return this.toDateString() !== 'Invalid Date'
}

Date.prototype.humanize = function (this: Date) {
    const formatter = new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' })
    const DIVISIONS: { amount: number, name: DateTimeUnitPluralType }[] = [
        { amount: 60, name: DateTimeUnitEnum.SECONDS },
        { amount: 60, name: DateTimeUnitEnum.MINUTES },
        { amount: 24, name: DateTimeUnitEnum.HOURS },
        { amount: 7, name: DateTimeUnitEnum.DAYS },
        { amount: 4.34524, name: DateTimeUnitEnum.WEEKS },
        { amount: 12, name: DateTimeUnitEnum.MONTHS },
        { amount: Number.POSITIVE_INFINITY, name: DateTimeUnitEnum.YEARS }
    ]
    let duration = (+this - +new Date()) / 1000

    for (const i in DIVISIONS) {
        const division = DIVISIONS[i]
        if (Math.abs(duration) < division.amount) {
            return formatter.format(Math.round(duration), division.name)
        }
        duration /= division.amount
    }
    return 'Invalid date'
}

Date.prototype.add = function (this: Date, unit: DateTimeUnitType, value: number): Date {
    switch (unit) {
        case DateTimeUnitEnum.SECOND:
        case DateTimeUnitEnum.SECONDS:
            this.setSeconds(this.getSeconds() + value)
            break
        case DateTimeUnitEnum.MINUTE:
        case DateTimeUnitEnum.MINUTES:
            this.setMinutes(this.getMinutes() + value)
            break
        case DateTimeUnitEnum.HOUR:
        case DateTimeUnitEnum.HOURS:
            this.setHours(this.getHours() + value)
            break
        case DateTimeUnitEnum.DAY:
        case DateTimeUnitEnum.DAYS:
            this.setDate(this.getDate() + value)
            break
        case DateTimeUnitEnum.WEEK:
        case DateTimeUnitEnum.WEEKS:
            this.setDate(this.getDate() + (value * 7))
            break
        case DateTimeUnitEnum.MONTH:
        case DateTimeUnitEnum.MONTHS:
            this.setMonth(this.getMonth() + value)
            break
        case DateTimeUnitEnum.QUARTER:
        case DateTimeUnitEnum.QUARTERS:
            this.setMonth(this.getMonth() + (value * 3))
            break
        case DateTimeUnitEnum.YEAR:
        case DateTimeUnitEnum.YEARS:
            this.setFullYear(this.getFullYear() + value)
            break
        default:
            return this
    }
    return this
}

Date.prototype.subtract = function (this: Date, unit: DateTimeUnitType, value: number): Date {
    return this.add(unit, -value)
}

Date.prototype.startOf = function (this: Date, unit: DateTimeUnitSingularType): Date {
    switch (unit) {
        case DateTimeUnitEnum.SECOND:
            return new Date(this.setMilliseconds(0))
        case DateTimeUnitEnum.MINUTE:
            return new Date(this.setSeconds(0, 0))
        case DateTimeUnitEnum.HOUR:
            return new Date(this.setMinutes(0, 0, 0))
        case DateTimeUnitEnum.DAY:
            return new Date(this.setHours(0, 0, 0, 0))
        case DateTimeUnitEnum.WEEK: {
            const dayOfWeek = this.getDay()
            const diff = this.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
            return new Date(new Date(this.setDate(diff)).setHours(0, 0, 0, 0))
        }
        case DateTimeUnitEnum.MONTH:
            return new Date(new Date(this.getFullYear(), this.getMonth(), 1).setHours(0, 0, 0, 0))
        case DateTimeUnitEnum.QUARTER: {
            const quarterStartMonth = Math.floor(this.getMonth() / 3) * 3
            return new Date(new Date(this.getFullYear(), quarterStartMonth, 1).setHours(0, 0, 0, 0))
        }
        case DateTimeUnitEnum.YEAR:
            return new Date(new Date(this.getFullYear(), 0, 1).setHours(0, 0, 0, 0))
        default:
            return this
    }
}

Date.prototype.endOf = function (this: Date, unit: DateTimeUnitSingularType): Date {
    switch (unit) {
        case DateTimeUnitEnum.SECOND:
            return new Date(this.setMilliseconds(999))
        case DateTimeUnitEnum.MINUTE:
            return new Date(this.setSeconds(59, 999))
        case DateTimeUnitEnum.HOUR:
            return new Date(this.setMinutes(59, 59, 999))
        case DateTimeUnitEnum.DAY:
            return new Date(this.setHours(23, 59, 59, 999))
        case DateTimeUnitEnum.WEEK: {
            const dayOfWeek = this.getDay()
            const diff = this.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1) + 6
            return new Date(new Date(this.setDate(diff)).setHours(23, 59, 59, 999))
        }
        case DateTimeUnitEnum.MONTH:
            return new Date(new Date(this.getFullYear(), this.getMonth() + 1, 0).setHours(23, 59, 59, 999))
        case DateTimeUnitEnum.QUARTER: {
            const quarterEndMonth = (Math.floor(this.getMonth() / 3) * 3) + 2
            return new Date(new Date(this.getFullYear(), quarterEndMonth + 1, 0).setHours(23, 59, 59, 999))
        }
        case DateTimeUnitEnum.YEAR:
            return new Date(new Date(this.getFullYear(), 11, 31).setHours(23, 59, 59, 999))
        default:
            return this
    }
}

Date.prototype.toJSON = function () {
    if (this.toISODateTime().endsWith('00:00:00')) {
        return this.toISODate()
    }
    return this.toISODateTime()
}

export {}
