import { Popup, Button, IconLogout } from '@/components'

type ImpersonationModePopupProps = {
    isOpen: boolean,
    onClose: () => void
}

const ImpersonationModePopup = ({ isOpen, ...props }: ImpersonationModePopupProps) =>
    <Popup open={isOpen} onClose={props.onClose} className="w-full lg:w-[718px] lg:px-10">
        <div className="flex items-center gap-2 mb-4">
            <IconLogout size="xl" className="stroke-primary-600"/>
            <span className="font-semibold text-xl font-display">Confirm Exit from Impersonation Mode</span>
        </div>
        <div className="pb-2 text-gray-500 font-body">
            <p>Are you sure you want to exit Impersonation Mode?</p>
            <p className="mt-4">
                Exiting will revert your view back to your account, and you will no longer see the activity or data of
                the impersonated user.
            </p>
        </div>
        <div className="mt-2 flex items-center justify-end gap-4">
            <Button type="button" design="btn-secondary-gray" hasError onClick={props.onClose}>
                Cancel
            </Button>
            <Button href="/logout" onClick={props.onClose}>
                Exit Impersonation Mode
            </Button>
        </div>
    </Popup>

export default ImpersonationModePopup
