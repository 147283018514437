import { useEffect, useState } from 'react'

import { IconLinkedIn, Button, Logo, IconArrowRight, Image } from '@/components'
import { api, isApiCancelError } from '@/services'

const LinkedinInsights = () => {
    const [abortController, setAbortController] = useState<AbortController | null>(null)
    const [data, setData] = useState({
        title: '',
        imageUrl: '',
        postUrl: ''
    })

    const fetchData = async () => {
        const fallbackData = {
            title: '',
            imageUrl: 'https://www.solarinsure.com/wp-content/uploads/2024/08/Blog-Photos-2.png',
            postUrl: 'https://www.linkedin.com/company/solarinsure/posts/?feedView=all'
        }

        try {
            const controller = new AbortController
            await setAbortController(controller)
            const res = await api.dashboard.linkedInInsights({ activity_tracker: 'user.dashboard' }, { signal: controller.signal })
            setData(res.data?.imageUrl ? res.data : fallbackData)
        } catch (err) {
            if (!isApiCancelError(err)) {
                setData(fallbackData)
                throw err
            }
        }
    }

    useEffect(() => {
        fetchData()
    }, [])


    useEffect(() => () => {
        abortController?.abort('canceled')
    }, [abortController])

    return (
        <div className="flex flex-col gap-6">
            <h2 className="font-md font-body whitespace-nowrap text-sm">Hear from Solar Insure members</h2>
            <div className="relative">
                <div className="flex justify-end">
                    <Logo type="primary" className="mx-auto w-12 object-contain mb-1"/>
                </div>
                <span className="font-body uppercase font-bold absolute -mt-3 bg-primary-400 text-white text-2xs px-1 py-2">
                    New Article
                </span>
                <a href={data.postUrl} target="_blank" rel="noreferrer">
                    <Image
                        src={data.imageUrl}
                        alt={data.title}
                        className="w-full h-full object-cover object-center rounded 2xl:max-h-[254px]"
                    />
                </a>

                {data.title && <a href={data.postUrl} target="_blank" rel="noreferrer" className="flex justify-between items-center">
                    <h3 className="font-body flex items-center text-2xs font-extrabold leading-3 text-primary-900 h-12 py-1 text-ellipsis overflow-hidden">
                        {data.title}
                    </h3>
                    <div className="bg-primary-400 w-6 h-6 p-0.5 ml-1 flex items-center justify-center">
                        <IconArrowRight className="stroke-white"/>
                    </div>
                </a>}
            </div>
            <Button href="https://www.linkedin.com/company/solarinsure/" target="_blank">
                <span className="whitespace-nowrap font-medium text-xs">
                    See Solar Insure on LinkedIn
                </span>
                <IconLinkedIn className="ml-1 stroke-none" size="md"/>
            </Button>
        </div>
    )
}

export default LinkedinInsights
