import { useEffect, useMemo, useRef, useState } from 'react'

import { Input, InputProps, SelectOptionProps } from '@/components'
import { FormChangeEvent } from '@/types'

export interface SearchableSelectProps extends InputProps {
    options: Array<SelectOptionProps>
    inputValue?: string
}

export const SearchableSelect = ({
    value,
    inputValue,
    options,
    ...props
}: SearchableSelectProps) => {
    const initRef = useRef<boolean>(false)
    const selectedOption = useMemo(() =>
        options.find(item => value && item.value.toString() === value.toString())
    , [options, value])
    const [stateValue, setStateValue] = useState<string>(selectedOption?.title as string || '')

    const handleChange = (e: FormChangeEvent) => {
        setStateValue(e.target.value as string)
        if (!e.target.value && value && props.onChange) {
            props.onChange({
                target: {
                    name: props.name as string,
                    value: ''
                }
            })
        }
    }

    const getSuggestions = () => options
        .filter(item => (item.title as string).toString()
            .toLowerCase()
            .includes(stateValue.toString()
                .toLowerCase()))
        .map(item => item.title as string)

    const handleSuggestionSelect = (index: number) => {
        const title = getSuggestions()[index]
        const option = options.find(item => item.title === title)
        if (props.onChange) {
            props.onChange({
                target: {
                    name: props.name as string,
                    value: option?.value || '',
                    displayValue: option?.title as string || undefined
                }
            })
        }
        setStateValue(title)
    }

    useEffect(() => {
        if (selectedOption) {
            setStateValue(selectedOption.title as string)
        } else if(!value) {
            setStateValue('')
        }
    }, [selectedOption])

    useEffect(() => {
        if (typeof inputValue === 'string' && inputValue !== stateValue) setStateValue(inputValue)
    }, [inputValue])

    useEffect(() => {
        if (selectedOption && props.onChange && !initRef.current) {
            props.onChange({
                target: {
                    name: props.name as string,
                    value: selectedOption.value,
                    displayValue: selectedOption.title as string
                }
            })
            initRef.current = true
        }
    }, [selectedOption])

    return <Input
        value={stateValue}
        suggestions={getSuggestions()}
        autoComplete="off"
        onSuggestionSelect={handleSuggestionSelect}
        {...props}
        onChange={handleChange}
    />
}
