import { api, Options, Params, Payload } from '@/services'
import { IdType, ModelDataType, TableMetaType } from '@/types'

export class Model {
    id: IdType

    created_at?: Date

    updated_at?: Date

    deleted_at?: Date

    raw: ModelDataType

    static endpoint = 'api/v1/models'

    constructor(data: ModelDataType) {
        this.id = data.id
        this.created_at = Model.toDate(data.created_at)
        this.updated_at = Model.toDate(data.updated_at)
        this.deleted_at = Model.toDate(data.deleted_at)

        this.raw = data
    }

    static toDate(value?: string | number) {
        let date
        if (typeof value === 'number') { // 1728969600
            date = new Date(value * 1000)
        } else if (typeof value === 'string') {
            const numericValue = Number(value)
            if (!isNaN(numericValue)) { // '1728969600'
                date = new Date(numericValue * 1000)
            } else if (value.includes('T')) { // 'YYYY-MM-DDTHH:mm:ss.000000Z'
                date = new Date(value)
            } else if (value.includes(' ')) { // 'YYYY-MM-DD HH:mm:ss'
                date = new Date(`${value.replace(' ', 'T')}Z`)
            } else { // 'YYYY-MM-DD'
                date = new Date(`${value}T00:00:00Z`)
            }
        } else {
            return undefined
        }

        return new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        )
    }

    static toNumber(value?: string | number, defaultValue: number | undefined = undefined) {
        if (typeof value === 'number') return value

        const sanitizedValue = typeof value === 'string' ? value.replace(/,/g, '') : value

        return typeof sanitizedValue !== 'undefined' && !isNaN(Number(sanitizedValue)) ? Number(sanitizedValue) : defaultValue
    }

    static async index<T extends typeof Model>(
        this: T,
        params?: Params,
        options?: Options
    ): Promise<{ data: InstanceType<T>[], meta?: TableMetaType }> {
        const res = await api.http.get(this.endpoint, params, options)
        res.data = res.data?.map((item: ModelDataType) => new this(item)) || []
        return res
    }

    static async show<T extends typeof Model>(
        this: T,
        id: IdType,
        params?: Params,
        options?: Options
    ): Promise<InstanceType<T> | null> {
        const res = await api.http.get(`${this.endpoint}/${id}`, params, options)
        if (!id || res.status !== 'Ok') return null
        return new this(res.data) as InstanceType<T>
    }

    static store<T extends typeof Model>(
        this: T,
        payload: Payload,
        params?: Params,
        options?: Options
    ) {
        return api.http.post(this.endpoint, payload, params, options)
    }

    update(payload: Payload, params?: Params, options?: Options) {
        if (payload instanceof FormData) {
            payload.append('_method', 'PATCH')
            return api.http.post(`${(this.constructor as typeof Model).endpoint}/${this.id}`, payload, params, options)
        }
        return api.http.put(`${(this.constructor as typeof Model).endpoint}/${this.id}`, payload, params, options)
    }

    destroy(params?: Params, options?: Options) {
        return api.http.delete(`${(this.constructor as typeof Model).endpoint}/${this.id}`, params, options)
    }
}
