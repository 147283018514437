import { Icon, IconProps } from './Icon'

export const IconChevronUp = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="m18 15-6-6-6 6"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
