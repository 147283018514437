import { useEffect, useMemo, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router'

import { WarrantyCreatePopupStep1, WarrantyCreatePopupStep1FormType } from './WarrantyCreatePopupStep1'
import { WarrantyCreatePopupStep2, WarrantyCreatePopupStep2FormType } from './WarrantyCreatePopupStep2'
import { Popup } from '@/components'
import { useAuth } from '@/hooks'
import { Company, WarrantyProductType, WarrantyRegistrationType } from '@/models'
import { IdType, FormChangeEvent } from '@/types'

type WarrantyCreatePopupProps = {
    reset?: boolean
    company?: Company
    warrantyProductType?: WarrantyProductType

    isOpen: boolean
    onClose: () => void
}

interface WarrantyCreatePopupFormDataType extends WarrantyCreatePopupStep1FormType, WarrantyCreatePopupStep2FormType {}

export const WarrantyCreatePopup = ({
    isOpen,
    reset = true,
    ...props
}: WarrantyCreatePopupProps) => {
    const auth = useAuth()
    const navigate = useNavigate()
    const [step, setStep] = useState(1)
    const [companies, setCompanies] = useState<Company[]>(props.company ? [props.company] : [])
    const [form, setForm] = useState<WarrantyCreatePopupFormDataType>({
        company_id: auth.user?.role.isAdminOrStaff ? props.company?.id || '' : auth.user?.company.id as IdType,
        warranty_product_type: props.warrantyProductType?.key || '',
        warranty_registration_type: ''
    })


    const company = useMemo(() => auth.user?.role.isAdminOrStaff
        ? companies.find(item => item.id === form.company_id) : auth.user?.company
    , [form.company_id, companies])
    const productType = useMemo(() => form.warranty_product_type
        ? WarrantyProductType.find(form.warranty_product_type) : undefined
    , [form.warranty_product_type])
    const registrationType = useMemo(() => form.warranty_registration_type
        ? WarrantyRegistrationType.find(form.warranty_registration_type) : undefined
    , [form.warranty_registration_type])

    const fetchCompanies = async () => {
        const data = await Company.autocomplete({ type: 'contractor' })
        setCompanies(data.filter((item: Company) => item.type.isContractor))
    }
    useEffect(() => {
        if (auth.user?.role.isAdminOrStaff) {
            fetchCompanies()
        }
    }, [])

    const handleChange = (e: FormChangeEvent) => {
        setForm(form => ({ ...form, [e.target.name]: e.target.value }))
    }

    const handleNext = () => {
        if (productType && productType.registrationTypes.length > 1 && !registrationType) {
            setStep(2)
        } else {
            navigate({
                pathname: (registrationType || WarrantyRegistrationType.defaultType).path,
                search: `?${createSearchParams({
                    warranty_product_type: form.warranty_product_type,
                    ...auth.user?.role.isAdminOrStaff ? { company_id: form.company_id as string } : {}
                })}`
            }, { state: { reset } })
            props.onClose()
        }
    }

    const handlePrevious = () => {
        setForm(form => ({ ...form, warranty_registration_type: '' }))
        setStep(1)
    }

    return <Popup
        open={isOpen}
        onClose={props.onClose}
        className="w-224"
    >
        {step === 1 && <WarrantyCreatePopupStep1
            company={company}
            companies={companies}
            productType={productType}
            form={form}
            onChange={handleChange}
            onNext={handleNext}
        />}

        {step === 2 && <WarrantyCreatePopupStep2
            form={form}
            onChange={handleChange}
            productType={productType as WarrantyProductType}
            company={company as Company}
            registrationType={registrationType}
            onNext={handleNext}
            onPrevious={handlePrevious}
        />}
    </Popup>
}
