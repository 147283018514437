import { useMemo, useState } from 'react'

import {
    Button, ButtonSize,
    WarrantiesCancelOrReinstatePopup,
    WarrantyReviewCancelOrReinstateRequestButton
} from '@/components'
import { useAuth } from '@/hooks'
import { CompanyAccountActivityStatus, Warranty, WarrantyCancelOrReinstateRequest, WarrantyStatus } from '@/models'
import { JobsBatchType, WarrantyIndexDataType, WarrantyIndexFiltersType } from '@/types'

type WarrantiesCancelOrReinstateButtonProps = {
    selected: Warranty[] | 'all'
    excluded?: Warranty[]
    data?: WarrantyIndexDataType
    quantity?: number
    size?: ButtonSize
    filters?: WarrantyIndexFiltersType

    onChange: () => void
    onPingJob?: (jobData: JobsBatchType) => void
}

export const WarrantiesCancelOrReinstateButton = ({
    quantity = 1,
    data,
    filters,
    size,
    selected,
    excluded = [],
    ...props
}: WarrantiesCancelOrReinstateButtonProps) => {
    const auth = useAuth()

    const [isOpen, setIsOpen] = useState(false)
    const isBulkAction = quantity > 1
    const isSingleAction = quantity === 1
    const isAllSelected = selected === 'all'

    const selectedWarranty = useMemo<Warranty>(() => selected === 'all' && data
        ? data.rows.find(item => excluded.every(excludedRow => excludedRow.id !== item.id)) as Warranty
        : selected[0] as Warranty
    , [selected, excluded])

    const isAllInactive = useMemo((): boolean => {
        if (isBulkAction && isAllSelected && filters) {
            return !!filters.statuses.value.length &&
                filters.statuses.value.every(status => WarrantyStatus.find(status).isInactive)
        } else if (isBulkAction && !isAllSelected) {
            return selected.every(item => item.status.isInactive)
        } else if (isSingleAction) {
            return selectedWarranty.status.isInactive
        }
        return false
    }, [selected])

    const isAllNotInactive = useMemo((): boolean => {
        if (isBulkAction && isAllSelected && filters) {
            return filters.statuses.value.every(status => !WarrantyStatus.find(status).isInactive)
        } else if (isBulkAction && !isAllSelected) {
            return selected.every(item => !item.status.isInactive)
        } else if (isSingleAction) {
            return !selectedWarranty?.status.isInactive
        }
        return true
    }, [selected])

    const action = isAllInactive ? 'reinstate' : 'cancel'
    const { ButtonIcon } = WarrantyCancelOrReinstateRequest.wordings[action]

    const isFiltersApplied = !filters || Object.keys(filters)
        .some(key => filters[key].value?.toString() !== filters[key].defaultValue?.toString())

    const isActionRestricted = useMemo(() => !quantity ||
        // Only admins can bulk cancel/reinstate
        (isBulkAction && !auth.user?.role.isAdminOrStaff) ||
        // All selected items should be  canceled or reinstated
        (isBulkAction && (!isAllNotInactive && !isAllInactive)) ||
        (isSingleAction && (
            selectedWarranty.status.isCancellationRequested ||
            selectedWarranty.status.isReinstatementRequested)
        ) ||
        auth.user?.company?.account_activity_status.isDisabled ||
        // One of filters need to be applied to allow cancel/reinstate all table records
        (isAllSelected && isBulkAction && !isFiltersApplied)
    , [selected, filters])

    const getCancelButtonTooltip = () => {
        if (auth.user?.company?.account_activity_status.isDisabled) {
            return CompanyAccountActivityStatus.DISABLED_STATUS_MESSAGE
        }

        if (!quantity) {
            return 'Select at least one warranty to enable cancelation.'
        }

        if (isBulkAction && !isFiltersApplied) {
            return 'Please apply filters to select a specific set of warranties for bulk action.'
        }

        if (isBulkAction) {
            if (!auth.user?.role.isAdminOrStaff) {
                return 'Only Admin or Staff can cancel multiple warranties.'
            }
            if (!isAllNotInactive && !isAllInactive) {
                return "Only warranties that are not canceled can be canceled. Deselect any 'Canceled' or" +
                    " 'Reinstatement Requested' warranties to enable this action."
            }
        }

        if (isSingleAction &&
            (selectedWarranty.status.isCancellationRequested || selectedWarranty.status.isReinstatementRequested)) {
            return 'Cancellation/reinstatement already requested for this warranty.'
        }

        return `${action.capitalize()} ${quantity > 1 ? 'Warranties' : 'Warranty'}`
    }

    if (isSingleAction && auth.user?.role.isAdminOrStaff &&
        (selectedWarranty.status.isCancellationRequested ||
        selectedWarranty.status.isReinstatementRequested)) {
        return <WarrantyReviewCancelOrReinstateRequestButton
            warranty={selectedWarranty}
            onChange={props.onChange}
            size={size}
        />
    }

    return <>
        <Button
            square
            hasError
            size={size}
            design="btn-secondary-gray"
            tooltip={getCancelButtonTooltip()}
            tooltipDisabled={false}
            onClick={() => setIsOpen(true)}
            disabled={isActionRestricted}
        >
            <ButtonIcon/>
        </Button>

        {!isActionRestricted && <WarrantiesCancelOrReinstatePopup
            isOpen={isOpen}
            filters={filters}
            selected={selected}
            excluded={excluded}
            quantity={quantity}
            action={action}
            onClose={() => setIsOpen(false)}
            onChange={props.onChange}
            onPingJob={props.onPingJob}
            selectedWarranty={selectedWarranty}
            isAllInactive={isAllInactive}
        />}
    </>
}
