import { useLocation } from 'react-router'

import { Tabs } from '@/components'
import { feature } from '@/services'

const MonitoringHeader = () => {
    if (!feature('monitoring-mapview')) return null
    const location = useLocation()
    return <>
        <div className="flex flex-col lg:flex-row justify-between mb-2">
            {<Tabs
                className="mb-4 font-medium text-sm leading-[23px] text-primary-700 font-body"
                tabs={[
                    { to: '/monitoring/', id: 'monitoring', title: 'Fleet Details Table' },
                    { to: '/monitoring-mapview', id: 'monitoring-mapview', title: 'Map View' }
                ]}
                value={location.pathname.includes('monitoring-mapview') ? 'monitoring-mapview' : 'monitoring'}
                data-test="monitoring-index-navigation"
            />}
        </div>
    </>
}

export default MonitoringHeader
