import { useEffect, useMemo } from 'react'

import { Select, SelectProps } from '@/components'
import { WarrantyStatusEnum } from '@/enums'
import { WarrantyStatus } from '@/models'
import { FormChangeEvent, FormChangeEventHandler } from '@/types'

interface WarrantyTypeDropdownProps extends Omit<SelectProps, 'options'> {
    availableStatuses: WarrantyStatusEnum[]
    onChange: FormChangeEventHandler
    value: string[]
    name: string
    id: string
}

export const WarrantyStatusFilter = ({
    availableStatuses,
    ...props
}: WarrantyTypeDropdownProps) => {
    const handleChange = (e: FormChangeEvent) => {
        const value = (e.target.value as string[])
            .filter(item => availableStatuses.includes(item as WarrantyStatusEnum))
        props.onChange({
            target: {
                name: props.name,
                value,
                displayValue: value.map(item => WarrantyStatus.find(item as WarrantyStatusEnum).title)
            }
        })
    }

    const options = useMemo(() => [
        {
            value: '',
            title: 'Any Status'
        },
        ...WarrantyStatus.all
            .filter(item => !item.isUnknown && availableStatuses.includes(item.key))
            .map(item => ({
                value: item.key,
                title: <div className="flex items-center gap-1 text-sm">
                    {item.icon}
                    {item.title}
                </div>
            }))]
    , [props.value, availableStatuses])

    useEffect(() => {
        if (props.value.length) {
            props.onChange({
                target: {
                    name: props.name,
                    value: props.value,
                    displayValue: props.value.map(item => WarrantyStatus.find(item as WarrantyStatusEnum).title)
                }
            })
        }
    }, [])

    return <Select
        {...props}
        multiple
        onChange={handleChange}
        options={options}
    />
}
