import { TeslaPowerwall3RestrictionAlert } from '@/components/alerts/TeslaPowerwall3RestrictionAlert'
import { WarrantyProductTypeEnum } from '@/enums/WarrantyProductTypeEnum'
import { InverterModelDataType } from '@/types'

export enum InverterModelEnum {
    TESLA_POWERWALL_3 = 'Tesla Powerwall 3',
    TESLA_SOLAR_INVERTER_7_6_KW = 'Tesla Solar Inverter - 7.6 kW',
    TESLA_SOLAR_INVERTER_5_7_KW = 'Tesla Solar Inverter - 5.7 kW',
    TESLA_SOLAR_INVERTER_5_KW = 'Tesla Solar Inverter - 5 kW',
    TESLA_SOLAR_INVERTER_3_8_KW = 'Tesla Solar Inverter - 3.8 kW',
}

export const TESLA_INVERTER_MODELS: InverterModelDataType[] = [
    {
        key: InverterModelEnum.TESLA_POWERWALL_3,
        name: 'Tesla Powerwall 3',
        warrantyProductTypes: [
            WarrantyProductTypeEnum.PRODUCTION_WITH_30_YEAR_SEPARATE_STORAGE,
            WarrantyProductTypeEnum.PRODUCTION_WITH_20_YEAR_SEPARATE_STORAGE,
            WarrantyProductTypeEnum.SEPARATE_30_YEAR_STORAGE_ONLY
        ],
        Alert: TeslaPowerwall3RestrictionAlert
    },
    {
        key: InverterModelEnum.TESLA_SOLAR_INVERTER_7_6_KW,
        name: 'Tesla Solar Inverter - 7.6 kW'
    },
    {
        key: InverterModelEnum.TESLA_SOLAR_INVERTER_5_7_KW,
        name: 'Tesla Solar Inverter - 5.7 kW'
    },
    {
        key: InverterModelEnum.TESLA_SOLAR_INVERTER_5_KW,
        name: 'Tesla Solar Inverter - 5 kW'
    },
    {
        key: InverterModelEnum.TESLA_SOLAR_INVERTER_3_8_KW,
        name: 'Tesla Solar Inverter - 3.8 kW'
    }
]
