import { Model } from './Model.ts'
import { IconFlipBackward, IconX, IconXCircle } from '@/components'
import { WarrantyCancelOrReinstateRequestDataType } from '@/types'

export class WarrantyCancelOrReinstateRequest extends Model {
    id: string

    cancellation_reason: string

    declare created_at: Date

    constructor(data: WarrantyCancelOrReinstateRequestDataType) {
        super(data)
        this.id = data.id
        this.cancellation_reason = data.cancellation_reason
    }

    static wordings = {
        cancel: {
            action: 'cancel',
            noun: 'cancellation',
            verb: 'cancelling',
            Icon: IconXCircle,
            ButtonIcon: IconX
        },
        reinstate: {
            action: 'reinstate',
            noun: 'reinstatement',
            verb: 'reinstating',
            Icon: IconFlipBackward,
            ButtonIcon: IconFlipBackward
        }
    }
}
