import { useEffect, useState, useMemo } from 'react'

import {
    Button,
    IconFileCheck02,
    IconFileClock,
    IconFileMultiple,
    Popup,
    Tabs, WarrantyDetailsPreview
} from '@/components'
import { Warranty } from '@/models'
import { IdType } from '@/types'

type HandleDuplicatePopupProps = {
    isOpen: boolean
    warranty: Warranty
    cancelButtonText?: string

    onClose: () => void
    onCancel: () => void
    onConfirm: () => void
}

export const HandleDuplicatesPopup = ({
    warranty,
    isOpen,
    cancelButtonText = 'Cancel Duplicate Registration',
    ...props
}: HandleDuplicatePopupProps) => {
    const [duplicates, setDuplicates] = useState<Warranty[]>([])
    const [tab, setTab] = useState<IdType>('current')

    const fetchDuplicates = async () => {
        const res = await Warranty.getAddressDuplicates({
            company_id: warranty.homeowner.company_id,
            street_address: warranty.homeowner.street_address,
            city: warranty.homeowner.city,
            state: warranty.homeowner.state,
            zip: warranty.homeowner.zip
        })
        setDuplicates(res.data)
    }

    useEffect(() => {
        fetchDuplicates()
    }, [])

    const active = useMemo(() => tab === 'current'
        ? warranty : duplicates.find(item => item.id === tab) as Warranty, [duplicates, tab])

    return <Popup open={isOpen} onClose={props.onClose} className="w-180">
        <h4 className="text-xl text-gray-900 flex gap-3 items-center mb-6">
            <IconFileMultiple className="stroke-primary-700" size="xl"/>
            Duplicate Address
        </h4>
        <p className="text-gray-700 mb-6">
            We detected a duplicate address in our database. This address already exists for another warranty entry.
            Please review your options to resolve this issue:
        </p>
        {warranty.id
            ? <>
                <p className="text-gray-700 mb-6 text-sm">
                    <span className="font-bold italic">Note:</span> This warranty shares an installation address with
                    another existing warranty in our system. In some cases, this is expected—such as when a property has
                    multiple solar systems, each with its own warranty. For example, a home may have one system for the
                    main house and another for a separate guest house or detached unit. It can also happen when a
                    homeowner installs a solar system first and later adds a battery, each covered under different
                    warranties.
                </p>
                <p className="text-gray-700 mb-6 text-sm">
                    If this warranty is valid and should remain separate,
                    select <span className="font-bold italic">Confirm Unique Warranty</span> to clear the duplicate flag.
                    If this warranty was created by mistake and should not remain active,
                    select <span className="font-bold italic">Cancel Current Warranty</span> to remove it and avoid being
                    billed twice for the same coverage.
                </p>
            </>
            : <p className="text-gray-700 mb-6 text-sm">
                <span className="font-bold italic">Note:</span> If this is a duplicate address, the warranty may already
                be registered, and proceeding could result in double billing. To address this, you can use the
                appropriate action: <span className="font-bold italic">Remove Duplicate from Upload</span> (for bulk
                uploads) or <span className="font-bold italic">Cancel Duplicate Registration</span> (for single warranty
                registration). If the warranty is legitimate and not a duplicate,
                select <span className="font-bold italic">Confirm Unique Warranty</span> to clear the error. Use this
                option if the repeated address is intentional, such as multiple warranties at the same address for
                different structures.
            </p>}

        <div>
            <Tabs
                value={tab}
                onChange={setTab}
                className="mb-6"
                size="sm"
                tabs={[
                    {
                        id: 'current',
                        title: <div className="flex items-center gap-2">
                            <IconFileClock/>
                            Current Warranty Enrollment
                        </div>
                    },
                    ...duplicates.map(item => ({
                        id: item.id,
                        title: <div className="flex items-center gap-2">
                            <IconFileCheck02/>
                            Existing Warranty {item.policy_num}
                        </div>
                    }))
                ]}
            />

            <WarrantyDetailsPreview
                className="mb-6"
                warranty={active}
                minCount={6}
            />
        </div>

        <div className="flex flex-wrap justify-end gap-4">
            <Button
                type="button"
                design="btn-secondary"
                hasError
                onClick={props.onConfirm}
            >
                Confirm Unique Warranty
            </Button>

            <Button
                type="button"
                onClick={props.onCancel}
            >
                {cancelButtonText}
            </Button>
        </div>
    </Popup>
}
