import { Icon, IconProps } from './Icon'

export const IconUploadCLoud02 = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="m8 16 4-4m0 0 4 4m-4-4v9m8-4.257A5.5 5.5 0 0 0 16.5 7a.62.62 0 0 1-.534-.302 7.5 7.5 0 1 0-11.78 9.096"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
