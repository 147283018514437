import classnames from 'classnames'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router'

import TablePlaceholder from './TablePlaceholder'
import Tbody from './Tbody'
import TFooter from './TFooter'
import Thead from './Thead'
import { useNavigateWithQuery, useQuery } from '@/hooks'
import {
    TableParamsType,
    TableColumnType,
    TableRowType,
    TableMetaType,
    FormChangeEvent,
    TableOptionsType,
    DEFAULT_TABLE_OPTIONS,
    DEFAULT_TABLE_PARAMS
} from '@/types'

type TableProps = {
    id?: string
    options: TableOptionsType
    columns: TableColumnType[]
    rows: TableRowType[]
    className?: string
    'data-test'?: string
    heading?: ReactNode
    processing?: boolean | 'prev' | 'next'
    meta?: TableMetaType
    selectedRows?: TableRowType[] | 'all'
    excludedRows?: TableRowType[]
    placeholder?: ReactNode
    minHeight?: number

    onChange?: (params: TableParamsType, isScrolled?: boolean, isPrevious?: boolean) => Promise<void>
    onRowClick?: (row: TableRowType) => void
    onRowsChange?: (rowsLength: number) => void,
    onSelectedRowsChange?: (selectedRows: TableRowType[] | 'all') => void,
    onExcludedRowsChange?: (excludedRows: TableRowType[]) => void,
}

const Table = ({
    id,
    columns,
    rows,
    meta,
    processing = false,
    minHeight = 400,
    placeholder,
    className = '',
    heading = null,
    selectedRows = [],
    excludedRows = [],
    'data-test': dataTest = 'table',
    ...props
}: TableProps) => {
    const tableContainerRef = useRef<HTMLDivElement>(null)
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const tableCustomHeadingRef = useRef<HTMLDivElement>(null)
    const tableRef = useRef<HTMLTableElement>(null)
    const localProcessingRef = useRef(false)
    const timeoutRef = useRef<number | undefined>(undefined)

    const navigateWithQuery = useNavigateWithQuery()
    const query = useQuery()
    const location = useLocation()

    const options = { ...DEFAULT_TABLE_OPTIONS, ...props.options }

    const currentParams = useMemo((): TableParamsType => ({
        page: options.useQueryParams && parseInt(query.page) ? parseInt(query.page) : 1,
        per_page: options.useQueryParams && parseInt(query.per_page)
            ? parseInt(query.per_page) : options.defaultPerPage,
        sort_column: options.useQueryParams && query.sort_column ? query.sort_column : '',
        descending: options.useQueryParams && query.descending ? !!query.descending : false
    }), [query, options.useQueryParams])

    const [activeColumns, setActiveColumns] = useState<string[]>([])
    const [lastScroll, setLastScroll] = useState({ x: 0, y: 0 })
    const [height, setHeight] = useState<string>('auto')
    const [params, setParams] = useState(currentParams)

    const isServerSide = !!meta?.current_page

    const handleSelectChange = ({ target }: FormChangeEvent) => {
        if (!props.onSelectedRowsChange || !options.selectable) return
        if (target.value === 'all') {
            if (isServerSide) {
                if (props.onExcludedRowsChange) {
                    props.onExcludedRowsChange([])
                    if (target.checked) {
                        props.onSelectedRowsChange('all')
                    } else {
                        props.onSelectedRowsChange([])
                    }
                }
            } else {
                if (target.checked) {
                    props.onSelectedRowsChange(rows)
                } else {
                    props.onSelectedRowsChange([])
                }
            }
        } else {
            if (selectedRows === 'all') {
                if (props.onExcludedRowsChange) {
                    const isExcluded = excludedRows.find(({ id }) => id.toString() === target.value)
                    if (isExcluded) {
                        props.onExcludedRowsChange(excludedRows.filter(item => item.id.toString() !== target.value))
                    } else {
                        const row = rows.find(item => item.id.toString() === target.value) as TableRowType
                        props.onExcludedRowsChange([...excludedRows, row])
                        if (excludedRows.length === meta?.total as number - 1) {
                            props.onSelectedRowsChange([])
                        }
                    }
                }
            } else {
                const isSelected = selectedRows.some(item => item.id.toString() === target.value)
                if (isSelected) {
                    props.onSelectedRowsChange(selectedRows.filter(item => item.id.toString() !== target.value))
                } else {
                    const row = rows.find(item => item.id.toString() === target.value) as TableRowType
                    props.onSelectedRowsChange([...selectedRows, row])
                }
            }
        }
    }

    const filterDefaults = (data: TableParamsType) =>
        Object.keys(data).reduce((acc, key): TableParamsType => {
            const value = data[key as keyof TableParamsType]
            const defaultValue = DEFAULT_TABLE_PARAMS[key as keyof TableParamsType]
            return {
                ...acc,
                [key]: defaultValue === value ? '' : value
            }
        }, {} as TableParamsType)

    const handleChange = (updated: TableParamsType) => {
        timeoutRef.current = setTimeout(() => {
            updated = filterDefaults(updated)
            if (props.onChange) props.onChange(updated)
            if (options.useQueryParams) navigateWithQuery(updated)
        }, 500) as unknown as number
    }

    const handleParamsChange = (key: string, value: string | number | boolean) => {
        setParams(filters => {
            const updated = { ...filters, page: 1, [key]: value }
            if (key !== 'page') updated.page = 1
            clearTimeout(timeoutRef.current)
            handleChange(updated)
            return updated
        })
    }

    const updateUrlPage = (scrollLength: number) => {
        if (!scrollLength || processing) return
        const isScrollToTop = scrollLength > 0
        const scrollContainer = scrollContainerRef.current
        if (!scrollContainer) return
        const containerRect = scrollContainer.getBoundingClientRect()
        const rows: HTMLTableRowElement[] = Array.from(scrollContainer.querySelectorAll('tbody tr'))
        for (const item of isScrollToTop ? rows : rows.reverse()) {
            const rect = item.getBoundingClientRect()
            if ((isScrollToTop && rect.y > containerRect.y) ||
                (!isScrollToTop && rect.y < containerRect.y + containerRect.height)) {
                const itemPage = item.dataset.page
                const curPage = query.page || '1'
                if (itemPage !== curPage) {
                    const updated = { ...params, page: parseInt(itemPage as string) }
                    if (options.useQueryParams) navigateWithQuery(filterDefaults(updated))
                    setParams(updated)
                }
                break
            }
        }
    }

    const preserveScroll = () => {
        if (!scrollContainerRef.current || !tableRef.current) return
        const oldScrollTop = scrollContainerRef.current.scrollTop
        const { height: oldHeight } = tableRef.current.getBoundingClientRect()
        const obs = new ResizeObserver(([entry], observer) => {
            if (!scrollContainerRef.current) return
            const { height: newHeight } = entry.contentRect
            if (newHeight !== oldHeight) {
                scrollContainerRef.current.scrollTo({ top: oldScrollTop + newHeight - oldHeight })
                observer.disconnect()
            }
        })
        obs.observe(tableRef.current)
    }

    const loadPrev = async () => {
        const curPage = rows[0]?._page as number || 1

        if (curPage > 1 && props.onChange && !localProcessingRef.current) {
            localProcessingRef.current = true
            const updated = { ...params, page: curPage - 1 }
            setParams(updated)
            preserveScroll()
            try {
                await props.onChange(filterDefaults(updated), true, true)
            } finally {
                localProcessingRef.current = false
            }
        }
    }
    const loadNext = async (page = 0) => {
        const curPage = page || rows[rows.length - 1]?._page as number || 1
        if (tableContainerRef.current && tableRef.current) {
            if (curPage < (meta?.last_page as number) && props.onChange && !localProcessingRef.current) {
                localProcessingRef.current = true
                const updated = { ...params, page: curPage + 1 }
                setParams(updated)
                try {
                    await props.onChange(filterDefaults(updated), true, false)
                } finally {
                    localProcessingRef.current = false
                }

                if (!tableContainerRef.current) return
                const tableContainerHeight = tableContainerRef.current.getBoundingClientRect().height
                const tableHeight = tableRef.current.getBoundingClientRect().height
                if (tableHeight < tableContainerHeight) {
                    await loadNext(curPage + 1)
                }
            }
        }
    }

    const handleScroll = async () => {
        if (!options.infinity) return
        const scrollContainer = scrollContainerRef.current
        if (!scrollContainer) return
        setLastScroll({ x: scrollContainer.scrollLeft, y: scrollContainer.scrollTop })
        if (!processing && (lastScroll.y !== scrollContainer.scrollTop)) {
            const pixelsUntilBottom = (scrollContainer.scrollHeight - scrollContainer.offsetHeight) -
                scrollContainer.scrollTop
            const pixelsUntilTop = scrollContainer.scrollTop
            if (lastScroll.y > scrollContainer.scrollTop && pixelsUntilTop < 300) {
                await loadPrev()
            } else if (pixelsUntilBottom < 300) {
                await loadNext()
            }
            if (Math.abs(lastScroll.y - scrollContainer.scrollTop) < 700) {
                updateUrlPage(lastScroll.y - scrollContainer.scrollTop)
            }
        }
    }

    const fetchInitialPages = async () => {
        await loadNext()
        await loadPrev()
    }

    useEffect(() => {
        if (meta?.total && options.infinity && scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo(0, 0)
            fetchInitialPages()
        }
    }, [
        meta?.per_page,
        meta?.sort_column,
        meta?.descending
    ])

    useEffect(() => {
        if (!options.useQueryParams) return
        const newValues: TableParamsType = currentParams
        const oldValues: TableParamsType = params
        if (Object.keys(oldValues).some(key => newValues[key as keyof TableParamsType]?.toString() !==
            oldValues[key as keyof TableParamsType]?.toString() && key !== 'page')) {
            setParams(newValues)
            if (props.onChange) {
                props.onChange(filterDefaults(newValues))
            }
        }
    }, [location])

    const sortedRows = useMemo(() => {
        if (isServerSide) return rows
        let res = [...rows]
        if (params.sort_column && options.sortable) {
            res = res.sort((a, b) => {
                const key = typeof a[`${params.sort_column}_raw`] !== 'undefined'
                    ? `${params.sort_column}_raw`
                    : params.sort_column as string
                const aItem = typeof a[key] === 'string' ? (a[key] as string).toLowerCase() : a[key] as number
                const bItem = typeof b[key] === 'string' ? (b[key] as string).toLowerCase() : b[key] as number
                if (typeof a._group !== 'undefined' &&
                    typeof b._group !== 'undefined' &&
                    a._group > b._group
                ) return 1
                if (aItem < bItem) {
                    return params.descending ? 1 : -1
                }
                if (aItem > bItem) {
                    return params.descending ? -1 : 1
                }
                return 0
            })
        }
        return res
    }, [params, rows])

    const rowsForPage = useMemo(() => {
        if (isServerSide) return sortedRows
        return options.pagination
            ? [...sortedRows].splice(
                (params.per_page as number * (params.page as number)) - (params.per_page as number),
                params.per_page)
            : sortedRows
    }, [options.pagination, sortedRows])

    const filteredColumns = useMemo(() => options.maxColumnsAmount && activeColumns.length
        ? columns.filter(({ field }) =>
            columns.length <= (options.maxColumnsAmount as number) || activeColumns.includes(field))
        : columns
    , [columns, options.maxColumnsAmount, activeColumns])

    useEffect(() => {
        if (props.onRowsChange) props.onRowsChange(rowsForPage.length)
    })

    const updateHeight = () => {
        if (tableContainerRef.current && tableRef.current && options.infinity) {
            const { top } = tableContainerRef.current.getBoundingClientRect()
            const { height: tableHeight } = tableRef.current.getBoundingClientRect()
            const height = window.innerHeight - (top + 32)
            setHeight((height > tableHeight) || !rows.length ? 'auto' : `${height < minHeight ? minHeight : height}px`)
        } else {
            setHeight('')
        }
    }

    useEffect(() => {
        if (options.infinity) {
            updateHeight()
            window.addEventListener('resize', updateHeight)
        }

        if (options.selectable && selectedRows && props.onSelectedRowsChange && props.onExcludedRowsChange) {
            if (selectedRows === 'all') {
                props.onExcludedRowsChange(excludedRows.filter(excludedRow =>
                    rows.some(row => row.id === excludedRow.id)))
            } else {
                props.onSelectedRowsChange(selectedRows.filter(selectedRow =>
                    rows.some(row => row.id === selectedRow.id)))
            }
        }

        return () => {
            window.removeEventListener('resize', updateHeight)
        }
    }, [rows])

    const getScrollContainerHeight = () => options.infinity
        ? `calc(100% - ${tableCustomHeadingRef.current?.getBoundingClientRect().height || 0}px)`
        : 'auto'

    return <div
        ref={tableContainerRef}
        className={classnames('datatable', className, { infinity: options.infinity })}
        style={{ height }}
        data-test={dataTest}
    >
        <div ref={tableCustomHeadingRef}>
            {heading}
        </div>
        <div
            ref={scrollContainerRef}
            style={{ height: getScrollContainerHeight() }}
            className="overflow-auto snap-x snap-mandatory md:snap-none" onScroll={handleScroll}
        >
            <div className={`loader loader-top ${processing === 'prev' ? 'show' : ''}`}>
                Loading...
            </div>
            <table ref={tableRef}>
                <Thead
                    id={id as string}
                    columns={filteredColumns}
                    allColumns={columns}
                    rows={rows}
                    meta={meta}
                    selectedRows={selectedRows}
                    excludedRows={excludedRows}
                    activeColumns={activeColumns}
                    onActiveColumnsChange={setActiveColumns}
                    params={params}
                    onSelectChange={handleSelectChange}
                    onParamsChange={handleParamsChange}
                    data-test={dataTest}
                    options={options}
                />
                <Tbody
                    columns={filteredColumns}
                    allColumns={columns}
                    rows={rowsForPage}
                    selectedRows={selectedRows}
                    excludedRows={excludedRows}
                    onSelectChange={handleSelectChange}
                    onRowClick={props.onRowClick}
                    data-test={dataTest}
                    options={options}
                />
            </table>
            <div className={`loader loader-bottom ${processing === 'next' ? 'show' : ''}`}>
                Loading...
            </div>
            {!rows.length && <TablePlaceholder processing={processing} content={placeholder}/>}
        </div>
        <TFooter
            total={isServerSide ? meta?.total : sortedRows.length}
            onChange={handleParamsChange}
            data-test={dataTest}
            params={params}
            options={options}
        />
    </div>
}

export default Table
