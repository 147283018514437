import { FormErrorsType, Input } from '@/components'
import { FormChangeEventHandler, JsonDataType } from '@/types'

export interface IntegrationMaintainerFormDataType extends JsonDataType {
    contact_name: string
    contact_email: string
}

type IntegrationMaintainerProps = {
    form: IntegrationMaintainerFormDataType
    errors: FormErrorsType
    onChange: FormChangeEventHandler
    disabled?: boolean
    inputClassName?: string
}

export const IntegrationMaintainerForm = ({
    form,
    inputClassName,
    disabled,
    errors,
    ...props
}: IntegrationMaintainerProps) =>
    <>
        <Input
            id="integration-portal-portal-namw"
            name="contact_name"
            label="Name"
            placeholder="Name"
            onChange={props.onChange}
            value={form.contact_name}
            errors={errors.contact_name}
            className={inputClassName}
            disabled={disabled}
        />

        <Input
            id="integration-portal-contact-email"
            name="contact_email"
            label="Contact Email"
            placeholder="Contact Email"
            onChange={props.onChange}
            value={form.contact_email}
            errors={errors.contact_email}
            className={inputClassName}
            disabled={disabled}
        />
    </>
