import classnames from 'classnames'
import { ReactNode } from 'react'

import { ClickToCopy } from '@/components'
import { Warranty } from '@/models'
import { FieldRawValueType } from '@/types'

export type WarrantyDetailsListField = {
    key: string
    icon?: ReactNode
    title?: ReactNode
    body?: ReactNode
    value: FieldRawValueType
    compareAtValue?: FieldRawValueType
    showIf?: boolean
    className?: string
    copyable?: boolean
}

type WarrantyDetailsListProps = {
    fields: WarrantyDetailsListField[]
    compare?: boolean
}

export const WarrantyDetailsPreviewList = ({
    fields,
    compare
}: WarrantyDetailsListProps) =>
    <>
        {fields.map(item => {
            if (item.showIf === false) return null

            const field = Warranty.getField(item.key)
            const value = field.format(item.value)
            const compareAtValue = field.format(item.compareAtValue)
            const isHighlighted = compare &&
                ((compareAtValue && compareAtValue.length) || (value && value.length)) &&
                !field.compare(item.value, item.compareAtValue)

            return <div
                key={item.key}
                className={classnames('card-row flex flex-col', item.className, { 'bg-orange-100 p-1.5 rounded-lg': isHighlighted })}
                data-test={`warranty-details-${field.key}`}
            >
                <h3 className="card-row-title gap-1">{item.title || field.title}</h3>
                {(typeof value === 'string' ? [value] : value).map((itemValue, index) =>
                    <div key={index} className="card-row-copy inline-flex gap-2 items-center break-anywhere">
                        {!!itemValue && <>
                            {item.icon && <span>{item.icon}</span>} {itemValue} {item.copyable &&
                                <span><ClickToCopy content={itemValue}/></span>}
                        </>}
                    </div>)}
            </div>
        })}
    </>
