import { WARRANTY_MATCH_METHODS, WarrantyMatchMethodEnum } from '@/enums'
import { WarrantyMatchMethodDataType } from '@/types'

export class WarrantyMatchMethod {
    key: WarrantyMatchMethodEnum

    title: string

    constructor(data: WarrantyMatchMethodDataType) {
        this.key = data.key
        this.title = data.title
    }

    static get all() {
        return WARRANTY_MATCH_METHODS.map(item => new WarrantyMatchMethod(item))
    }

    static find(key: WarrantyMatchMethodEnum) {
        return WarrantyMatchMethod.all.find(item => item.key === key) || WarrantyMatchMethod.defaultValue
    }

    static get defaultValue() {
        return WarrantyMatchMethod.all
            .find(item => item.key === WarrantyMatchMethodEnum.EXTERNAL_UPLOAD_MATCH) as WarrantyMatchMethod
    }

    get isExternalUploadMatch() {
        return this.key === WarrantyMatchMethodEnum.EXTERNAL_UPLOAD_MATCH
    }

    get isFullSubcontractorMatch() {
        return this.key === WarrantyMatchMethodEnum.FULL_SUBCONTRACTOR_MATCH
    }
}
