import { useEffect, useMemo, useState } from 'react'

import { Button, Popup } from '@/components'
import { WarrantyCreateFormType } from '@/containers/warranties/Create'
import { ProductRateTypeEnum } from '@/enums'
import { Company, Product, WarrantyProductType } from '@/models'
import { CompanyProductRatesDataType } from '@/types'

type WarrantyPricingOverviewProps = {
    form: WarrantyCreateFormType
    company: Company
    warrantyProductType: WarrantyProductType

    onClose: () => void
    onNext: () => void
}

type RowDataType = {
    isPowerProduction?: boolean
    title?: string
    price: number
    qty: number
}

export const WarrantyPricingOverview = ({
    form,
    company,
    warrantyProductType,
    ...props
}: WarrantyPricingOverviewProps) => {
    const [batteryProducts, setBatteryProducts] = useState<Product[]>([])
    const fetchProducts = async () => {
        const res = await Product.onlyBatteries()
        setBatteryProducts(res)
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    const productRates = company.product_rates as CompanyProductRatesDataType
    const powerProductionRate = warrantyProductType.hasPowerProduction
        ? productRates[warrantyProductType.powerProductionRateKey as ProductRateTypeEnum] as number : 0
    const storageRate = warrantyProductType.hasStorage
        ? productRates[warrantyProductType.storageRateKey as ProductRateTypeEnum] as number : 0

    const rows = useMemo((): RowDataType[] => [
        ...warrantyProductType.hasPowerProduction ? [{
            isPowerProduction: true,
            title: `${form.size_kw} kW Solar System`,
            price: powerProductionRate * (form.size_kw as number) * 1000,
            qty: 1
        }] : [],
        ...warrantyProductType.hasStorage ? form.batteries.reduce((acc, item) => {
            const title = `${(item.size_kw as number).format()} kWh Battery Capacity`
            const index = acc.findIndex(item => item.title === title)

            if (index === -1) {
                return [
                    ...acc,
                    {
                        title,
                        price: storageRate * (item.size_kw as number) * 1000,
                        qty: 1
                    }
                ]
            }

            acc[index].qty += 1
            return acc
        }, [] as RowDataType[]) : []
    ], [form, batteryProducts])

    const total = useMemo(() =>
        rows.reduce((acc, item) => acc + (item.price * item.qty), 0), [rows])

    return <Popup
        placement="bottom-right"
        open={true}
        onClose={props.onClose}
        backdropStyle="gradient"
    >
        <div className="flex items-center font-medium text-xl gap-2 mt-6">
            {warrantyProductType.brandIcon('sm', true)}
            {warrantyProductType.title}
        </div>
        <h4 className="font-semibold text-sm mt-5">Pricing Overview</h4>

        {rows.map(item =>
            <div className="flex mt-5 gap-4 text-xs items-center" key={item.title}>
                <div className="bg-gray-50 w-18 h-18 rounded-lg flex justify-center items-center">
                    {item.isPowerProduction
                        ? WarrantyProductType.solar.coverageIcon('md')
                        : warrantyProductType.coverageIcon('md')}
                </div>
                <div className="leading-[18px]">
                    {item.isPowerProduction
                        ? <h5 className="font-semibold text-gray-800">{WarrantyProductType.solar.coverageTitle}</h5>
                        : <h5 className="font-semibold text-gray-800">{warrantyProductType.coverageTitle}</h5>}
                    <p className="text-gray-500">{item.title}</p>
                    <p className="text-gray-500">
                        Coverage Rate: ${item.isPowerProduction ? `${powerProductionRate} per W` : `${storageRate} per Wh`}
                    </p>
                    <div className="mt-1.5">
                        <span className="text-gray-700">{item.price.money()}</span>
                        {item.qty > 1 && <span className="text-gray-400 ml-4">x{item.qty}</span>}
                    </div>
                </div>
            </div>)}

        <div className="flex justify-between gap-2 mt-4 pt-8 border-t border-dashed border-gray-200">
            <span className="text-gray-500">Total</span>
            <span className="font-semibold">{total.money()}</span>
        </div>

        <Button size="btn-sm" className="w-full mt-8" onClick={props.onNext}>
            Continue & Generate Certificate
        </Button>
        <Button size="btn-sm" design="btn-link" className="w-full mt-3" onClick={props.onClose}>
            Back to Registration
        </Button>
    </Popup>
}
