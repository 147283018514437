import EnergySection from './EnergySection'
import {
    Alert,
    Card, DeviceAlerts, DevicesList,
    IconHelpCircle,
    MonitoringError, Tooltip, TroubleshootingGuidePopup,
    ClickToCopy, IconCopy02
} from '@/components'
import { GeneracSystem, SystemStatus, Warranty } from '@/models'

const MonitoringShow = ({ warranty }: { warranty: Warranty }) => {
    const getErrors = (): string[] => {
        const errors = warranty.homeowner?.monitoring_data?.errors
        if (typeof errors?.message === 'string') return [errors.message]
        if (Array.isArray(errors)) {
            return errors.reduce((acc, item) => {
                try {
                    item = JSON.parse(item)
                } catch {
                    // do nothing
                }
                if (typeof item === 'string') {
                    try {
                        return [...acc, JSON.parse(item).message]
                    } catch {
                        return [...acc, item]
                    }
                } else if (typeof item === 'object') {
                    if (typeof item.message === 'string') {
                        return [...acc, `${item.message}${item.url ? ` (${item.url})` : ''}`]
                    } else if (typeof item.message === 'object') {
                        return [...acc, ...item.message]
                    }
                    return acc
                } else {
                    return acc
                }
            }, [] as string[]).filter((item: string) => !item.includes('!doctype'))
        }
        return []
    }

    const detectedAtLabel = warranty?.homeowner?.system?.status_change_detected_at &&
        <div className="flex gap-2 text-xs font-body text-gray-400 leading-6">
            {new Date(warranty?.homeowner?.system.status_change_detected_at).format()}
            <Tooltip
                content={<div className="max-w-[18.5rem]">
                    <h4 className="font-semibold mb-1">Detection Date</h4>
                    <p>
                        Solar Insure&apos;s Detection Date reflects when the company
                        became aware of a site&apos;s system status. Since Solar
                        Insure pulls data from the inverter manufacturer every 3-7
                        days, there may be a delay between the detection date and
                        the actual date when the status first appeared in the
                        manufacturer&apos;s database.
                    </p>
                </div>}
            >
                <IconHelpCircle className="stroke-gray-400" size="sm"/>
            </Tooltip>
        </div>

    return <>
        {!!getErrors().length && <Alert type="error" className="mb-6">
            <ul>
                {getErrors().map((item, index) => <li
                    key={index} dangerouslySetInnerHTML={{ __html: item }}
                />)}
            </ul>
        </Alert>}
        {!(warranty.homeowner?.system?.status as SystemStatus)?.category.isConnected
            ? <MonitoringError warranty={warranty}/>
            : <>
                <div className="grid lg:grid-cols-3 lg:grid-rows-3 gap-6 mb-6">
                    <Card className="lg:row-span-3">
                        <h2 className="card-title mb-3 font-semibold text-base text-gray-900 flex lg:gap-2 justify-between items-center" data-test="monitoring-details-system-status-title">
                            System Status {warranty.homeowner?.system.status.troubleshooting &&
                                <TroubleshootingGuidePopup system={warranty.homeowner?.system}/>}
                        </h2>
                        <p className="text-gray-500 mb-4 text-xs" data-test="monitoring-details-last-updated-at">
                            Last updated at {warranty.homeowner?.monitoring_data?.updated_at
                                ? warranty.homeowner?.monitoring_data?.updated_at.format({
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit'
                                })
                                : '-'}
                        </p>
                        {warranty?.homeowner?.system.manufacturer.isGenerac
                            ? warranty?.homeowner?.system.generacSystems?.map((item: GeneracSystem) =>
                                <div key={item.id} className="card-row mb-6" data-test="monitoring-details-system-status-generac">
                                    <h3 className="card-row-title">System {item.serialNumber} Status</h3>
                                    <div>
                                        <div className="card-row-copy flex items-center gap-2">
                                            {item?.status?.iconBadge()}
                                            <div className="flex flex-col">
                                                {item?.status.title}
                                                {detectedAtLabel}
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            : <div className="card-row mb-6" data-test="monitoring-details-system-status">
                                <h3 className="card-row-title">Current System Status</h3>
                                <div>
                                    <div className="card-row-copy flex items-center gap-2">
                                        {warranty?.homeowner?.system?.status.iconBadge()}
                                        <div className="flex flex-col">
                                            {warranty.homeowner?.system?.status.title}
                                            {detectedAtLabel}
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        <div className="grid grid-cols-1 sm:grid-cols-2">
                            <div className="card-row mb-6" data-test="monitoring-details-homeowner-name">
                                <h3 className="card-row-title">Homeowner Name</h3>
                                <p className="card-row-copy">
                                    {warranty.systemDetails?.homeowner_name || warranty.homeowner?.fullName}
                                </p>
                            </div>
                            <div className="card-row mb-6" data-test="monitoring-details-system-name">
                                <h3 className="card-row-title">System Name</h3>
                                <p className="card-row-copy">
                                    {warranty.systemDetails?.system_name || '-'}
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2">
                            <div className="card-row mb-6 sm:pr-2" data-test="monitoring-details-system-id">
                                <h3 className="card-row-title">System ID</h3>
                                <div className="card-row-copy flex gap-1">
                                    <span className="truncate">
                                        {warranty.systemDetails?.securedSystemId}
                                    </span>
                                    <ClickToCopy content={warranty.systemDetails?.system_id as string}>
                                        <IconCopy02 className="stroke-gray-400" size="sm"/>
                                    </ClickToCopy>
                                </div>
                            </div>
                            <div className="card-row mb-6" data-test="monitoring-details-system-size">
                                <h3 className="card-row-title">System Size</h3>
                                <p className="card-row-copy">
                                    {warranty.systemDetails?.system_size ? `${Warranty.getField('size_kw').format(warranty.systemDetails?.system_size / 1000)} kW` : '-'}
                                </p>
                            </div>
                        </div>
                        <div className="card-row mb-6" data-test="monitoring-details-location">
                            <h3 className="card-row-title">Location</h3>
                            <div className="card-row-copy flex gap-1">
                                <span>
                                    {warranty.homeowner?.address}
                                </span>
                                <ClickToCopy content={warranty.homeowner?.address as string}>
                                    <IconCopy02 className="stroke-gray-400" size="sm"/>
                                </ClickToCopy>
                            </div>
                        </div>
                    </Card>

                    <Card className="lg:row-span-3">
                        <h2 className="card-title mb-6 font-semibold text-base text-gray-900" data-test="monitoring-details-info-title">Info</h2>

                        <div className="grid grid-cols-1 sm:grid-cols-2">
                            <div className="card-row mb-6" data-test="monitoring-details-installer">
                                <h3 className="card-row-title">Installer</h3>
                                <p className="card-row-copy">
                                    {warranty.homeowner?.company?.name || '-'}
                                </p>
                            </div>

                            <div className="card-row mb-6" data-test="monitoring-details-install-date">
                                <h3 className="card-row-title">Install Date</h3>
                                <p className="card-row-copy">
                                    {warranty.homeowner?.install_date?.format()}
                                </p>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2">
                            <div className="card-row mb-6" data-test="monitoring-details-pto-date">
                                <h3 className="card-row-title gap-1">
                                    <span>
                                        PTO Date
                                    </span>
                                    <Tooltip
                                        content={<div className="max-w-[18.5rem]">
                                            <h4 className="font-semibold mb-1">Permission to Operate (PTO) Date</h4>
                                            <p className="mb-1">
                                                The PTO date indicates when your solar system was authorized
                                                by the utility to connect to the grid. This date is entered manually
                                                by the contractor in the manufacturer&apos;s portal, which can
                                                sometimes lead to inaccuracies due to human error or delays in updating.
                                                If this field is blank but energy production data is consistent, it
                                                likely means the PTO has occurred, though the date may not have been
                                                updated in the portal. For a more reliable reference, you can also
                                                check the ‘Initial Energy Data’ date.
                                            </p>
                                            <p>
                                                Additionally, we’ll retain the &apos;Initial Energy Data&apos;
                                                visibility for all sites, regardless of PTO data availability,
                                                due to potential inaccuracies in the PTO dates.
                                            </p>
                                        </div>}
                                    >
                                        <IconHelpCircle className="stroke-gray-400" size="sm"/>
                                    </Tooltip>
                                </h3>
                                <p className="card-row-copy">
                                    {warranty.systemDetails?.pto_date ? warranty.systemDetails.pto_date.format() : '-'}
                                </p>
                            </div>
                            <div className="card-row mb-6" data-test="monitoring-details-initial-energy-date">
                                <h3 className="card-row-title gap-1">
                                    <span>
                                        Initial Energy Date
                                    </span>
                                    <Tooltip
                                        content={<div className="max-w-[18.5rem]">
                                            <h4 className="font-semibold mb-1">Initial Energy Date</h4>
                                            <p>
                                                It is calculated by detecting the first in a series of consecutive days
                                                with recorded energy production, indicating regular system operation. We
                                                look for a sequence of production days to ensure we&apos;re not
                                                capturing one-off preliminary testing prior to PTO. It can be used as
                                                a proxy for the PTO date when the actual PTO is not recorded.
                                            </p>
                                        </div>}
                                    >
                                        <IconHelpCircle className="stroke-gray-400" size="sm"/>
                                    </Tooltip>
                                </h3>
                                <p className="card-row-copy">
                                    {warranty.systemDetails?.initial_energy_date ? warranty.systemDetails.initial_energy_date.format() : '-'}
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2">
                            <div className="card-row mb-6" data-test="monitoring-details-inverter-make">
                                <h3 className="card-row-title">Inverter Make</h3>
                                <p className="card-row-copy">
                                    {warranty.systemDetails?.inverter_make || '-'}
                                </p>
                            </div>
                            <div className="card-row mb-6" data-test="monitoring-details-inverter-model">
                                <h3 className="card-row-title">Inverter Model</h3>
                                <p className="card-row-copy">{warranty.systemDetails?.inverter_model}</p>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2">
                            <div className="card-row mb-6" data-test="monitoring-details-inverter-count">
                                <h3 className="card-row-title"># of Inverters</h3>
                                <p className="card-row-copy">
                                    {warranty.systemDetails?.inverter_count || '-'}
                                </p>
                            </div>
                            <div className="card-row mb-6" data-test="monitoring-details-panel-count">
                                <h3 className="card-row-title"># of Panels</h3>
                                <p className="card-row-copy">
                                    {warranty.systemDetails?.panel_count || '-'}
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2">
                            <div className="card-row mb-6" data-test="monitoring-details-panel-make">
                                <h3 className="card-row-title">Panel Make</h3>
                                <p className="card-row-copy">
                                    {warranty.systemDetails?.panel_make || '-'}
                                </p>
                            </div>
                            <div className="card-row mb-6" data-test="monitoring-details-panel-model">
                                <h3 className="card-row-title">Panel Model</h3>
                                <p className="card-row-copy">{warranty.systemDetails?.panel_model || '-'}</p>
                            </div>
                        </div>
                    </Card>

                    <Card className="lg:row-span-3">
                        <EnergySection warranty={warranty}/>
                    </Card>
                </div>

                {warranty.homeowner?.system?.manufacturer.isGenerac
                    ? <DeviceAlerts warranty={warranty}/>
                    : <DevicesList warranty={warranty}/>}
            </>}
    </>
}

export default MonitoringShow
