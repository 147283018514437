import { Alert, IconXCircle } from '@/components'
import { Warranty } from '@/models'


type WarrantyCancelBannerProps = {
    warranty: Warranty
}

export const WarrantyCancelledBanner = ({ warranty }: WarrantyCancelBannerProps) =>
    <Alert
        type="error"
        icon={<div className="w-13 h-13 rounded-full bg-error-50 flex justify-center items-center">
            <div className="w-9 h-9 rounded-full bg-error-100 flex justify-center items-center stroke-error-700">
                <IconXCircle size="lg"/>
            </div>
        </div>}
        className="mb-4"
    >
        <div className="flex items-center h-full">
            This warranty was canceled on {(warranty.deleted_at as Date).format()}.
        </div>
    </Alert>
