import { Icon, IconProps } from './Icon'

export const IconX = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M17 7 7 17M7 7l10 10"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
