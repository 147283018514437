import classnames from 'classnames'
import { HTMLAttributes, useEffect, useState } from 'react'

import { FormControl, FormControlProps } from '@/components'
import { useDebounce } from '@/hooks'
import { FormChangeEvent, FormChangeEventHandler } from '@/types'

interface RangeInputProps extends FormControlProps, Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> {
    size?: string
    min: number
    max: number
    step?: number
    value: [number, number]
    onChange: FormChangeEventHandler
    name: string
    valueLabelOverride?: (value: number) => string | number
}

export const RangeInput = ({
    id,
    label,
    errors,
    warnings,
    hint,
    transparent,
    tooltip,
    className,
    'data-test': dataTest,
    min,
    max,
    step = 1,
    name,
    valueLabelOverride = (value: number) => value,
    ...props
}: RangeInputProps) => {
    const [value, setValue] = useState(props.value)
    useEffect(() => {
        if (props.value.toString() !== value.toString()) {
            setValue(props.value)
        }
    }, [props.value])

    const debounceChange = useDebounce(async (value: string) => {
        props.onChange({
            target: {
                name,
                value,
                displayValue: value[0] !== null && value[1] !== null
                    ? `${value[0]} To ${value[1]}` : undefined
            }
        })
    }, 400)
    useEffect(() => {
        if (value[0] !== null && value[1] !== null) {
            props.onChange({
                target: {
                    name,
                    value: props.value,
                    displayValue: `${props.value[0]} To ${props.value[1]}`
                }
            })
        }
    }, [])

    const handleChange = (e: FormChangeEvent) => {
        const isMin = e.target.name === `${name}[0]`
        const newCurValue = isMin
            ? Math.min(e.target.value as number, value[1] - step)
            : Math.max(e.target.value as number, value[0] + step)
        const newValue = isMin
            ? [newCurValue, value[1]]
            : [value[0], newCurValue]
        if (props.value.toString() !== newValue.toString()) {
            setValue(newValue as [number, number])
        }
        debounceChange(newValue)
    }

    const minPos = ((value[0] - min) / (max - min)) * 100
    const maxPos = ((value[1] - min) / (max - min)) * 100

    return <FormControl
        id={id}
        label={label}
        data-test={dataTest}
        errors={errors}
        warnings={warnings}
        hint={hint}
        transparent={transparent}
        tooltip={tooltip}
        className={classnames('form-control-range-input', className)}
    >
        <input
            type="range"
            value={value[0]}
            min={min}
            max={max}
            step={step}
            onChange={handleChange}
            name={`${name}[0]`}
        />
        <input
            type="range"
            value={value[1]}
            min={min}
            max={max}
            step={step}
            onChange={handleChange}
            name={`${name}[1]`}
        />
        <div className="control-wrapper">
            <div className="control" style={{ left: `${minPos}%` }}>
                <div className="control-label">
                    {valueLabelOverride(value[0])}
                </div>
            </div>
            <div className="rail">
                <div
                    className="inner-rail"
                    style={{
                        left: `${minPos}%`,
                        right: `${100 - maxPos}%`
                    }}
                />
            </div>
            <div className="control" style={{ left: `${maxPos}%` }}>
                <div className="control-label">
                    {valueLabelOverride(value[1])}
                </div>
            </div>
        </div>
    </FormControl>
}
