import { ReactNode, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

import { StepsInfo, StepOne, StepTwo, StepThree, StepFour, StepFive, StepSix } from '@/components'
import { GuestLayout } from '@/containers'


export type SignupStepDataType = {
    id: number
    title?: string
    subTitle?: ReactNode
    form: ReactNode
}
const SignUp = () => {
    const { step } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (!step) navigate('/sign-up/1')
    }, [])

    if (!step) return null

    const handleSubmit = async () => {
        if (parseInt(step) === 6) {
            navigate('/dashboard')
            return
        }
        navigate(`/sign-up/${parseInt(step) + 1}`)
    }

    const steps: SignupStepDataType[] = [
        {
            id: 1,
            title: 'New Account Verification',
            subTitle: 'Enter a Solar Insure provided security key to verify your Certified Installer status',
            form: <StepOne onSubmit={handleSubmit}/>
        },
        {
            id: 2,
            title: 'Your Details',
            subTitle: 'Please provide your name and email, and verify company details',
            form: <StepTwo onSubmit={handleSubmit}/>
        },
        {
            id: 3,
            title: 'Choose a password',
            subTitle: 'Set a secure password',
            form: <StepThree onSubmit={handleSubmit}/>
        },
        {
            id: 4,
            title: 'Define Your Preferences',
            subTitle: 'Share your core workflows and data needs',
            form: <StepFour onSubmit={handleSubmit}/>
        },
        {
            id: 5,
            title: 'Invite Your Team',
            subTitle: <>Share platform access with other employee roles at your company</>,
            form: <StepFive onSubmit={handleSubmit}/>
        },
        {
            id: 6,
            form: <StepSix onSubmit={handleSubmit}/>
        }
    ]

    const { form } = steps.find(item => item.id === parseInt(step)) || {}

    return <GuestLayout>
        <div className={`signup-container step-${step}`}>
            <StepsInfo current={parseInt(step)} steps={steps}/>
            <div className="md:w-2/3 overflow-y-auto flex items-center">
                <div className="step-content">
                    {form}
                    {parseInt(step) < 6 &&
                        <div className="steps-indicator">
                            {steps.map(({ id }) => id !== 6 && <div key={id} className={`w-16 h-2 bg-gray-300 rounded ${parseInt(step) === id ? 'active' : ''}`}/>)}
                        </div>}
                </div>
            </div>
        </div>
    </GuestLayout>
}

export default SignUp
