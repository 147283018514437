import {
    FormErrorsType,
    FormWarningsType,
    IconAlertCircle,
    IconHelpCircle,
    Input,
    Tooltip
} from '@/components'
import { InverterManufacturerEnum } from '@/enums'
import { FormChangeEventHandler, JsonDataType, IdType } from '@/types'

export interface SolarEdgeFormData extends JsonDataType {
    company_id: IdType
    provider: InverterManufacturerEnum
    api_key: string
}

type SolarEdgeFormProps = {
    form: SolarEdgeFormData
    errors: FormErrorsType
    warnings: FormWarningsType
    onChange: FormChangeEventHandler
    disabled?: boolean
    inputClassName?: string
}
export const SolarEdgeForm = ({
    form,
    inputClassName,
    disabled,
    errors,
    warnings,
    ...props
}: SolarEdgeFormProps) => {
    const getIcon = () => {
        if (warnings.api_key) {
            return <IconAlertCircle className="stroke-warning-600" size="sm"/>
        }
        if (errors.api_key) {
            return <IconAlertCircle className="stroke-error-600" size="sm"/>
        }
        return <Tooltip
            interactive
            content={<div className="w-70">
                <h4 className="mb-3 font-semibold">SolarEdge API Key</h4>
                <ul className="list-outside list-decimal ml-3 mb-3">
                    <li>
                        Log into monitoring.solaredge.com
                    </li>
                    <li>
                        Click on <span className="italic font-bold">Monitoring Admin</span> and select <span className="italic font-bold">My Account</span>
                    </li>
                    <li>
                        Navigate to the <span className="italic font-bold">Company Details</span> tab, scroll down, and click the checkbox indicating you have read, understood, and agreed to the SolarEdge API T&C
                    </li>
                    <li>
                        Generate and Copy the API Key
                    </li>
                    <li>
                        Paste the API Key in Solar Insure&apos;s Integrations field
                    </li>
                </ul>
                <p>For more help with your SolarEdge API Key, reference our <a className="text-orange-500" target="_blank" rel="noreferrer" href="/resources/Add_your_Monitoring_Credentials.pdf"><b>tutorial documentation</b></a>
                </p>
            </div>}
        >
            <IconHelpCircle className="stroke-gray-400"/>
        </Tooltip>
    }

    return <>
        <Input
            id="solaredge-api_key"
            name="api_key"
            label="SolarEdge API Key*"
            placeholder="API Key"
            onChange={props.onChange}
            value={form.api_key}
            errors={errors.api_key}
            warnings={warnings.api_key}
            postIcon={getIcon()}
            className={inputClassName}
            disabled={disabled}
        />
    </>
}
