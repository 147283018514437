import { Icon, IconProps } from './Icon'

export const IconFlipBackward = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M3 9h13.5a4.5 4.5 0 1 1 0 9H12M3 9l4-4M3 9l4 4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
