import { FormErrorsType, Input, TextArea } from '@/components'
import { FormChangeEventHandler, JsonDataType } from '@/types'

export interface ActionReasonFormDataType extends JsonDataType {
    reason?: string
    edit_reason?: string
    contact_email?: string
    edit_contact_email?: string
}

type ActionReasonFormProps = {
    form: ActionReasonFormDataType
    errors: FormErrorsType
    onChange: FormChangeEventHandler
    actionName?: string
}
export const ActionReasonForm = ({
    form,
    errors,
    actionName = '',
    ...props
}: ActionReasonFormProps) =>
    <>
        {(typeof form.reason !== 'undefined' || typeof form.edit_reason !== 'undefined') &&
            <TextArea
                id="email"
                name={typeof form.reason === 'undefined' ? 'edit_reason' : 'reason'}
                label={`${actionName.capitalize()} Reason*`}
                onChange={props.onChange}
                value={form.reason || form.edit_reason}
                placeholder={`${actionName.capitalize()} Reason`}
                errors={errors.reason || errors.edit_reason}
            />}

        {(typeof form.contact_email !== 'undefined' || typeof form.edit_contact_email !== 'undefined') &&
            <Input
                id="contact-email"
                name={typeof form.contact_email === 'undefined' ? 'edit_contact_email' : 'contact_email'}
                label="Contact Email*"
                type="email"
                placeholder="you@company.com"
                onChange={props.onChange}
                value={typeof form.contact_email === 'undefined' ? form.edit_contact_email : form.contact_email}
                errors={errors.contact_email || errors.edit_contact_email}
            />}
    </>
