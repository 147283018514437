import { ReactNode } from 'react'

import { IconChevronRight } from './icons'

export type BreadcrumbProps = {
    items: Array<ReactNode>
}

const Breadcrumb = ({ items }: BreadcrumbProps) => <ul className="breadcrumb" data-test="page-heading-breadcrumb">
    {items.map((item, index) => <li key={index} data-test={`page-heading-breadcrumb-${index}`}>
        {item}
        {index < items.length - 1 && index > 0 && <IconChevronRight size="md" className="breadcrumb-chevron"/>}
    </li>)}
</ul>

export default Breadcrumb
