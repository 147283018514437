import { Icon, IconProps } from './Icon'

export const IconCheckCircle = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="m7.5 12 3 3 6-6m5.5 3c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
