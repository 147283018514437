import { Table } from '@/components'
import { Warranty } from '@/models'
import { TableRowType, WarrantyChangeDataType } from '@/types'

type WarrantyUpdateDetailsProps = {
    oldValues?: WarrantyChangeDataType
    newValues?: WarrantyChangeDataType
}

export const WarrantyUpdateDetails = ({
    oldValues = {},
    newValues = {}
}: WarrantyUpdateDetailsProps) => {
    const oldPrice = oldValues.price || oldValues.other_changes?.price || 0
    const newPrice = newValues.price || newValues.other_changes?.price || 0

    const getRows = () => {
        if (oldValues.batteries?.length || newValues.batteries?.length) {
            oldValues.num_batteries = oldValues.batteries?.length
            newValues.num_batteries = newValues.batteries?.length
        }
        const rows = Object.keys(newValues).reduce((acc, key) => {
            if (['price', 'batteries'].includes(key)) return acc
            const field = Warranty.getField(key)
            if (field.isDefault) return acc
            const oldValue = field.format(oldValues[key as keyof WarrantyChangeDataType])
            const newValue = field.format(newValues[key as keyof WarrantyChangeDataType])
            if (oldValue === newValue) return acc
            return [{
                id: key,
                attribute: field.title,
                old_value: oldValue,
                new_value: newValue
            }, ...acc]
        }, [] as TableRowType[])

        for (let i = 0; i < Math.max(oldValues.batteries?.length || 0, newValues.batteries?.length || 0); i = i + 1) {
            const oldBattery = oldValues.batteries?.[i]
            const newBattery = newValues.batteries?.[i]
            const oldMake = Warranty.getField('battery_make').format(oldBattery?.product?.model_name)
            const newMake = Warranty.getField('battery_make').format(newBattery?.product?.model_name)
            if (oldMake !== newMake) {
                rows.push({
                    id: `battery_make_model_${i}`,
                    attribute: `Battery ${i + 1}: Make & Model`,
                    old_value: oldMake,
                    new_value: newMake
                })
            }
            const oldSizeKw = Warranty.getField('battery_size_kw').format(oldBattery?.size_kw)
            const newSizeKw = Warranty.getField('battery_size_kw').format(newBattery?.size_kw)
            if (oldSizeKw !== newSizeKw) {
                rows.push({
                    id: `battery_size_kw_${i}`,
                    attribute: `Battery ${i + 1}: Capacity (kWh)`,
                    old_value: oldSizeKw,
                    new_value: newSizeKw
                })
            }
            const oldSerialNumber = Warranty.getField('battery_serial_number').format(oldBattery?.serial_number)
            const newSerialNumber = Warranty.getField('battery_serial_number').format(newBattery?.serial_number)
            if (oldSerialNumber !== newSerialNumber) {
                const isTesla = newBattery?.product?.model_name.toLowerCase().includes('tesla')
                rows.push({
                    id: `battery_serial_number_${i}`,
                    attribute: `Battery ${i + 1}: ${isTesla ? 'Tesla DIN' : 'Serial Number'}`,
                    old_value: oldSerialNumber,
                    new_value: newSerialNumber
                })
            }
        }

        return rows
    }

    return <>
        <Table
            options={{
                sortable: false,
                pagination: false,
                useQueryParams: false
            }}
            className="table-secondary"
            data-test="warranty-update-details-table"
            columns={[
                { field: 'attribute', title: 'Attribute', cellClassName: 'font-semibold' },
                { field: 'old_value', title: 'Old Value' },
                { field: 'new_value', title: 'New Value' }
            ]}
            rows={getRows()}
        />

        <p className="text-sm font-semibold mt-4" data-test="edit-affect-price-text">
            Did the Edit affect the Warranty Price?
            <span className="text-gray-500 ml-1">
                {oldPrice === newPrice ? 'No' : 'Yes'}
            </span>
        </p>

        {oldPrice !== newPrice && <Table
            options={{
                sortable: false,
                pagination: false,
                useQueryParams: false
            }}
            data-test="warranty-update-details-price-table"
            columns={[
                { field: 'old_price', title: 'Old Warranty Price' },
                { field: 'new_price', title: 'New Warranty Price' }
            ]}
            rows={[{
                id: 1,
                old_price: Warranty.getField('price').format(oldPrice),
                new_price: Warranty.getField('price').format(newPrice)
            }]}
        />}
    </>
}
