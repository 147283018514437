import { USER_ROLES, UserRoleEnum } from '@/enums'
import { UserRoleDataType } from '@/types'

export class UserRole {
    key: UserRoleEnum

    title: string

    constructor(data: UserRoleDataType) {
        this.key = data.key
        this.title = data.title
    }

    static get all() {
        return USER_ROLES.map(item => new UserRole(item))
    }

    static find(key: UserRoleEnum) {
        return UserRole.all.find(item => item.key === key) || UserRole.defaultValue
    }

    static get defaultValue() {
        return UserRole.all.find(item => item.key === UserRoleEnum.CONTRACTOR) as UserRole
    }

    get isAdmin() {
        return this.key === UserRoleEnum.ADMINISTRATOR
    }

    get isStaff() {
        return this.key === UserRoleEnum.STAFF
    }

    get isAdminOrStaff() {
        return this.isAdmin || this.isStaff
    }

    get isAdvisor() {
        return this.key === UserRoleEnum.ADVISOR
    }

    get isAdminOrStaffOrAdvisor() {
        return this.isAdminOrStaff || this.isAdvisor
    }

    get isContractor() {
        return this.key === UserRoleEnum.CONTRACTOR
    }

    get isAffiliate() {
        return this.key === UserRoleEnum.CONGLOMERATE
    }

    get isAffiliateOrContractor() {
        return this.isAffiliate || this.isContractor
    }
}
