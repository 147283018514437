import { useEffect, useMemo, useState } from 'react'

import { Table } from '@/components'
import { Warranty } from '@/models'
import { isApiCancelError } from '@/services'
import { IndexContainerDataType, TableParamsType, TableMetaType, WarrantyIndexFiltersType } from '@/types'

interface WarrantyIndexMeta extends TableMetaType {
    current_page: number
    per_page: number
}
interface WarrantyIndexData extends IndexContainerDataType {
    meta: WarrantyIndexMeta
    rows: Warranty[]
}

type WarrantyIndexPreviewProps = {
    selected: Warranty[] | 'all'
    excluded: Warranty[]
    filters: WarrantyIndexFiltersType
}

export const WarrantyIndexPreview = ({
    selected,
    excluded,
    filters
}: WarrantyIndexPreviewProps) => {
    const [abortController, setAbortController] = useState<AbortController | null>(null)
    const [processing, setProcessing] = useState(false)
    const [data, setData] = useState<WarrantyIndexData>({
        meta: {
            total: 0,
            current_page: 0,
            per_page: 0
        },
        rows: selected === 'all' ? [] : selected
    })

    const selectedWarranties = useMemo(() => selected === 'all'
        ? data.rows
        : selected.reduce((acc: Warranty[], item) => {
            const warranty = data.rows.find(({ id }) => id === item.id)
            return warranty ? [...acc, warranty] : acc
        }, []), [selected, data.rows])

    const rows = useMemo(() => (selected === 'all' ? data.rows : selectedWarranties).map((item, index) => ({
        id: item.id,
        index: `${(selected === 'all'
            ? index + 1 + ((data.meta.current_page - 1) * data.meta.per_page)
            : index + 1).format()}.`,
        customer_info: item.customer_info?.full_name,
        policy_num: item.policy_num
    })), [data])

    const fetchWarranties = async (params?: TableParamsType) => {
        try {
            abortController?.abort()
            const controller = new AbortController()
            setAbortController(controller)

            const { data: rows, meta } = await Warranty.index({
                ...params,
                per_page: params?.per_page ? params.per_page : 10,
                excluded_ids: excluded.map(({ id }) => id),
                ...Object.keys(filters).reduce((acc, key) => ({
                    ...acc,
                    [key]: filters[key].value?.toString() === filters[key].defaultValue?.toString()
                        ? null : filters[key].value
                }), {})
            }, { signal: controller.signal })

            setData({ meta: meta as WarrantyIndexMeta, rows })
        } catch (err) {
            if (!isApiCancelError(err)) {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        if (selected === 'all') {
            fetchWarranties()
        }
    }, [])

    return <Table
        className="mb-4 emphasized"
        options={{
            sortable: false,
            useQueryParams: false,
            dataName: 'Warranties'
        }}
        processing={processing}
        onChange={fetchWarranties}
        data-test="warranties-bulk-cancel-reinstate-table"
        meta={data.meta}
        columns={[
            { title: '', field: 'index' },
            { field: 'customer_info', title: 'Homeowner Name' },
            { field: 'policy_num', title: 'Plan ID' }
        ]}
        rows={rows}
    />
}
