import { useState } from 'react'

import { Button, WarrantyCreatePopup } from '@/components'
import { useAuth } from '@/hooks'
import { CompanyAccountActivityStatus } from '@/models'

type WarrantyCreateButtonProps = {
    className?: string
}
export const WarrantyCreateButton = ({ className }: WarrantyCreateButtonProps) => {
    const auth = useAuth()
    const [isOpen, setIsOpen] = useState(false)

    return <>
        <Button
            onClick={() => setIsOpen(true)}
            className={className}
            data-test="warranty-create-button"
            disabled={
                !auth.user?.isAllowedToCreateWarranty || auth.user.company.account_activity_status.isDisabled
            }
            tooltipDisabled={
                auth.user?.isAllowedToCreateWarranty && !auth.user.company.account_activity_status.isDisabled
            }
            tooltip={
                auth.user?.company.account_activity_status.isDisabled
                    ? CompanyAccountActivityStatus.DISABLED_STATUS_MESSAGE
                    : <div className="text-center">
                        <p>Registration disabled: Certification incomplete.</p>
                        <p>Please submit the required credentials to proceed with warranty registration.</p>
                        <p>Contact your account manager if you have questions about your certification status.</p>
                    </div>
            }
        >
            Add New Warranty
        </Button>

        {isOpen && <WarrantyCreatePopup
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
        />}
    </>
}
