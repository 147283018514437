import { Company } from './Company'
import { Warranty } from './index'
import { Model } from './Model'
import { MonitoringData } from './MonitoringData'
import { System } from './System'
import { HomeownerDataType, IdType } from '@/types'

export class Homeowner extends Model {
    static endpoint = 'api/v1/homeowners'

    raw: HomeownerDataType

    system_id: IdType

    company_id: IdType

    first_name: string

    last_name: string

    full_name?: string

    email: string

    phone?: string

    address?: string

    street_address?: string

    city?: string

    state?: string

    zip?: string

    systems: System[]

    company?: Company

    monitoring_data?: MonitoringData

    warranties: Warranty[]

    allow_duplicate?: boolean

    allow_invalid_address?: boolean

    install_date?: Date

    constructor(data: HomeownerDataType) {
        super(data)
        this.raw = data
        this.system_id = data.system_id
        this.company_id = data.company_id
        this.first_name = data.first_name
        this.last_name = data.last_name
        this.full_name = data.full_name
        this.email = data.email
        this.phone = data.phone
        this.address = data.address
        this.street_address = data.street_address
        this.city = data.city
        this.state = data.state
        this.zip = data.zip
        this.company = data.company ? new Company(data.company) : undefined

        this.warranties = data.warranties?.map(item => new Warranty(item)) || []
        this.install_date = Model.toDate(data.install_date)
        this.allow_duplicate = data.allow_duplicate
        this.allow_invalid_address = data.allow_invalid_address

        this.systems = data.systems ? data.systems.map(item => new System({ ...item, homeowner: data })) : []
        this.monitoring_data = data.monitoring_data ? new MonitoringData(data.monitoring_data) : undefined
    }

    get warranty() {
        return this.raw.warranty ? new Warranty(this.raw.warranty) : undefined
    }

    get system() {
        return this.systems[0]
    }

    get fullName() {
        return `${this.first_name}${this.last_name ? ` ${this.last_name}` : ''}`
    }
}
