import { UserRoleDataType } from '@/types'

export enum UserRoleEnum {
    ADMINISTRATOR = 'administrator',
    CONGLOMERATE = 'conglomerate',
    CONTRACTOR = 'contractor',
    STAFF = 'staff',
    ADVISOR = 'advisor'
}

export const USER_ROLES: UserRoleDataType[] = [
    {
        key: UserRoleEnum.ADMINISTRATOR,
        title: 'Administrator'
    },
    {
        key: UserRoleEnum.STAFF,
        title: 'Staff'
    },
    {
        key: UserRoleEnum.ADVISOR,
        title: 'Advisor'
    },
    {
        key: UserRoleEnum.CONTRACTOR,
        title: 'Contractor'
    },
    {
        key: UserRoleEnum.CONGLOMERATE,
        title: 'Conglomerate'
    }
]
