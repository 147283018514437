import { ReactNode } from 'react'

import emptyState from '@/assets/images/table/tableEmptyStateDefault.svg'
import loadingState from '@/assets/images/table/tableLoadingStateDefault.svg'
import { Image } from '@/components'

type TablePlaceholderProps = {
    content: ReactNode
    processing: boolean | string
}

const TablePlaceholder = ({
    content,
    processing
}: TablePlaceholderProps) => {
    const getContent = () => {
        if (processing === true) {
            return <Image
                className="w-30 animate-pulse"
                src={loadingState}
            />
        }

        if (content) {
            return content
        }
        return <Image
            className="w-30"
            src={emptyState}
        />
    }

    return <div className="w-full h-full bg-white flex justify-center py-16">
        <div className="max-w-[22rem] flex flex-col items-center">
            {getContent()}
        </div>
    </div>
}

export default TablePlaceholder
