import { ACCOUNT_TYPES, AccountFormTypeEnum, AccountTypeEnum } from '@/enums'
import { AccountTypeDataType } from '@/types'

export class AccountType {
    key: AccountTypeEnum

    type: AccountFormTypeEnum

    title: string

    shortTitle?: string

    constructor(data: AccountTypeDataType) {
        this.key = data.key
        this.type = data.type
        this.title = data.title
        this.shortTitle = data.shortTitle
    }

    static get all() {
        return ACCOUNT_TYPES.map(item => new AccountType(item))
    }

    static find(key: AccountTypeEnum) {
        return AccountType.all.find(item => item.key === key)
    }

    static get userTypes() {
        return AccountType.all.filter(({ type }) => type === AccountFormTypeEnum.USER)
    }

    static get companyTypes() {
        return AccountType.all.filter(({ type }) => type === AccountFormTypeEnum.COMPANY)
    }

    static get companyUserTypes() {
        return AccountType.all.filter(({ type }) => type === AccountFormTypeEnum.COMPANY_USER)
    }
}
