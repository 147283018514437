import { useEffect, useMemo, useState } from 'react'

import { Select, SelectOptionProps, SelectProps, Switch } from '@/components'
import { InverterManufacturerEnum } from '@/enums'
import { InverterManufacturer } from '@/models'
import { FormChangeEvent, FormChangeEventHandler, MonitoringSummaryDataType, WarrantySummaryDataType } from '@/types'

interface InverterManufacturerFilterProps extends Omit<SelectProps, 'options'> {
    onChange: FormChangeEventHandler
    value: string[]
    name: string
    summary: MonitoringSummaryDataType | WarrantySummaryDataType
}

const InverterManufacturerFilter = ({
    summary,
    ...props
}: InverterManufacturerFilterProps) => {
    const [showInactiveOptions, setShowInactiveOptions] = useState(false)
    const handleChange = (e: FormChangeEvent) => {
        if (e.target.value === 'toggle') {
            setShowInactiveOptions(value => !value)
            return
        }
        const value = e.target.value as string[]
        props.onChange({
            target: {
                name: props.name,
                value,
                displayValue: value.map(item =>
                    InverterManufacturer.find(item as InverterManufacturerEnum).title)
            }
        })
    }

    const getStatusCount = (item: InverterManufacturer) =>
        (summary.manufacturers?.[item.key] || 0).format()

    const options = useMemo(() => [
        {
            value: 'toggle',
            title: <div className="flex w-full gap-4 items-center justify-between font-body text-gray-900 text-sm">
                Show Inactive Manufacturers
                <Switch
                    name="toggle"
                    input-size="sm"
                    checked={showInactiveOptions}
                    value="toggle"
                    onChange={() => {}}
                    onClick={() => {}}
                />
            </div>
        },
        {
            value: '',
            title: 'Any Manufacturer'
        },
        ...InverterManufacturer.all
            .filter(item => !item.isAlternative)
            .sort((a, b) => {
                if (a.isUnknown !== b.isUnknown) return a.isUnknown ? 1 : -1
                return a.title.localeCompare(b.title)
            })
            .reduce((acc, item) =>
                summary.manufacturers?.[item.key] ||
                props.value.includes(item.key) ||
                showInactiveOptions ? [...acc, {
                        value: item.key,
                        title: <div
                            className="flex gap-4 items-center justify-between font-body text-gray-900 text-sm w-[18rem]"
                        >
                            <div className="flex gap-2 items-center">
                                {item.getLogo()} {item.title}
                            </div>
                            {getStatusCount(item)}
                        </div>
                    }] : acc, [] as SelectOptionProps[])
    ], [props.value, summary, showInactiveOptions])

    useEffect(() => {
        if (props.value.length) {
            props.onChange({
                target: {
                    name: props.name,
                    value: props.value,
                    displayValue: props.value.map(item =>
                        InverterManufacturer.find(item as InverterManufacturerEnum).title)
                }
            })
        }
    }, [])

    return <Select
        {...props}
        multiple
        onChange={handleChange}
        options={options}
    />
}

export default InverterManufacturerFilter
