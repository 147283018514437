import { Icon, IconProps } from './Icon'

export const IconMinusCircle = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M8 12h8m6 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
