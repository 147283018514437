import { Icon, IconProps } from './Icon'

export const IconSpeedometer = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M5 12a7 7 0 0 1 7-7m4.5 2.5L12 12m10 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-9 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
