import { useEffect, useState } from 'react'

import {
    CompanyAffiliateChangePrompt,
    Select,
    Feature,
    Input,
    FormErrorsType
} from '@/components'
import { BatteryEligibilityTypeEnum } from '@/enums'
import { useAuth } from '@/hooks'
import { Company, CompanyBatteryEligibility, WarrantyProductType, AccountType, WarrantyMatchMethod } from '@/models'
import { feature } from '@/services'
import { FormChangeEventHandler, JsonDataType, IdType } from '@/types'

export interface CompanyFormDataType extends JsonDataType {
    id?: string | number
    username?: string
    name: string
    email: string
    role?: string
    type?: string
    company_id?: string
    create_warranty?: string
    unique_id?: string
    policy_rate?: number | string
    battery_eligibility?: string
    battery_only_30_year_rate?: string | number
    battery_20_year_rate?: string | number
    battery_30_year_rate?: string | number
    warranty_match_method?: string
    solar_commission_rate?: string | number
    monthly_sales_quota?: string | number
    battery_commission_rate?: string | number
    conglomerate_id?: IdType
    update_policy?: string
}

type CompanyFormProps = {
    form: CompanyFormDataType
    company?: Company
    errors?: FormErrorsType
    onChange: FormChangeEventHandler
    onEligibleCommissionFocus?: (type: 'solar' | 'battery') => void
    isNew?: boolean
}

export const CompanyForm = ({ form, errors = {}, company, ...props }: CompanyFormProps) => {
    const [affiliates, setAffiliates] = useState<Company[]>([])
    const auth = useAuth()
    const fetchAffiliates = async () => {
        if (affiliates.length) return
        const data = await Company.onlyAffiliates()
        setAffiliates(data)
    }

    useEffect(() => {
        if (auth.user?.role.isAdminOrStaff) {
            fetchAffiliates()
        }
    }, [])

    const getSelectedAffiliate = () => affiliates.find(({ id }) => id === form.conglomerate_id)

    const handleEligibleCommissionInputFocus = (type: 'solar' | 'battery') => {
        if (props.onEligibleCommissionFocus) props.onEligibleCommissionFocus(type)
    }

    const getCommissionRateHint = () => {
        if (!company) return ''
        const curIndex = company?.eligible_commission_rates
            .findIndex(item => item.id === company?.currentEligibleCommission?.id) || 0
        const nextCommission = company?.eligible_commission_rates[curIndex - 1]

        const curDate = company?.currentEligibleCommission
            ? new Date(company.currentEligibleCommission.effective_date)
            : new Date()
        const nextDate = new Date(nextCommission?.effective_date)

        if (nextCommission) {
            return `Effective ${curDate.format()} to ${nextDate.format()}`
        }
        return `Effective as of ${curDate.format()}`
    }

    return <>
        {typeof form.username !== 'undefined' && <Input
            id="username"
            name="username"
            label="Username*"
            placeholder="Username"
            onChange={props.onChange}
            value={form.username}
            errors={errors.username}
        />}
        {typeof form.name !== 'undefined' && <Input
            id="name"
            name="name"
            label="Company Name*"
            placeholder="Company Name"
            onChange={props.onChange}
            value={form.name}
            errors={errors.name}
        />}
        {typeof form.email !== 'undefined' && <Input
            id="email"
            name="email"
            label="Accounting Email*"
            type="email"
            placeholder="Email"
            onChange={props.onChange}
            value={form.email}
            errors={errors.email}
        />}

        {typeof form.role !== 'undefined' && form.id && <Select
            id="role"
            name="role"
            label="Account Type"
            disabled
            options={[{
                value: '',
                title: 'Account Type'
            }, ...AccountType.all.map(item => ({
                value: item.key,
                title: item.title
            }))]}
            value={form.role}
            errors={errors.role}
            onChange={props.onChange}
        />}

        {typeof form.create_warranty !== 'undefined' && <Feature name="onboarding">
            <Select
                id="create_warranty"
                name="create_warranty"
                label="Create Warranty Feature"
                options={[
                    {
                        value: 'disabled',
                        title: 'Disabled'
                    },
                    {
                        value: 'enabled',
                        title: 'Enabled'
                    }
                ]}
                value={form.create_warranty}
                errors={errors.create_warranty}
                onChange={props.onChange}
            />
        </Feature>}

        {typeof form.unique_id !== 'undefined' && <Input
            id="unique_id"
            name="unique_id"
            label="Salesforce Unique ID*"
            placeholder="Salesforce Unique ID"
            onChange={props.onChange}
            value={form.unique_id}
            errors={errors.unique_id}
        />}
        {(typeof form.policy_rate !== 'undefined' ||
            typeof form.battery_only_30_year_rate !== 'undefined' ||
            typeof form.battery_20_year_rate !== 'undefined' ||
            typeof form.battery_30_year_rate !== 'undefined') && <div className="lg:col-span-2 grid xl:grid-cols-4 gap-6">
            {typeof form.policy_rate !== 'undefined' && <Input
                id="policy_rate"
                name="policy_rate"
                preIcon={WarrantyProductType.solar.coverageIcon()}
                label={`${WarrantyProductType.solar.shortCoverageTitle} Rate*`}
                placeholder={`${WarrantyProductType.solar.shortCoverageTitle} Rate`}
                onChange={props.onChange}
                value={form.policy_rate}
                errors={errors.policy_rate}
                disabled={!feature('battery-eligibility')}
                mask={{
                    numeral: true,
                    numeralDecimalScale: 2,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'none'
                }}
            />}
            {typeof form.battery_only_30_year_rate !== 'undefined' && <Input
                id="battery_only_30_year_rate"
                name="battery_only_30_year_rate"
                preIcon={WarrantyProductType.battery.coverageIcon()}
                label={`${WarrantyProductType.battery.shortCoverageTitle} Rate*`}
                placeholder={WarrantyProductType.battery.shortCoverageTitle}
                onChange={props.onChange}
                className="whitespace-nowrap"
                value={form.battery_only_30_year_rate}
                errors={errors.battery_only_30_year_rate}
                disabled={!feature('battery-eligibility')}
                mask={{
                    numeral: true,
                    numeralDecimalScale: 2,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'none'
                }}
            />}
            {typeof form.battery_20_year_rate !== 'undefined' && <Input
                id="battery_20_year_rate"
                name="battery_20_year_rate"
                preIcon={WarrantyProductType.legacy20.coverageIcon()}
                label={`${CompanyBatteryEligibility.battery20.shortSecondaryTitle} Rate*`}
                placeholder={CompanyBatteryEligibility.battery20.shortSecondaryTitle}
                onChange={props.onChange}
                value={form.battery_20_year_rate}
                errors={errors.battery_20_year_rate}
                disabled={form.battery_eligibility !== BatteryEligibilityTypeEnum.BATTERY_20_YEAR || !feature(
                    'battery-eligibility')}
                mask={{
                    numeral: true,
                    numeralDecimalScale: 2,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'none'
                }}
            />}
            {typeof form.battery_30_year_rate !== 'undefined' && <Input
                id="battery_30_year_rate"
                name="battery_30_year_rate"
                preIcon={WarrantyProductType.total30.coverageIcon()}
                label={`${CompanyBatteryEligibility.battery30.shortSecondaryTitle} Rate*`}
                placeholder={CompanyBatteryEligibility.battery30.shortSecondaryTitle}
                onChange={props.onChange}
                value={form.battery_30_year_rate}
                errors={errors.battery_30_year_rate}
                disabled={form.battery_eligibility !== BatteryEligibilityTypeEnum.BATTERY_30_YEAR || !feature(
                    'battery-eligibility')}
                mask={{
                    numeral: true,
                    numeralDecimalScale: 2,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'none'
                }}
            />}
        </div>}
        {typeof form.battery_eligibility !== 'undefined' && <Select
            name="battery_eligibility"
            id="battery-eligibility-filter"
            label="Battery Product Eligibility"
            options={[
                ...CompanyBatteryEligibility.all.map(item => ({
                    value: item.key as string,
                    title: <div className="flex gap-2 items-center">
                        {item.icon()} {item.title}
                    </div>
                }))
            ]}
            value={form.battery_eligibility}
            onChange={props.onChange}
            disabled={!feature('battery-eligibility')}
            showChevron={feature('battery-eligibility')}
        />}
        {typeof form.warranty_match_method !== 'undefined' && <Select
            id="warranty-match-method"
            name="warranty_match_method"
            label="Warranty Match Method*"
            options={WarrantyMatchMethod.all.map(item => ({
                value: item.key,
                title: item.title
            }))}
            value={form.warranty_match_method}
            errors={errors.warranty_match_method}
            onChange={props.onChange}
        />}
        {typeof form.solar_commission_rate !== 'undefined' && <Input
            id="solar-commission-rate"
            name="solar_commission_rate"
            label={<div className="flex gap-1.5">
                Solar Warranty Eligible Commission Rate {WarrantyProductType.solar.coverageIcon('sm')}
            </div>}
            placeholder="Solar Warranty Eligible Commission Rate"
            onChange={props.onChange}
            onFocus={() => handleEligibleCommissionInputFocus('solar')}
            value={form.solar_commission_rate}
            errors={errors.solar_commission_rate}
            hint={getCommissionRateHint()}
            mask={{
                numeral: true,
                numeralDecimalScale: 4,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'none'
            }}
        />}
        {typeof form.monthly_sales_quota !== 'undefined' && <Input
            id="monthly_sales_quota"
            name="monthly_sales_quota"
            label="Monthly Sales Quota"
            placeholder="Monthly Sales Quota"
            onChange={props.onChange}
            value={form.monthly_sales_quota}
            errors={errors.monthly_sales_quota}
            mask={{
                numeral: true,
                numeralDecimalScale: 2,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'none'
            }}
        />}
        {typeof form.battery_commission_rate !== 'undefined' && <Input
            id="battery-commission-rate"
            name="battery_commission_rate"
            label={<div className="flex gap-1.5">
                Battery Warranty Eligible Commission Rate {WarrantyProductType.legacy20.coverageIcon('sm', false)}
            </div>}
            placeholder="Battery Warranty Eligible Commission Rate"
            hint={getCommissionRateHint()}
            onChange={props.onChange}
            onFocus={() => handleEligibleCommissionInputFocus('battery')}
            value={form.battery_commission_rate}
            errors={errors.battery_commission_rate}
            mask={{
                numeral: true,
                numeralDecimalScale: 4,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'none'
            }}
        />}
        {typeof form.conglomerate_id !== 'undefined' && company && <>
            <Select
                id="conglomerate-id"
                name="conglomerate_id"
                label="Affiliate"
                options={[
                    {
                        value: '',
                        title: 'Affiliate'
                    },
                    ...affiliates.map(item => ({
                        value: item.id,
                        title: item.name
                    }))
                ]}
                value={form.conglomerate_id}
                errors={errors.conglomerate_id}
                onChange={e => {
                    const oldAffiliate = company?.conglomerate
                    const newAffiliate = affiliates.find(({ id }) => id === e.target.value)
                    if (oldAffiliate?.id !== newAffiliate?.id &&
                        newAffiliate?.warranty_match_method.isFullSubcontractorMatch) {
                        props.onChange({
                            target: {
                                name: 'update_policy',
                                value: ''
                            }
                        })
                    } else {
                        props.onChange({
                            target: {
                                name: 'update_policy',
                                value: 'future_only'
                            }
                        })
                    }
                    props.onChange(e)
                }}
            />
            <CompanyAffiliateChangePrompt
                open={company?.conglomerate?.id?.toString() !== form.conglomerate_id?.toString() &&
                    !!getSelectedAffiliate()?.warranty_match_method.isFullSubcontractorMatch &&
                    !form.update_policy}
                onChange={props.onChange}
                company={company}
            />
        </>}
    </>
}
