import { WarrantyMatchMethodDataType } from '@/types'

export enum WarrantyMatchMethodEnum {
    EXTERNAL_UPLOAD_MATCH = 'external_upload_match',

    FULL_SUBCONTRACTOR_MATCH = 'full_subcontractor_match'
}

export const WARRANTY_MATCH_METHODS: WarrantyMatchMethodDataType[] = [
    {
        key: WarrantyMatchMethodEnum.EXTERNAL_UPLOAD_MATCH,
        title: '100% Subcontractor Warranties Matched'
    },
    {
        key: WarrantyMatchMethodEnum.FULL_SUBCONTRACTOR_MATCH,
        title: 'External Data Uploads Matching'
    }
]
