import { AccountType } from './AccountType'
import { Company } from './Company'
import { Model } from './Model'
import { Badge } from '@/components'
import { AccountTypeEnum } from '@/enums'
import { UserRole } from '@/models/UserRole'
import { api, Options, Params, Payload } from '@/services'
import {
    ArticleNotificationType,
    IdType,
    UserActivityType,
    UserDataType,
    UserPreferenceType
} from '@/types'

export class User extends Model {
    static endpoint = 'api/v1/users'

    raw: UserDataType

    name: string

    email: string

    phone?: string

    role: UserRole

    company?: Company

    status?: string

    job_title?: string

    preferred_features: string[]

    permissions: string[]

    article_notifications: ArticleNotificationType[]

    preferences?: UserPreferenceType

    activity?: UserActivityType

    last_online?: string

    email_verified_at?: Date

    constructor(data: UserDataType) {
        super(data)
        this.raw = data
        this.name = data.name?.capitalize()
        this.email = data.email
        this.phone = data.phone
        this.role = UserRole.find(data.role)
        this.company = data.company ? new Company(data.company) : undefined
        this.status = data.status
        this.job_title = data.job_title
        this.preferred_features = data.preferred_features || []
        this.permissions = data.permissions || []
        this.article_notifications = data.article_notifications
        this.preferences = data.preferences
        this.last_online = data.last_online
        this.email_verified_at = Model.toDate(data.email_verified_at)
    }

    static impersonateEmail: string = import.meta.env.VITE_IMPERSONATION_EMAIL

    get isAllowedToCreateWarranty() {
        return this.company?.isEligibleToSellWarranties || this.role.isAdminOrStaff
    }

    get accountType() {
        return AccountType.find((this.role.isAdminOrStaffOrAdvisor
            ? this.role.key : this.company?.type.key) as unknown as AccountTypeEnum)
    }

    get preferredFeaturesBadges() {
        return this.preferred_features.map((item: string) => {
            switch (item) {
                case 'Monitoring': {
                    return <Badge key={item} className="text-purple-700 bg-purple-50">{item}</Badge>
                }
                case 'Invoicing': {
                    return <Badge key={item} className="text-success-700 bg-success-50">{item}</Badge>
                }
                default: {
                    return null
                }
            }
        })
    }

    updatePreferences(payload: Payload) {
        return api.users.updatePreferences(this.id, payload)
    }

    updateOwnPreferences(payload: Payload) {
        return api.users.updateOwnPreferences(payload)
    }

    activities() {
        return api.users.activities(this.id)
    }

    updatePassword(payload: Payload) {
        return api.users.updatePassword(this.id, payload)
    }

    toggleVerification() {
        return api.users.toggleVerification(this.id)
    }

    static updatePreferencesSelf(payload: Payload) {
        return api.auth.updatePreferences(payload)
    }

    static updatePasswordSelf(payload: Payload) {
        return api.auth.updatePassword(payload)
    }

    static impersonate(companyId: IdType, password: string) {
        return api.users.impersonate(companyId, password)
    }

    static updateSelf(payload: Payload, params?: Params, options?: Options) {
        return api.http.put(User.endpoint, payload, params, options)
    }
}
