import { ReactNode, useMemo } from 'react'

import {
    IconSearch,
    Input,
    IndexFiltersPopup,
    IndexItemsCountBadge,
    IndexAppliedFiltersSection
} from '@/components'
import {
    FormChangeEventHandler,
    IndexContainerDataType,
    IndexContainerFiltersType,
    AppliedFilterType
} from '@/types'

type IndexContainerHeadingProps = {
    id: string
    data: IndexContainerDataType
    leftSide?: ReactNode
    rightSide?: ReactNode
    popupContent?: ReactNode
    filters: IndexContainerFiltersType
    dataName: string
    onClear?: () => void
    onChange: FormChangeEventHandler
    'data-test'?: string
}

export const IndexContainerHeading = ({
    id,
    'data-test': dataTest,
    data,
    filters,
    dataName,
    leftSide,
    rightSide,
    popupContent,
    ...props
}: IndexContainerHeadingProps) => {
    const appliedFilters = useMemo(() =>
        Object.keys(filters).reduce((acc, key) => {
            const filter = filters[key]
            if (filter.isStandalone || !filter.value || !filter.displayValue ||
                filter.value.toString() === filter.defaultValue?.toString()) {
                return acc
            }
            const displayValues = Array.isArray(filter.displayValue) ? filter.displayValue : [filter.displayValue]
            const values = Array.isArray(filter.value) ? filter.value : [filter.value]
            return [
                ...acc,
                ...displayValues.map((value, index): AppliedFilterType => ({
                    key,
                    value: values.length === displayValues.length
                        ? values[index]?.toString() || ''
                        : values.toString(),
                    label: filter.label,
                    displayValue: value
                }))
            ]
        }, [] as AppliedFilterType[]), [filters])

    return <div className="flex flex-col gap-4">
        <div className="flex flex-col xl:flex-row sm:gap-3 items-baseline justify-between">
            <div className="flex flex-col lg:flex-row gap-3 lg:items-center flex-wrap justify-start w-full lg:w-auto">
                {leftSide}

                <IndexItemsCountBadge
                    id={id}
                    data={data}
                    dataName={dataName}
                />
            </div>
            <div className="flex gap-3 items-center flex-wrap justify-end w-full md:w-auto self-end">
                {rightSide}
            </div>
        </div>

        <div className="flex gap-4">
            <Input
                id="datatable-search"
                placeholder="Search"
                name="search"
                value={filters.search.value}
                onChange={props.onChange}
                preIcon={<IconSearch className="stroke-gray-700"/>}
                data-test={`${dataTest}-search`}
                className="grow"
            />

            <IndexFiltersPopup
                popupContent={popupContent}
                appliedFilters={appliedFilters}
                onClear={props.onClear}
            />
        </div>

        <IndexAppliedFiltersSection
            filters={filters}
            appliedFilters={appliedFilters}
            onClear={props.onClear}
            onChange={props.onChange}
        />
    </div>
}
