import { Icon, IconProps } from './Icon'

export const IconTarget04 = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M16 8V5l3-3 1 2 2 1-3 3h-3zm0 0-4 4m10 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2m5 10a5 5 0 1 1-5-5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
