import { Model } from './Model'
import { SystemStatus } from './SystemStatus'
import { api, Options, Params } from '@/services'
import { SystemHistoryDataType, TableMetaType } from '@/types'

export class SystemHistory extends Model {
    static endpoint = 'api/v1/monitoring/systems-history'

    status: SystemStatus

    duration_seconds: number

    detection_start_date?: Date

    detection_end_date?: Date

    constructor(data: SystemHistoryDataType) {
        super(data)
        this.status = SystemStatus.find(data.status) as SystemStatus
        this.duration_seconds = data.duration_seconds
        this.detection_start_date = Model.toDate(data.detection_start_date)
        this.detection_end_date = Model.toDate(data.detection_end_date)
    }


    static async index<T extends typeof Model>(
        this: T,
        { id, ...params }: Params,
        options?: Options
    ): Promise<{ data: InstanceType<T>[], meta: TableMetaType }> {
        const res = await api.http.get(`${SystemHistory.endpoint}/${id}`, params, options)
        res.data = res.data?.map((item: SystemHistoryDataType) => new SystemHistory(item)) || []
        return res
    }
}
