import { ReactElement } from 'react'

import { Tooltip } from '@/components'
import { IconProps, IconSize, BadgeSizes } from '@/components/icons/Icon'
import { BATTERY_ELIGIBILITY_TYPES, BatteryEligibilityTypeEnum, WarrantyProductTypeEnum } from '@/enums'
import { CompanyBatteryEligibilityColorType, CompanyBatteryEligibilityDataType } from '@/types'

const COLOR_MAP = {
    green: 'bg-green-25 stroke-brand-green fill-brand-green',
    gray: 'bg-gray-200 stroke-gray-600 fill-brand-gray',
    purple: 'bg-purple-25 stroke-brand-purple fill-brand-purple'
}

export class CompanyBatteryEligibility {
    key: BatteryEligibilityTypeEnum

    title: string

    coverageTitle?: string

    subtitle?: string

    color: CompanyBatteryEligibilityColorType

    IconComponent: (props: IconProps) => ReactElement

    eligibleProductTypes: WarrantyProductTypeEnum[]

    constructor(data: CompanyBatteryEligibilityDataType) {
        this.key = data.key
        this.title = data.title
        this.coverageTitle = data.coverageTitle
        this.subtitle = data.subtitle
        this.color = data.color
        this.IconComponent = data.IconComponent
        this.eligibleProductTypes = data.eligibleProductTypes
    }

    static get all() {
        return BATTERY_ELIGIBILITY_TYPES.map(item => new CompanyBatteryEligibility(item))
    }

    static find(key: BatteryEligibilityTypeEnum) {
        return CompanyBatteryEligibility.all.find(item => item.key === key) ||
            CompanyBatteryEligibility.defaultType
    }

    static get defaultType() {
        return CompanyBatteryEligibility.all
            .find(item => item.key === BatteryEligibilityTypeEnum.NO_BATTERY) as CompanyBatteryEligibility
    }

    static get noBattery() {
        return CompanyBatteryEligibility.find(BatteryEligibilityTypeEnum.NO_BATTERY) as CompanyBatteryEligibility
    }

    static get battery20() {
        return CompanyBatteryEligibility.find(BatteryEligibilityTypeEnum.BATTERY_20_YEAR) as CompanyBatteryEligibility
    }

    static get battery30() {
        return CompanyBatteryEligibility.find(BatteryEligibilityTypeEnum.BATTERY_30_YEAR) as CompanyBatteryEligibility
    }

    get shortSecondaryTitle() {
        return this.coverageTitle?.replace('Year', 'Yr').replace('Coverage', 'Cvg')
    }

    icon(size: IconSize = 'xs') {
        const Icon = this.IconComponent
        return <Tooltip
            content={<div className="flex flex-col"><span className="font-bold">{this.title}:</span><span className="italic">{this.subtitle}</span></div>}
            className={`warranty-type-icon ${COLOR_MAP[this.color]} ${BadgeSizes[size]}`}
        >
            <Icon size={size}/>
        </Tooltip>
    }
}
