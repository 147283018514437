import { useEffect, useMemo, useRef, useState } from 'react'

import NotificationItem from './NotificationItem'
import { Button, IconReloadCw05, Dropdown, IconBell, IconClose, IconBellOff } from '@/components'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { Notification } from '@/models'
import { localStorage } from '@/services'
import { fetchNotifications, hideNotifications, showNotifications } from '@/store'

const Notifications = () => {
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)
    const { all: notifications, isOpen } = useAppSelector(state => state.notifications)
    const dispatch = useAppDispatch()
    const [processing, setProcessing] = useState(false)
    const [isEnabled, setIsEnabled] = useState(localStorage.get('notifications.enabled') !== false)

    const fetch = async () => {
        setProcessing(true)
        try {
            await dispatch(fetchNotifications)
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        if (isEnabled) {
            const hasProcessingNotifications = notifications.some(item =>
                item.data['jobs-batch'] && item.data['jobs-batch'].percentage < 100)
            if (hasProcessingNotifications) {
                intervalRef.current = setInterval(() => {
                    fetch()
                }, 3000)
            } else if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }, [isEnabled, notifications])

    useEffect(() => {
        if (isEnabled) {
            fetch()
        }
    }, [])

    const handleClearAll = async () => {
        await Notification.destroy()
        fetch()
    }

    const handleDisableClick = () => {
        localStorage.set('notifications.enabled', !isEnabled)
        setIsEnabled(!isEnabled)
    }

    const handleChange = () => {
        fetch()
    }

    const unreadNotifications = useMemo(() =>
        notifications?.filter(({ read_at: readAt }) => !readAt) || [], [notifications])

    return <Dropdown
        isOpen={isOpen}
        placement="bottom-end"
        persistent
        onChange={({ open }) => {
            dispatch(open ? showNotifications() : hideNotifications())
            if (open && unreadNotifications?.length) Notification.markAllAsRead()
        }}
        reference={<Button
            square
            design="btn-secondary-gray"
            className="relative"
            size="btn-lg"
            data-test="header-notifications-button"
        >
            {isEnabled ? <IconBell/> : <IconBellOff/>}
            {unreadNotifications?.length > 0 && isEnabled &&
                <div className="text-2xs rounded-full text-white absolute -right-1 -top-1 bg-error-600 flex justify-center items-center w-5 h-5">
                    {unreadNotifications.length}
                </div>}
        </Button>}
    >
        <div className="w-[calc(100vw-60px)] sm:w-96 h-auto py-1 px-4">
            <div className="flex justify-between items-center mb-6 gap-2">
                <h3 className="text-gray-900 text-xl">Notifications</h3>

                <Button tooltip="Refresh Notifications" design="btn-link" onClick={fetch}>
                    <IconReloadCw05 className={`stroke-gray-700 ${processing ? 'animate-spin' : ''}`}/>
                </Button>
            </div>
            <div className="mb-6 flex flex-col gap-2">

                {isEnabled && notifications.map(item => <NotificationItem
                    key={item.id}
                    item={item}
                    onChange={handleChange}
                />)}

                {(!notifications?.length || !isEnabled) && !processing &&
                    <p className="p-2 text-xs">There are no new notifications.</p>}
            </div>

            <div className="flex justify-between">
                <Button design="btn-link" onClick={handleClearAll}>
                    <IconClose size="xs" className="mr-1"/>
                    Clear All
                </Button>

                <Button design="btn-link" onClick={handleDisableClick}>
                    Turn {isEnabled ? 'Off' : 'On'} Notifications
                </Button>
            </div>
        </div>
    </Dropdown>
}

export default Notifications
