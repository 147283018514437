import classnames from 'classnames'
import { HTMLAttributes, useEffect } from 'react'

import { Checkbox } from '@/components'
import { CompanyAccountActivityStatusEnum } from '@/enums'
import { CompanyAccountActivityStatus } from '@/models'
import { FormChangeEvent, FormChangeEventHandler } from '@/types'

interface CompanyAccountActivityStatusFilterProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
    value: string[]
    name: string
    onChange: FormChangeEventHandler
}

export const CompanyAccountActivityStatusFilter = ({
    name,
    value,
    className,
    ...props
}: CompanyAccountActivityStatusFilterProps) => {
    const handleChange = (e: FormChangeEvent) => {
        const newValue = e.target.checked
            ? [...value, e.target.value] as string[]
            : value.filter(item => item !== e.target.value)
        props.onChange({
            target: {
                name,
                value: newValue,
                displayValue: newValue.map(item =>
                    CompanyAccountActivityStatus.find(item as CompanyAccountActivityStatusEnum).title)
            }
        })
    }

    useEffect(() => {
        if (value.length) {
            props.onChange({
                target: {
                    name,
                    value,
                    displayValue: value.map(item =>
                        CompanyAccountActivityStatus.find(item as CompanyAccountActivityStatusEnum).title)
                }
            })
        }
    }, [])

    const {
        onChange,
        ...divProps
    } = props
    return <div className={classnames('flex flex-wrap gap-2', className)} {...divProps}>
        {CompanyAccountActivityStatus.all
            .filter(item => item.key !== CompanyAccountActivityStatusEnum.AFFILIATE)
            .map(item => <label
                key={item.key}
                className="border border-gray-200 px-2.5 py-1 rounded flex items-center gap-2 cursor-pointer"
            >
                <Checkbox
                    id={`${props.id}-${item.key}`}
                    name={name}
                    value={item.key}
                    onChange={handleChange}
                    checked={value.includes(item.key)}
                />
                <div className="flex items-center gap-2">
                    {item.icon}{item.title}
                </div>
            </label>)}
    </div>
}
