import { useMemo, useState } from 'react'

import { ActionReasonForm, Button, Form, FormErrorsType, Popup, WarrantyIndexPreview } from '@/components'
import { useAppDispatch, useAuth, useQuery, useToastNotifications } from '@/hooks'
import { Warranty, WarrantyCancelOrReinstateRequest } from '@/models'
import { isApiError } from '@/services'
import { fetchNotifications, showNotifications } from '@/store'
import { FormChangeEvent, JobsBatchType, WarrantyIndexFiltersType } from '@/types'

type WarrantiesCancelOrReinstatePopupProps = {
    isOpen: boolean
    selected: Warranty[] | 'all'
    excluded?: Warranty[]
    quantity?: number
    action?: keyof typeof WarrantyCancelOrReinstateRequest.wordings
    selectedWarranty?: Warranty
    isAllInactive?: boolean
    filters?: WarrantyIndexFiltersType

    onClose: () => void
    onChange: () => void
    onPingJob?: (jobData: JobsBatchType) => void
}

export const WarrantiesCancelOrReinstatePopup = ({
    isOpen,
    filters,
    selected,
    excluded = [],
    quantity = 1,
    ...props
}: WarrantiesCancelOrReinstatePopupProps) => {
    const query = useQuery()
    const auth = useAuth()
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState<FormErrorsType>({})
    const dispatch = useAppDispatch()
    const { success, error } = useToastNotifications()
    const [form, setForm] = useState({
        reason: '',
        contact_email: ''
    })

    const isBulkAction = quantity > 1
    const isSingleAction = quantity === 1
    const isAllSelected = selected === 'all'
    const selectedWarranty = props.selectedWarranty || selected[0] as Warranty
    const isAllInactive = props.isAllInactive || selectedWarranty.status.isInactive
    const action = props.action || (selectedWarranty.status.isInactive ? 'reinstate' : 'cancel')

    const {
        noun,
        verb,
        Icon
    } = WarrantyCancelOrReinstateRequest.wordings[action]

    const title = useMemo(() => {
        if (isBulkAction || isAllSelected) {
            return `Warranty Bulk ${noun}`
        }
        if (isSingleAction && auth.user?.role.isContractor) {
            if (!selected[0].isLocked) {
                return `${action} Same-Day Registered Warranty`
            }
            return `Warranty ${noun} Request`
        }
        return `Warranty ${noun}`
    }, [quantity])

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors({})

        try {
            if (isBulkAction) {
                const { data } = await Warranty.bulkCancel({
                    ids: isAllSelected ? [] : selected.map(item => item.id),
                    select_all: isAllSelected,
                    excluded_ids: excluded.map(({ id }) => id),
                    ...form,
                    ...query
                })
                dispatch(fetchNotifications)
                const toast = success(
                    `Bulk ${noun} is being processed. You can track the progress in the notifications center.`)
                toast.onClose = () => dispatch(showNotifications())
                if (props.onPingJob) {
                    props.onPingJob(data)
                }
            } else if (selectedWarranty) {
                if (auth?.user?.role.isAdminOrStaff ||
                    (!selectedWarranty.isLocked && !selectedWarranty.status.isCanceled)) {
                    await selectedWarranty.destroy(form)
                } else {
                    await selectedWarranty.submitCancellationRequest(form)
                }
            }
            props.onClose()
            setForm({
                reason: '',
                contact_email: ''
            })
            props.onChange()
        } catch (err) {
            if (!isApiError(err)) throw err
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }
    const handleChange = (e: FormChangeEvent) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    return <Popup
        open={isOpen}
        onClose={props.onClose}
        className="w-200"
    >
        <Form
            onSubmit={handleSubmit}
            className="flex flex-col gap-4 text-gray-900"
            noValidate
        >
            <h3 className="flex items-center gap-2 text-gray-900 text-lg font-semibold capitalize">
                <Icon className="stroke-error-600 w-6 h-6"/>
                {title}
            </h3>
            <span className="text-gray-500 mb-4">
                    Do you want to {action} {isBulkAction
                    ? <>{quantity.format()} warranties</>
                    : <><span className="font-semibold">Plan ID {selectedWarranty?.policy_num}</span> warranty</>}
            </span>

            {isBulkAction && <WarrantyIndexPreview
                excluded={excluded}
                selected={selected}
                filters={filters as WarrantyIndexFiltersType}
            />}

            {isSingleAction && selectedWarranty && auth.user?.role.isContractor && <>
                {!selectedWarranty.isLocked
                    ? !isAllInactive && <span className="text-gray-500 font-light">
                        Since your cancellation request is being
                        made <span className="font-medium">within 24 hours</span> of registration, we will process
                        it immediately. <br/>
                        Please note that if a cancellation request is
                        submitted <span className="font-medium">after 24 hours</span>, it will be reviewed by our
                        Solar Insure staff to ensure any necessary billing adjustments are made. Thank you for
                        choosing our warranty services.
                    </span>
                    : <span className="text-gray-500 font-light">
                        Please note <span className="font-medium">the reason for {noun}</span> below. A Solar
                        Insure representative will contact you shortly to review the request. If any adjustments to
                        billing are necessary, they will be reflected accordingly in your subsequent invoice.
                    </span>}
            </>}

            <ActionReasonForm
                form={form}
                errors={errors}
                onChange={handleChange}
                actionName={noun}
            />

            <div className="flex flex-col lg:flex-row gap-2 justify-center mt-2">
                <Button
                    hasError
                    className="whitespace-nowrap"
                    design="btn-secondary-gray"
                    onClick={() => props.onClose()}
                    type="button"
                    processing={processing}
                >
                    No, Exit without {verb}
                </Button>
                <Button
                    className="whitespace-nowrap capitalize"
                    processing={processing}
                >
                    Yes, {action} {isBulkAction ? quantity.format() : null} Warranties
                </Button>
            </div>
        </Form>
    </Popup>
}
