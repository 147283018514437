import { Button, IconAlertCircle, IconSearch } from '@/components'
import { IndexContainerFiltersType } from '@/types'

type IndexTablePlaceholderProps = {
    error: boolean
    filters: IndexContainerFiltersType
    dataName: string
}

export const IndexTablePlaceholder = ({
    error,
    filters,
    dataName
}: IndexTablePlaceholderProps) => {
    if (error) {
        return <>
            <div className="w-14 h-14 rounded-full bg-warning-50 flex justify-center items-center">
                <div className="w-10 h-10 rounded-full bg-warning-100 flex justify-center items-center">
                    <IconAlertCircle className="stroke-warning-600"/>
                </div>
            </div>

            <h4 className="mt-4 font-light">Something went wrong...</h4>
            <p className="text-sm text-gray-500 font-light mt-1 text-center">
                We had some trouble loading this page. Please refresh the page to try again or get in touch if the
                problem sticks around!
            </p>

            <Button className="mt-6" size="btn-lg" onClick={() => window.location.reload()}>
                Refresh page
            </Button>
        </>
    }

    if (Object.values(filters).some(item =>
        Array.isArray(item.value) ? item.value.some(Boolean) : item.value)) {
        return <>
            <div className="w-14 h-14 rounded-full bg-blue-50 flex justify-center items-center">
                <div className="w-10 h-10 rounded-full bg-blue-100 flex justify-center items-center">
                    <IconSearch className="stroke-blue-600"/>
                </div>
            </div>

            <h4 className="mt-4 font-light">No records found</h4>
            {filters.search.value
                ? <p className="text-sm text-gray-500 font-light mt-1 text-center">
                    <>Your search “{filters.search.value}” did not match any {dataName}. Please try again.</>
                </p>
                : <p className="text-sm text-gray-500 font-light mt-1 text-center">
                    The filters you applied did not match any records in the table. Please try adjusting your
                    filters and searching again.
                </p>}
        </>
    }
    return null
}
