import { Icon, IconProps } from './Icon'

export const IconArrowRight = (props: IconProps) =>
    <Icon {...props}>
        <path
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5 12h14m0 0-7-7m7 7-7 7"
        />
    </Icon>
