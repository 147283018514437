import { useEffect, useMemo, useState } from 'react'

import { SearchableSelect, PageHeader } from '@/components'
import { AuthLayout, IndexContainer } from '@/containers'
import { useAuth, useIndexContainer } from '@/hooks'
import { Company } from '@/models'
import {
    IndexContainerChangeEvent,
    ContractorIndexFiltersType,
    ContractorIndexDataType
} from '@/types'

export const CONTRACTOR_INDEX_FILTERS: ContractorIndexFiltersType = {
    search: {
        label: 'Search',
        defaultValue: '',
        value: '',
        isStandalone: true
    },
    contractor_id: {
        label: 'Contractor',
        defaultValue: '',
        value: '',
        isStandalone: true
    }
}
const ContractorsIndex = () => {
    const auth = useAuth()
    const {
        filters,
        handleFiltersChange
    } = useIndexContainer(CONTRACTOR_INDEX_FILTERS)
    const [contractors, setContractors] = useState<Company[]>([])

    const [data, setData] = useState<ContractorIndexDataType>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0
        },
        rows: []
    })

    const handleChange = (e: IndexContainerChangeEvent) => {
        setData(data => ({ ...data, [e.name]: e.value }))
    }

    const fetchAllContractors = async () => {
        const data = await Company.onlyContractors()
        setContractors(data)
    }

    useEffect(() => {
        fetchAllContractors()
    }, [])

    const rowsMemo = useMemo(() => data.rows.map(item => ({
        id: item.id,
        name: item.name,
        warranties_this_month: item.warranties_this_month.format(),
        warranties_lifetime: item.warranties_lifetime.format(),
        warranties_max_created_at: item.last_warranty_date ? new Date(item.last_warranty_date).format() : '-',
        warranties_sum_eligible_commission: item.lifetime_eligible_commission.money(),
        _path: `/contractors/${item.id}`
    })), [data.rows])

    return <AuthLayout heading={<PageHeader title="Contractors"/>}>
        <IndexContainer
            id="contractors"
            api={Company.contractors}
            data={data}
            onDataChange={handleChange}
            filters={filters}
            onFiltersChange={handleFiltersChange}
            tableOptions={{ dataName: 'Contractors' }}
            columns={[
                { title: 'Contractor Name', field: 'name' },
                { title: 'Warranties This Month', field: 'warranties_this_month' },
                { title: 'Lifetime Warranties', field: 'warranties_lifetime' },
                { title: 'Last Warranty Date', field: 'warranties_max_created_at' },
                { title: 'Lifetime Eligible Commission', field: 'warranties_sum_eligible_commission', showIf: auth.user?.company.type.isSoftwareAffiliate }
            ]}
            rows={rowsMemo}
            leftSide={<>
                <SearchableSelect
                    id="contractor-id"
                    name="contractor_id"
                    placeholder="Contractor"
                    onChange={handleFiltersChange}
                    value={filters.contractor_id.value}
                    options={contractors.map(item => ({ value: item.id, title: item.name }))}
                />
            </>}
        />
    </AuthLayout>
}

export default ContractorsIndex
