import { useParams } from 'react-router'

import { Button, Card, DeviceAlertsCard } from '@/components'
import { Device, Warranty } from '@/models'

type DevicesAlertsProps = {
    warranty: Warranty
}

const DevicesAlerts = ({ warranty }: DevicesAlertsProps) => {
    const { type } = useParams()

    return <div className="grid lg:grid-cols-3 gap-6 mb-6">
        <Card className="lg:col-span-3">
            <h2 className="card-title mb-6 font-semibold text-base text-gray-900 flex justify-between items-center">
                Device Alerts
                <Button className="leading-6 text-sm" design="btn-secondary" href={`/${type}/${warranty.id}/devices`}>
                    View More
                </Button>
            </h2>
            <div className="grid lg:grid-cols-3 gap-6">
                {warranty.homeowner?.system.erroredDevices
                    .map((item: Device) => <DeviceAlertsCard key={item.deviceRcpn} device={item}/>)}
            </div>
        </Card>
    </div>
}

export default DevicesAlerts
