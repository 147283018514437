import { Icon, IconProps } from './Icon'

export const IconFilter = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M6 12h12M3 6h18M9 18h6"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
