import { Icon, IconProps } from './Icon'

export const IconExpand01 = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M14 10L21 3M21 3H15M21 3V9M10 14L3 21M3 21H9M3 21L3 15"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
