import { useEffect, useMemo, useState } from 'react'

import { IconArrowLeft, IconArrowRight, Table } from '@/components'
import { useAppSelector } from '@/hooks'
import { WarrantyProductType } from '@/models'
import { api, isApiCancelError } from '@/services'
import { WarrantySalesItemDataType } from '@/types'

type SalesTrackingByMonthProps = {
    className?: string
    onChange: (key: string) => void
    processing: boolean
}

const SalesTrackingByMonth = ({ className, processing, ...props }: SalesTrackingByMonthProps) => {
    const auth = useAppSelector(({ auth }) => auth)
    const [data, setData] = useState<WarrantySalesItemDataType[]>([])
    const [abortController, setAbortController] = useState<AbortController | null>(null)

    const fetchData = async () => {
        try {
            const controller = new AbortController
            setAbortController(controller)
            const res = await api.dashboard.warrantySales({
                start_date: new Date().startOf('month').subtract('months', 11)
                    .toISODate(),
                end_date: new Date().endOf('month').toISODate(),
                period: 'month'
            }, { signal: controller.signal })

            setData(res.reverse())
        } catch (err) {
            if (!isApiCancelError(err)) {
                throw err
            }
        } finally {
            props.onChange('salesTrackingByMonth')
        }
    }

    useEffect(() => {
        if (processing) {
            fetchData()
        }
    }, [processing])

    useEffect(() =>
        () => {
            abortController?.abort('canceled')
        }, [abortController])

    const rows = useMemo(() => data.map(item => ({
        id: new Date(`${item.date} 00:00`).toISODate(),
        month: new Date(`${item.date} 00:00`).format({ day: null }),
        power_production_coverage_sum: item.power_production_coverage_sum.money(),
        storage_only_30_year_coverage_sum: item.storage_only_30_year_coverage_sum.money(),
        storage_30_year_coverage_sum: item.storage_30_year_coverage_sum.money(),
        storage_20_year_coverage_sum: item.storage_20_year_coverage_sum.money(),
        total_sum: item.total_sum.money(),
        total_count: item.total_count.format()
    })), [data])

    return <div className={`${className || ''}`}>
        <Table
            className="shadow-sm"
            processing={processing}
            rows={rows}
            data-test="dashboard-sales-tracking-table"
            options={{
                useQueryParams: false,
                sortable: false,
                defaultPerPage: auth.user?.role.isContractor ? 5 : 6,
                showPerPage: !auth.user?.role.isContractor,
                nextButtonText: <IconArrowRight/>,
                prevButtonText: <IconArrowLeft/>
            }}
            columns={[
                {
                    field: 'month',
                    title: 'Month',
                    cellClassName: '!py-4.5'
                },
                ...WarrantyProductType.all.map(item => ({
                    field: `${item.coverageKey}_sum`,
                    title: item.coverageIcon('sm', true),
                    cellClassName: '!py-4.5'
                })),
                {
                    field: 'total_sum',
                    title: 'Total Charges',
                    cellClassName: '!py-4.5'
                },
                {
                    field: 'total_count',
                    title: 'Warranties',
                    cellClassName: '!py-4.5'
                }
            ]}
        />
    </div>
}

export default SalesTrackingByMonth
