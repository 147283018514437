import { useMemo, useState } from 'react'

import { Button, ContactsForm, ContactsFormDataType, Form, FormErrorsType } from '@/components'
import { useToastNotifications } from '@/hooks'
import { Company } from '@/models'
import { isApiError } from '@/services'
import { FormChangeEvent } from '@/types'

type UsersEditFormProps = {
    company: Company,
    onSuccess?: () => void
    onExit: () => void
}

const UsersEditForm = ({ company, ...props }: UsersEditFormProps) => {
    const { success } = useToastNotifications()
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState<FormErrorsType>({})
    const defaultForm = useMemo<ContactsFormDataType>(() => ({
        contacts: company.users.map(item => ({
            id: item.id,
            role: item.role.key,
            company_id: company.id,
            name: item.name,
            email: item.email,
            job_title: item.job_title,
            phone: item.phone
        }))
    }), [company])
    const [form, setForm] = useState<ContactsFormDataType>(
        { contacts: defaultForm.contacts.map(item => ({ ...item })) })

    const handleChange = (e: FormChangeEvent) => {
        setForm({ ...form, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value })
    }

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors({})
        try {
            await company.updateContacts(form)
            if (props.onSuccess) await props.onSuccess()
            success('Users successfully created/updated.')
            props.onExit()
        } catch (err) {
            if (!isApiError(err)) throw err
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }
    const isDirty = useMemo(() => form.contacts.some((item, index: number) => !defaultForm.contacts[index] ||
        item.name.capitalize() !== defaultForm.contacts[index].name ||
        item.email !== defaultForm.contacts[index].email),
    [form, company])

    return <Form isDirty={isDirty} confirmation className="grid lg:grid-cols-2 gap-6" noValidate onSubmit={handleSubmit}>
        <ContactsForm
            form={form}
            errors={errors}
            onChange={handleChange}
            company={company}
        />

        <div className="lg:col-span-2 flex flex-col gap-3 md:flex-row justify-end">
            <Button design="btn-secondary-gray" hasError onClick={props.onExit} type="button">
                Exit Edit Mode Without Saving
            </Button>
            <Button processing={processing} disabled={!isDirty}>
                Save and Exit
            </Button>
        </div>
    </Form>
}

export default UsersEditForm
