import { Icon, IconProps } from './Icon'

export const IconArrowLeft = (props: IconProps) =>
    <Icon {...props}>
        <path
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 12H5M5 12L12 19M5 12L12 5"
        />
    </Icon>
