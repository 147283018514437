import { ChartDataset } from 'chart.js'
import { useMemo } from 'react'

import { BarChart } from '@/components'
import { WarrantyProductTypeEnum } from '@/enums'
import { WarrantyProductType } from '@/models'
import { WarrantySalesItemDataType } from '@/types'


type InstallsGraphProps = {
    labels: string[]
    data: WarrantySalesItemDataType[]
}

export const InstallsGraph = ({
    labels,
    data
}: InstallsGraphProps) => {
    const colors: Record<WarrantyProductTypeEnum, string> = {
        power_production_only: '#ffd8a2',
        separate_30_year_storage_only: '#8bd1e6',
        production_with_30_year_separate_storage: '#b2e89a',
        production_with_20_year_separate_storage: '#e478fa'
    }
    const datasets = useMemo((): ChartDataset[] => WarrantyProductType.all.map(item => ({
        label: item.title,
        data: data.map(dataItem => dataItem[`${item.key}_count`]),
        backgroundColor: colors[item.key],
        borderColor: colors[item.key],
        tension: 0.25,
        fill: true,
        maxBarThickness: 50
    })), [data])
    return <BarChart
        datasets={datasets}
        labels={labels}
        customTooltip={index => {
            const item = data[index]
            return <div className="px-4 py-2 font-body bg-black text-white shadow-md rounded-md text-xs">
                <div className="hidden md:block absolute w-2 h-2 -left-1 top-1/2 -translate-y-1/2 bg-black rotate-45 origin-center"/>
                <ul className="flex flex-col gap-2">
                    <li>{labels[index]}</li>
                    {WarrantyProductType.all.reverse().map(item => <li key={item.key} className="flex items-center gap-3">
                        {item.brandIcon()}
                        {(data[index][`${item.key}_count`] as number).format()}
                    </li>)}
                    <li>Total: {item.total_count.format()} Installs</li>
                </ul>
            </div>
        }}
        options={{
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                y: {
                    beginAtZero: true,
                    stacked: true,
                    grid: { color: '#f5f4f1' },
                    border: { display: false }
                },
                x: {
                    stacked: true,
                    grid: { display: false },
                    border: { display: false }
                }
            },
            elements: {
                point: {
                    radius: 0,
                    hoverRadius: 0
                }
            },
            interaction: {
                intersect: false,
                mode: 'index'
            }
        }}
    />
}
