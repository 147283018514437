import { useEffect, useState } from 'react'

import ReportBox from './ReportBox'
import { IconCertificate, IconCurrencyDollar } from '@/components'
import { WarrantyProductType } from '@/models'
import { api, isApiCancelError } from '@/services'
import { WarrantySalesItemDataType } from '@/types'

type ReportBoxesProps = {
    className?: string
    onChange: (name: string) => void
    processing: boolean
}

const ReportBoxes = ({ className, processing, ...props }: ReportBoxesProps) => {
    const [monthData, setMonthData] = useState<Partial<WarrantySalesItemDataType>>({
        power_production_only_count: 0,
        separate_30_year_storage_only_count: 0,
        production_with_20_year_separate_storage_count: 0,
        production_with_30_year_separate_storage_count: 0,
        total_count: 0
    })
    const [yearData, setYearData] = useState<Partial<WarrantySalesItemDataType>>({
        power_production_only_count: 0,
        separate_30_year_storage_only_count: 0,
        production_with_20_year_separate_storage_count: 0,
        production_with_30_year_separate_storage_count: 0,
        total_count: 0,

        power_production_coverage_sum: 0,
        storage_only_30_year_coverage_sum: 0,
        storage_20_year_coverage_sum: 0,
        storage_30_year_coverage_sum: 0,
        total_sum: 0
    })

    const [abortController, setAbortController] = useState<AbortController | null>(null)

    useEffect(() => () => {
        abortController?.abort('canceled')
    }, [abortController])

    const fetchMonthData = async (controller: AbortController) => {
        const [curMonth] = await api.dashboard.warrantySales({
            start_date: new Date().startOf('month').toISODate(),
            end_date: new Date().endOf('month').toISODate(),
            period: 'month'
        }, { signal: controller.signal })

        setMonthData(curMonth)
    }
    const fetchYearData = async (controller: AbortController) => {
        const [curYear] = await api.dashboard.warrantySales({
            start_date: new Date().startOf('year').toISODate(),
            end_date: new Date().endOf('year').toISODate(),
            period: 'year'
        }, { signal: controller.signal })
        setYearData(curYear)
    }

    const fetchData = async () => {
        try {
            const controller = new AbortController
            await setAbortController(controller)
            await Promise.all([
                fetchMonthData(controller),
                fetchYearData(controller)
            ])
        } catch (err) {
            if (!isApiCancelError(err)) {
                throw err
            }
        } finally {
            props.onChange('reportBoxes')
        }
    }

    useEffect(() => {
        if (processing) {
            fetchData()
        }
    }, [processing])

    const boxes = [
        {
            key: 'installs-this-month',
            icon: <IconCertificate size="lg"/>,
            title: 'Installs This Month',
            className: 'col-span-12 lg:col-span-6 xl:col-span-4',
            value: <div data-test="dashboard-report-box-installs-this-month-value">
                {monthData.total_count?.format()}
            </div>,
            titleClass: 'font-semibold font-brand',
            rows: WarrantyProductType.all.map(item => ({
                key: item.key,
                value: monthData[`${item.key}_count`]?.format(),
                title: item.title,
                icon: item.brandIcon('md', true)
            }))
        },
        {
            key: 'installs-this-year',
            icon: <IconCertificate size="lg"/>,
            title: 'Installs This Year',
            className: 'col-span-12 lg:col-span-6 xl:col-span-4',
            value: <div data-test="dashboard-report-box-installs-this-year-value">
                {yearData.total_count?.format()}
            </div>,
            titleClass: 'font-semibold font-brand',
            rows: WarrantyProductType.all.map(item => ({
                key: item.key,
                value: yearData[`${item.key}_count`]?.format(),
                title: item.title,
                icon: item.brandIcon('md', true)
            }))
        },
        {
            key: 'sales-this-year',
            icon: <IconCurrencyDollar size="lg"/>,
            title: 'Sales This Year',
            className: 'col-span-12 xl:col-span-8 grid md:grid-cols-2',
            value: <div className="lg:w-full" data-test="dashboard-report-box-sales-this-year-value">
                {yearData.total_sum?.money()}
            </div>,
            rows: WarrantyProductType.all.map(item => ({
                key: item.key,
                value: yearData[`${item.coverageKey}_sum`]?.money(),
                title: item.coverageTitle,
                icon: item.coverageIcon('sm', true)
            }))
        }
    ]

    return <>
        {boxes.map(item =>
            <ReportBox
                key={item.key}
                className={`${className} ${item.className}`}
                icon={item.icon}
                title={item.title}
                value={item.value}
                data-test={`dashboard-report-box-${item.key}`}
            >
                <div className="flex flex-col gap-1 text-sm leading-6 w-full">
                    {item.rows.map(row => <div key={row.key as string} className="flex gap-2 justify-start">
                        <div className="flex items-center">{row.icon}</div>
                        <div className="flex justify-between w-full" data-test={`dashboard-report-box-${item.key}-${row.key}-data`}>
                            <span
                                className={`${item.titleClass}`}
                                data-test={`dashboard-report-box-${item.key}-${row.key}-title`}
                            >{row.title}</span>
                        </div>
                        <div
                            className="font-semibold"
                            data-test={`dashboard-report-box-${item.key}-${row.key}-value`}
                        >{row.value}</div>
                    </div>)}
                </div>
            </ReportBox>)}
    </>
}

export default ReportBoxes
