import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import '@/utils'
import '@/assets/css/index.css'

import App from '@/App'
import store from '@/store'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
    .render(
        <Provider store={store}>
            <App/>
        </Provider>
    )
