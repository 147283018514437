import { Link } from 'react-router'

import {
    Badge, IconBarLineChart,
    IconCopy04, IconDataFlow,
    IconEdit05,
    IconFlipBackward,
    IconInfoCircle, IconPasscodeLock, IconStopCircle,
    IconTarget04,
    IconUpload01,
    IconX
} from '@/components'
import { WarrantyStatusEnum } from '@/enums'
import { Company } from '@/models'
import { toastNotifications } from '@/services'
import { JobsBatchType } from '@/types'
import {
    NotificationTypeDataType,
    NotificationMessageDataType
} from '@/types/NotificationTypeDataType'


export enum NotificationTypeEnum {
    WARRANTY_DUPLICATE_DISCOVERED = 'warranty_duplicate_discovered',
    WARRANTY_DUPLICATE_RESOLVED = 'warranty_duplicate_resolved',

    WARRANTY_EDITED = 'warranty_edited',
    WARRANTY_EDIT_REQUESTED = 'warranty_edit_requested',
    WARRANTY_EDIT_REQUEST_APPROVED = 'warranty_edit_request_approved',
    WARRANTY_EDIT_REQUEST_DENIED = 'warranty_edit_request_denied',
    WARRANTY_EDIT_REQUEST_DELETED = 'warranty_edit_request_deleted',
    WARRANTY_EDIT_REQUEST_MODIFIED_AND_APPROVED = 'warranty_edit_request_modified_and_approved',

    WARRANTY_CANCELLED_OR_REINSTATED = 'warranty_cancelled_or_reinstated',
    WARRANTY_CANCEL_OR_REINSTATE_REQUESTED = 'warranty_cancel_or_reinstate_requested',
    WARRANTY_CANCEL_OR_REINSTATE_REQUEST_APPROVED = 'warranty_cancel_or_reinstate_request_approved',
    WARRANTY_CANCEL_OR_REINSTATE_REQUEST_DENIED = 'warranty_cancel_or_reinstate_request_denied',
    WARRANTY_CANCEL_REQUEST_DELETED = 'warranty_cancel_request_deleted',
    WARRANTY_REINSTATEMENT_REQUEST_DELETED = 'warranty_reinstatement_request_deleted',
    WARRANTIES_BULK_CANCELLATION_OR_REINSTATEMENT = 'warranties_bulk_cancellation_or_reinstatement',

    WARRANTIES_BULK_UPLOAD = 'warranties_bulk_upload',
    WARRANTIES_UPLOAD_STATUS = 'warranties_upload_status',
    CONGRATS_MORE_WARRANTIES = 'congrats_more_warranties',
    CONGRATS_MORE_CONTRACTORS_WARRANTIES = 'congrats_more_contractors_warranties',
    NEW_AFFILIATE_WARRANTIES = 'new_affiliate_warranties',
    NEW_COMPANY_AFFILIATE_WARRANTIES = 'new_company_affiliate_warranties',

    SYSTEM_UPLOAD_FAILED = 'system_upload_failed',

    MONITORING_API_THROTTLED = 'monitoring_api_throttled',
    INACTIVITY_WARNING = 'inactivity_warning',
    INVALID_MONITORING_CREDENTIALS_DISCOVERED = 'invalid_monitoring_credentials_discovered',

    DEFAULT = 'default'
}

export const NOTIFICATION_TYPES: NotificationTypeDataType[] = [
    {
        key: NotificationTypeEnum.WARRANTY_DUPLICATE_DISCOVERED,
        icon: <IconCopy04/>,
        message: ({ warranty, message }: NotificationMessageDataType) => warranty ? <>
            A duplicate entry was discovered for <Link className="text-primary-700" to={`/warranties/${warranty.id}/warranty`}>{warranty.homeowner?.fullName}</Link>
        </> : message
    },
    {
        key: NotificationTypeEnum.WARRANTY_DUPLICATE_RESOLVED,
        icon: <IconEdit05/>,
        message: ({ warranty, message }: NotificationMessageDataType) => warranty ? <>
            A duplicate entry was resolved for <Link className="text-primary-700" to={`/warranties/${warranty.id}/warranty`}>{warranty.homeowner?.fullName}</Link>
        </> : message
    },

    {
        key: NotificationTypeEnum.WARRANTY_EDITED,
        icon: <IconEdit05/>,
        message: ({ warranty, message }: NotificationMessageDataType) => warranty ? <>
            Warranty Edited for <Link className="text-primary-700" to={`/warranties/${warranty.id}/warranty`}>{warranty.homeowner?.fullName}</Link>
        </> : message
    },
    {
        key: NotificationTypeEnum.WARRANTY_EDIT_REQUESTED,
        icon: <IconEdit05/>,
        message: ({ warranty, message }: NotificationMessageDataType) => warranty ? <>
            <span className="font-semibold">{warranty.company?.name}</span> requested a Warranty Edit for <Link className="text-primary-700" to={`/warranties/${warranty.id}/warranty`}>{warranty.homeowner?.fullName}</Link>
        </> : message
    },
    {
        key: NotificationTypeEnum.WARRANTY_EDIT_REQUEST_APPROVED,
        icon: <IconEdit05/>,
        message: ({ warranty, message }: NotificationMessageDataType) => warranty ? <>
            Warranty Edit Request Approved for <Link className="text-primary-700" to={`/warranties/${warranty.id}/warranty`}>{warranty.homeowner?.fullName}</Link>
        </> : message
    },
    {
        key: NotificationTypeEnum.WARRANTY_EDIT_REQUEST_DENIED,
        icon: <IconEdit05/>,
        message: ({ warranty, message }: NotificationMessageDataType) => warranty ? <>
            Warranty Edit Denied for <Link className="text-primary-700" to={`/warranties/${warranty.id}/warranty`}>{warranty.homeowner?.fullName}</Link>
        </> : message
    },
    {
        key: NotificationTypeEnum.WARRANTY_EDIT_REQUEST_DELETED,
        icon: <IconInfoCircle/>,
        message: ({ warranty, message }: NotificationMessageDataType) => warranty ? <>
            Warranty Edit Request was deleted for <Link className="text-primary-700" to={`/warranties/${warranty.id}/warranty`}>{warranty.homeowner?.fullName}</Link>
        </> : message
    },
    {
        key: NotificationTypeEnum.WARRANTY_EDIT_REQUEST_MODIFIED_AND_APPROVED,
        icon: <IconEdit05/>,
        message: ({ warranty, message }: NotificationMessageDataType) => warranty ? <>
            Edit Request Modified & Approved for <Link className="text-primary-700" to={`/warranties/${warranty.id}/warranty`}>{warranty.homeowner?.fullName}</Link>
        </> : message
    },

    {
        key: NotificationTypeEnum.WARRANTY_CANCELLED_OR_REINSTATED,
        icon: ({ warranty }: NotificationMessageDataType) => warranty?.deleted_at ? <IconX/> : <IconFlipBackward/>,
        message: ({ warranty, message }: NotificationMessageDataType) => {
            if (!warranty) return message

            let text = warranty.deleted_at ? 'Warranty Cancelled for ' : 'Warranty Reinstated for '
            if (warranty.cancellation_requested_at) {
                text = warranty.deleted_at ? 'Warranty Cancellation Request Approved for ' : 'Warranty Reinstate Request Approved for '
            }
            return <>
                {text}
                <Link className="text-primary-700" to={`/warranties/${warranty.id}/warranty`}>{warranty.homeowner?.fullName}</Link>
            </>
        }
    },
    {
        key: NotificationTypeEnum.WARRANTY_CANCEL_OR_REINSTATE_REQUESTED,
        icon: ({ warranty }: NotificationMessageDataType) => warranty?.deleted_at ? <IconFlipBackward/> : <IconX/>,
        message: ({ warranty, message }: NotificationMessageDataType) => {
            if (!warranty) return message

            const text = warranty.deleted_at
                ? <>
                    <span className="font-semibold">{warranty.company?.name}</span> requested to Reinstate a Warranty for </>
                : <>
                    <span className="font-semibold">{warranty.company?.name}</span> requested a Warranty Cancellation for </>
            return <>
                {text}
                <Link className="text-primary-700" to={`/warranties/${warranty.id}/warranty`}>{warranty.homeowner?.fullName}</Link>
            </>
        }
    },
    {
        key: NotificationTypeEnum.WARRANTY_CANCEL_OR_REINSTATE_REQUEST_APPROVED,
        icon: <IconEdit05/>,
        message: ({ warranty, message }: NotificationMessageDataType) => warranty ? <>
            Warranty {warranty.deleted_at ? 'Cancellation' : 'Reinstatement'} Request Approved for <Link className="text-primary-700" to={`/warranties/${warranty.id}/warranty`}>{warranty.homeowner?.fullName}</Link>
        </> : message
    },
    {
        key: NotificationTypeEnum.WARRANTY_CANCEL_OR_REINSTATE_REQUEST_DENIED,
        icon: <IconEdit05/>,
        message: ({ warranty, message }: NotificationMessageDataType) => warranty ? <>
            Warranty {warranty.deleted_at ? 'Reinstatement' : 'Cancellation'} Request Denied for <Link className="text-primary-700" to={`/warranties/${warranty.id}/warranty`}>{warranty.homeowner?.fullName}</Link>
        </> : message
    },
    {
        key: NotificationTypeEnum.WARRANTY_CANCEL_REQUEST_DELETED,
        icon: <IconInfoCircle/>,
        message: ({ warranty, message }: NotificationMessageDataType) => warranty ? <>
            Warranty {warranty.deleted_at ? 'Reinstatement' : 'Cancellation'} Request was deleted for <Link className="text-primary-700" to={`/warranties/${warranty.id}/warranty`}>{warranty.homeowner?.fullName}</Link>
        </> : message
    },
    {
        key: NotificationTypeEnum.WARRANTY_REINSTATEMENT_REQUEST_DELETED,
        icon: <IconInfoCircle/>,
        message: ({ warranty, message }: NotificationMessageDataType) => warranty ? <>
            Warranty Reinstatement Request was deleted for <Link className="text-primary-700" to={`/warranties/${warranty.id}/warranty`}>{warranty.homeowner?.fullName}</Link>
        </> : message
    },
    {
        key: NotificationTypeEnum.WARRANTIES_BULK_CANCELLATION_OR_REINSTATEMENT,
        icon: <IconEdit05/>,
        showTimestamp: false,
        message: ({
            'jobs-batch': jobsBatch,
            action = WarrantyStatusEnum.CANCELLATION_REQUESTED
        }: NotificationMessageDataType) => {
            const jobs = jobsBatch as JobsBatchType
            return <>
                <Link className="text-primary-700" to={`/warranties${action === WarrantyStatusEnum.CANCELLATION_REQUESTED ? '?statuses[0]=canceled' : ''}`}>{jobs.processed_jobs} out of {jobs.total_jobs} warranties</Link> are being {action === WarrantyStatusEnum.CANCELLATION_REQUESTED ? 'canceled' : 'reinstated'}.
                <div className="flex items-center gap-3 text-gray-700">
                    <div className="bg-gray-200 rounded grow h-2">
                        <div className="bg-primary-600 rounded h-full max-w-full" style={{ width: `${jobs.percentage}%` }}></div>
                    </div>
                    {jobs.percentage}%
                </div>
            </>
        },
        onComplete({ action = WarrantyStatusEnum.CANCELLATION_REQUESTED }: NotificationMessageDataType) {
            toastNotifications.success(
                `Bulk ${action === WarrantyStatusEnum.CANCELLATION_REQUESTED ? 'cancellation' : 'reinstatement'} has been completed.`)
        }
    },

    {
        key: NotificationTypeEnum.WARRANTIES_BULK_UPLOAD,
        icon: <IconUpload01/>,
        showTimestamp: false,
        message: ({ company, 'jobs-batch': jobsBatch }: NotificationMessageDataType) => {
            const jobs = jobsBatch as JobsBatchType
            return <>
                <Link className="text-primary-700" to="/warranties">{jobs.processed_jobs} out of {jobs.total_jobs} warranties</Link> from <span className="font-semibold">{(company as Company).name}</span> are upload{jobs.processed_jobs === jobs.total_jobs ? 'ed' : 'ing'}.
                <div className="flex items-center gap-3 text-gray-700">
                    <div className="bg-gray-200 rounded grow h-2">
                        <div className="bg-primary-600 rounded h-full" style={{ width: `${jobs.percentage}%` }}></div>
                    </div>
                    {jobs.percentage}%
                </div>
            </>
        },
        onComplete() {
            toastNotifications.success('Bulk upload has been completed.')
        }
    },
    {
        key: NotificationTypeEnum.WARRANTIES_UPLOAD_STATUS,
        icon: <IconUpload01/>,
        message: ({ status, message, company_id: companyId }: NotificationMessageDataType) => {
            if (!status) return message

            let statusBadge = <Badge className="bg-blue-100">Queued</Badge>
            if (status === 'In Progress') statusBadge = <Badge className="bg-purple-100">In Progress</Badge>
            if (status === 'Completed') statusBadge = <Badge className="bg-green-100">Completed</Badge>
            return <>
                {statusBadge} Upload status {status === 'Completed' &&
                <Link className="text-primary-700" to={`/warranties${companyId ? `?company_id=${companyId}` : ''}`}>View Records</Link>}
            </>
        }
    },
    {
        key: NotificationTypeEnum.CONGRATS_MORE_WARRANTIES,
        icon: <IconTarget04/>,
        message: () => <>
            <span className="font-semibold">Congrats!</span> You have registered more warranties this month than last month. Keep up the great work!
        </>
    },
    {
        key: NotificationTypeEnum.CONGRATS_MORE_CONTRACTORS_WARRANTIES,
        icon: <IconTarget04/>,
        message: () => <>
            <span className="font-semibold">Congrats!</span> Your contractors have registered more warranties this month than last month. Keep up the great work!
        </>
    },
    {
        key: NotificationTypeEnum.NEW_AFFILIATE_WARRANTIES,
        icon: <IconDataFlow/>,
        message: ({
            affiliate,
            warranties_count: warrantiesCount
        }: NotificationMessageDataType) =>
            <>
                <Link className="text-primary-700" to={`/warranties?company_id=${(affiliate as Company).id}`}>{warrantiesCount} New {'Warranty'.pluralize(warrantiesCount as number)}</Link> {'was'.pluralize(warrantiesCount as number)} associated with <span className="font-semibold">{(affiliate as Company).name}.</span>
            </>
    },
    {
        key: NotificationTypeEnum.NEW_COMPANY_AFFILIATE_WARRANTIES,
        icon: <IconDataFlow/>,
        message: ({
            company,
            affiliate,
            warranties_count: warrantiesCount
        }: NotificationMessageDataType) => <>
            <Link className="text-primary-700" to={`/warranties?company_id=${(affiliate as Company).id}`}>{warrantiesCount} New {'Warranty'.pluralize(warrantiesCount as number)}</Link> from <span className="font-semibold">{(company as Company).name}</span> {'was'.pluralize(warrantiesCount as number)} associated with <span className="font-semibold">{(affiliate as Company).name}.</span>
        </>
    },

    {
        key: NotificationTypeEnum.SYSTEM_UPLOAD_FAILED,
        icon: <IconUpload01/>,
        message: () => <>
            <Badge className="bg-error-100">Failed</Badge> Upload status
        </>
    },

    {
        key: NotificationTypeEnum.MONITORING_API_THROTTLED,
        icon: <IconBarLineChart/>,
        message: () => <>
            <span className="font-semibold">Monitoring API throttled.</span> We{'\''}re resolving the issue, but data updates may be delayed.
        </>
    },
    {
        key: NotificationTypeEnum.INACTIVITY_WARNING,
        icon: <IconStopCircle/>,
        message: ({ company }: NotificationMessageDataType) => <>
            <Badge className="bg-warning-100">Inactivity Warning</Badge>
            <span className="font-semibold">{(company as Company).name}</span> has not registered any warranties in the last 45 days.
        </>
    },
    {
        key: NotificationTypeEnum.INVALID_MONITORING_CREDENTIALS_DISCOVERED,
        icon: <IconPasscodeLock/>,
        message: ({ company }: NotificationMessageDataType) => <>
            Invalid Monitoring Credentials Discovered for <Link className="text-primary-700" to={`/companies/${(company as Company).id}`}>{(company as Company).name}</Link>
        </>
    },

    {
        key: NotificationTypeEnum.DEFAULT,
        icon: <IconInfoCircle/>,
        message: (data: string | NotificationMessageDataType) => {
            if (typeof data === 'string') return data
            if (data.message) {
                if (data.action) {
                    return <Link className="text-primary-700" to={data.action}>{data.message}</Link>
                }
                return data.message
            }
            return data.toString()
        }
    }
]
