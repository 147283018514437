import { useEffect, useMemo, useState } from 'react'
import { Link, useLoaderData, useParams } from 'react-router'

import {
    Button, Card, IconBatteryCharging,
    IconCertificate,
    IconDownload,
    IconEdit,
    IconHome, IconMail,
    IconX,
    Table,
    IconPin, IconGlobe06,
    IconSun, IconBuilding07, IconHelpCircle,
    Tooltip, Feature, Select, CompanyHeader, CompanyIntegrationsSection, Badge, CompanyAffiliateChangePrompt,
    IconCurrencyDollar,
    IconCalendarCheck
} from '@/components'
import { AuthLayout } from '@/containers'
import { BatteryEligibilityTypeEnum } from '@/enums'
import { useAuth } from '@/hooks'
import { Company, User, CompanyBatteryEligibility, WarrantyProductType, WarrantyMatchMethod } from '@/models'
import { feature, sessionStorage } from '@/services'
import { FormChangeEvent, JsonDataType, IdType } from '@/types'

interface CompanyUpdateFormData extends JsonDataType {
    warranty_match_method?: string
    conglomerate_id?: IdType
    update_policy?: string
    battery_eligibility?: BatteryEligibilityTypeEnum
}

const CompaniesShow = () => {
    const { affiliateId, id } = useParams()
    const auth = useAuth()
    const data = useLoaderData() as Company

    const [company, setCompany] = useState<Company>(data)
    const [affiliates, setAffiliates] = useState<Company[]>([])
    const [form, setForm] = useState<CompanyUpdateFormData>({
        warranty_match_method: company.warranty_match_method.key || '',
        conglomerate_id: company.conglomerate?.id || '',
        update_policy: '',
        battery_eligibility: company.battery_eligibility.key
    })

    const breadcrumb = useMemo(() => {
        if (affiliateId) {
            return [
                <IconBuilding07 key={1} className="stroke-gray-500"/>,
                <Link key={2} to="/companies">Companies</Link>,
                <Link key={2} to={`/companies/${affiliateId}`}>Company Details</Link>,
                'Subcontractor Details'
            ]
        }
        return [
            <IconBuilding07 key={1} className="stroke-gray-500"/>,
            <Link key={2} to="/companies">Companies</Link>,
            'Company Details'
        ]
    }, [affiliateId, id])

    const fetchCompany = async () => {
        const res = await Company.show(id as string) as Company
        res.activity = await res.activities()
        setCompany(res)
    }

    const fetchAffiliates = async () => {
        const data = await Company.onlyAffiliates()
        setAffiliates(data)
    }

    useEffect(() => {
        if (auth.user?.role.isAdminOrStaff) {
            fetchAffiliates()
        }
        setCompany(data)
        setForm({
            warranty_match_method: data.warranty_match_method.key || '',
            conglomerate_id: data.conglomerate?.id || '',
            update_policy: '',
            battery_eligibility: company.battery_eligibility.key
        })
    }, [id])

    const getShowCompanyCompanyAffiliateChangePrompt = () =>
        company.conglomerate?.id !== form.conglomerate_id &&
        !!affiliates.find(({ id }) => id === form.conglomerate_id)?.warranty_match_method.isFullSubcontractorMatch &&
        !form.update_policy

    const handleCompanyUpdate = async (payload: CompanyUpdateFormData) => {
        await company.updateSingleField(payload)
        fetchCompany()
    }

    const handleChange = (e: FormChangeEvent) => {
        setForm(form => {
            const data = { [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value }
            if (e.target.name === 'conglomerate_id') {
                const newAffiliate = affiliates.find(({ id }) => id?.toString() === e.target.value?.toString())
                if (!newAffiliate?.warranty_match_method.isFullSubcontractorMatch) {
                    handleCompanyUpdate({ ...data, update_policy: 'future_only' })
                }
            } else {
                if (e.target.name === 'update_policy') {
                    data.conglomerate_id = form.conglomerate_id
                }
                handleCompanyUpdate(data)
            }
            return {
                ...form,
                ...data,
                ...e.target.name === 'conglomerate_id' ? { update_policy: '' } : {}
            }
        })
    }

    return <AuthLayout
        breadcrumb={breadcrumb}
        heading={<CompanyHeader
            company={company}
            backUrl={(affiliateId ? `/companies/${affiliateId}` : '/companies') + sessionStorage.get('preservedQuery.companies')}
        />}
    >
        {auth.user?.role.isAdminOrStaffOrAdvisor && <div className="flex gap-2 justify-between lg:items-end flex-col lg:flex-row mb-8">
            <div className="flex gap-3 flex-wrap">
                <Feature name="onboarding">
                    <Select
                        name="create-warranties"
                        id="create-warranties"
                        label="Add New Warranties"
                        className="grow lg:grow-0"
                        options={[
                            { value: '', title: 'Disabled' }
                        ]}
                        value=""
                        input-size="sm"
                        onChange={() => {}}
                    />
                </Feature>
                {company.type.isAffiliate &&
                    <Select
                        name="warranty_match_method"
                        id="warranty-match-methods"
                        label="Warranty Match Method"
                        className="grow lg:grow-0"
                        options={WarrantyMatchMethod.all.map(item => ({
                            value: item.key,
                            title: item.title
                        }))}
                        value={form.warranty_match_method}
                        input-size="sm"
                        onChange={handleChange}
                    />}
                {company.type.isContractor && <>
                    <Select
                        name="conglomerate_id"
                        id="conglomerate-id"
                        label="Affiliate"
                        className="grow lg:grow-0"
                        options={[
                            { value: '', title: 'None' },
                            ...affiliates.map(item => ({
                                value: item.id,
                                title: item.name
                            }))
                        ]}
                        value={form.conglomerate_id}
                        input-size="sm"
                        onChange={handleChange}
                        disabled={!auth.user?.role.isAdminOrStaff}
                    />
                    <CompanyAffiliateChangePrompt
                        open={getShowCompanyCompanyAffiliateChangePrompt()}
                        onChange={handleChange}
                        company={company}
                    />
                </>}
                <Select
                    name="battery_eligibility"
                    id="battery-eligibility-filter"
                    label="Battery Product Eligibility"
                    className="grow lg:grow-0"
                    disabled={!feature('battery-eligibility')}
                    showChevron={feature('battery-eligibility')}
                    options={[
                        { value: '', title: 'Any Battery Eligibility' },
                        ...CompanyBatteryEligibility.all.map(item => ({
                            value: item.key,
                            title: <div className="flex gap-2 items-center h-5">
                                {item.icon()} {item.title}
                            </div>
                        }))
                    ]}
                    value={form.battery_eligibility}
                    onChange={handleChange}
                    input-size="sm"
                />
            </div>

            <div className="flex justify-end gap-3">
                <Button
                    square
                    disabled={!auth.user?.role.isAdminOrStaff}
                    href={affiliateId ? `/companies/${affiliateId}/subsidiaries/${id}/edit` : `/companies/${id}/edit`}
                    design="btn-secondary-gray"
                    tooltip="Edit Company"
                >
                    <IconEdit/>
                </Button>
                <Feature name="company-verification">
                    <Button
                        square
                        design="btn-secondary-gray"
                        tooltip="Mark as Not Verified"
                    >
                        <IconX/>
                    </Button>
                </Feature>
                <Button
                    square
                    design="btn-secondary-gray"
                    onClick={() => company.downloadSystemData()}
                    tooltip="Download System Data"
                >
                    <IconDownload/>
                </Button>
            </div>
        </div>}

        <div className="grid grid-cols-1 font-light gap-6 mb-6 xl:grid-cols-4">
            <Card className="lg:col-span-2">
                <h2 className="text-lg font-normal mb-4">Company Info</h2>
                <div className="flex flex-col lg:flex-row text-gray-500 text-sm">
                    <div className="lg:w-1/2 flex flex-col items-start">
                        <Tooltip content="Company Name" className="flex mb-4">
                            <IconBuilding07 className="stroke-gray-500 mr-2"/>
                            {company.name}
                        </Tooltip>
                        <Tooltip content="Salesforce Unique ID" className="flex mb-4">
                            <div className="text-gray-500 font-semibold mr-2">ID</div>
                            {company.unique_id || '-'}
                        </Tooltip>

                        {company.type.isSoftwareAffiliate && <Tooltip
                            content={Company.eligibleCommissionTooltipContent}
                            interactive
                            hideOnClick
                        >
                            <>
                                <div className="flex mb-4">
                                    <IconSun className="stroke-gray-500 mr-2"/>
                                    {company.currentEligibleCommission?.solar_commission_rate?.format({ minimumFractionDigits: 4 }) || '-'}
                                </div>
                                <div className="flex mb-4">
                                    <IconBatteryCharging className="stroke-gray-500 mr-2"/>
                                    {company.currentEligibleCommission?.battery_commission_rate?.format({ minimumFractionDigits: 4 }) || '-'}
                                </div>
                            </>
                        </Tooltip>}
                    </div>
                    <div className="lg:w-1/2 flex flex-col items-start">
                        <Tooltip content="Email" className="flex mb-4">
                            <IconMail className="stroke-gray-500 mr-2"/>
                            {company.email || '-'}
                        </Tooltip>

                        <Tooltip content="Address" className="flex mb-4">
                            <IconPin className="stroke-gray-500 mr-2"/>
                            {company.address || '-'}
                        </Tooltip>
                    </div>
                </div>
                {auth.user?.role.isAdminOrStaffOrAdvisor && !company.type.isAffiliate &&
                <div className="grid grid-cols-2 w-full text-gray-500 text-sm mb-4">
                    <div className="flex gap-2 sm:gap-4">
                        <Tooltip
                            content={`${WarrantyProductType.solar.coverageTitle} Rate`}
                            className="flex gap-2 items-center"
                        >
                            {WarrantyProductType.solar.coverageIcon('sm')}
                            {company.product_rates?.power_production ? company.product_rates.power_production.money() : '-'}
                        </Tooltip>
                        <Tooltip
                            content={`${WarrantyProductType.battery.coverageTitle} Rate`}
                            className="flex gap-2 items-center"
                        >
                            {WarrantyProductType.battery.coverageIcon('sm')}
                            {company.product_rates?.storage_only ? company.product_rates.storage_only.money() : 'N/A'}
                        </Tooltip>
                    </div>
                    <div className="flex gap-2 sm:gap-4">
                        <Tooltip
                            content={`${WarrantyProductType.legacy20.coverageTitle} Rate`}
                            className="flex gap-2 items-center"
                        >
                            {WarrantyProductType.legacy20.coverageIcon('sm')}
                            {company.product_rates?.separate_20_year_storage ? company.product_rates.separate_20_year_storage.money() : 'N/A'}
                        </Tooltip>
                        <Tooltip
                            content={`${WarrantyProductType.total30.coverageTitle} Rate`}
                            className="flex gap-2 items-center"
                        >
                            {WarrantyProductType.total30.coverageIcon('sm')}
                            {company.product_rates?.separate_30_year_storage ? company.product_rates.separate_30_year_storage.money() : 'N/A'}
                        </Tooltip>
                    </div>
                </div>}
                <div className="flex flex-col lg:flex-row text-gray-500 text-sm">
                    <div className="lg:w-1/2 flex flex-col items-start">
                        <Tooltip content="Monthly Sales Quota" className="flex mb-4">
                            <IconHome className="stroke-gray-500 mr-2"/>
                            {company.monthly_sales_quota ? `${company.monthly_sales_quota} warranties/month` : '-'}
                        </Tooltip>
                    </div>
                    <div className="lg:w-1/2 flex flex-col items-start">
                        <Tooltip content="Total Claims Payout" className="flex mb-4">
                            <IconCurrencyDollar className="stroke-gray-500 mr-2"/>
                            {company.total_claim_payout_amount ? company.total_claim_payout_amount.money() : '-'}
                        </Tooltip>
                    </div>
                </div>
            </Card>
            <Card className="lg:col-span-1">
                <h2 className="text-lg mb-4">Account Manager</h2>
                <div className="flex flex-col lg:flex-row text-gray-500 text-sm">
                    <div className="flex flex-col items-start">
                        <Tooltip content="Account Manager" className="flex mb-4">
                            <IconBuilding07 className="stroke-gray-500 mr-2"/>
                            {company.account_manager || '-'}
                        </Tooltip>
                        <Tooltip content="Account Manager Email" className="flex mb-4">
                            <IconMail className="stroke-gray-500 mr-2"/>
                            {company.account_manager_email || '-'}
                        </Tooltip>
                    </div>
                </div>
            </Card>
            <Card className="lg:col-span-1">
                <h2 className="text-lg mb-4">Activity</h2>
                <div className="text-gray-500 text-sm flex flex-col">
                    <Tooltip content="Partner Start Date" className="flex mb-4 justify-self-start">
                        <IconCalendarCheck className="stroke-gray-500 mr-2"/>
                        {company.partner_start_date?.format() || '-'}
                    </Tooltip>

                    <Tooltip content="Last Online" className="flex mb-4 justify-self-start">
                        <IconGlobe06 className="stroke-gray-500 mr-2"/>
                        {company.activity?.last_online || '-'}
                    </Tooltip>

                    <Tooltip content="Last Warranty Submission Date" className="flex mb-4 justify-self-start">
                        <IconCertificate className="stroke-gray-500 mr-2"/>
                        {company.activity?.last_warranty_submitted_at ? new Date(company.activity?.last_warranty_submitted_at).format() : '-'}
                    </Tooltip>

                    <Tooltip content="Average # of Warranties Registered Per Month" className="flex mb-4 justify-self-start">
                        <IconHome className="stroke-gray-500 mr-2"/>
                        {company.activity?.average_warranties_per_month.format()} warranties/month
                    </Tooltip>

                    <Tooltip content="Lifetime # of Warranties" className="flex mb-4 justify-self-start">
                        <IconSun className="stroke-gray-500 mr-2"/>
                        {company.activity?.warranties_registered_lifetime.format()} lifetime warranties
                    </Tooltip>
                </div>
            </Card>
        </div>

        {auth.user?.role.isAdminOrStaffOrAdvisor && <Card className="mb-4">
            <CompanyIntegrationsSection company={company}/>
        </Card>}

        {company.type.isAffiliate && <>
            <h2 className="text-lg mb-6">Affiliate Subcontractors</h2>
            <Table
                className="mb-6"
                options={{ useQueryParams: false }}
                columns={[
                    { field: 'name', title: 'Company Name' },
                    { field: 'email', title: 'Main Company Email' },
                    { field: 'last_online', title: 'Last Online' },
                    { field: 'warranties_this_month', title: 'Warranties This Month' },
                    {
                        field: 'all_warranties_count',
                        title: 'Lifetime Warranties'
                    },
                    { field: 'monitoring_status', title: 'Monitoring Status' }
                ]}
                rows={company.subsidiaries.map(item => {
                    const activities = company.activity?.company_projections?.find(({ id }) => item.id === id)
                    return {
                        id: item.id,
                        name: item.name,
                        email: item.email,
                        last_online: activities?.last_online || '-',
                        warranties_this_month: activities?.warranties_registered_this_month?.format(),
                        all_warranties_count: activities?.warranties_registered_lifetime?.format(),
                        monitoring_status: <div className="w-10 h-10">{item.monitoringStatus.getBadge()}</div>,
                        monitoring_status_raw: item.monitoringStatus.index,
                        _path: `/companies/${id}/subsidiaries/${item.id}`
                    }
                })}
            />
        </>}

        <h2 className="text-lg mb-6">Company Users</h2>
        <Table
            className="mb-6"
            options={{ useQueryParams: false }}
            columns={[
                { field: 'name', title: 'User Name' },
                { field: 'email', title: 'Login  Email' },
                { field: 'role', title: 'Employee Role', showIf: feature('onboarding') },
                {
                    field: 'preferred_features',
                    title: <span className="flex gap-2">
                        Preferred Features
                        <Tooltip content="Preferred features were optionally defined by the <br/> user at Onboarding as frequently used features.">
                            <IconHelpCircle className="stroke-gray-400" size="sm"/>
                        </Tooltip>
                    </span>,
                    showIf: feature('onboarding') && !auth.user?.role.isAdminOrStaffOrAdvisor
                },
                { field: 'last_online', title: 'Last Online' },
                { field: 'warranties_this_month', title: 'Warranties This Month' },
                { field: 'all_warranties_count', title: 'Lifetime Warranties' },
                { field: 'status', title: 'Status' }
            ]}
            rows={company.users.map((item: User) => {
                const activities = company.activity?.user_projections?.find(({ id }) => item.id === id)
                return {
                    id: item.id,
                    name: item.name,
                    email: item.email,
                    role: item.role.title,
                    preferred_features: item.preferredFeaturesBadges,
                    last_online: activities?.last_online || '-',
                    warranties_this_month: activities?.warranties_registered_this_month?.format(),
                    all_warranties_count: activities?.warranties_registered_lifetime?.format(),
                    status: item.deleted_at
                        ? <Badge className="bg-error-100 text-error-700">Disabled</Badge>
                        : <Badge className="bg-success-100 text-success-700">Active</Badge>,
                    _path: affiliateId
                        ? `/companies/${affiliateId}/subsidiaries/${id}/users/${item.id}`
                        : `/companies/${id}/users/${item.id}`
                }
            })}
        />
    </AuthLayout>
}

export default CompaniesShow
