import { Chart as ChartJs } from 'chart.js'
import classnames from 'classnames'
import { useMemo } from 'react'

import { LineChart } from '@/components'
import { WarrantyProductTypeEnum } from '@/enums'
import { WarrantyProductType } from '@/models'
import { WarrantySalesItemDataType } from '@/types'


type SalesGraphProps = {
    labels: string[]
    data: WarrantySalesItemDataType[]
}

export const SalesGraph = ({
    labels,
    data
}: SalesGraphProps) => {
    const datasets = useMemo(() => {
        const ctx = document.createElement('canvas').getContext('2d') as CanvasRenderingContext2D
        const gradientGray = ctx.createLinearGradient(0, 0, 0, 300)
        gradientGray.addColorStop(0, 'rgba(102,112,133,0.1)')
        gradientGray.addColorStop(0.5, 'transparent')
        const gradientOrange = ctx.createLinearGradient(0, 0, 0, 300)
        gradientOrange.addColorStop(0, 'rgba(255,165,0,0.1)')
        gradientOrange.addColorStop(0.5, 'transparent')
        const gradientBlue = ctx.createLinearGradient(0, 0, 0, 300)
        gradientBlue.addColorStop(0, 'rgba(6,174,212,0.1)')
        gradientBlue.addColorStop(0.5, 'transparent')
        const gradientGreen = ctx.createLinearGradient(0, 0, 0, 300)
        gradientGreen.addColorStop(0, 'rgba(133,225,58,0.1)')
        gradientGreen.addColorStop(0.5, 'transparent')
        const gradientPurple = ctx.createLinearGradient(0, 0, 0, 300)
        gradientPurple.addColorStop(0, 'rgba(228,120,250,0.1)')
        gradientPurple.addColorStop(0.5, 'transparent')

        const gradients: Record<WarrantyProductTypeEnum, CanvasGradient> = {
            power_production_only: gradientOrange,
            separate_30_year_storage_only: gradientBlue,
            production_with_30_year_separate_storage: gradientGreen,
            production_with_20_year_separate_storage: gradientPurple
        }

        const colors: Record<WarrantyProductTypeEnum, string> = {
            power_production_only: '#ffa500',
            separate_30_year_storage_only: '#06AED4',
            production_with_30_year_separate_storage: '#85E13A',
            production_with_20_year_separate_storage: '#E478FA'
        }

        return [{
            label: 'Total Sales',
            data: data.map(item => item.total_sum),
            borderColor: '#667085',
            backgroundColor: gradientGray,
            borderWidth: 2,
            tension: 0.25,
            fill: true
        },
        ...WarrantyProductType.all.map(item => ({
            label: item.title,
            data: data.map(dataItem => dataItem[`${item.coverageKey}_sum`]),
            borderColor: colors[item.key],
            backgroundColor: gradients[item.key],
            borderWidth: 2,
            tension: 0.25,
            fill: true
        }))]
    }, [data])

    const classNames: Record<WarrantyProductTypeEnum, string> = {
        power_production_only: 'bg-brand-orange',
        separate_30_year_storage_only: 'bg-brand-blue',
        production_with_30_year_separate_storage: 'bg-brand-green',
        production_with_20_year_separate_storage: 'bg-brand-purple'
    }

    return <LineChart
        datasets={datasets}
        labels={labels}
        customTooltip={index => {
            const item = data[index]
            return <div className="px-4 py-2 font-body bg-black text-white shadow-md rounded-md text-xs">
                <div className="hidden md:block absolute w-2 h-2 -left-1 top-1/2 -translate-y-1/2 bg-black rotate-45 origin-center"/>
                <ul className="flex flex-col gap-2">
                    <li>{labels[index]}</li>
                    <li className="flex items-center gap-3">
                        <div className="rounded-full w-2 aspect-square h-2 bg-brand-gray"/>
                        {item.total_sum.money()}
                    </li>
                    {WarrantyProductType.all.reverse().map(item => <li className="flex items-center gap-3" key={item.key}>
                        <div className={classnames('rounded-full w-2 aspect-square h-2', classNames[item.key])}/>
                        {data[index][`${item.coverageKey}_sum`].money()}
                    </li>)}
                </ul>
            </div>
        }}
        plugins={[
            {
                id: 'plugin-sales-graph',
                beforeDraw: (chart: ChartJs) => {
                    if (!chart.tooltip?.opacity) return
                    const {
                        ctx,
                        chartArea: {
                            bottom,
                            top
                        }
                    } = chart
                    ctx.save()
                    ctx.lineWidth = 1
                    ctx.beginPath()
                    ctx.setLineDash([2, 2])
                    ctx.moveTo(chart.tooltip?.caretX || 0, bottom)
                    ctx.lineTo(chart.tooltip?.caretX || 0, top)
                    ctx.strokeStyle = '#d5d3d0'
                    ctx.stroke()
                }
            }
        ]}
    />
}
