import { ReactNode } from 'react'

import { Company } from './Company'
import { Tooltip } from '@/components'
import { BadgeSizes, IconSize } from '@/components/icons/Icon'
import { COMPANY_MONITORING_STATUSES, CompanyMonitoringStatusEnum } from '@/enums'
import { CompanyMonitoringStatusDataType } from '@/types'

export class CompanyMonitoringStatus {
    raw: CompanyMonitoringStatusDataType

    key: CompanyMonitoringStatusEnum

    company?: Company

    color: string

    secondaryColor: string

    discovered_at?: Date

    index: number

    icon: (size?: IconSize) => ReactNode

    constructor(data: CompanyMonitoringStatusDataType) {
        this.raw = data
        this.key = this.company?.type.isContractor && data.key === CompanyMonitoringStatusEnum.NOT_REQUIRED
            ? CompanyMonitoringStatusEnum.INCOMPLETE
            : data.key
        this.color = data.color
        this.index = data.index
        this.secondaryColor = data.secondaryColor
        this.icon = data.icon
    }

    get title() {
        return <span className={this.color}>{this.raw.title}</span>
    }

    get subTitle() {
        if (!this.company) return null
        if (this.raw.subtitle) return this.raw.subtitle
        const invalidOrMissingIntegrations = this.company.integrations.reduce((acc: string[], item) =>
            item.isInvalidOrMissing ? [...acc, item.manufacturer.title] : acc, []) || []
        return invalidOrMissingIntegrations.length ? `Resolve ${invalidOrMissingIntegrations.join('/')}` : ''
    }

    static find(key: CompanyMonitoringStatusEnum) {
        return CompanyMonitoringStatus.all.find(item => item.key === key) || CompanyMonitoringStatus.all
            .find(item => item.key === CompanyMonitoringStatusEnum.UNKNOWN) as CompanyMonitoringStatus
    }

    static get all() {
        return COMPANY_MONITORING_STATUSES.map(item => new CompanyMonitoringStatus(item))
    }

    get isValid() {
        return this.key === CompanyMonitoringStatusEnum.VALID
    }

    get isNotRequired() {
        return this.key === CompanyMonitoringStatusEnum.NOT_REQUIRED
    }

    get isIncomplete() {
        return this.key === CompanyMonitoringStatusEnum.INCOMPLETE
    }

    get isInvalid() {
        return this.key === CompanyMonitoringStatusEnum.INVALID
    }

    get isMissing() {
        return this.key === CompanyMonitoringStatusEnum.MISSING
    }

    get isUnknown() {
        return this.key === CompanyMonitoringStatusEnum.UNKNOWN
    }

    getBadge(size: IconSize = 'md') {
        const icon = this.icon(size)
        return <Tooltip content={this.raw.title}>
            <div className={`rounded-full flex justify-center items-center ${this.secondaryColor} ${BadgeSizes[size]}`}>
                {icon}
            </div>
        </Tooltip>
    }
}
