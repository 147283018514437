import { Button, Card } from '@/components'
import { SystemStatusEnum } from '@/enums'
import { useAuth } from '@/hooks'
import { Warranty } from '@/models'

type MonitoringErrorProps = {
    warranty: Warranty
}

const MonitoringError = ({ warranty }: MonitoringErrorProps) => {
    const auth = useAuth()
    const system = warranty.homeowner?.system

    const showUpdateButton = !auth.user?.role.isAdvisor && system?.status.value && (
        [
            SystemStatusEnum.MISSING_INTEGRATION,
            SystemStatusEnum.INTEGRATION_UPDATE_REQUIRED
        ].includes(system?.status.value) || (
            [
                SystemStatusEnum.AUTH_ERROR
            ].includes(system?.status.value) && system.manufacturer.isSolaredge
        )
    )

    return <Card className="flex flex-col items-start">
        <>
            {system?.status.category.isUnmonitored &&
                <span className="mt-2 font-body text-gray-900 font-semibold text-sm">Unmonitored Site: No Data Available</span>}
            {system?.status.category.isExcluded &&
                <span className="mt-2 font-body text-gray-900 font-semibold text-sm">Excluded Site: No Data Available</span>}
            <div className="mt-6 flex gap-2 items-center">
                {system?.status?.iconBadge('xs')}
                <span className="font-body text-gray-900 font-semibold text-sm">{system?.status?.title}</span>
            </div>
            <div className="mt-2 font-body text-gray-700 text-sm mb-6">
                {system?.status.description}
            </div>
            {system?.status.troubleshooting}
            {[SystemStatusEnum.UNKNOWN_MANUFACTURER].includes(system?.status.value) &&
                (auth.user?.role.isContractor || auth.user?.role.isAdminOrStaff) &&
                <div className="flex justify-center gap-3 mb-20 mt-5">
                    <Button href={auth.user?.role.isContractor ? '/profile/integrations#unsupported' : `/companies/${warranty.homeowner?.company_id}/edit/integrations#unsupported`}>
                        Share Monitoring Access
                    </Button>
                </div>}
            {showUpdateButton &&
                <div className="flex justify-center gap-3 mb-20 mt-5">
                    <Button href={auth.user?.role.isContractor ? '/profile/integrations' : `/companies/${warranty.homeowner?.company_id}/edit/integrations`}>
                        {system?.manufacturer.isSolaredge
                            ? 'Update Your SolarEdge API Key'
                            : 'Update Credentials'}
                    </Button>
                </div>}
        </>
    </Card>
}

export default MonitoringError
