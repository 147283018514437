import { IconChevronDown, IconChevronSelectorVertical, IconChevronUp } from '@/components'
import { TableColumnType, TableParamsType, TableOptionsType } from '@/types'

type TableSortIconProps = {
    params: TableParamsType
    options: TableOptionsType
    column: TableColumnType
}

export const TableSortIcon = ({
    params,
    options,
    column
}: TableSortIconProps) => {
    const { sortable = true } = column
    if (!sortable || !options.sortable) {
        return null
    }
    if (params.sort_column === column.field) {
        return params.descending
            ? <IconChevronDown size="xs" className="stroke-gray-500"/>
            : <IconChevronUp size="xs" className="stroke-gray-500"/>
    }
    return <IconChevronSelectorVertical size="sm" className="stroke-gray-300"/>
}
