import classnames from 'classnames'
import { useMemo } from 'react'

import { Pagination, Select } from '@/components'
import { TableParamsType, TableOptionsType } from '@/types'

type TFooterProps = {
    total: number,
    onChange: (name: string, value: string) => void
    'data-test'?: string
    options: TableOptionsType
    params: TableParamsType
}

const PER_PAGE_OPTIONS = [
    5,
    10,
    15,
    25,
    35,
    50
]

const TFooter = ({
    total,
    options,
    params,
    'data-test': dataTest,
    ...props
}: TFooterProps) => {
    if (options.infinity || !options.pagination) return null
    const perPageOptions = useMemo(() => PER_PAGE_OPTIONS.includes(params.per_page as number)
        ? PER_PAGE_OPTIONS
        : [...PER_PAGE_OPTIONS, params.per_page as number].sort((a, b) => a - b), [params.per_page])
    if (total <= perPageOptions[0]) return null

    const className = classnames('datatable-footer', { 'show-per-page': options.showPerPage })
    return <div className={className}>
        <Pagination
            current={params.page as number}
            perPage={params.per_page as number}
            total={total}
            nextButtonText={options.nextButtonText}
            prevButtonText={options.prevButtonText}
            onChange={e => props.onChange(e.target.name, e.target.value as string)}
            data-test={dataTest}
        />
        {options.showPerPage &&
            <Select
                id="per-page-select"
                input-size="sm"
                options={perPageOptions.map(item => ({
                    value: item,
                    title: item
                }))}
                value={params.per_page as number}
                name="per_page"
                onChange={e => props.onChange(e.target.name, e.target.value)}
                data-test={`${dataTest}-per-page-select`}
            />}
    </div>
}

export default TFooter
