import {
    useFloating,
    offset,
    flip,
    shift,
    arrow,
    autoUpdate,
    FloatingPortal,
    FloatingOverlay,
    useHover,
    useFocus,
    useDismiss,
    useRole,
    useInteractions,
    safePolygon,
    useTransitionStatus,
    Placement
} from '@floating-ui/react'
import classnames from 'classnames'
import React, { useState, useRef, useEffect } from 'react'

const sideMap: Record<'top' | 'right' | 'bottom' | 'left', string> = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right'
}

type TriggerType = 'mouseenter' | 'focus' | 'click' | 'mouseenter focus' | 'click focus'

interface TooltipProps {
  content: React.ReactNode
  children: React.ReactNode
  placement?: Placement
  disabled?: boolean
  className?: string
  interactive?: boolean
  backdrop?: boolean
  maxWidth?: number
  hideOnClick?: boolean
  color?: 'default' | 'white'
  trigger?: TriggerType
  onShow?: () => void
  onHide?: () => void
}

export const Tooltip = ({
    content,
    children,
    placement = 'top',
    disabled = false,
    className,
    interactive = false,
    backdrop = false,
    maxWidth = 540,
    hideOnClick = true,
    color = 'default',
    trigger = 'mouseenter focus',
    ...props
}: TooltipProps) => {
    const arrowRef = useRef<HTMLDivElement | null>(null)
    const [isOpen, setIsOpen] = useState(false)

    const {
        x,
        y,
        refs,
        strategy,
        context,
        middlewareData,
        placement: realPlacement
    } = useFloating({
        placement,
        open: isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(8),
            flip(),
            shift({ padding: 8 }),
            arrow({ element: arrowRef })
        ]
    })

    const hover = useHover(context, {
        enabled: trigger.includes('mouseenter') && !disabled,
        handleClose: interactive ? safePolygon() : undefined,
        delay: { open: 0, close: 10 }
    })
    const focus = useFocus(context, { enabled: trigger.includes('focus') && !disabled })
    const dismiss = useDismiss(context, { outsidePress: hideOnClick })
    const role = useRole(context, { role: 'tooltip' })

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        focus,
        dismiss,
        role
    ])

    const { isMounted } = useTransitionStatus(context, { duration: 300 })

    const arrowX = middlewareData.arrow?.x ?? 0
    const arrowY = middlewareData.arrow?.y ?? 0

    useEffect(() => {
        if (isOpen) {
            if (props.onShow) {
                props.onShow()
            }
        } else {
            if (props.onHide) {
                props.onHide()
            }
        }
    }, [isOpen, props.onShow, props.onHide])

    const handleClick = () => {
        if (!disabled && trigger.includes('click')) {
            setIsOpen(prev => !prev)
        }
    }

    return <>
        <div
            aria-label="Tooltip trigger"
            {...getReferenceProps({
                ref: refs.setReference,
                role: trigger.includes('click') ? 'button' : undefined,
                tabIndex: trigger.includes('click') ? 0 : undefined,
                className: classnames(className, { 'cursor-pointer': trigger.includes('click') }),
                onClick: handleClick,
                onKeyDown: e => {
                    if ((e.key === 'Enter' || e.key === ' ') && trigger.includes('click')) {
                        handleClick()
                    }
                }
            })}
        >
            {children}
        </div>
        {!disabled && isMounted &&
            <FloatingPortal>
                {backdrop &&
                    <FloatingOverlay
                        className={classnames(
                            'z-50 bg-black/50 pointer-events-none',
                            isOpen ? 'animate-fade-in' : 'animate-fade-out'
                        )}
                    />}
                <div
                    ref={refs.setFloating}
                    data-state={isOpen ? 'visible' : 'hidden'}
                    {...getFloatingProps({
                        className: classnames(
                            'z-50 px-3 py-2 rounded-lg text-xs whitespace-normal',
                            isOpen ? 'animate-fade-in' : 'animate-fade-out',
                            color === 'white' ? 'bg-white text-black' : 'bg-black text-white'
                        ),
                        style: {
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                            maxWidth: maxWidth ? `${maxWidth}px` : undefined
                        }
                    })}
                >
                    {content}
                    <div
                        ref={arrowRef}
                        className={classnames(
                            'absolute w-2.5 h-2.5 rotate-45',
                            color === 'white' ? 'bg-white' : 'bg-black'
                        )}
                        style={{
                            left: arrowX ? `${arrowX}px` : '',
                            top: arrowY ? `${arrowY}px` : '',
                            [sideMap[realPlacement.split('-')[0] as 'top' | 'right' | 'bottom' | 'left']]:
                            '-5px'
                        }}
                    />
                </div>
            </FloatingPortal>}</>
}
