import classnames from 'classnames'
import { memo } from 'react'

import { IconStep, IconStepPassed } from '@/components'

type StepsProps = {
    steps: string[]
    step: number
    className?: string
}

export const Steps = memo(({ steps, step, className }: StepsProps) =>
    <div className={classnames('steps', className)}>
        {steps.map((item, index) =>
            <div
                className={`step${step === index ? ' active' : ''}${step > index ? ' passed' : ''}`}
                key={index}
                data-step={index}
            >
                <div className="step-lines">
                    <div className="step-line step-line-left"></div>
                    <div className="steps-icon">
                        {step > index && <IconStepPassed size="lg"/>}
                        {step <= index && <IconStep
                            className={step === index ? 'stroke-primary-700 stroke-primary-700' : 'stroke-gray-200'}
                            size="lg"
                        />}
                    </div>
                    <div className="step-line step-line-right"></div>
                </div>
                {item}
            </div>)}
    </div>)
