import { useState } from 'react'

import { Button, Logo, Input, Form, FormErrorsType } from '@/components'
import { FormChangeEvent } from '@/types'

type ComponentProps = {
    onSubmit: () => void
}

const StepOne = ({ ...props }: ComponentProps) => {
    const [errors, setErrors] = useState<FormErrorsType>({})
    const [verificationKey, setVerificationKey] = useState('')

    const handleSubmit = async () => {
        if (!verificationKey) {
            setErrors({ verification_key: ['This field is required.'] })
        } else {
            props.onSubmit()
        }
    }

    const handleChange = (e: FormChangeEvent) => {
        setVerificationKey(e.target.value as string)
    }

    return <div className="h-auto">
        <Logo type="small" className="mx-auto small-logo w-12 h-12 object-contain mb-8"/>
        <h1 className="text-3xl text-center text-header font-medium">
            New Account <br/> Verification
        </h1>
        <h3 className="text-gray-600 text-center signup-subheader text-gray-700 font-light">
            Account Creation is only available for partners who have completed Solar Insure’s application and
            certification process. Eligible partners will recieve a verification key via email.
        </h3>
        <Form onSubmit={handleSubmit}>
            <Input
                id="verificationKey"
                name="verification_key"
                label="Account Verification Key"
                className="mb-6 font-body"
                onChange={handleChange}
                errors={errors.verification_key}
                value={verificationKey}
            />
            <Button className="w-full">
                Continue
            </Button>
            <div className="text-xs font-normal text-left supporting-text text-gray-500 flex flex-col md:flex-row justify-center items-center my-5 gap-3 mt-16">
                <span>Interested in working with Solar Insure? </span>
                <a href="/sign-up" className="text-orange-700 text-sm">Learn More</a>
            </div>
            <div className="text-xs font-normal text-left supporting-text text-gray-500 flex flex-col md:flex-row justify-center items-center my-5 gap-3">
                <span>Unsure about your Application Status? </span>
                <a href="/sign-up" className="text-orange-700 text-sm">Check Your Status</a>
            </div>
            <div className="text-xs font-normal text-left supporting-text text-gray-500 flex flex-col md:flex-row justify-center items-center my-5 gap-3">
                <span>Already have an Account?</span>
                <a href="/sign-up" className="text-orange-700 text-sm">Login</a>
            </div>
        </Form>
    </div>
}

export default StepOne
