export type FeatureNameType =
    'onboarding' |
    'monitoring-mapview' |
    'company-verification' |
    'battery-eligibility' |
    'upload-affiliate-data-button' |
    'new-index-page-filters' |
    'marketing-popup'

type Feature = {
    name: FeatureNameType,
    active: string | boolean
}

export const features: Feature[] = [
    {
        name: 'onboarding',
        active: import.meta.env.VITE_FEATURE_ONBOARDING || false
    },
    {
        name: 'monitoring-mapview',
        active: import.meta.env.VITE_FEATURE_MONITORING_MAPVIEW || false
    },
    {
        name: 'company-verification',
        active: import.meta.env.VITE_FEATURE_COMPANY_VERIFICATION || false
    },
    {
        name: 'battery-eligibility',
        active: import.meta.env.VITE_FEATURE_BATTERY_ELIGIBILITY || false
    },
    {
        name: 'upload-affiliate-data-button',
        active: import.meta.env.VITE_FEATURE_UPLOAD_AFFILIATE_DATA_BUTTON || false
    },
    {
        name: 'new-index-page-filters',
        active: import.meta.env.VITE_FEATURE_NEW_INDEX_PAGE_FILTERS || false
    },
    {
        name: 'marketing-popup',
        active: import.meta.env.VITE_FEATURE_MARKETING_POPUP || false
    }
]
