import classnames from 'classnames'
import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react'

import { Badge, Button, IconTrash02, Radio, Select, SelectOptionProps, Tooltip } from '@/components'
import { UploadWarrantiesFormDataType, UploadWarrantiesRowDataType } from '@/containers/warranties/Upload'
import { useAnalytics } from '@/hooks'
import { FormChangeEvent, WarrantyBulkUploadColumnType } from '@/types'

type StepVerifyColumnsProps = {
    form: UploadWarrantiesFormDataType
    columns: WarrantyBulkUploadColumnType[]
    headers: string[]
    onNext: () => void
    onPrev: () => void
    processing: boolean
    onColumnsChange: (columns: WarrantyBulkUploadColumnType[]) => void
}

const THRESHOLD = 0.5

const StepVerifyColumns = ({ form, columns, headers, processing, ...props }: StepVerifyColumnsProps) => {
    const { trackEvent } = useAnalytics()
    const [showHelp, setShowHelp] = useState(false)

    const error = useMemo<ReactNode>(() => {
        const missingRequiredFields = columns.filter(item => item.required && item.show &&
            (item.index === '' || item.index === null))
        if (missingRequiredFields.length) {
            return <div className="mt-6 text-sm">
                <Badge className="bg-error-50 text-error-700">
                    {missingRequiredFields.length} required column{missingRequiredFields.length > 1 ? 's' : ''} {missingRequiredFields.length > 1 ? 'are' : 'is'} missing
                </Badge> <span className="text-gray-700">
                    Please ensure all mandatory fields are included. Update your file to add the missing columns and
                    re-upload it to proceed.
                </span>
            </div>
        }
        if (columns.some(item => columns.some(cur =>
            cur.show && item.show &&
            cur.index === item.index &&
            cur.index !== null &&
            item.index !== null &&
            cur.value !== item.value))) {
            return <div className="mt-6 text-sm">
                <Badge className="bg-error-50 text-error-700 inline">
                    Each column in your uploaded file must be mapped to a unique expected header.
                </Badge> <span className="text-gray-700">
                    Please ensure no two columns are mapped to the same expected header.
                </span>
            </div>
        }
    }, [columns])

    const handleSelectChange = (e: FormChangeEvent) => {
        const clone = [...columns]
        const index = clone.findIndex(item => item.value === e.target.name)
        clone[index].score = 0
        clone[index].index = e.target.value as string
        clone[index].header = headers[parseInt(e.target.value as string)]
        props.onColumnsChange(clone)
    }

    const trackHoverEvent = (eventType: string) => {
        const category = 'User Interaction'

        if (eventType === 'bulk_upload_step_2_back') {
            trackEvent('hover_bulk_upload_step_2_back', category, 'Bulk Upload Back Button Hover')
        } else if (eventType === 'bulk_upload_step_2_save') {
            trackEvent('hover_bulk_upload_step_2_save', category, 'Bulk Upload Save Button Hover')
        } else if (eventType === 'email_homeowner_toggle') {
            trackEvent('hover_email_homeowner_toggle', category, 'Bulk Upload Email Homeowner Button Hover')
        } else if (eventType === 'hover_email_homeowner_toggle') {
            trackEvent('hover_email_homeowner_toggle', category, 'Email Homeowner Toggle Hover')
        }
    }

    const handleClickBack = () => {
        props.onPrev()
        trackEvent('click_bulk_upload_step_2_back', 'User Interaction', 'Bulk Upload Step 2 Back Button Click')
    }

    const handleClickDelete = (column: WarrantyBulkUploadColumnType) => {
        const clone = [...columns]
        const index = clone.findIndex(item => item.value === column.value)
        clone[index].show = false
        props.onColumnsChange(clone)
    }
    const handleRadioChange = (e: FormChangeEvent) => {
        props.onColumnsChange(columns.map(item => {
            if (e.target.name === 'full_name') {
                if (['first_name', 'last_name'].includes(item.value)) {
                    item.show = e.target.value !== 'full'
                }
                if (item.value === 'full_name') {
                    item.show = e.target.value === 'full'
                }
            }
            if (e.target.name === 'address') {
                if (['street_address', 'city', 'state', 'zip'].includes(item.value)) {
                    item.show = e.target.value !== 'full'
                }
                if (item.value === 'address') {
                    item.show = e.target.value === 'full'
                }
            }
            return item
        }))
    }

    const [firstRow = {}] = form.warranties

    const columnsGroups = useMemo(() =>
        columns.reduce(([required, optional]: [
            WarrantyBulkUploadColumnType[],
            WarrantyBulkUploadColumnType[]
        ], item: WarrantyBulkUploadColumnType) => [
            [...required, ...item.required ? [item] : []],
            [...optional, ...!item.required ? [item] : []]
        ] as [WarrantyBulkUploadColumnType[], WarrantyBulkUploadColumnType[]], [[], []]), [columns])

    const fullNameColumn = columns.find(item => item.value === 'full_name')
    const addressColumn = columns.find(item => item.value === 'address')

    const showFullNameRadioButtons = useMemo(() =>
        columns.some(item => ['first_name', 'last_name'].includes(item.value) && item.score > THRESHOLD), [])
    const showAddressRadioButtons = useMemo(() =>
        columns.some(item => ['street_address', 'city', 'state', 'zip'].includes(item.value) && item.score > THRESHOLD), [])

    useEffect(() => {
        if (showFullNameRadioButtons) {
            handleRadioChange({ target: { name: 'full_name', value: 'full' } })
        }
        if (showAddressRadioButtons) {
            handleRadioChange({ target: { name: 'address', value: 'full' } })
        }
    }, [])

    const headerOptions = useMemo(() => {
        const [unselected, selected] = headers
            .reduce(([unselected, selected]: [SelectOptionProps[], SelectOptionProps[]], item, index) => {
                const isSelected = columns.some(item => item.index === index && item.show)
                const option = {
                    value: index,
                    title: item,
                    className: isSelected ? 'text-gray-400' : 'text-gray-900'
                }
                return [
                    [...unselected, ...!isSelected ? [option] : []],
                    [...selected, ...isSelected ? [option] : []]
                ]
            }, [[], []])
        if (selected[0]) (selected[0] as SelectOptionProps).group = true
        return [
            ...unselected,
            ...selected
        ]
    }, [columns])

    return <>
        <div className={`flex flex-col gap-2 lg:gap-6 pt-3.5 ${showHelp ? 'bg-gray-50 px-4 lg:px-8 py-4' : ''}`}>
            <div className="flex flex-col-reverse lg:flex-row items-start gap-2 lg:gap-6 mb-2">
                <Button design="btn-link" className="!text-primary-800" onClick={() => setShowHelp(state => !state)}>
                    Click {showHelp ? 'to Hide' : 'for More Help'}
                </Button>

                <Badge className="bg-primary-50 text-primary-700">
                    You have uploaded {form.warranties.length} warranties
                </Badge>
            </div>

            {showHelp && <>
                <h4 className="font-semibold text-sm text-gray-700">Verify Columns</h4>
                <ul className="text-sm text-gray-700 list-decimal pl-4">
                    <li>
                        <span className="font-semibold">Required Fields</span>: Match each &apos;Solar Insure Header&apos; to a column in your file, ensuring each column has a unique header.
                        <ul className="list-disc pl-8">
                            <li>
                                Example: Map &quot;First Name&quot; in our system to &quot;Name&quot; in your file.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className="font-semibold">Optional Fields</span>: Matching &apos;Number of Microinverters/Optimizers&apos; and &apos;System ID&apos; is optional but beneficial.
                    </li>
                </ul>

                <p className="text-sm text-gray-700">
                    Think of it as fitting puzzle pieces. Proper matching ensures smooth data processing.
                </p>
            </>}
        </div>

        {(showFullNameRadioButtons || showAddressRadioButtons) && <div>
            <h4 className="font-semibold text-sm text-gray-700">Column Format</h4>
            <div className="flex mt-4 w-full max-w-5xl">
                {showFullNameRadioButtons && <div className="lg:w-1/2">
                    <h4 className="text-sm text-gray-700">Select the name format that matches your data:</h4>
                    <ul className="mt-4 !text-sm !lg:text-base">
                        <li>
                            <Radio
                                id="separate-name-radio"
                                name="full_name"
                                value="separate"
                                checked={!fullNameColumn?.show}
                                label="First Name and Last Name in separate columns"
                                onChange={handleRadioChange}
                            />
                        </li>
                        <li>
                            <Radio
                                id="full-name-radio"
                                name="full_name"
                                value="full"
                                checked={fullNameColumn?.show}
                                label="Full Name in one column"
                                onChange={handleRadioChange}
                            />
                        </li>
                    </ul>
                </div>}
                {showAddressRadioButtons && <div className="lg:w-1/2">
                    <h4 className="text-sm text-gray-700">Select the Address format that matches your data:</h4>
                    <ul className="mt-4 !text-sm !lg:text-base">
                        <li>
                            <Radio
                                id="separate-address-radio"
                                name="address"
                                value="separate"
                                checked={!addressColumn?.show}
                                label="Address in multiple columns (Street, City, ZIP, State)"
                                onChange={handleRadioChange}
                            />
                        </li>
                        <li>
                            <Radio
                                id="full-address-radio"
                                name="address"
                                value="full"
                                checked={addressColumn?.show}
                                label="Full Address in one column (Street, City, ZIP, State)"
                                onChange={handleRadioChange}
                            />
                        </li>
                    </ul>
                </div>}
            </div>
        </div>}

        {error}

        <div className="w-full overflow-auto">
            <table className="w-full text-gray-500 text-left border-collapse border-spacing-5">
                {columnsGroups.map((columnGroup, index) => {
                    const isRequired = index === 0
                    return <Fragment key={index}>
                        <thead className="text-sm">
                            <tr className="font-semibold ">
                                <th colSpan={4} className="pt-4">
                                    <div className="px-6 py-3 bg-orange-50">
                                        {isRequired ? 'Required' : 'Currently Optional'} Fields
                                    </div>
                                </th>
                            </tr>
                            <tr className="font-semibold border-b border-b-gray-100">
                                <th className="w-1/3 px-6 py-3 whitespace-nowrap font-normal">
                                    Solar Insure Expected Headers
                                </th>
                                <th className="w-1/3 px-6 py-3 whitespace-nowrap font-normal">
                                    Uploaded File Sample Data
                                </th>
                                <th className="w-1/3 px-6 py-3 whitespace-nowrap font-normal">
                                    Uploaded File Headers
                                </th>
                                <th className="w-18"></th>
                            </tr>
                        </thead>
                        <tbody className="text-base">
                            {columnGroup.map(item => {
                                if (!item.show) return null
                                const hasError = (item.required && (item.index === '' || item.index === null)) ||
                                    columns.some(cur =>
                                        cur.show && item.show &&
                                        cur.index === item.index &&
                                        cur.index !== null && item.index !== null &&
                                        cur.value !== item.value)
                                return <tr key={item.value} className="border-b border-b-gray-100">
                                    <td
                                        className={classnames('px-6 py-3', isRequired ? 'bg-indigo-50' : 'bg-gray-50', { 'text-error-600': item.required && (item.index === '' || item.index === null) })}
                                        style={{ boxShadow: 'inset 0px 4px white' }}
                                    >
                                        {item.title}
                                    </td>
                                    <td className="px-6 py-3">
                                        {firstRow[item.value as keyof UploadWarrantiesRowDataType]}
                                    </td>
                                    <td className="px-6 py-3">
                                        <Select
                                            input-size="sm"
                                            className={hasError ? 'has-error whitespace-nowrap' : 'whitespace-nowrap'}
                                            id={`${item.value}-select`}
                                            options={headerOptions}
                                            value={item.index === null ? '' : item.index}
                                            name={item.value}
                                            onChange={handleSelectChange}
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        {!item.required && <Tooltip content="Remove Column">
                                            <button className="cursor-pointer" onClick={() => handleClickDelete(item)}>
                                                <IconTrash02 size="lg" className="stroke-gray-700"/>
                                            </button>
                                        </Tooltip>}
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Fragment>
                })}
            </table>
        </div>

        <div className="flex justify-end items-center gap-3 mt-6">
            <Button
                design="btn-secondary-gray"
                onClick={handleClickBack}
                className="w-36"
                onMouseOver={() => trackHoverEvent('bulk_upload_step_2_back')}
            >
                Previous
            </Button>
            <Button
                onClick={props.onNext}
                processing={processing}
                disabled={!!error}
                className="w-36"
                onMouseOver={() => trackHoverEvent('bulk_upload_step_2_save')}
            >
                Next
            </Button>
        </div>
    </>
}

export default StepVerifyColumns
