import { ReactNode, useState } from 'react'

import { Button, IconDownload } from '@/components'

type CompanyActivityItemProps = {
    icon: ReactNode
    title: ReactNode
    content: ReactNode
    className: string
    onDownload: () => void
    'data-test'?: string
}

const CompanyActivityItem = ({ icon, title, content, className, 'data-test': dataTest, ...props }: CompanyActivityItemProps) => {
    const [open, setOpen] = useState(false)

    const handleToggle = () => {
        setOpen(!open)
    }

    return <div className={`shadow-md text-left border border-gray-200 bg-white rounded px-2 lg:px-4 py-4 lg:py-8 ${className}`}>
        <div className="flex gap-3 2xl:gap-5 items-center justify-between">
            <span data-test={`${dataTest}-icon`}>
                {icon}
            </span>
            <h3 data-test={`${dataTest}-title`} className="font-normal text-sm xl:text-base grow text-left flex gap-5 items-center">{title}</h3>
            <Button design="btn-link" className="whitespace-nowrap" onClick={handleToggle} data-test={`${dataTest}-expand-button`}>
                See {open ? 'Less' : 'More'}
            </Button>
        </div>
        <div className={`mt-2 ${open ? 'flex flex-col' : 'hidden'}`} data-test={`${dataTest}-content`}>
            {content}
            <div className="flex justify-between items-center mt-2">
                <p className="text-gray-600 font-open-sans text-sm" data-test={`${dataTest}-download-title`}>
                    Here is a preview, click Download to see more
                </p>
                <Button design="btn-link-gray" onClick={props.onDownload} data-test={`${dataTest}-download-button`} className="w-9 !h-9 border border-gray-300 p-2 rounded-lg shadow-md">
                    <IconDownload className="stroke-gray-700" size="md"/>
                </Button>
            </div>
        </div>
    </div>
}

export default CompanyActivityItem
