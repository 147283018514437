import { FormErrorsType, Input } from '@/components'
import { Company, Warranty, WarrantyProductType } from '@/models'
import { FormChangeEventHandler, JsonDataType } from '@/types'

export interface PolicyRatesFormDataType extends JsonDataType {
    policy_rate: number
    battery_rate: number
}

type PolicyRatesFormProps = {
    form: PolicyRatesFormDataType
    warranty: Warranty
    onChange: FormChangeEventHandler
    errors: FormErrorsType
    company: Company
}

export const PolicyRatesForm = ({
    form,
    warranty,
    errors,
    company,
    ...props
}: PolicyRatesFormProps) =>
    <>
        {warranty.type.hasPowerProduction &&
            <Input
                id="policy-rate"
                name="policy_rate"
                label={`${WarrantyProductType.solar.coverageTitle} Rate*`}
                placeholder="Policy rate"
                onChange={props.onChange}
                value={form.policy_rate}
                errors={errors.policy_rate}
                mask={{
                    numeral: true,
                    numeralDecimalScale: 2,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'none'
                }}
            />}

        {warranty.type.hasStorage &&
            <Input
                id="battery-rate"
                name="battery_rate"
                label={`${warranty.batteries.length ? warranty.type.coverageTitle : company.battery_eligibility.coverageTitle} Rate*`}
                placeholder="Battery rate"
                onChange={props.onChange}
                value={form.battery_rate}
                errors={errors.battery_rate}
                mask={{
                    numeral: true,
                    numeralDecimalScale: 2,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'none'
                }}
            />}
    </>
