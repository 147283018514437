import { Icon, IconProps } from './Icon'

export const IconChevronLeft = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="m15 18-6-6 6-6"
            stroke="#000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
