import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'

import { Table, IndexContainerHeading, IndexTablePlaceholder } from '@/components'
import { useIndexContainer, useQuery } from '@/hooks'
import { isApiError, Options, Params, sessionStorage } from '@/services'
import {
    TableRowType,
    TableColumnType,
    IndexContainerFiltersType,
    IndexContainerDataType,
    IndexContainerChangeEvent,
    Row,
    TableOptionsType, TableParamsType, FormChangeEventHandler
} from '@/types'

export interface IndexContainerProps<T extends IndexContainerFiltersType = IndexContainerFiltersType> {
    id: string
    reload?: boolean
    columns: TableColumnType[]
    rows: TableRowType[]
    data: IndexContainerDataType
    dataName?: string
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    api: (params?: Params, options?: Options) => Promise<any>
    placeholder?: ReactNode
    filters: T
    defaultTableParams?: TableParamsType
    leftSide?: ReactNode
    rightSide?: ReactNode
    popupContent?: ReactNode
    tableOptions: TableOptionsType

    onDataChange: (e: IndexContainerChangeEvent) => void
    onFiltersChange: FormChangeEventHandler
    onReloadChange?: (value: boolean) => void
    onFiltersClear?: () => void
}

export const IndexContainer = <T extends IndexContainerFiltersType>({
    id,
    reload,
    data,
    columns,
    rows,
    leftSide,
    rightSide,
    popupContent,
    api,
    filters,
    defaultTableParams,
    placeholder,
    tableOptions,
    dataName = 'Items',
    ...props
}: IndexContainerProps<T>) => {
    const { filtersStringValue } = useIndexContainer(filters)
    const query = useQuery()
    const location = useLocation()
    const [abortController, setAbortController] = useState<AbortController | null>()
    const [error, setError] = useState(false)
    const [processing, setProcessing] = useState<boolean | 'prev' | 'next'>(false)

    const getTableHeight = () => {
        const MIN_HEIGHT = 700
        const FILTERS_HEIGHT = 46 + 46
        const PADDINGS_HEIGHT = 64 + 64
        const height = window.innerHeight - FILTERS_HEIGHT - PADDINGS_HEIGHT
        return height < MIN_HEIGHT ? MIN_HEIGHT : height
    }

    const fetchData = async (params: TableParamsType, isScrolled = false, isPrevious = false) => {
        abortController?.abort('canceled')
        const controller = new AbortController
        setAbortController(controller)
        if (isScrolled) {
            setProcessing(isPrevious ? 'prev' : 'next')
        } else {
            setProcessing(true)
        }
        try {
            const { data: rows, meta, ...rest } = await api({
                ...defaultTableParams,
                ...params,
                ...Object.keys(filters).reduce((acc, key) => ({
                    ...acc,
                    [key]: filters[key].value?.toString() === filters[key].defaultValue?.toString()
                        ? null : filters[key].value
                }), {})
            } as Params, { signal: controller.signal })
            const newData = rows.map((item: Row) => {
                item.page = meta.current_page
                return item
            })

            if (isScrolled) {
                props.onDataChange({ name: 'rows', value: isPrevious ? [...newData, ...data.rows] : [...data.rows, ...newData] })
            } else {
                props.onDataChange({ name: 'rows', value: newData })
            }

            props.onDataChange({ name: 'meta', value: meta })
            props.onDataChange({ name: 'rest', value: rest })
        } catch (err) {
            if (!isApiError(err)) {
                throw err
            } else {
                if (!err.message.includes('cancel')) {
                    setError(true)
                }
            }
        } finally {
            setProcessing(false)
        }
    }

    const handleSelectedRowsChange = (selected: TableRowType[] | 'all') => {
        props.onDataChange({ name: 'selectedRows', value: selected })
    }

    const handleExcludedRowsChange = (selected: TableRowType[]) => {
        props.onDataChange({ name: 'excludedRows', value: selected })
    }

    useEffect(() => {
        fetchData(data.meta.current_page ? { ...query, page: 1 } : query)
    }, [filtersStringValue])

    const handleReload = async () => {
        if (reload) {
            await fetchData({ ...query, page: 1 })
            if (props.onReloadChange) props.onReloadChange(false)
        }
    }

    useEffect(() => {
        handleReload()
    }, [reload])

    const handleRowClick = () => {
        if (location.search) {
            sessionStorage.set(`preservedQuery.${id}`, location.search)
        }
    }

    useEffect(() => {
        sessionStorage.delete(`preservedQuery.${id}`)
    }, [])

    const rowsMemo = useMemo(() => rows.map((item, index) => {
        item._page = data.rows[index].page
        return item
    }), [rows, data.rows])

    return <div className="index-container">
        <IndexContainerHeading
            id={id}
            data={data}
            dataName={dataName}
            leftSide={leftSide}
            rightSide={rightSide}
            popupContent={popupContent}
            filters={filters}
            onClear={props.onFiltersClear}
            onChange={props.onFiltersChange}
        />
        <div className="index-container-table-container">
            <Table
                id={id}
                columns={columns}
                rows={rowsMemo}
                meta={data.meta}
                minHeight={getTableHeight()}
                placeholder={
                    <IndexTablePlaceholder
                        error={error}
                        filters={filters}
                        dataName={dataName}
                    />
                }
                processing={processing}
                data-test={`${id}-index-table`}
                selectedRows={data.selectedRows}
                excludedRows={data.excludedRows}
                onChange={fetchData}
                onRowClick={handleRowClick}
                onSelectedRowsChange={handleSelectedRowsChange}
                onExcludedRowsChange={handleExcludedRowsChange}
                options={tableOptions}
            />
        </div>
    </div>
}
