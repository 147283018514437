import { IconSlashCircle01 } from '@/components'
import { WARRANTY_PRODUCT_TYPES, WarrantyProductTypeEnum } from '@/enums/WarrantyProductTypeEnum'
import {
    CompanyBatteryEligibilityColorType,
    CompanyBatteryEligibilityDataType,
    WarrantyProductTypeDataType
} from '@/types'

export enum BatteryEligibilityTypeEnum {
    NO_BATTERY = 'no_battery',
    BATTERY_20_YEAR = 'battery_20_year',
    BATTERY_30_YEAR = 'battery_30_year'
}

const LEGACY_20 = WARRANTY_PRODUCT_TYPES.find(item =>
    item.key === WarrantyProductTypeEnum.PRODUCTION_WITH_20_YEAR_SEPARATE_STORAGE) as WarrantyProductTypeDataType
const TOTAL_30 = WARRANTY_PRODUCT_TYPES.find(item =>
    item.key === WarrantyProductTypeEnum.PRODUCTION_WITH_30_YEAR_SEPARATE_STORAGE) as WarrantyProductTypeDataType

export const BATTERY_ELIGIBILITY_TYPES: CompanyBatteryEligibilityDataType[] = [
    {
        key: BatteryEligibilityTypeEnum.BATTERY_20_YEAR,
        title: 'SI-30:20 Legacy',
        coverageTitle: LEGACY_20.coverageTitle,
        subtitle: LEGACY_20.subtitle,
        color: LEGACY_20.color as CompanyBatteryEligibilityColorType,
        IconComponent: LEGACY_20.IconComponent,
        eligibleProductTypes: [
            WarrantyProductTypeEnum.POWER_PRODUCTION_ONLY,
            WarrantyProductTypeEnum.PRODUCTION_WITH_20_YEAR_SEPARATE_STORAGE
        ]
    },
    {
        key: BatteryEligibilityTypeEnum.BATTERY_30_YEAR,
        title: 'SI-30 Total & Battery',
        coverageTitle: TOTAL_30.coverageTitle,
        subtitle: TOTAL_30.subtitle,
        color: TOTAL_30.color as CompanyBatteryEligibilityColorType,
        IconComponent: TOTAL_30.IconComponent,
        eligibleProductTypes: [
            WarrantyProductTypeEnum.POWER_PRODUCTION_ONLY,
            WarrantyProductTypeEnum.SEPARATE_30_YEAR_STORAGE_ONLY,
            WarrantyProductTypeEnum.PRODUCTION_WITH_30_YEAR_SEPARATE_STORAGE
        ]
    },
    {
        key: BatteryEligibilityTypeEnum.NO_BATTERY,
        title: 'Not Eligible to Sell Battery',
        color: 'gray',
        IconComponent: IconSlashCircle01,
        eligibleProductTypes: [
            WarrantyProductTypeEnum.POWER_PRODUCTION_ONLY
        ]
    }
]
