import { ReactNode, useMemo, useState } from 'react'

import { Select, SelectOptionProps, Switch } from '@/components'
import { SystemStatusCategoryEnum, SystemStatusEnum, SystemStatusSubcategoryEnum } from '@/enums'
import { SystemStatus, SystemStatusCategory } from '@/models'
import { FormChangeEvent, FormChangeEventHandler, MonitoringSummaryDataType } from '@/types'

type MonitoringStatusFilterProps = {
    onChange: FormChangeEventHandler
    value: string[]
    name: string
    id: string
    'data-test': string
    summary: MonitoringSummaryDataType
    className?: string
}

const MonitoringStatusFilter = ({
    value,
    name,
    id,
    'data-test': dataTest,
    summary,
    className,
    ...props
}: MonitoringStatusFilterProps) => {
    const [showInactiveStatuses, setShowInactiveStatuses] = useState(false)

    const handleChange = (e: FormChangeEvent) => {
        if (e.target.value === 'toggle') {
            setShowInactiveStatuses(value => !value)
            return
        }
        props.onChange({ target: { value: e.target.value, name } })
    }

    const getStatusCount = (statusKey: SystemStatusEnum | SystemStatusSubcategoryEnum | SystemStatusCategoryEnum) => {
        if (summary) {
            const count = summary.statuses_count?.[statusKey as SystemStatusEnum] ||
                summary.groups?.[statusKey as SystemStatusSubcategoryEnum | SystemStatusCategoryEnum] ||
                0
            return count.format()
        }

        return ''
    }

    const statusOptions = useMemo<SelectOptionProps[]>(() => [
        {
            value: 'toggle',
            title: <div className="flex w-full gap-4 items-center justify-between font-body text-gray-900 text-sm">
                Show Inactive Issues

                <Switch
                    name="toggle"
                    input-size="sm"
                    checked={showInactiveStatuses}
                    value="toggle"
                    onChange={() => {}}
                    onClick={() => {}}
                />
            </div>
        },
        {
            value: '',
            title: 'Any Status'
        },
        ...SystemStatusCategory.all.reduce((acc: SelectOptionProps[], category, index) =>
            showInactiveStatuses || summary.groups?.[category.value] || value.includes(category.value) ? [
                ...acc,
                {
                    value: category.value,
                    title: <div className="font-semibold flex gap-3 items-center justify-between">
                        {category.title}
                        <div className="text-purple-600 bg-purple-100 w-8 h-8 flex justify-center items-center rounded-full text-2xs">
                            {getStatusCount(category.value)}
                        </div>
                    </div>,
                    group: index > 0
                },
                ...category.subcategories.reduce((acc: SelectOptionProps[], subcategory, _, all) =>
                    showInactiveStatuses ||
                    summary.groups?.[subcategory.value] ||
                    value.includes(subcategory.value) ||
                    all.length === 1 ? [
                            ...acc,
                            ...all.length > 1 ? [{
                                value: subcategory.value,
                                parent: category.value,
                                title: (checkbox: ReactNode) => <div className="flex flex-col w-full gap-4 font-semibold text-sm">
                                    <div className="flex gap-3 items-center justify-between">
                                        <span>• {subcategory.title}</span> {getStatusCount(subcategory.value)}
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        {checkbox}
                                        <div className="flex gap-2 items-center">
                                            {value.includes(subcategory.value) || value.includes(category.value)
                                                ? 'Deselect' : 'Select'} {subcategory.title}
                                        </div>
                                    </div>
                                </div>
                            }] : [],
                            ...subcategory.statuses.reduce((acc: SelectOptionProps[], status: SystemStatus) =>
                                showInactiveStatuses ||
                                summary.statuses_count?.[status.value] ||
                                value.includes(status.value) ? [
                                        ...acc,
                                        {
                                            value: status.value,
                                            parent: all.length > 1 ? subcategory.value : category.value,
                                            title: <div className="flex justify-between font-body text-gray-900 text-sm gap-4">
                                                <div className="flex gap-2 items-center">
                                                    {status.iconBadge('xs')} {status.title}
                                                </div>
                                                {getStatusCount(status.value)}
                                            </div>
                                        }
                                    ] : acc, [] as SelectOptionProps[])
                        ] : acc, [] as SelectOptionProps[])
            ] : acc, [] as SelectOptionProps[])
    ], [value, summary, showInactiveStatuses])

    return <Select
        id={id}
        name={name}
        className={className}
        data-test={dataTest}
        options={statusOptions}
        multiple
        value={value}
        onChange={handleChange}
    />
}

export default MonitoringStatusFilter
