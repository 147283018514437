import { Icon, IconProps } from './Icon'

export const IconLinkedIn = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M20.4375 0.515704H2.5625C2.15693 0.511577 1.76628 0.668509 1.47628 0.952066C1.18628 1.23562 1.02061 1.62264 1.01562 2.0282V19.9754C1.02151 20.3804 1.18758 20.7665 1.47748 21.0493C1.76739 21.3321 2.15752 21.4886 2.5625 21.4845H20.4375C20.8431 21.4877 21.2335 21.3302 21.5233 21.0465C21.8132 20.7627 21.9789 20.3758 21.9844 19.9702V2.02305C21.9771 1.61868 21.8106 1.2335 21.5209 0.951245C21.2313 0.66899 20.8419 0.512469 20.4375 0.515704Z"
            fill="#0076B2"
        />
        <path
            d="M4.11957 8.375H7.23223V18.3902H4.11957V8.375ZM5.67676 3.39063C6.03377 3.39063 6.38275 3.49651 6.67957 3.69489C6.97638 3.89327 7.20769 4.17522 7.34424 4.50509C7.48078 4.83495 7.51642 5.1979 7.44664 5.54802C7.37687 5.89814 7.20482 6.21971 6.95226 6.47203C6.69969 6.72435 6.37797 6.89609 6.02778 6.96553C5.67759 7.03497 5.31468 6.99899 4.98494 6.86213C4.65521 6.72528 4.37347 6.4937 4.17538 6.19669C3.97729 5.89969 3.87173 5.5506 3.87207 5.19359C3.87253 4.71526 4.06286 4.25667 4.40126 3.9186C4.73965 3.58052 5.19842 3.39062 5.67676 3.39063ZM9.18473 8.375H12.1685V9.75H12.2097C12.6257 8.96281 13.6397 8.13266 15.1539 8.13266C18.3061 8.12578 18.8905 10.2003 18.8905 12.8902V18.3902H15.7779V13.5175C15.7779 12.3573 15.7572 10.8637 14.1605 10.8637C12.5638 10.8637 12.2922 12.1287 12.2922 13.4419V18.3902H9.18473V8.375Z"
            fill="white"
        />
    </Icon>
