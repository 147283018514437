import { FormErrorsType, Input } from '@/components'
import { FormChangeEventHandler, JsonDataType } from '@/types'

export interface PanelFormDataType extends JsonDataType {
    panel_name: string
    num_panels: number | string
    panel_wattage: number | string
    size_kw: number | string
}

type PanelFormProps = {
    form: PanelFormDataType
    onChange: FormChangeEventHandler
    errors: FormErrorsType
    inputClassName?: string
}
export const PanelForm = ({ form, inputClassName, errors, ...props }: PanelFormProps) =>
    <>
        <Input
            id="panel-name"
            name="panel_name"
            label="Panel Model*"
            placeholder="Panel Model"
            className={inputClassName}
            onChange={props.onChange}
            value={form.panel_name}
            errors={errors.panel_name}
        />
        <Input
            id="num-panels"
            name="num_panels"
            label="Number of Panels*"
            placeholder="Number of Panels"
            className={inputClassName}
            onChange={props.onChange}
            value={form.num_panels}
            errors={errors.num_panels}
            mask={{
                numeral: true,
                numeralIntegerScale: 4,
                numeralDecimalScale: 0,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'none'
            }}
        />
        <Input
            id="panel-wattage"
            name="panel_wattage"
            label="Panel Wattage (W)*"
            placeholder="Panel Wattage (W)"
            className={inputClassName}
            onChange={props.onChange}
            value={form.panel_wattage}
            errors={errors.panel_wattage}
            mask={{
                numeral: true,
                numeralIntegerScale: 6,
                numeralDecimalScale: 4,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'none'
            }}
        />
        <Input
            id="size-kw"
            name="size_kw"
            label="System Size (kW)*"
            placeholder="System Size (kW)"
            className={inputClassName}
            onChange={props.onChange}
            value={form.size_kw}
            errors={errors.size_kw}
            mask={{
                numeral: true,
                numeralIntegerScale: 3,
                numeralDecimalScale: 4,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'none'
            }}
        />
    </>
