import { useEffect, useState } from 'react'

import { SearchableSelect, SearchableSelectProps } from '@/components'
import { Company } from '@/models'

interface CompanyFilterProps extends Omit<SearchableSelectProps, 'options'> {
    type: 'conglomerate' | 'contractor'
}

export const CompanyFilter = ({ type, ...props }: CompanyFilterProps) => {
    const [companies, setCompanies] = useState<Company[]>([])

    const fetchCompanies = async () => {
        const data = await Company.autocomplete({ type })
        setCompanies(data)
    }
    useEffect(() => {
        fetchCompanies()
    }, [])

    return <SearchableSelect
        {...props}
        options={companies.map(item => ({
            value: item.id,
            title: item.name
        }))}
    />
}
