import { MouseEvent, ReactNode } from 'react'

export interface TableParamsType {
    page?: number
    per_page?: number
    search?: string
    sort_column?: string
    descending?: boolean
}

type ReservedFields = '_page' | '_className' | '_tooltip' | '_path' | '_group' | 'onClick'

export type TableColumnType = {
    title: ReactNode
    title_raw?: string
    field: Exclude<string, ReservedFields>
    clickable?: boolean
    sticky?: boolean
    className?: string
    cellClassName?: string | ((row: TableRowType) => string)
    sortable?: boolean
    showIf?: boolean
}

export type TableRowType = {
    id: string | number
    [key: string]: ReactNode | Date | ((event: MouseEvent<HTMLElement>) => void)

    _page?: string | number
    _className?: string
    _tooltip?: ReactNode
    _group?: number
    _path?: string
    onClick?: (event: MouseEvent<HTMLElement>) => void
}

export type TableMetaType = {
    total: number
    overall_count?: number
    last_page?: number
    current_page?: number
    per_page?: number
    search?: string
    sort_column?: string
    descending?: boolean
}

export type TableOptionsType = {
    dataName?: string
    selectable?: boolean
    clickable?: true
    pagination?: boolean
    sortable?: boolean
    useQueryParams?: boolean
    infinity?: boolean
    defaultPerPage?: number
    showPerPage?: boolean
    searchPlaceholder?: string
    nextButtonText?: ReactNode
    prevButtonText?: ReactNode
    maxColumnsAmount?: number
}


export const DEFAULT_TABLE_PARAMS: TableParamsType = {
    page: 1,
    per_page: 10,
    search: '',
    sort_column: '',
    descending: false
}

export const DEFAULT_TABLE_OPTIONS: TableOptionsType = {
    dataName: 'Items',
    sortable: true,
    pagination: true,
    useQueryParams: true,
    defaultPerPage: 10,
    showPerPage: true
}
