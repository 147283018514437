import { useEffect, useMemo, useRef } from 'react'

import {
    ExportButton, ExportFileType, DownloadWarrantiesButton,
    EmailWarrantiesButton,
    WarrantiesCancelOrReinstateButton
} from '@/components'
import { useAuth } from '@/hooks'
import { Warranty } from '@/models'
import { api } from '@/services'
import { JobsBatchType, WarrantyIndexDataType, WarrantyIndexFiltersType } from '@/types'

type WarrantyIndexButtonsProps = {
    data: WarrantyIndexDataType
    filters: WarrantyIndexFiltersType
    'data-test'?: string
    onChange: () => void
}

export const WarrantyIndexButtons = ({
    data,
    filters,
    'data-test': dataTest,
    ...props
}: WarrantyIndexButtonsProps) => {
    const auth = useAuth()
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)
    const jobBatchRef = useRef<JobsBatchType | null>(null)

    const selected = useMemo(() => data.selectedRows === 'all' ? 'all' : data.selectedRows
        .map(item => data.rows.find(row => row.id === item.id) as Warranty).filter(Boolean)
    , [data.rows, data.selectedRows])
    const excluded = useMemo(() => data.excludedRows
        .map(item => data.rows.find(row => row.id === item.id) as Warranty).filter(Boolean)
    , [data.rows, data.excludedRows])

    const selectedWarrantiesCount = selected === 'all' ? data.meta.total - excluded.length : selected.length

    const handleExport = (format: ExportFileType) =>
        Warranty.export(selected === 'all'
            ? {
                format,
                select_all: true,
                excluded_ids: excluded.map(({ id }) => id),
                ...Object.keys(filters).reduce((acc, key) => ({
                    ...acc,
                    [key]: filters[key].value?.toString() === filters[key].defaultValue?.toString()
                        ? null : filters[key].value
                }), {})
            }
            : {
                format,
                ids: selected.map(({ id }) => id)
            })

    const pingJobBatch = async () => {
        if (jobBatchRef.current) {
            const { data } = await api.jobs.progress(jobBatchRef.current.id)
            if (data.percentage > jobBatchRef.current.percentage) {
                props.onChange()
            }
            if (data.percentage === 100 && intervalRef.current) {
                clearInterval(intervalRef.current)
            } else {
                jobBatchRef.current = data
            }
        }
    }

    const handlePing = (data: JobsBatchType) => {
        jobBatchRef.current = data
        intervalRef.current = setInterval(() => pingJobBatch(), 3000)
    }

    useEffect(() => () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }
    }, [])

    return <>
        {(auth.user?.role.isAdminOrStaff || auth.user?.role.isContractor) &&
            <WarrantiesCancelOrReinstateButton
                selected={selected}
                excluded={excluded}
                filters={filters}
                data={data}
                size="btn-lg"
                quantity={selectedWarrantiesCount}
                onChange={props.onChange}
                onPingJob={handlePing}
            />}

        <ExportButton
            disabled={!selected.length}
            tooltip={!selected.length ? 'Select at least one warranty to export data.' : 'Export Warranty Data'}
            data-test={dataTest}
            onExport={handleExport}
        />

        {(auth.user?.role.isAdminOrStaff || auth.user?.role.isContractor) &&
            <EmailWarrantiesButton
                quantity={selectedWarrantiesCount}
                selected={selected}
                size="btn-lg"
                data={data}
                data-test={`${dataTest}-send-button`}
            />}

        <DownloadWarrantiesButton
            quantity={selectedWarrantiesCount}
            selected={selected}
            size="btn-lg"
            excluded={excluded}
            data-test={`${dataTest}-download-button`}
        />
    </>
}
