import classnames from 'classnames'
import Litepicker from 'litepicker'
import { InputHTMLAttributes, memo, useEffect, useRef, useState } from 'react'

import {
    FormControl,
    FormControlProps,
    IconCalendar,
    IconCalendarDate,
    IconClose
} from '@/components'
import { DateRangeType, FormChangeEventHandler } from '@/types'

type LitePickerOptions = {
    singleMode?: boolean
    maxDate?: Date
    minDate?: Date
}

interface Props extends FormControlProps, Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
    value: Date | null | DateRangeType
    onChange: FormChangeEventHandler
    options?: LitePickerOptions
    clearable?: boolean
}

const DEFAULT_OPTIONS = { singleMode: true }

export const DatePicker = memo(({
    id,
    label,
    errors = [],
    warnings = [],
    hint,
    preIcon,
    postIcon,
    clearable,
    className,
    value,
    onChange,
    transparent,
    tooltip,
    'input-size': size,
    'data-test': dataTest,
    ...props
}: Props) => {
    dataTest = dataTest || id
    const inputRef = useRef(null)
    let instance: Litepicker | null = null
    const [inst, setInst] = useState<Litepicker | null>(null)
    const options = {
        ...DEFAULT_OPTIONS,
        ...props.options
    }

    const handleChange = ({ start, end }: { start: Date | null, end: Date | null }) => {
        if (options.singleMode) {
            const oldVal = value as Date | null

            if (!start) {
                onChange({
                    target: {
                        name: props.name as string,
                        value: null
                    }
                })
                return
            }

            if (!oldVal || start.toISODate() !== oldVal.toISODate()) {
                onChange({
                    target: {
                        name: props.name as string,
                        value: start,
                        displayValue: start.format()
                    }
                })
            }
        } else {
            const rangeValue = value as DateRangeType | null

            if (!start && !end) {
                onChange({
                    target: {
                        name: props.name as string,
                        value: [null, null]
                    }
                })
                return
            }

            if (
                !rangeValue ||
                !rangeValue[0] ||
                !rangeValue[1] ||
                start?.toISODate() !== rangeValue[0].toISODate() ||
                end?.toISODate() !== rangeValue[1].toISODate()
            ) {
                onChange({
                    target: {
                        name: props.name as string,
                        value: [start, end],
                        displayValue: `${start?.format()} - ${end?.format()}`
                    }
                })
            }
        }
    }

    const init = () => {
        if (!inputRef.current) return
        instance = new Litepicker({
            element: inputRef.current,
            autoApply: true,
            format: 'MMM DD, YYYY',
            dropdowns: { minYear: 2000, maxYear: 2100, months: true, years: true },
            startDate: options.singleMode
                ? (value as Date | null) || undefined
                : ((value as [Date, Date])[0] || undefined),
            endDate: options.singleMode
                ? undefined
                : ((value as [Date, Date])[1] || undefined),
            buttonText: {
                apply: 'Apply',
                cancel: 'Cancel',
                previousMonth: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m12.5 15-5-5 5-5" stroke-width="1.667" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                nextMonth: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m7.5 15 5-5-5-5" stroke-width="1.667" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                reset: 'Reset'
            },
            setup: picker => {
                picker.on('render', ui => {
                    if (!ui) return

                    const weekdaysRow = ui.querySelectorAll('.month-item-weekdays-row')
                    if (!weekdaysRow) return

                    weekdaysRow.forEach((item: HTMLDivElement) => {
                        item.querySelectorAll('div').forEach((item: HTMLDivElement, index: number) => {
                            if (item) item.innerHTML = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sat', 'Su'][index]
                        })
                    })
                })

                picker.on('selected', (start, end) => {
                    handleChange({
                        start: start ? start.toJSDate() : null,
                        end: end ? end.toJSDate() : null
                    })
                })
            },
            ...options
        })

        setInst(instance)
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (inputRef.current) {
                init()
            }
        }, 10)

        return () => {
            clearTimeout(timeout)
            instance?.destroy()
        }
    }, [])

    useEffect(() => {
        if (inst) {
            if (options.singleMode) {
                if (!value) {
                    inst.clearSelection()
                } else {
                    const singleValue = value as Date
                    const date = inst.getDate()?.toJSDate()
                    if (singleValue.toISODate() !== date?.toISODate()) {
                        inst.setDate(singleValue)
                    }
                }
            } else {
                const rangeValue = value as DateRangeType | null
                const start = inst.getStartDate()?.toJSDate()
                const end = inst.getEndDate()?.toJSDate()

                if (!rangeValue || (!rangeValue[0] && !rangeValue[1])) {
                    inst.clearSelection()
                } else if (rangeValue[0] && rangeValue[1]) {
                    if (
                        rangeValue[0].toISODate() !== start?.toISODate() ||
                        rangeValue[1].toISODate() !== end?.toISODate()
                    ) {
                        inst.setDateRange(rangeValue[0], rangeValue[1])
                    }
                }
            }
        }
    }, [value, inst, options.singleMode])

    const getPostIcon = () => {
        if (clearable) {
            const rangeValue = value as DateRangeType | null
            const isRangeSelected = rangeValue?.[0] || rangeValue?.[0]
            if (
                (options.singleMode && value) ||
                (!options.singleMode && isRangeSelected)
            ) {
                return <button
                    data-test={`${dataTest}-clear-icon`}
                    className="cursor-pointer"
                    onClick={() => {
                        inst?.clearSelection()
                        onChange({
                            target: {
                                name: props.name as string,
                                value: options.singleMode ? null : [null, null],
                                displayValue: ''
                            }
                        })
                    }}
                >
                    <IconClose className="stroke-gray-500"/>
                </button>
            }
            return postIcon
        }
        return <IconCalendarDate className="stroke-gray-500"/>
    }

    useEffect(() => {
        if (value instanceof Date) {
            onChange({
                target: {
                    name: props.name as string,
                    value,
                    displayValue: value.format()
                }
            })
        } else if (value && value[0] instanceof Date && value[1] instanceof Date) {
            onChange({
                target: {
                    name: props.name as string,
                    value,
                    displayValue: `${value[0].format()} - ${value[1].format()}`
                }
            })
        }
    }, [])

    return <FormControl
        id={id}
        label={label}
        data-test={dataTest}
        preIcon={clearable ? <IconCalendar className="stroke-gray-500"/> : preIcon}
        postIcon={getPostIcon()}
        errors={errors}
        warnings={warnings}
        input-size={size}
        transparent={transparent}
        tooltip={tooltip}
        hint={hint}
        className={classnames('form-control-datepicker', className)}
    >
        <input
            id={id}
            ref={inputRef}
            autoComplete="off"
            data-test={`${dataTest}-input`}
            {...props}
        />
    </FormControl>
})
