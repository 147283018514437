import classnames from 'classnames'
import { useMemo } from 'react'

import { TableSortIcon } from './TableSortIcon'
import { Checkbox, TableEditColumns } from '@/components'
import {
    FormChangeEventHandler,
    TableColumnType,
    TableParamsType,
    TableMetaType,
    TableOptionsType,
    TableRowType
} from '@/types'

type TheadProps = {
    id: string
    'data-test'?: string
    columns: TableColumnType[]
    allColumns: TableColumnType[]
    rows: TableRowType[]
    selectedRows: TableRowType[] | 'all'
    excludedRows: TableRowType[]
    meta?: TableMetaType
    params: TableParamsType
    activeColumns?: string[]
    options: TableOptionsType
    onSelectChange: FormChangeEventHandler
    onActiveColumnsChange: (columns: string[]) => void
    onParamsChange: (key: string, value: string | number | boolean) => void
}

const Thead = ({
    id,
    columns,
    rows,
    meta,
    selectedRows,
    excludedRows,
    allColumns,
    activeColumns,
    'data-test': dataTest,
    options,
    params,
    ...props
}: TheadProps) => {
    const handleClick = async (column: TableColumnType) => {
        if (column.sortable && options.sortable) {
            props.onParamsChange('sort_column', column.field)
            props.onParamsChange('descending', column.field === params?.sort_column ? !params.descending : false)
        }
    }

    const getSelectedCount = useMemo(() => selectedRows === 'all' && meta?.total
        ? meta.total - excludedRows.length
        : selectedRows.length
    , [selectedRows, excludedRows, meta])

    const getIsIndeterminate = useMemo(() => selectedRows === 'all'
        ? !!excludedRows.length
        : selectedRows.length !== rows.length && !!selectedRows.length
    , [selectedRows, excludedRows, rows])

    const getIsChecked = useMemo(() => selectedRows === 'all'
        ? !excludedRows.length
        : selectedRows.length === rows.length && !!selectedRows.length
    , [selectedRows, excludedRows, rows])

    return <thead>
        <tr>
            {options.selectable && <th className="w-14 pr-1.5 pl-6">
                <Checkbox
                    name="rows"
                    value="all"
                    checked={getIsChecked}
                    onChange={props.onSelectChange}
                    indeterminate={getIsIndeterminate}
                    data-test={`${dataTest}-header-checkbox`}
                />
            </th>}
            {columns
                .filter(({ showIf = true }) => showIf)
                .map((item, index) => {
                    const { sticky = true, sortable = true } = item
                    const className = classnames(item.className, { 'sticky-column': sticky })
                    const cellClassName = classnames('thead-cell !block w-full relative', item.cellClassName, {
                        sortable: options.sortable && sortable,
                        '!pl-1.5': options.selectable
                    })
                    return <th
                        key={item.field}
                        className={className}
                        data-test={`${dataTest}-header-${item.field}`}
                    >
                        <div className="flex justify-between items-center">
                            <button
                                className={cellClassName}
                                onClick={() => handleClick({ sortable, ...item })}
                            >
                                {options.selectable && !index && <div className="whitespace-nowrap">
                                    <span className="font-semibold">{getSelectedCount.format()}</span> {options.dataName} Selected
                                </div>}
                                <div className="flex items-center gap-0.5">
                                    {item.title}
                                    <TableSortIcon params={params} options={options} column={item}/>
                                </div>
                            </button>
                        </div>
                    </th>
                })}
            <th>
                <div className="flex justify-end items-center">
                    {options.maxColumnsAmount &&
                        allColumns.length > options.maxColumnsAmount &&
                        <TableEditColumns
                            key={`${id}-index-columns-edit`}
                            name={`${id}-index-columns-edit`}
                            maxAmount={options.maxColumnsAmount}
                            columns={allColumns}
                            value={activeColumns as string[]}
                            onChange={props.onActiveColumnsChange}
                            data-test={`${id}-columns-edit`}
                        />}
                </div>
            </th>
        </tr>
    </thead>
}

export default Thead
