import { useParams } from 'react-router'

import {
    Button,
    IconLightBulb05, IconLightning02, Logo
} from '@/components'
import { Warranty } from '@/models'
import { ConsumptionType, ProductionType } from '@/models/MonitoringData'

const EnergySection = ({ warranty }: { warranty: Warranty }) => {
    const { type, id } = useParams()
    const manufacturer = warranty.homeowner?.system.manufacturer

    const getEnergyLogo = () => {
        if (manufacturer?.isSolaredge) {
            return <Logo type="solarEdgeEnergy" className="max-w-[5rem]"/>
        }
        if (manufacturer?.isGenerac) {
            return <Logo type="generacEnergy" className="max-w-[5rem] mt-1 mb-3"/>
        }
        return 'kWh'
    }

    const rows = [
        {
            key: 'today',
            title: 'Today',
            showIf: true
        },
        {
            key: 'yesterday',
            title: 'Yesterday',
            showIf: manufacturer?.isGenerac || manufacturer?.isSolaredge
        },
        {
            key: 'past_seven_days',
            title: 'Past 7 Days',
            showIf: manufacturer?.isEnphase
        },
        {
            key: 'month_to_date',
            title: manufacturer?.isGenerac || manufacturer?.isSolaredge
                ? 'This Month' : 'Mo To Date',
            showIf: true
        },
        {
            key: 'last_month',
            title: 'Last Month',
            showIf: manufacturer?.isGenerac
        },
        {
            key: 'year_to_date',
            title: 'This Year',
            showIf: manufacturer?.isGenerac || manufacturer?.isSolaredge
        },
        {
            key: 'life_time',
            title: 'Lifetime',
            showIf: !manufacturer?.isGenerac
        }
    ]

    return <div>
        <h2 className="mb-6 text-base font-semibold card-title flex justify-between items-center text-gray-900">
            <span data-test="monitoring-details-energy-title">
                Energy
            </span>
            <Button className="text-sm leading-6" design="btn-secondary" href={`/${type}/${id}/monitoring/energy-graph`} data-test="monitoring-details-view-graph-button">
                View Graph
            </Button>
        </h2>

        <div className="grid grid-cols-3 gap-2">
            <div className="col-span-3">
                <p className="text-gray-500 mb-4 text-xs" data-test="monitoring-details-energy-section-last-updated-at">
                    Last updated at {warranty.homeowner?.monitoring_data?.updated_at
                        ? warranty.homeowner?.monitoring_data?.updated_at.format({
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                        })
                        : '-'}
                </p>
            </div>
            <div className="card-row mb-2 col-span-3 grid grid-cols-3">
                {getEnergyLogo()}
                <h3 className="card-row-title items-baseline flex-wrap">
                    Produced {(manufacturer?.isSolaredge || manufacturer?.isGenerac) &&
                        <span className="text-xs font-normal">(kWh)</span>}
                </h3>
                <h3 className="card-row-title items-baseline flex-wrap">
                    Consumed {(manufacturer?.isSolaredge || manufacturer?.isGenerac) &&
                        <span className="text-xs font-normal">(kWh)</span>}
                </h3>
            </div>
            {rows.map(item => item.showIf && <div key={item.key} className="contents">
                <div className="text-sm mb-2" data-test={`monitoring-details-${item.key}-title`}>
                    <div className="text-warning-700 bg-warning-50 px-2.5 py-0.5 rounded-xl font-light inline-block leading-5">
                        {item.title}
                    </div>
                </div>
                <div className="card-row mb-6" data-test={`monitoring-details-produced-${item.key}`}>
                    <p className="card-row-copy flex items-center font-semibold gap-2">
                        <IconLightning02 className="stroke-gray-900"/>
                        {warranty?.systemDetails?.energy_production_summary?.[
                            item.key as keyof ProductionType] === null
                            ? '-'
                            : ((warranty?.systemDetails?.energy_production_summary?.[
                                item.key as keyof ProductionType
                            ] as number) / 1000).format({
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                    </p>
                </div>
                <div className="card-row mb-6" data-test={`monitoring-details-consumed-${item.key}`}>
                    <p className="card-row-copy flex items-center font-semibold gap-2">
                        <IconLightBulb05 className="stroke-gray-900"/>
                        {warranty?.systemDetails?.energy_consumption_summary?.[
                            item.key as keyof ConsumptionType] === null
                            ? '-'
                            : ((warranty?.systemDetails?.energy_consumption_summary?.[
                                item.key as keyof ConsumptionType] as number) / 1000).format({
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                    </p>
                </div>
            </div>)}
            {manufacturer?.isEnphase && <div className="mt-6 flex justify-center col-span-3">
                <Logo type="poweredByEnphase" className="w-[246px]"/>
            </div>}
        </div>
    </div>
}

export default EnergySection
