import { Chart as ChartJs, ChartDataset, ChartOptions, Plugin } from 'chart.js'
import { ReactNode } from 'react'

import Chart from './Chart'

type DoughnutChartProps = {
    datasets: ChartDataset[]
    plugins?: Plugin[]
    options?: ChartOptions
    onInit?: (chart: ChartJs) => void
    customTooltip?: (index: number) => ReactNode
}

const defaultOptions = { plugins: { tooltip: { enabled: false } } }

const DoughnutChart = ({ plugins, datasets, customTooltip, options = defaultOptions, ...props }: DoughnutChartProps) =>
    <Chart
        type="doughnut"
        data={{ datasets }}
        options={options}
        plugins={plugins}
        customTooltip={customTooltip}
        onInit={props.onInit}
    />

export default DoughnutChart
