import React, { ReactNode } from 'react'

import { WarrantyRegistrationType } from './WarrantyRegistrationType'
import { IconBatteryCharging, IconDiamondBrand, IconSolarPanel, Tooltip } from '@/components'
import { IconProps, IconSize } from '@/components/icons/Icon'
import {
    ProductRateTypeEnum,
    WARRANTY_PRODUCT_TYPES,
    WarrantyProductCoverageTypeEnum,
    WarrantyProductTypeEnum
} from '@/enums'
import { WarrantyProductTypeColorType, WarrantyProductTypeDataType } from '@/types'

const COLOR_MAP = {
    orange: 'bg-orange-25 stroke-brand-orange fill-brand-orange',
    blue: 'bg-blue-25 stroke-brand-blue fill-brand-blue',
    green: 'bg-green-25 stroke-brand-green fill-brand-green',
    purple: 'bg-purple-25 stroke-brand-purple fill-brand-purple'
}

export class WarrantyProductType {
    key: WarrantyProductTypeEnum

    coverageKey: WarrantyProductCoverageTypeEnum

    title: string

    brandedTitle: ReactNode

    brandedTitleColored: ReactNode

    coverageTitle: string

    subtitle: string

    color: WarrantyProductTypeColorType

    image: ReactNode

    description: string

    hasStorage: boolean

    hasPowerProduction: boolean

    isDeprecated: boolean

    powerProductionRateKey?: ProductRateTypeEnum

    storageRateKey?: ProductRateTypeEnum

    registrationTypes: WarrantyRegistrationType[]

    IconComponent: (props: IconProps) => React.JSX.Element

    constructor(data: WarrantyProductTypeDataType) {
        this.key = data.key
        this.coverageKey = data.coverageKey
        this.title = data.title
        this.brandedTitle = data.brandedTitle
        this.brandedTitleColored = data.brandedTitleColored
        this.coverageTitle = data.coverageTitle
        this.subtitle = data.subtitle
        this.color = data.color
        this.IconComponent = data.IconComponent
        this.image = data.image
        this.description = data.description
        this.registrationTypes = data.registrationTypes.map(item => WarrantyRegistrationType.find(item))
        this.hasStorage = !!data.hasStorage
        this.hasPowerProduction = !!data.hasPowerProduction
        this.isDeprecated = !!data.isDeprecated
        this.powerProductionRateKey = data.powerProductionRateKey
        this.storageRateKey = data.storageRateKey
    }

    get shortCoverageTitle() {
        return this.coverageTitle.replace('Year', 'Yr').replace('Coverage', 'Cvg')
    }

    get isSolar() {
        return this.key === WarrantyProductTypeEnum.POWER_PRODUCTION_ONLY
    }

    get isBattery() {
        return this.key === WarrantyProductTypeEnum.SEPARATE_30_YEAR_STORAGE_ONLY
    }

    get isTotal30() {
        return this.key === WarrantyProductTypeEnum.PRODUCTION_WITH_30_YEAR_SEPARATE_STORAGE
    }

    get isLegacy20() {
        return this.key === WarrantyProductTypeEnum.PRODUCTION_WITH_20_YEAR_SEPARATE_STORAGE
    }

    static get all() {
        return WARRANTY_PRODUCT_TYPES.map(item => new WarrantyProductType(item))
    }

    static find(key: WarrantyProductTypeEnum) {
        return WarrantyProductType.all.find(item => item.key === key)
    }

    static get defaultType() {
        return WarrantyProductType.all
            .find(item => item.key === WarrantyProductTypeEnum.POWER_PRODUCTION_ONLY) as WarrantyProductType
    }

    static get solar() {
        return new WarrantyProductType(WARRANTY_PRODUCT_TYPES.find(item =>
            item.key === WarrantyProductTypeEnum.POWER_PRODUCTION_ONLY) as WarrantyProductTypeDataType)
    }

    static get battery() {
        return new WarrantyProductType(WARRANTY_PRODUCT_TYPES.find(item =>
            item.key === WarrantyProductTypeEnum.SEPARATE_30_YEAR_STORAGE_ONLY) as WarrantyProductTypeDataType)
    }

    static get total30() {
        return new WarrantyProductType(WARRANTY_PRODUCT_TYPES.find(item =>
            item.key === WarrantyProductTypeEnum.PRODUCTION_WITH_30_YEAR_SEPARATE_STORAGE) as
                WarrantyProductTypeDataType)
    }

    static get legacy20() {
        return new WarrantyProductType(WARRANTY_PRODUCT_TYPES.find(item =>
            item.key === WarrantyProductTypeEnum.PRODUCTION_WITH_20_YEAR_SEPARATE_STORAGE) as
                WarrantyProductTypeDataType)
    }

    static get PowerProductionIcon() {
        return IconSolarPanel
    }

    static get StorageIcon() {
        return IconBatteryCharging
    }

    brandIcon(size: IconSize = 'xs', tooltip = false) {
        return <Tooltip
            key={this.key}
            disabled={!tooltip}
            content={<div className="flex flex-col font-normal">
                {this.title}:
                <span className="italic">{this.subtitle}</span>
            </div>}
        >
            <IconDiamondBrand size={size} className={`${COLOR_MAP[this.color]} !bg-transparent`}/>
        </Tooltip>
    }

    coverageIcon(size: IconSize = 'xs', tooltip = false) {
        const Icon = this.IconComponent
        const SIZE_MAP = {
            xs: 'warranty-type-icon-xs',
            sm: 'warranty-type-icon-sm',
            md: 'warranty-type-icon-md',
            lg: 'warranty-type-icon-lg',
            xl: 'warranty-type-icon-xl',
            xxl: 'warranty-type-icon-xxl'
        }
        return <Tooltip
            key={this.key}
            disabled={!tooltip}
            content={this.coverageTitle}
            className={`${COLOR_MAP[this.color]} ${SIZE_MAP[size]} warranty-type-icon hover:z-10`}
        >
            <Icon size={size}/>
        </Tooltip>
    }
}
