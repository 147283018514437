import classnames from 'classnames'
import { Link } from 'react-router'

import { Checkbox, Tooltip } from '@/components'
import { FormChangeEventHandler, TableColumnType, TableOptionsType, TableRowType } from '@/types'

type TbodyProps = {
    columns: TableColumnType[]
    rows: TableRowType[]
    selectedRows: TableRowType[] | 'all'
    excludedRows: TableRowType[]
    onSelectChange: FormChangeEventHandler
    onRowClick?: (row: TableRowType) => void
    allColumns: TableColumnType[]
    'data-test'?: string
    options: TableOptionsType
}

const Tbody = ({
    columns,
    rows,
    selectedRows,
    excludedRows,
    allColumns,
    'data-test': dataTest,
    options,
    ...props
}: TbodyProps) => {
    const handleRowClick = (row: TableRowType) => {
        if (props.onRowClick) {
            props.onRowClick(row)
        }
    }

    const getIsSelected = (row: { id: string | number }) => selectedRows === 'all'
        ? excludedRows.every(item => item.id !== row.id)
        : selectedRows.some(item => item.id === row.id)

    return <tbody>
        {rows.map((row, index) =>
            <tr key={index} data-page={row._page} data-id={row.id} data-test={`${dataTest}-row-${row.id}`}>
                {options.selectable && <td data-test={`${dataTest}-row-${row.id}-checkbox`}>
                    <div className="cell !pr-1.5">
                        <Checkbox
                            name="row"
                            value={row.id}
                            checked={getIsSelected(row)}
                            onChange={props.onSelectChange}
                        />
                    </div>
                </td>}
                {columns.map(({
                    field,
                    clickable = true,
                    showIf = true,
                    sticky,
                    ...column
                }, columnsIndex) => {
                    if (!showIf) return null
                    const content = row[field as keyof typeof row]
                    if (typeof content === 'function' || content instanceof Date) {
                        return <td key={`${field}-${index}`}/>
                    }

                    const className = classnames(row._className, { 'sticky-column': sticky })
                    const cellClassName = classnames('cell',
                        typeof column.cellClassName === 'function' ? column.cellClassName(row) : column.cellClassName,
                        {
                            '!pl-1.5': options.selectable,
                            'cursor-pointer w-full h-full': row.onClick
                        })
                    const cell = clickable && (row._path || row.onClick)
                        ? (row._path && <Link
                            onClick={() => handleRowClick(row)}
                            className={cellClassName}
                            to={row._path}
                        >
                            {content}
                        </Link>) ||
                        (row.onClick && <button
                            onClick={row.onClick}
                            className={cellClassName}
                        >
                            {content}
                        </button>)
                        : <div className={cellClassName}>{content}</div>
                    return <td
                        key={`${field}-${index}`}
                        className={className}
                        data-test={`${dataTest}-row-${row.id}-${field}`}
                        colSpan={options.maxColumnsAmount &&
                            allColumns.length > options.maxColumnsAmount &&
                        columnsIndex === columns.length - 1 ? 2 : 1}
                    >
                        {row._tooltip ? <Tooltip content={row._tooltip}>{cell}</Tooltip> : cell}
                    </td>
                })}
            </tr>)}
    </tbody>
}

export default Tbody
