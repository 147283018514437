import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'

import {
    Badge,
    DatePicker, IconActivity, IconBankNote,
    IconUser01,
    Popup, Select,
    SearchableSelect,
    PageHeader
} from '@/components'
import { AuthLayout, IndexContainer } from '@/containers'
import { useIndexContainer } from '@/hooks'
import { Activity, Model } from '@/models'
import {
    ActivityIndexDataType, ActivityIndexFiltersType, DateRangeType,
    IndexContainerChangeEvent,
    IndexContainerFilterValueType
} from '@/types'

const ACTIVITY_INDEX_FILTERS: ActivityIndexFiltersType = {
    search: {
        label: 'Search',
        defaultValue: '',
        value: '',
        isStandalone: true
    },
    created_at_range: {
        label: 'Create Date',
        defaultValue: [new Date().startOf('month'), new Date().endOf('month')],
        value: [new Date().startOf('month'), new Date().endOf('month')],
        isStandalone: true,
        convert: (value: IndexContainerFilterValueType) => value
            ? (value as [string, string]).map(Model.toDate) as DateRangeType
            : [new Date().startOf('month'), new Date().endOf('month')]
    },
    causer_id: {
        label: 'User',
        defaultValue: '',
        value: '',
        isStandalone: true
    },
    action_type: {
        label: 'Action Type',
        defaultValue: '',
        value: '',
        isStandalone: true
    }
}

const ActivitiesIndex = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const {
        filters,
        handleFiltersChange
    } = useIndexContainer(ACTIVITY_INDEX_FILTERS)

    const [activity, setActivity] = useState<Activity | null>(null)
    const [data, setData] = useState<ActivityIndexDataType>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0
        },
        rows: [],
        rest: {
            users: [],
            actionTypes: []
        }
    })

    const handleChange = (e: IndexContainerChangeEvent) => {
        setData(data => ({ ...data, [e.name]: e.value }))
    }

    const fetchActivity = async () => {
        const activity = data.rows.find(item => item.id?.toString() === id?.toString())
        setActivity(activity || null)
    }
    useEffect(() => {
        fetchActivity()
    }, [id])

    const formatValue = (value?: string | number | null) => {
        if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z$/)) {
            const date = new Date(value)
            return date.format()
        }
        if (typeof value === 'object') {
            return JSON.stringify(value)
        }
        return value
    }

    const rowsMemo = useMemo(() => data.rows.map(item => ({
        id: item.id,
        'users.name': item.user_name,
        subject_type: item.subject_type,
        description: item.actionBadge,
        'activity_logs.created_at': item.created_at?.format({
            hour: '2-digit',
            minute: '2-digit'
        }),
        _path: `/activities/${item.id}${location.search}`
    })), [data.rows])

    return <AuthLayout heading={<PageHeader title="Activities"/>}>
        <IndexContainer
            id="activities"
            api={(...args) => Activity.index(...args)}
            data={data}
            onDataChange={handleChange}
            filters={filters}
            onFiltersChange={handleFiltersChange}
            tableOptions={{ dataName: 'Actions' }}
            columns={[
                { title: 'User', field: 'users.name' },
                { title: 'Type', field: 'subject_type' },
                { title: 'Action', field: 'description' },
                { title: 'Date', field: 'activity_logs.created_at' }
            ]}
            rows={rowsMemo}
            leftSide={<>
                <DatePicker
                    id="created-at-range"
                    name="created_at_range"
                    placeholder="Date range"
                    value={filters.created_at_range.value}
                    onChange={handleFiltersChange}
                    options={{ singleMode: false, maxDate: new Date() }}
                />
                <SearchableSelect
                    id="causer_id"
                    name="causer_id"
                    value={filters.causer_id.value}
                    placeholder="Filter by User"
                    options={data.rest.users.map(item => ({ value: item.id, title: item.name }))}
                    onChange={handleFiltersChange}
                />
                <Select
                    name="action_type"
                    id="activity-action-type"
                    options={[
                        { value: '', title: 'Action Type' },
                        ...data.rest.actionTypes.map(actionType => ({
                            value: actionType,
                            title: actionType.capitalize()
                        }))
                    ]}
                    className="md:ml-3"
                    value={filters.action_type.value}
                    onChange={handleFiltersChange}
                />
            </>}
        />

        {activity && <Popup open={!!activity.id} onClose={() => navigate(`/activities${location.search}`)}>
            {activity.id && <div className="flex flex-col max-w-full min-w-full sm:min-w-[39rem]">
                <h3 className="font-semibold flex gap-2 items-center">
                    <Badge className="badge-circle bg-primary-100 stroke-primary-700">
                        <IconActivity/>
                    </Badge>
                    {activity.subject_type} #{activity.subject?.id}
                </h3>
                <div className="lg:pl-12">
                    <h4 className="text-sm mt-4 font-semibold border-b border-orange-400 py-2">General</h4>
                    <p className="flex mt-2 text-sm">
                        <span className="font-semibold mr-2">User:</span>
                        <span className="text-primary-700 flex">
                            <IconUser01 className="stroke-primary-700 mr-2"/>
                            {activity.causer?.name || 'System'}
                        </span>
                    </p>
                    <p className="flex mt-2 text-sm">
                        <span className="font-semibold mr-2">{activity.subject_type}:</span>
                        <span className="text-primary-700 flex">
                            <IconBankNote className="stroke-primary-700 mr-2"/>
                            {activity.subject_type} ID: #{activity.subject?.id}
                        </span>
                    </p>
                    <p className="flex mt-2 text-sm">
                        <span className="font-semibold mr-2">Action:</span>
                        {activity.actionBadge}
                    </p>
                    <p className="flex mt-2 text-sm">
                        <span className="font-semibold mr-2">Date:</span>
                        {activity.created_at?.format()}
                    </p>

                    <div className="flex flex-col lg:flex-row gap-8 break-anywhere">
                        <div className="lg:w-1/2">
                            <h4 className="text-sm mt-4 font-semibold border-b border-orange-400 py-2">Old Attributes</h4>
                            {activity.properties?.old && Object.keys(activity.properties.old)
                                .map(key =>
                                    <p key={key} className="flex mt-2 text-sm break-anywhere">
                                        <span className="font-semibold mr-2">{key}:</span>
                                        {formatValue(activity.properties.old[key])}
                                    </p>)}
                        </div>
                        <div className="lg:w-1/2">
                            <h4 className="text-sm mt-4 font-semibold border-b border-orange-400 py-2">New Attributes</h4>
                            {activity.properties?.attributes && Object.keys(activity.properties.attributes)
                                .map(key =>
                                    <p key={key} className="flex mt-2 text-sm break-anywhere">
                                        <span className="font-semibold mr-2">{key}:</span>
                                        {formatValue(activity.properties.attributes[key] as string | number | null)}
                                    </p>)}
                        </div>
                    </div>
                </div>
            </div>}
        </Popup>}
    </AuthLayout>
}

export default ActivitiesIndex
