import { Icon, IconProps } from './Icon'

export const IconLifeBuoy = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M9.136 9.136 4.93 4.93m0 14.142 4.239-4.239m5.693.032 4.207 4.207m0-14.142-4.24 4.24M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-6 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
