import { ReactNode } from 'react'

import { Card, Table } from '@/components'
import { Device } from '@/models'

type DeviceCardProps = {
    device: Device
}

const DeviceAlertsCard = ({ device, ...props }: DeviceCardProps) =>
    <Card {...props}>
        <h3 className="flex justify-between items-center text-lg font-semibold">
            {device.category.title}
            {device.statusIcon}
        </h3>

        <Table
            options={{
                sortable: false,
                pagination: false,
                useQueryParams: false
            }}
            className="mt-5 table-secondary"
            columns={[
                {
                    field: 'attribute',
                    title: 'Attribute',
                    cellClassName: 'font-semibold !py-3 !px-1 lg:!px-4'
                },
                {
                    field: 'value',
                    title: 'Value',
                    cellClassName: '!py-3 !px-1 lg:!px-4'
                }
            ]}
            rows={device.category.attributes.map(item => ({
                id: item.key,
                attribute: item.title,
                value: device[item.key as keyof Device] as ReactNode
            }))}
        />
    </Card>

export default DeviceAlertsCard
