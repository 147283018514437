import React from 'react'

import { IconProps } from '@/components/icons/Icon'
import { WARRANTY_REGISTRATION_TYPES, WarrantyRegistrationTypeEnum } from '@/enums'
import { WarrantyRegistrationTypeDataType } from '@/types'

export class WarrantyRegistrationType {
    key: string

    path: string

    title: string

    Icon: (props: IconProps) => React.JSX.Element

    constructor(data: WarrantyRegistrationTypeDataType) {
        this.key = data.key
        this.path = data.path
        this.title = data.title
        this.Icon = data.Icon
    }

    static get all() {
        return WARRANTY_REGISTRATION_TYPES.map(item => new WarrantyRegistrationType(item))
    }

    static find(key: WarrantyRegistrationTypeEnum) {
        return WarrantyRegistrationType.all.find(item => item.key === key) ||
            WarrantyRegistrationType.defaultType
    }

    static get defaultType() {
        return WarrantyRegistrationType.all
            .find(item => item.key === WarrantyRegistrationTypeEnum.SINGLE) as WarrantyRegistrationType
    }
}
