import classnames from 'classnames'

import RouterView from './router'
import { LoadingBar, ToastNotifications } from '@/components'
import { useAuth, useReleaseCheck } from '@/hooks'

const App = () => {
    const releaseCheck = useReleaseCheck()
    const auth = useAuth()

    return <main className={classnames('main', { impersonated: auth.user?.isImpersonated })}>
        <LoadingBar/>
        <RouterView/>
        <ToastNotifications/>
        {releaseCheck}
    </main>
}

export default App
