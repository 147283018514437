import { Image } from './ui'
import logoApsSystems from '@/assets/images/logos/APSystems.png'
import logoApsSystems2x from '@/assets/images/logos/APSystems@2x.png'
import logoApsSystems3x from '@/assets/images/logos/APSystems@3x.png'
import logoEg4 from '@/assets/images/logos/EG4.png'
import logoEg42x from '@/assets/images/logos/EG4@2x.png'
import logoEg43x from '@/assets/images/logos/EG4@3x.png'
import logoEnphase from '@/assets/images/logos/Enphase.png'
import logoEnphase2x from '@/assets/images/logos/Enphase@2x.png'
import logoEnphase3x from '@/assets/images/logos/Enphase@3x.png'
import logoFortress from '@/assets/images/logos/Fortress.png'
import logoFortress2x from '@/assets/images/logos/Fortress@2x.png'
import logoFortress3x from '@/assets/images/logos/Fortress@3x.png'
import logoFoxess from '@/assets/images/logos/Foxess.png'
import logoFoxess2x from '@/assets/images/logos/Foxess@2x.png'
import logoFoxess3x from '@/assets/images/logos/Foxess@3x.png'
import logoFranklinWH from '@/assets/images/logos/FranklinWH.png'
import logoFranklinWH2x from '@/assets/images/logos/FranklinWH@2x.png'
import logoFranklinWH3x from '@/assets/images/logos/FranklinWH@3x.png'
import logoGenerac from '@/assets/images/logos/Generac.png'
import logoGenerac2x from '@/assets/images/logos/Generac@2x.png'
import logoGenerac3x from '@/assets/images/logos/Generac@3x.png'
import logoGeneracEnergy from '@/assets/images/logos/Generac_Energy.png'
import logoHoymiles from '@/assets/images/logos/Hoymiles.png'
import logoHoymiles2x from '@/assets/images/logos/Hoymiles@2x.png'
import logoHoymiles3x from '@/assets/images/logos/Hoymiles@3x.png'
import logoCompactWhite from '@/assets/images/logos/Logo-Compact-white.png'
import logoCompactWhite2x from '@/assets/images/logos/Logo-Compact-white@2x.png'
import logoCompactWhite3x from '@/assets/images/logos/Logo-Compact-white@3x.png'
import logoPrimaryDaybreakWhite from '@/assets/images/logos/Logo-Primary-daybreak-white.svg'
import logoPrimaryDaybreak from '@/assets/images/logos/Logo-Primary-daybreak.svg'
import logoSmall from '@/assets/images/logos/Logo-Small.png'
import logoSmall2x from '@/assets/images/logos/Logo-Small@2x.png'
import logoSmall3x from '@/assets/images/logos/Logo-Small@3x.png'
import logoNep from '@/assets/images/logos/NEP.png'
import logoNep2x from '@/assets/images/logos/NEP@2x.png'
import logoNep3x from '@/assets/images/logos/NEP@3x.png'
import logoPanasonic from '@/assets/images/logos/Panasonic.png'
import logoPanasonic2x from '@/assets/images/logos/Panasonic@2x.png'
import logoPanasonic3x from '@/assets/images/logos/Panasonic@3x.png'
import logoPointguard from '@/assets/images/logos/Pointguard.png'
import logoPointguard2x from '@/assets/images/logos/Pointguard@2x.png'
import logoPointguard3x from '@/assets/images/logos/Pointguard@3x.png'
import poweredByEnphase from '@/assets/images/logos/Powered_By_Enphase.png'
import poweredByEnphase2x from '@/assets/images/logos/Powered_By_Enphase@2x.png'
import poweredByEnphase3x from '@/assets/images/logos/Powered_By_Enphase@3x.png'
import logoQcells from '@/assets/images/logos/Qcells.png'
import logoQcells2x from '@/assets/images/logos/Qcells@2x.png'
import logoQcells3x from '@/assets/images/logos/Qcells@3x.png'
import logoSma from '@/assets/images/logos/SMA.png'
import logoSma2x from '@/assets/images/logos/SMA@2x.png'
import logoSma3x from '@/assets/images/logos/SMA@3x.png'
import logoSolarEdge from '@/assets/images/logos/SolarEdge.png'
import logoSolarEdge2x from '@/assets/images/logos/SolarEdge@2x.png'
import logoSolarEdge3x from '@/assets/images/logos/SolarEdge@3x.png'
import logoSolarEdgeEnergy from '@/assets/images/logos/SolarEdge_Energy.png'
import logoSolArk from '@/assets/images/logos/SolArk.png'
import logoSolArk2x from '@/assets/images/logos/SolArk@2x.png'
import logoSolArk3x from '@/assets/images/logos/SolArk@3x.png'
import logoSonen from '@/assets/images/logos/Sonnen.png'
import logoSonen2x from '@/assets/images/logos/Sonnen@2x.png'
import logoSonen3x from '@/assets/images/logos/Sonnen@3x.png'
import logoTesla from '@/assets/images/logos/Tesla.png'
import logoTesla2x from '@/assets/images/logos/Tesla@2x.png'
import logoTesla3x from '@/assets/images/logos/Tesla@3x.png'
import logoTigo from '@/assets/images/logos/Tigo.png'
import logoTigo2x from '@/assets/images/logos/Tigo@2x.png'
import logoTigo3x from '@/assets/images/logos/Tigo@3x.png'
import { InverterManufacturerEnum } from '@/enums'

const logos = {
    compactWhite: {
        src: logoCompactWhite,
        src2x: logoCompactWhite2x,
        src3x: logoCompactWhite3x
    },
    small: {
        src: logoSmall,
        src2x: logoSmall2x,
        src3x: logoSmall3x
    },
    enphase: {
        src: logoEnphase,
        src2x: logoEnphase2x,
        src3x: logoEnphase3x
    },
    generac: {
        src: logoGenerac,
        src2x: logoGenerac2x,
        src3x: logoGenerac3x
    },
    solaredge: {
        src: logoSolarEdge,
        src2x: logoSolarEdge2x,
        src3x: logoSolarEdge3x
    },
    tesla: {
        src: logoTesla,
        src2x: logoTesla2x,
        src3x: logoTesla3x
    },
    ap_systems: {
        src: logoApsSystems,
        src2x: logoApsSystems2x,
        src3x: logoApsSystems3x
    },
    sol_ark: {
        src: logoSolArk,
        src2x: logoSolArk2x,
        src3x: logoSolArk3x
    },
    hoymiles: {
        src: logoHoymiles,
        src2x: logoHoymiles2x,
        src3x: logoHoymiles3x
    },
    nep: {
        src: logoNep,
        src2x: logoNep2x,
        src3x: logoNep3x
    },
    tigo: {
        src: logoTigo,
        src2x: logoTigo2x,
        src3x: logoTigo3x
    },
    sma: {
        src: logoSma,
        src2x: logoSma2x,
        src3x: logoSma3x
    },
    franklinwh: {
        src: logoFranklinWH,
        src2x: logoFranklinWH2x,
        src3x: logoFranklinWH3x
    },
    panasonic: {
        src: logoPanasonic,
        src2x: logoPanasonic2x,
        src3x: logoPanasonic3x
    },
    sonnen: {
        src: logoSonen,
        src2x: logoSonen2x,
        src3x: logoSonen3x
    },
    poweredByEnphase: {
        src: poweredByEnphase,
        src2x: poweredByEnphase2x,
        src3x: poweredByEnphase3x
    },
    foxess: {
        src: logoFoxess,
        src2x: logoFoxess2x,
        src3x: logoFoxess3x
    },
    qcells: {
        src: logoQcells,
        src2x: logoQcells2x,
        src3x: logoQcells3x
    },
    fortress: {
        src: logoFortress,
        src2x: logoFortress2x,
        src3x: logoFortress3x
    },
    eg4: {
        src: logoEg4,
        src2x: logoEg42x,
        src3x: logoEg43x
    },
    pointguard: {
        src: logoPointguard,
        src2x: logoPointguard2x,
        src3x: logoPointguard3x
    },
    solarEdgeEnergy: { src: logoSolarEdgeEnergy },
    generacEnergy: { src: logoGeneracEnergy },
    primary: { src: logoPrimaryDaybreak },
    primaryWhite: { src: logoPrimaryDaybreakWhite }
}

type LogoProps = {
    type:
    'primary'
    | 'primaryWhite'
    | 'compactWhite'
    | 'small'
    | 'poweredByEnphase'
    | 'solarEdgeEnergy'
    | 'generacEnergy'
    | InverterManufacturerEnum
    className?: string
}

const Logo = ({ type = 'primary', ...props }: LogoProps) => <Image
    {...logos[type as keyof typeof logos]}
    {...props}
/>

export default Logo
