import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import tableEmptyStateAdd from '@/assets/images/table/tableEmptyStateAdd.svg'
import {
    MonitoringHeader,
    MonitoringReport,
    Image,
    WarrantyCreateButton,
    PageHeader,
    Tooltip,
    MonitoringStatusDropdown,
    InverterManufacturerFilter,
    ExportButton,
    ExportFileType,
    WarrantyProductTypeFilter, CompanyFilter
} from '@/components'
import { AuthLayout, IndexContainer } from '@/containers'
import { useAuth, useQuery, useAnalytics, useIndexContainer } from '@/hooks'
import { System, Warranty } from '@/models'
import {
    MonitoringSummaryDataType,
    IdType,
    TableRowType,
    IndexContainerChangeEvent,
    MonitoringIndexDataType,
    MonitoringIndexFiltersType
} from '@/types'

export const MONITORING_INDEX_FILTERS: MonitoringIndexFiltersType = {
    search: {
        label: 'Search',
        defaultValue: '',
        value: '',
        isStandalone: true
    },
    manufacturers: {
        label: 'Manufacturer',
        defaultValue: [],
        value: [],
        isStandalone: true,
        isMultiple: true
    },
    statuses: {
        label: 'Status',
        defaultValue: [],
        value: [],
        isStandalone: true,
        isMultiple: true
    },
    company_id: {
        label: 'Company',
        defaultValue: '',
        value: ''
    },
    types: {
        label: 'Warranty Type',
        defaultValue: [],
        value: [],
        isMultiple: true
    }
}

const MonitoringIndex = () => {
    const navigate = useNavigate()
    const query = useQuery()
    const auth = useAuth()
    const { trackEvent } = useAnalytics()
    const {
        filters,
        handleFiltersChange
    } = useIndexContainer(MONITORING_INDEX_FILTERS)

    const [abortController, setAbortController] = useState<AbortController | null>(null)
    const [data, setData] = useState<MonitoringIndexDataType>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0
        },
        rows: [],
        selectedRows: [],
        excludedRows: []
    })

    const [summary, setSummary] = useState<MonitoringSummaryDataType>({
        manufacturers: {},
        statuses_count: {},
        groups: { tier_1: 0, tier_2: 0, tier_3: 0, normal_sites: 0, monitored: 0 },
        sites: { total: 0 }
    })

    const handleExport = async (format: ExportFileType) => {
        trackEvent(`export_monitoring_${format}`, 'User Interaction', 'Export Systems')

        return System.export(data.selectedRows === 'all'
            ? { excluded_ids: data.excludedRows.map(({ id }) => id), select_all: true, ...query, format }
            : { ids: data.selectedRows.map(({ id }) => id), format }
        )
    }

    const handleDataChange = (e: IndexContainerChangeEvent) => {
        setData(data => ({ ...data, [e.name]: e.value }))
    }

    const fetchSummary = async () => {
        abortController?.abort('canceled')
        const controller = new AbortController
        setAbortController(controller)
        const filterParams = Object.keys(filters)
            .reduce((acc, key) => ({
                ...acc,
                [key]: filters[key].value?.toString() === filters[key].defaultValue?.toString()
                    ? null : filters[key].value
            }), {})
        const { data } = await System.summary(filterParams, { signal: controller.signal })
        setSummary(data)
    }

    useEffect(() => {
        fetchSummary()
    }, [filters])

    const rowsMemo = useMemo<TableRowType[]>(() => data.rows.map(item => ({
        id: item.warranty_id as IdType,
        status: <div className="flex items-center gap-3">
            <Tooltip content={<div className="p-1">
                <div className="flex items-start">
                    <div>
                        {item.status.iconBadge()}
                    </div>
                    <div className="flex flex-col gap-1 pl-2">
                        <span className="font-bold">{item.status.title}:</span>
                        <div className="font-normal">
                            {item.status.description}
                        </div>
                    </div>
                </div>
            </div>}>
                {item.status.iconBadge()}
            </Tooltip>
            <div className="flex flex-col">
                {item.status.title}
                {item.status_change_detected_at &&
                    <div className="text-xs font-body text-gray-500 font-normal leading-[14px]">
                        Detected {new Date(item.status_change_detected_at).format()}
                    </div>}
            </div>
        </div>,
        customer_name: item.customer_name,
        contractor: item.company?.name || '-',
        manufacturer: item.manufacturer.title,
        location: `${item.city}, ${item.state}`,
        system_size: `${Warranty.getField('size_kw').format(item.system_size)} kW`,
        pto_date: item.pto_date ? item.pto_date?.format() : '-',
        inverter_model: item.inverter_model || '-',
        plan_number: item.plan_number || '-',
        last_updated_at: item.last_updated_at?.format() || '-',
        registration_date: item.registration_date?.format() || '-',
        system_id: item.system_id || '-',
        panel_model: item.panel_model || '-',
        installation_date: item.installation_date?.format() || '-',

        type: <div className="flex items-center gap-2 text-xs font-semibold">
            {item.type.brandIcon('sm', true)}
            {item.type.title}
        </div>,
        num_panels: item.num_panels,
        battery_coverage_end_date: item.battery_coverage_end_date?.format() || '-',
        battery_make: item.battery_make,
        battery_size_kw: item.battery_size_kw
            ? `${Warranty.getField('battery_size_kw').format(item.battery_size_kw)} kW` : '-',
        detection_date: item.status_change_detected_at?.format() || '-',
        salesforce_id: item.company?.unique_id,
        _path: `/monitoring/${item.warranty_id}/monitoring`
    })), [data.rows])

    return <AuthLayout heading={<PageHeader title="Monitoring"/>}>

        <MonitoringHeader/>

        <MonitoringReport
            summary={summary}
        />

        <IndexContainer
            id="monitoring"
            tableOptions={{
                infinity: true,
                selectable: true,
                dataName: data.meta.overall_count && data.meta.overall_count !== data.meta.total
                    ? 'Sites' : 'Total Sites',
                maxColumnsAmount: 6
            }}
            data={data}
            filters={filters}
            defaultTableParams={{ descending: true }}
            onDataChange={handleDataChange}
            onFiltersClear={() => navigate('/monitoring')}
            onFiltersChange={handleFiltersChange}
            api={(...args) => System.index(...args)}
            leftSide={<>
                <InverterManufacturerFilter
                    name="manufacturers"
                    id="manufacturers"
                    value={filters.manufacturers.value}
                    onChange={handleFiltersChange}
                    summary={summary}
                    data-test="monitoring-index-manufacturers-filter"
                    className="flex-1 lg:flex-none lg:min-w-[13rem]"
                />
                <MonitoringStatusDropdown
                    name="statuses"
                    id="warranty-statuses"
                    value={filters.statuses.value}
                    onChange={handleFiltersChange}
                    summary={summary}
                    data-test="monitoring-index-statuses-filter"
                    className="flex-1 lg:flex-none lg:min-w-[13rem]"
                />
            </>}
            rightSide={<>
                <ExportButton
                    disabled={!data.selectedRows.length}
                    onExport={handleExport}
                    tooltip={!data.selectedRows.length ? 'Select at least one site to export data' : 'Export data'}
                    data-test="monitoring-index"
                />
            </>}
            popupContent={<>
                <WarrantyProductTypeFilter
                    name="types"
                    id="warranty-types"
                    value={filters.types.value}
                    summary={summary}
                    onChange={handleFiltersChange}
                    data-test="warranties-index-types-filter"
                />
                {auth.user?.role.isAdminOrStaffOrAdvisor &&
                    <CompanyFilter
                        id="company-id"
                        name="company_id"
                        type="contractor"
                        value={filters.company_id.value}
                        label="Contractor"
                        placeholder="Any Contractor"
                        onChange={handleFiltersChange}
                        data-test="monitoring-index-company-id-filter"
                        input-size="sm"
                    />}
            </>}
            placeholder={data.meta.total === 0 && !auth.user?.role.isAdvisor && <>
                <Image
                    className="w-30"
                    src={tableEmptyStateAdd}
                />
                <div className="mt-4 flex flex-col items-center">
                    <h4 className="text-center font-light">Start by Adding a New Warranty</h4>
                    <p className="text-center text-gray-500 font-light text-sm mt-1">
                        Monitoring Data for registered warranties will live here. Start registering Solar Insure
                        Warranties to see monitoring connections.
                    </p>
                    <WarrantyCreateButton className="mt-6"/>
                </div>
            </>}
            columns={[
                { title: 'System Status', field: 'status', cellClassName: '!py-3.5' },
                { title: 'Homeowner Name', field: 'customer_name' },
                { title: 'Contractor', field: 'contractor', showIf: auth.user?.role.isAdminOrStaffOrAdvisor },
                { title: 'Manufacturer', field: 'manufacturer' },
                { title: 'Location', field: 'location' },
                { title: 'System Size', field: 'system_size' },
                { title: 'PTO Date', field: 'pto_date', sortable: false },
                { title: 'Inverter Model', field: 'inverter_model' },
                { title: 'Plan ID', field: 'plan_number' },
                { title: 'Last Update Date', field: 'last_updated_at' },
                { title: 'Submission Date', field: 'registration_date' },
                { title: 'System ID', field: 'system_id' },
                { title: 'Panel Model', field: 'panel_model' },
                { title: 'Installation Date', field: 'installation_date' },

                { title: 'Warranty Type', field: 'type' },
                { title: 'Number of Panels', field: 'num_panels' },
                { title: 'Battery Coverage End Date', field: 'battery_coverage_end_date' },
                { title: 'Battery Make', field: 'battery_make' },
                { title: 'Battery Storage', field: 'battery_size_kw' },
                { title: 'Detection Date', field: 'detection_date' },
                { title: 'Contractor SalesForce Unique ID', field: 'salesforce_id', showIf: auth.user?.role.isAdminOrStaffOrAdvisor }
            ]}
            rows={rowsMemo}
        />
    </AuthLayout>
}

export default MonitoringIndex
