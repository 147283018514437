import classnames from 'classnames'

import { Tooltip } from '@/components'
import { COMPANY_ACCOUNT_ACTIVITY_STATUSES, CompanyAccountActivityStatusEnum } from '@/enums'
import { CompanyAccountActivityStatusDataType } from '@/types'

export class CompanyAccountActivityStatus {
    key: CompanyAccountActivityStatusEnum

    title: string

    className: string

    iconClassName: string

    isDisabled: boolean

    constructor(data: CompanyAccountActivityStatusDataType) {
        this.key = data.key
        this.title = data.title
        this.className = data.className
        this.iconClassName = data.iconClassName
        this.isDisabled = !!data.isDisabled
    }

    static DISABLED_STATUS_MESSAGE = 'This action is currently disabled for this account. Please reach out to' +
        ' your account manager or contact compliance@solarinsure.com for assistance.'

    static get all() {
        return COMPANY_ACCOUNT_ACTIVITY_STATUSES.map(item => new CompanyAccountActivityStatus(item))
    }

    static find(key: CompanyAccountActivityStatusEnum) {
        return CompanyAccountActivityStatus.all.find(item => item.key === key) ||
            CompanyAccountActivityStatus.defaultType
    }

    static get defaultType() {
        return CompanyAccountActivityStatus.all
            .find(item => item.key === CompanyAccountActivityStatusEnum.CERTIFICATION) as CompanyAccountActivityStatus
    }

    get icon() {
        return <div
            className={classnames('w-2 h-2 rounded-full', this.iconClassName)}
        />
    }

    badge() {
        return <Tooltip content={this.title}>
            <span className={`flex items-center badge !rounded-md ${this.className} whitespace-nowrap`}>
                {this.title}
            </span>
        </Tooltip>
    }
}
