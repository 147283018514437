import { Icon, IconProps } from './Icon'

export const IconMinimize01 = (props: IconProps) =>
    <Icon {...props}>
        <path
            d="M4 14h6m0 0v6m0-6-7 7m17-11h-6m0 0V4m0 6 7-7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
