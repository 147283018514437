import { Feature, FormErrorsType, Input } from '@/components'
import { FormChangeEventHandler, JsonDataType, IdType } from '@/types'

export interface ContactFormDataType extends JsonDataType {
    id?: IdType
    name: string
    email: string
    job_title?: string
    phone?: string

    company_id?: string | number
    role?: string
    is_new?: boolean
}

type ContactFormProps = {
    index?: number
    form: ContactFormDataType
    errors?: FormErrorsType
    onChange: FormChangeEventHandler
}

export const ContactForm = ({
    index,
    form,
    errors = {},
    ...props
}: ContactFormProps) =>
    <>
        <Input
            id={typeof index === 'undefined' ? 'name' : `name-${index}`}
            name="name"
            label="Username*"
            placeholder="Username"
            onChange={props.onChange}
            value={form.name}
            errors={errors.name}
        />
        <Input
            id={typeof index === 'undefined' ? 'email' : `email-${index}`}
            name="email"
            type="email"
            label="Email*"
            placeholder="Email"
            onChange={props.onChange}
            value={form.email}
            errors={errors.email}
        />
        <Feature name="onboarding">
            <Input
                id="job_title"
                name="job_title"
                label="Employee Role/Job Title"
                placeholder="Employee Role/Job Title"
                onChange={props.onChange}
                value={form.job_title}
                errors={errors.job_title}
            />
        </Feature>
        <Feature name="onboarding">
            <Input
                id="phone"
                name="phone"
                label="Phone Number"
                placeholder="Phone Number"
                onChange={props.onChange}
                value={form.phone}
                errors={errors.phone}
                mask={{
                    numericOnly: true,
                    blocks: [0, 3, 3, 4],
                    delimiters: ['(', ') ', '-']
                }}
            />
        </Feature>
    </>
