import { IconFile04, IconFileMultiple } from '@/components'
import { WarrantyRegistrationTypeDataType } from '@/types'

export enum WarrantyRegistrationTypeEnum {
    SINGLE = 'single',
    BULK = 'bulk'
}

export const WARRANTY_REGISTRATION_TYPES: WarrantyRegistrationTypeDataType[] = [
    {
        key: WarrantyRegistrationTypeEnum.SINGLE,
        title: 'Single Registration',
        path: '/warranties/create',
        Icon: IconFile04
    },
    {
        key: WarrantyRegistrationTypeEnum.BULK,
        title: 'Bulk Upload',
        path: '/warranties/upload',
        Icon: IconFileMultiple
    }
]
