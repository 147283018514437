import { Icon, IconProps } from './Icon'

export const IconStar08 = (props: IconProps) =>
    <Icon {...props} viewBox="0 0 40 40">
        <path
            d="m20 3.333 3.353 8.719c.47 1.222.705 1.833 1.07 2.347a5 5 0 0 0 1.178 1.177c.514.366 1.125.6 2.347 1.07L36.667 20l-8.719 3.353c-1.222.47-1.833.705-2.347 1.07a5 5 0 0 0-1.177 1.178c-.366.514-.6 1.125-1.07 2.347L20 36.667l-3.353-8.719c-.47-1.222-.705-1.833-1.07-2.347a5 5 0 0 0-1.178-1.177c-.514-.366-1.125-.6-2.347-1.07L3.333 20l8.719-3.353c1.222-.47 1.833-.705 2.347-1.07a5 5 0 0 0 1.177-1.178c.366-.514.6-1.125 1.07-2.347L20 3.333z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
